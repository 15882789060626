import React, { useEffect } from 'react';
import ApexCharts, { ApexOptions } from 'apexcharts';
import { useThemeMode } from '../../../../../../_metronic/partials';
import { getCSS, getCSSVariableValue } from '../../../../../../_metronic/assets/ts/_utils';
import { useIntl } from 'react-intl';

type Props = {
    className: string;
    serieData: number[];
    serieDates: string[];
};

const YearlyReportSellerChart: React.FC<Props> = ({ className, serieData, serieDates }) => {
    const { mode } = useThemeMode();
    //Variable para el uso de diccionario de lenguajes
    const intl = useIntl()

    console.log({serieData})
    useEffect(() => {
        const chart = refreshChart();

        return () => {
            if (chart) {
                chart.destroy();
            }
        };
    }, [mode, serieData]);

    const refreshChart = () => {
        const height = chartRef.current ? parseInt(getCSS(chartRef.current, 'height')) || 0 : 0;
        const chart = new ApexCharts(chartRef.current, getChartOptions(height, serieData, serieDates));
        chart.render();
        return chart;
    };

    const chartRef = React.useRef<HTMLDivElement>(null);

    function getChartOptions(height: number, serie_data: number[], serie_dates: string[]): ApexOptions {
        return {
            series: [
                {
                    name: "Leads",
                    data: serie_data,
                },
            ],
            chart: {
                fontFamily: 'inherit',
                type: "bar",
                height: height,
                stacked: true,
                toolbar: {
                    show: true,
                    tools: {
                        download: true,
                        selection: true,
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: true,
                        reset: true,
                    },
                    export: {
                        csv: {
                            filename: intl.formatMessage({id: 'REPORTS.YEARLY.ANNUAL_PRODUCT_REPORT'}),
                        },
                        svg: {
                            filename: intl.formatMessage({id: 'REPORTS.YEARLY.ANNUAL_PRODUCT_REPORT'}),
                        },
                        png: {
                            filename: intl.formatMessage({id: 'REPORTS.YEARLY.ANNUAL_PRODUCT_REPORT'}),
                        },
                    },
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '90%',
                    borderRadius: 5,
                },
            },
            legend: {
                show: true,
            },
            dataLabels: {
                enabled: true,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent'],
            },
            xaxis: {
                categories: serie_dates,
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
                labels: {
                    style: {
                        colors: getCSSVariableValue('--bs-gray-500'),
                        fontSize: '12px',
                    },
                },
            },
            yaxis: {
                labels: {
                    style: {
                        colors: getCSSVariableValue('--bs-gray-500'),
                        fontSize: '12px',
                    },
                },
            },
            fill: {
                opacity: 1,
            },
            states: {
                normal: {
                    filter: {
                        type: 'none',
                        value: 0,
                    },
                },
                hover: {
                    filter: {
                        type: 'none',
                        value: 0,
                    },
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: 'none',
                        value: 0,
                    },
                },
            },
            tooltip: {
                style: {
                    fontSize: '12px',
                },
                y: {
                    formatter: function (val) {
                        return 'Leads ' + val;
                    },
                },
            },
            colors: [getCSSVariableValue('--bs-primary'), getCSSVariableValue('--bs-gray-300')],
            grid: {
                borderColor: getCSSVariableValue('--bs-gray-200'),
                strokeDashArray: 4,
                yaxis: {
                    lines: {
                        show: true,
                    },
                },
            },
        };
    }
    return (
        <div className={`${className}`}>
            <div>
                <div ref={chartRef} id='kt_charts_widget_1_chart' style={{ height: '350px' }} />
            </div>
        </div>
    );
};

export { YearlyReportSellerChart };

