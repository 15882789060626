import {LoadingAnimation} from '../../../../../../utils/components/loading/LoadingAnimation'
import {Seller, Channel} from '../../interfaces/yearlyReportTypes'
import React, {FC, useState, useContext, useEffect} from 'react'
import {
  YearlyReportSeller,
  YearlyRegistersSeller,
  YearlyRegistersSellerForTable,
  YearlyRegistersSellerTotals,
  SellerFilter,
  Product,
} from '../../interfaces/yearlyReportTypes'
import {YearlyReportSellerChart} from '../../charts/YearlyReportSellerChart'
import {YearlyReportSellerTable} from '../../tables/YearlyReportSellerTable'
import {IntlShape, useIntl} from 'react-intl'

type Props = {
  productsList: Product[]
  sellersList: Seller[]
  channelsList: Channel[]
}

export const YearlySellersReport: FC<Props> = ({productsList, sellersList, channelsList}) => {
  const intl: IntlShape = useIntl()

  //Manejador del id del producto seleccionado
  const [selectedIdProduct, setSelectedIdProduct] = useState<string>('')

  //Manejador del nombre del producto seleccionado
  const [selectedNameProduct, setSelectedNameProduct] = useState<string>('')

  //Manejador del select de los años
  const [selectedYear, setSelectedYear] = useState<string>('2024')

  //Manejador del switch de duplicados
  const [duplicates, setDuplicates] = useState<boolean>(true)

  //Manejador de array con totales mensuales para la grafica
  const [monthlyTotals, setMonthlyTotals] = useState<number[]>([])

  //Manejador par almacenar la data transformada para las tabla
  const [processedDataForTable, setProcessedDataForTable] = useState<
    YearlyRegistersSellerForTable[]
  >([])

  //Manejador para el array que guarda los canales para el dropdown de canales (cambia segun el producto seleccionado)
  const [sellersListByProduct, setSellersListByProduct] = useState<SellerFilter[]>([])

  //Manejador para el valor del id y nombre de canal seleccionado en dropdown
  const [sellerIdSelected, setSellerIdSelected] = useState<string>('')
  const [sellerNameSelected, setSellerNameSelected] = useState<string>('')

  //Manejador para la data sin procesar obtenida en la peticion
  const [yearlySellerList, setYearlySellerList] = useState<YearlyReportSeller[]>([])

  //Constante para el manejo de estado de la animacion de carga
  const [isLoading, setIsLoading] = useState<boolean>(false)

  //Constante para el manejo de estado del mensaje post carga de registros
  const [infoMessageLoading, setInfoMessageLoading] = useState<string>('')

  //Funcion que procesa la datra ovtenida en peticion para la tabla
  const flattenData = (data: YearlyReportSeller[]): YearlyRegistersSellerForTable[] => {
    return data.flatMap((product) => [
      ...product.registers.map((registers) => ({
        seller: product.seller,
        sellerId: product.sellerId,
        ...registers,
        isTotal: false,
      })),
      {
        seller: product.seller,
        sellerId: product.sellerId,
        channel: 'Totales',
        channelId: '',
        isTotal: true,
        ...product.monthlyTotals,
      },
    ])
  }

  //Funcion que arma el array de objetos de vendedores que vienen en la data de la peticion
  const setSellerArray = (flattened: YearlyRegistersSellerForTable[]) => {
    let sellersMap = new Map<string, string>()

    flattened.forEach((item) => {
      sellersMap.set(item.seller, item.sellerId)
    })

    let uniqueSellers = Array.from(sellersMap, ([seller, sellerId]) => ({seller, sellerId}))

    setSellersListByProduct(uniqueSellers)

    console.log(uniqueSellers)
  }

  //Peticion principal para obtener la data de este reporte
  const getYearlySellerList = (productId: string, year?: string, duplicates?: boolean) => {
    setTimeout(() => {
      try {
        const newYearlySellerList: YearlyReportSeller[] = [
          {
            seller: 'Pedro Fuentes',
            sellerId: 'SLLR-PF-001',
            registers: [
              {
                channel: 'Facebook Ads',
                channelId: 'CH-FAD-001',
                january: 130,
                february: 404,
                march: 90,
                april: 110,
                may: 20,
                june: 60,
                july: 90,
                august: 240,
                september: 50,
                october: 120,
                november: 20,
                december: 210,
                total: 1544,
              },
              {
                channel: 'Calendly',
                channelId: 'CH-CL-002',
                january: 13,
                february: 40,
                march: 903,
                april: 100,
                may: 200,
                june: 30,
                july: 20,
                august: 210,
                september: 100,
                october: 90,
                november: 40,
                december: 20,
                total: 1766,
              },
            ],
            monthlyTotals: {
              january: 143,
              february: 444,
              march: 993,
              april: 110,
              may: 220,
              june: 90,
              july: 110,
              august: 450,
              september: 150,
              october: 210,
              november: 60,
              december: 230,
              total: 3310,
            },
          },
          {
            seller: 'Armando Piña',
            sellerId: 'SLLR-AP-002',
            registers: [
              {
                channel: 'Calendly',
                channelId: 'CH-CL-002',
                january: 3,
                february: 4,
                march: 90,
                april: 10,
                may: 20,
                june: 3,
                july: 2,
                august: 20,
                september: 10,
                october: 9,
                november: 40,
                december: 2,
                total: 213,
              },
              {
                channel: 'Facebook Ads',
                channelId: 'CH-FAD-001',
                january: 3,
                february: 4,
                march: 90,
                april: 10,
                may: 20,
                june: 3,
                july: 2,
                august: 20,
                september: 10,
                october: 9,
                november: 40,
                december: 2,
                total: 213,
              },
              {
                channel: 'Instagram Ads',
                channelId: 'CH-IAD-003',
                january: 31,
                february: 41,
                march: 91,
                april: 11,
                may: 21,
                june: 31,
                july: 21,
                august: 21,
                september: 11,
                october: 91,
                november: 41,
                december: 21,
                total: 432,
              },
            ],
            monthlyTotals: {
              january: 37,
              february: 49,
              march: 271,
              april: 31,
              may: 61,
              june: 37,
              july: 25,
              august: 61,
              september: 31,
              october: 109,
              november: 121,
              december: 25,
              total: 858,
            },
          },
          {
            seller: 'Domingo Sabatino',
            sellerId: 'SLLR-DS-003',
            registers: [
              {
                channel: 'Instagram Ads',
                channelId: 'CH-IAD-003',
                january: 31,
                february: 41,
                march: 91,
                april: 11,
                may: 21,
                june: 31,
                july: 21,
                august: 21,
                september: 11,
                october: 91,
                november: 41,
                december: 21,
                total: 432,
              },
              {
                channel: 'Facebook Ads',
                channelId: 'CH-FAD-001',
                january: 310,
                february: 40,
                march: 90,
                april: 111,
                may: 211,
                june: 313,
                july: 214,
                august: 214,
                september: 115,
                october: 916,
                november: 40,
                december: 210,
                total: 2784,
              },
            ],
            monthlyTotals: {
              january: 341,
              february: 81,
              march: 181,
              april: 122,
              may: 232,
              june: 343,
              july: 234,
              august: 234,
              september: 125,
              october: 1007,
              november: 81,
              december: 220,
              total: 3216,
            },
          },
        ]

        setYearlySellerList(newYearlySellerList)
        const flattened = flattenData(newYearlySellerList)
        setProcessedDataForTable(flattened)
        setSellerArray(flattened)
        setIsLoading(false)
      } catch (error) {
        console.log('Error obteniendo la data del reporte anual', error)
        setIsLoading(false)
        setInfoMessageLoading('Error en la peticion')
      }
    }, 3000)
  }

  console.log({sellersList})

  const handleProductChange = async (productId: string) => {
    console.log('cambio de producto')

    setIsLoading(true)
    setSellerIdSelected('')
    setSellerNameSelected('')
    setMonthlyTotals([])

    if (productId === '0') {
      setSelectedIdProduct(productId)
      setSelectedNameProduct(intl.formatMessage({id: 'REPORTS.YEARLY.ALL_PRODUCTS'}))
      getYearlySellerList(productId, selectedYear)
      return
    }

    const selectedProduct: Product | undefined = productsList.find(
      (product: Product) => product.pro_id === productId
    )

    if (selectedProduct) {
      setSelectedIdProduct(productId)
      setSelectedNameProduct(selectedProduct.name)
      getYearlySellerList(productId, selectedYear, duplicates)
    } else {
      setSelectedIdProduct('')
      setSelectedNameProduct('')
    }
  }

  const handleYearChange = async (yearValue: string) => {
    console.log('cambio de año')
    setIsLoading(true)
    setSellerIdSelected('')
    setSellerNameSelected('')
    setMonthlyTotals([])
    setSelectedYear(yearValue)
    getYearlySellerList(selectedIdProduct, yearValue, duplicates)
  }

  const handleDuplicatesChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('se cambia los duplicados')
    setIsLoading(true)
    setSellerIdSelected('')
    setSellerNameSelected('')
    setMonthlyTotals([])
    setDuplicates(event.target.checked)
    getYearlySellerList(selectedIdProduct, selectedYear, event.target.checked)
  }

  const handleSellerSelected = (e: React.ChangeEvent<HTMLSelectElement> | string) => {
    console.log('cambio de vendedor')

    let sellerValue: string

    if (typeof e === 'string') {
      sellerValue = e
      console.log('entro numero string: ', sellerValue)
      setSellerIdSelected(sellerValue)
      setSellerNameSelected(intl.formatMessage({id: 'REPORTS.YEARLY.ALL_SELLERS'}))
    } else {
      sellerValue = e.target.value
      console.log('entro numero HTML: ', sellerValue)
      setSellerIdSelected(sellerValue)
      setSellerNameSelected(e.target.options[e.target.selectedIndex].text)
    }

    if (sellerValue === '0') {
      console.log('es igual a 0')
      console.log({yearlySellerList})

      const totalesAcumulados = {
        january: 0,
        february: 0,
        march: 0,
        april: 0,
        may: 0,
        june: 0,
        july: 0,
        august: 0,
        september: 0,
        october: 0,
        november: 0,
        december: 0,
      }

      yearlySellerList.forEach((product) => {
        totalesAcumulados.january += product.monthlyTotals.january
        totalesAcumulados.february += product.monthlyTotals.february
        totalesAcumulados.march += product.monthlyTotals.march
        totalesAcumulados.april += product.monthlyTotals.april
        totalesAcumulados.may += product.monthlyTotals.may
        totalesAcumulados.june += product.monthlyTotals.june
        totalesAcumulados.july += product.monthlyTotals.july
        totalesAcumulados.august += product.monthlyTotals.august
        totalesAcumulados.september += product.monthlyTotals.september
        totalesAcumulados.october += product.monthlyTotals.october
        totalesAcumulados.november += product.monthlyTotals.november
        totalesAcumulados.december += product.monthlyTotals.december
      })

      setMonthlyTotals([
        totalesAcumulados.january,
        totalesAcumulados.february,
        totalesAcumulados.march,
        totalesAcumulados.april,
        totalesAcumulados.may,
        totalesAcumulados.june,
        totalesAcumulados.july,
        totalesAcumulados.august,
        totalesAcumulados.september,
        totalesAcumulados.october,
        totalesAcumulados.november,
        totalesAcumulados.december,
      ])
    } else {
      const findedSeller = yearlySellerList.find((product) => product.sellerId === sellerValue)

      if (findedSeller) {
        const {monthlyTotals} = findedSeller
        setMonthlyTotals([
          monthlyTotals.january,
          monthlyTotals.february,
          monthlyTotals.march,
          monthlyTotals.april,
          monthlyTotals.may,
          monthlyTotals.june,
          monthlyTotals.july,
          monthlyTotals.august,
          monthlyTotals.september,
          monthlyTotals.october,
          monthlyTotals.november,
          monthlyTotals.december,
        ])
      }

      return null
    }
  }

  useEffect(() => {
    if (yearlySellerList.length > 0) {
      handleSellerSelected('0')
    }
  }, [yearlySellerList])

  return (
    <>
      <div className='container-fluid'>
        <div className='row justify-content-left mb-4'>
          <div className='col-md-12'>
            <div className='d-flex flex-wrap flex-stack mb-6'>
              <h3 className='my-2 d-flex flex-wrap flex-stack'>
                {selectedNameProduct && selectedYear ? (
                  <div className='mb-2'>
                    {intl.formatMessage({id: 'REPORTS.YEARLY.FILTERED_BY'})}
                    <span className='ms-2 fs-3 text-gray-500 fw-bold'>
                      {selectedNameProduct} - {selectedYear}
                    </span>
                  </div>
                ) : (
                  <div className='mb-2'>
                    {intl.formatMessage({id: 'REPORTS.YEARLY.SELECT_PRODUCT'})}
                  </div>
                )}
              </h3>
              <div className='d-flex flex-wrap flex-stack align-items-center my-2'>
                <div className='w-280px me-4'>
                  <select
                    name='product'
                    data-control='selectProduct'
                    data-hidden-search='true'
                    className='form-select form-select-solid form-select-lg'
                    value={selectedIdProduct}
                    onChange={(e) => {
                      handleProductChange(e.target.value)
                    }}
                  >
                    <option value=''>
                      {' '}
                      {intl.formatMessage({id: 'REPORTS.YEARLY.SELECT_PRODUCT'})}{' '}
                    </option>
                    <option value='0'>
                      {' '}
                      {intl.formatMessage({id: 'REPORTS.YEARLY.ALL_PRODUCTS'})}{' '}
                    </option>
                    {productsList.map((product: Product) => (
                      <option key={product.pro_id} value={product.pro_id}>
                        {product.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className='w-100px me-4'>
                  <select
                    name='year'
                    data-control='selectyear'
                    data-hidden-search='true'
                    className='form-select form-select-solid form-select-lg'
                    value={selectedYear}
                    onChange={(e) => {
                      handleYearChange(e.target.value)
                    }}
                    disabled={selectedIdProduct === ''}
                  >
                    <option value=''>Año</option>
                    <option value='2024'>2024</option>
                    <option value='2023'>2023</option>
                    <option value='2022'>2022</option>
                    <option value='2021'>2021</option>
                    <option value='2020'>2020</option>
                  </select>
                </div>

                <div
                  className='form-check form-switch form-check-custom form-check-solid mb-6'
                  key='poo'
                >
                  <label className='form-check-label fs-4'>Duplicados</label>
                  &nbsp;&nbsp;&nbsp;
                  <input
                    className='form-check-input'
                    type='checkbox'
                    checked={duplicates}
                    onChange={(e) => {
                      handleDuplicatesChange(e)
                    }}
                    disabled={selectedIdProduct === ''}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row gx-2 mb-6'>
          <div className='col-xl-12'>
            <div className='card card-custom px-8 py-10 h-100'>
              <div className='row-justify-content-center mb-2'>
                <div className='col-md-12'>
                  {!selectedIdProduct && (
                    <>
                      <div
                        className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                        role='alert'
                      >
                        <h5 className='mb-1 text-center'>
                          {intl.formatMessage({id: 'REPORTS.YEARLY.SELECT_PRODUCT'})}
                        </h5>
                      </div>
                    </>
                  )}

                  {isLoading && selectedIdProduct && (
                    <div className='py-6'>
                      <LoadingAnimation
                        alignment='center'
                        label={intl.formatMessage({id: 'REPORTS.YEARLY.GENERATING_GRAPH'})}
                        size='lg'
                      />
                    </div>
                  )}

                  {!isLoading && yearlySellerList.length > 0 && selectedIdProduct && (
                    <>
                      <div className='d-flex flex-wrap flex-stack mb-4'>
                        <h5 className='card-label me-4 d-flex flex-wrap flex-stack'>
                          <span className='text-dark text-sm font-weight-bolder mt-3'>
                            {intl.formatMessage({id: 'REPORTS.YEARLY.MONTHLY_TOTAL_BY_SELLER'})}:
                          </span>
                          &nbsp;&nbsp;
                          {/* <span className="text-muted mt-1">{channelNameSelected}</span> */}
                        </h5>
                        <div className='d-flex flex-wrap flex-stack align-items-center my-2'>
                          <div className='w-250px me-3'>
                            <select
                              name='channel'
                              data-control='selectChannel'
                              data-hidden-search='true'
                              className='form-select form-select-solid form-select-lg'
                              value={sellerIdSelected}
                              onChange={(e) => {
                                handleSellerSelected(e)
                              }}
                              disabled={selectedIdProduct === '' || selectedYear === ''}
                            >
                              <option key='' value=''>
                                {intl.formatMessage({id: 'REPORTS.YEARLY.SELECT_SELLER'})}
                              </option>
                              <option key='all' value='0'>
                                {intl.formatMessage({id: 'REPORTS.YEARLY.ALL_SELLERS'})}
                              </option>
                              {sellersListByProduct.map((seller: SellerFilter) => (
                                <option key={seller.sellerId} value={seller.sellerId}>
                                  {seller.seller}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <YearlyReportSellerChart
                        className='card-xxl-stretch mb-5 mb-xl-10'
                        serieData={monthlyTotals}
                        serieDates={[
                          'Enero',
                          'Febrero',
                          'Marzo',
                          'Abril',
                          'Mayo',
                          'Junio',
                          'Julio',
                          'Agosto',
                          'Septiembre',
                          'Octubre',
                          'Noviembre',
                          'Diciembre',
                        ]}
                      />
                    </>
                  )}

                  {!isLoading && yearlySellerList.length === 0 && selectedIdProduct && (
                    <div
                      className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                      role='alert'
                    >
                      <span className='mb-1 text-center'>{infoMessageLoading}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row gx-2 mb-6'>
          <div className='col-xl-12'>
            <div className='card card-custom px-8 py-10 h-100'>
              <div className='row justify-content-center mb-2'>
                <div className='col-md-12'>
                  {!selectedIdProduct && (
                    <>
                      <div
                        className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                        role='alert'
                      >
                        <h5 className='mb-1 text-center'>
                          {intl.formatMessage({id: 'REPORTS.YEARLY.SELECT_PRODUCT'})}
                        </h5>
                      </div>
                    </>
                  )}

                  {isLoading && selectedIdProduct && (
                    <div className='py-6'>
                      <LoadingAnimation
                        alignment='center'
                        label={intl.formatMessage({id: 'REPORTS.YEARLY.GENERATING_TABLE'})}
                        size='lg'
                      />
                    </div>
                  )}

                  {!isLoading && yearlySellerList.length > 0 && selectedIdProduct && (
                    <YearlyReportSellerTable processedDataForTable={processedDataForTable} />
                  )}

                  {!isLoading && yearlySellerList.length === 0 && selectedIdProduct && (
                    <div
                      className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                      role='alert'
                    >
                      <span className='mb-1 text-center'>{infoMessageLoading}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
