import {FC, useEffect, useState, useContext} from 'react'
import {LeadSpamTable} from './tables/LeadSpamTable'
import {setPageTitle} from '../../../utils/functions/_Functions'
import {DragonCem} from '../../../utils/constants/_Constants'
import {leadSpamListByClient} from '../services/_LeadSpamApi'
import {UserContext} from '../../../app-context/user-context/UserContext'
import {sessionExpired} from '../../../utils/functions/_Functions'
import {SpamDataSimplyfied, LeadSpamData, SpamData} from '../interfaces/leadsTypes'
import {LoadingAnimation} from '../../../utils/components/loading/LoadingAnimation'
import {useIntl} from 'react-intl'

const LeadSpam: FC = () => {
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()
  //Algunas constantes desde el userContext
  const {user, token, updateToken} = useContext(UserContext)
  const clientId: string = user.cli_id
  const userToken: string = token

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [infoMessageLoading, setInfoMessageLoading] = useState<string>('')

  const [leadSpamList, setLeadSpamList] = useState<SpamDataSimplyfied[]>([])

  //Funcion para verificar el tamaño del listado de leads
  const checkLeadsSpamListLength = () => {
    leadSpamList.length === 0 && setInfoMessageLoading('No se encontro ningún lead')
  }

  const getLeadsSpamByClient = async (): Promise<void> => {
    setIsLoading(true)

    try {
      const {data: jsonData, code, msg} = await leadSpamListByClient(userToken, clientId)

      if (code === 401 && msg === 'Token no valido') {
        sessionExpired('La sesion ha terminado')
        return
      } else {
        const leadsSpamListReceived: SpamData[] = jsonData.data
        const userTokenNew: string = jsonData.token

        updateToken(userTokenNew)

        console.log({leadsSpamListReceived})

        const leadSpamRow: SpamDataSimplyfied[] = leadsSpamListReceived.flatMap(
          (group: SpamData) => {
            return group.data_spam.map((lead: LeadSpamData) => ({
              ...lead,
              pro_name: group.pro_name,
              pro_id: group.pro_id,
            }))
          }
        )

        setLeadSpamList(leadSpamRow)
        setIsLoading(false)
        checkLeadsSpamListLength()
      }
    } catch (error) {
      console.log('Error receiving leads spam list: ', error)
      setInfoMessageLoading('Ocurrio un error al obtener el listado de leads')
      setIsLoading(false)
    }
  }

  useEffect(() => {
    // Cambiar el título de la página al montar el componente
    setPageTitle(`Spam | ${DragonCem}`)
    getLeadsSpamByClient()
  }, [])

  useEffect(() => {
    console.log({leadSpamList})
  }, [leadSpamList])

  return (
    <>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='col-md-12'>
            <div className='d-flex flex-wrap flex-stack mb-6'>
              <h1 className='my-2'>SPAM</h1>
            </div>
          </div>
          <div className='row gy-12 gx-xl-12 mb-5'>
            <div className='card card-custom py-12'>
              <div className='card-header'>
                <p className='fw-semibold fs-4 text-gray-600 mb-2'>
                  {intl.formatMessage({id: 'LEADS.SPAM_DESCRIPTION'})}
                </p>
              </div>
            </div>
          </div>
          <div className='row gy-12 gx-xl-12 mb-6'>
            {/* <LeadsSpamTable /> */}
            <div className='card card-custom'>
              {isLoading && (
                <div className='py-12'>
                  <LoadingAnimation
                    alignment='center'
                    label={intl.formatMessage({id: 'LEADS.SPAM_LOADING'})}
                    size='lg'
                  />
                </div>
              )}

              {!isLoading && leadSpamList.length > 0 && (
                <LeadSpamTable
                  leadSpamList={leadSpamList}
                  getLeadsSpamByClient={getLeadsSpamByClient}
                />
              )}

              {!isLoading && leadSpamList.length === 0 && (
                <div
                  className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3 py-10'
                  role='alert'
                >
                  <div>{infoMessageLoading}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {LeadSpam}
