//historyNav

import React, {useState, useEffect, FC} from 'react'
import {HistoryLeadDetail, Lead, LeadDetailInfo} from '../../../../interfaces/leadsTypes'
import {LoadingAnimation} from '../../../../../../utils/components/loading/LoadingAnimation'
import { useIntl } from 'react-intl'

type Props = {
  leadData: LeadDetailInfo | undefined
  currentNavSelected: string
}

export const HistoryNav: FC<Props> = ({leadData, currentNavSelected}) => {
  const HistoryLeadData: HistoryLeadDetail = {
    history: [],
    historyCount: 0,
  }
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()


  const [historyLeadDetail, setHistoryLeadDetail] = useState<HistoryLeadDetail>(HistoryLeadData)

  //Constante para el manejo de estado de la animacion de carga
  const [isLoading, setIsLoading] = useState<boolean>(true)

  //Constante para el manejo de estado del mensaje post carga de registros
  const [infoMessageLoading, setInfoMessageLoading] = useState<string>('')

  //Funcion para verificar el tamaño del arreglo del historial
  const checkHistoryListLength = () => {
    //Seteamos mensaje informativo
    historyLeadDetail.history.length === 0 &&
      setInfoMessageLoading(intl.formatMessage({ id: 'LEADS.NO_HISTORY_LOG_FOUND' }))
  }

  const getHistoryData = async () => {
    //Simulación de la respuesta de la API

    setTimeout(() => {
      try {
        const historyLeadData: HistoryLeadDetail = {
          history: [
            {
              historyLeadId: '123',
              historyUserId: 'Angel Alfaro',
              historyCrud: '1111',
              historyItem: 'Se modifica la calificación del Lead.',
              historyDescMod: 'Creo que esta es la descripción',
              historyUrlMod: 'detail-lead',
              historyOldVal: '35',
              historyNewVal: '10',
              historyDateAdd: '2024-05-01 12:12:00',
              historyComments: 'No contestaba el pibe',
            },
            {
              historyLeadId: '123',
              historyUserId: 'Angel Alfaro',
              historyCrud: '1111',
              historyItem: 'Se reasigna el vendedor del Lead.',
              historyDescMod: 'Creo que esta es la descripción',
              historyUrlMod: 'detail-lead',
              historyOldVal: 'Leonardo Guzmán',
              historyNewVal: 'Edson Mendoza',
              historyDateAdd: '2024-05-01 12:13:00',
              historyComments: 'No contestaba el pibe',
            },
            {
              historyLeadId: '123',
              historyUserId: 'Angel Alfaro',
              historyCrud: '1111',
              historyItem: 'Se mueve Lead a Spam.',
              historyDescMod: 'Creo que esta es la descripción',
              historyUrlMod: 'detail-lead',
              historyOldVal: 'Status normal',
              historyNewVal: 'Status spam',
              historyDateAdd: '2024-05-01 12:11:00',
              historyComments: 'No contestaba el pibe',
            },
          ],
          historyCount: 3,
        }

        // Ordenar history por historyDateAdd de manera descendente (más reciente primero)
        historyLeadData.history.sort((a, b) => {
          return new Date(b.historyDateAdd).getTime() - new Date(a.historyDateAdd).getTime()
        })

        setHistoryLeadDetail(historyLeadData)
        setIsLoading(false)
        //Verificamos el tamaño del arreglo de whatsapps disparando la funcion
        checkHistoryListLength()
      } catch (error) {
        console.log('Error obteniedo los registros: ', error)
        //Ocultamos la animacion de carga
        setIsLoading(false)
        //Seteamos mensaje informativo
        setInfoMessageLoading(intl.formatMessage({ id: 'GLOBAL.ALERT_ERROR_AXIOS_REQUEST' }))
      }
    }, 2500)
  }

  useEffect(() => {
    getHistoryData()
  }, [])

  return (
    <>
      <div className='container ml-15 mt-4 py-12' id='history-container'>
        {isLoading && (
          <div className='py-12'>
            <LoadingAnimation
              alignment='center'
              label={intl.formatMessage({ id: 'LEADS.HISTORY_LOADING' })}
              size='lg'
            />
          </div>
        )}

        {!isLoading && historyLeadDetail.history.length > 0 && (
          <>
            <div className='mt-5'>
              {historyLeadDetail.history.map((historyItem, index) => (
                <div key={index} className='d-flex align-items-center mb-8'>
                  <div className='bullet bullet-vertical h-40px bg-warning me-3'></div>
                  <div className='flex-grow-1'>
                    <span className='text-gray-900 text-hover-primary fw-bold fs-6'>
                      Se realiza modificación por el usuario: {historyItem.historyUserId}
                    </span>
                    <span className='text-gray-700 fw-semibold d-block'>
                      {historyItem.historyItem} De {historyItem.historyOldVal} pasó a{' '}
                      {historyItem.historyNewVal}
                    </span>
                  </div>
                  <span className='badge badge-light-warning fs-8 fw-bold'>
                    {new Date(historyItem.historyDateAdd).toLocaleString('es-MX', {
                      day: '2-digit',
                      month: 'long',
                      year: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      hour12: true,
                    })}
                  </span>
                </div>
              ))}
            </div>
          </>
        )}

        {/* //Si isLoading es diferente a 'true' y la longitud del arreglo del historial es igual a '0' mostramos mensaje de error */}
        {!isLoading && historyLeadDetail.history.length === 0 && (
          <div className='py-12'>
            <p>{infoMessageLoading}</p>
          </div>
        )}
      </div>
    </>
  )
}
