import {useIntl} from 'react-intl'
import {useContext, useEffect, useState} from 'react'
import {canAccessFeature, setPageTitle} from '../../utils/functions/_Functions'
import {DragonCem} from '../../utils/constants/_Constants'
import {Modal} from 'react-bootstrap'
import {ShareAccessMainTable} from './tables/ShareAccessMainTable'
import {UserContext} from '../../app-context/user-context/UserContext'
import {DRG_STANDAR_MODULES} from '../../routing/constants/_Contstants'

const ShareAccess = () => {
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()
  //Obtener datos del context
  const {user} = useContext(UserContext)
  const userType: string = user.user_type
  return (
    // <p>Esta es la nueva pagina para share access</p>
    <>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='col-md-12'>
            <h1 className='my-2'>{intl.formatMessage({id: 'SHAREACCESS.TITLE'})}</h1>
            <div className='card card-custom'>
              {canAccessFeature(DRG_STANDAR_MODULES.BRANDS.value, userType, 2) && (
                <ShareAccessMainTable />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {ShareAccess}
