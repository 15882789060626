import {FC, useState, useContext, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {UserContext} from '../../../../app-context/user-context/UserContext'
import {ProductsForBrand} from '../../../brands/interfaces/brandsTypes'

import {SellerAddModalFormProps} from '../Interfaces/sellersTypes'
import PhoneInput from 'react-phone-number-input'
import {allowedCountries} from '../../../../utils/constants/_Constants'

// Define el componente funcional con TypeScript
const SellerAddModalForm: FC<SellerAddModalFormProps> = ({
  formData,
  setFormData,
  errors,
  brandProductsList,
}) => {
  const intl = useIntl()

  const {user} = useContext(UserContext)

  const lvlUser = user.user_type

  const [passwordError, setPasswordError] = useState('')
  const [passwordLengthError, setPasswordLengthError] = useState('')
  const [emailError, setEmailError] = useState('')

  const handlePhoneInputChange = (value: any) => {
    setFormData((prevData) => ({
      ...prevData,
      phone: value,
    }))
  }

  const handleWhatsAppInputChange = (value: any) => {
    setFormData((prevData) => ({
      ...prevData,
      whatsapp: value,
    }))
  }

  // Inicializa brandProducts como una copia de brandProductsList
  useEffect(() => {
    const updatedBrandProducts = brandProductsList.map((product) => ({...product, status: false}))
    setFormData((prevData) => ({
      ...prevData,
      brandProducts: updatedBrandProducts,
    }))
  }, [brandProductsList])

  const isEmailValid = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const {name, type, value} = e.target

    setFormData((prevData) => {
      if (type === 'checkbox') {
        const productId = String(value)

        // Actualiza el estado del producto correspondiente
        const updatedBrandProducts = prevData.brandProducts.map((product) => {
          if (product.pro_id === productId) {
            return {...product, status: !product.status} // Invierte el estado
          }
          return product
        })

        // Crea el nuevo objeto formData con la matriz de productos actualizada
        const updatedFormData = {
          ...prevData,
          brandProducts: updatedBrandProducts,
        }

        return updatedFormData
      } else {
        // Si no es un checkbox, actualiza el estado normalmente
        return {
          ...prevData,
          [name]: value,
        }
      }
    })

    // Validación de la longitud de la contraseña
    if (name === 'newpassword' || name === 'verifypassword') {
      if (value.length < 6) {
        setPasswordLengthError(intl.formatMessage({id: 'AUTH.ALERT.PASSWORD.LENGTH'}))
      } else {
        setPasswordLengthError('')
      }

      const otherPassword = name === 'newpassword' ? formData.verifypassword : formData.newpassword

      if (value !== otherPassword) {
        setPasswordError(intl.formatMessage({id: 'AUTH.ALERT.MATCHING.PASSWORD'}))
      } else {
        setPasswordError('')
      }
    }

    // Validación específica para el campo de correo electrónico
    if (name === 'email') {
      if (!isEmailValid(value)) {
        setEmailError(intl.formatMessage({id: 'AUTH.ALERT.INVALID_EMAIL'}))
      } else {
        setEmailError('')
      }
    }
  }

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='row mb-7'>
            {/* Columna 1 */}
            <div className='col-md-6'>
              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>
                  {intl.formatMessage({id: 'GLOBAL.NAME'})}
                </label>
                <input
                  placeholder={intl.formatMessage({id: 'GLOBAL.NAME'})}
                  type='text'
                  name='name'
                  value={formData.name}
                  className={`form-control form-control-lg form-control-solid ${
                    errors.SellerName && 'is-invalid'
                  }`}
                  autoComplete='off'
                  onChange={handleInputChange}
                />
                {errors.SellerName && <div className='invalid-feedback'>{errors.SellerName}</div>}
              </div>
            </div>
            {/* Columna 2 */}
            <div className='col-md-6'>
              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>
                  {intl.formatMessage({id: 'GLOBAL.EMAIL'})}
                </label>
                <input
                  placeholder={intl.formatMessage({id: 'GLOBAL.EMAIL'})}
                  type='email'
                  name='email'
                  value={formData.email}
                  className={`form-control form-control-lg form-control-solid ${
                    (errors.SellerMail || emailError) && 'is-invalid'
                  }`}
                  autoComplete='off'
                  onChange={handleInputChange}
                />
                {errors.SellerMail && <div className='invalid-feedback'>{errors.SellerMail}</div>}
                {emailError && <div className='invalid-feedback'>{emailError}</div>}
              </div>
            </div>
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='row mb-7'>
            {/* Columna 1 */}
            <div className='col-md-6'>
              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>
                  {intl.formatMessage({id: 'GLOBAL.PHONE'})}
                </label>
                <PhoneInput
                  placeholder={intl.formatMessage({id: 'GLOBAL.ENTER_PHONE'})}
                  className='mt-4 form-control input-test'
                  name='phone'
                  value={formData.phone}
                  onChange={handlePhoneInputChange}
                  countryCallingCodeEditable={false}
                  defaultCountry='MX'
                  international
                  autoComplete='off'
                  countries={allowedCountries}
                />
                {errors.SellerPhone && <div className='invalid-feedback'>{errors.SellerPhone}</div>}
              </div>
            </div>

            {/* Columna 2 */}
            <div className='col-md-6'>
              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>
                  {intl.formatMessage({id: 'GLOBAL.ENTER_WHATSAPP'})}
                </label>
                <PhoneInput
                  placeholder={intl.formatMessage({id: 'GLOBAL.ENTER_WHATSAPP'})}
                  className='mt-4 form-control input-test'
                  name='whatsapp'
                  value={formData.whatsapp}
                  onChange={handleWhatsAppInputChange}
                  countryCallingCodeEditable={false}
                  defaultCountry='MX'
                  international
                  autoComplete='off'
                  countries={allowedCountries}
                />
                {errors.SellerWhatsApp && (
                  <div className='invalid-feedback'>{errors.SellerWhatsApp}</div>
                )}
              </div>
            </div>
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='row mb-7'>
            {/* Columna 1 */}
            <div className='col-md-6'>
              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>
                  {intl.formatMessage({id: 'GLOBAL.NEW_PASSWORD'})}
                </label>
                <input
                  placeholder={intl.formatMessage({id: 'GLOBAL.NEW_PASSWORD'})}
                  type='password'
                  name='newpassword'
                  value={formData.newpassword}
                  className={`form-control form-control-lg form-control-solid ${
                    (errors.SellerPassword || passwordError) && 'is-invalid'
                  }`}
                  autoComplete='off'
                  onChange={handleInputChange}
                />
                {passwordError && <div className='invalid-feedback'>{passwordError}</div>}
                {passwordLengthError && (
                  <div className='invalid-feedback'>{passwordLengthError}</div>
                )}
                {errors.SellerPassword && (
                  <div className='invalid-feedback'>{errors.SellerPassword}</div>
                )}
              </div>
            </div>

            {/* Columna 2 */}
            <div className='col-md-6'>
              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>
                  {intl.formatMessage({id: 'GLOBAL.VERIFY_PASSWORD'})}
                </label>
                <input
                  placeholder={intl.formatMessage({id: 'GLOBAL.VERIFY_PASSWORD'})}
                  type='password'
                  name='verifypassword'
                  value={formData.verifypassword}
                  className={`form-control form-control-solid mb-3 mb-lg-0 form-control-solid ${
                    passwordError && 'is-invalid'
                  }`}
                  autoComplete='off'
                  onChange={handleInputChange}
                />
                {passwordError && <div className='invalid-feedback'>{passwordError}</div>}
              </div>
            </div>
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='row mb-7'>
            {/* Columna 1 */}
            <div className='col-md-6'>
              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>
                  {intl.formatMessage({id: 'GLOBAL.CALENDLY'})}
                </label>
                <input
                  placeholder={intl.formatMessage({id: 'GLOBAL.CALENDLY'})}
                  type='text'
                  name='calendly'
                  value={formData.calendly}
                  className={`form-control form-control-lg form-control-solid ${
                    errors.SellerCalendly && 'is-invalid'
                  }`}
                  autoComplete='off'
                  onChange={handleInputChange}
                />
                {errors.SellerCalendly && (
                  <div className='invalid-feedback'>{errors.SellerCalendly}</div>
                )}
              </div>
            </div>

            {/* Columna 2 */}
            <div className='col-md-6'>
              <div className='mb-7'>
                <label className='required fw-bold fs-6 mb-2'>
                  {intl.formatMessage({id: 'CONTROL_SELLERS.USER_TYPE'})}
                </label>
                <select
                  name='role'
                  className={`form-control form-control-lg form-control-solid ${
                    errors.SellerRole && 'is-invalid'
                  }`}
                  value={formData.role}
                  onChange={handleInputChange}
                >
                  <option value=''>
                    {intl.formatMessage({id: 'CONTROL_SELLERS.SELECT_OPTION'})}
                  </option>

                  {lvlUser === 'DEVELOPER' || lvlUser === 'SUPER_ADMIN' ? (
                    <>
                      <option value='DEVELOPER'>DEVELOPER</option>
                      <option value='SUPER__ADMIN'>SUPER__ADMIN</option>
                    </>
                  ) : null}

                  <option value='ADMIN_DC'>ADMIN_DC</option>
                </select>
                {errors.SellerRole && <div className='invalid-feedback'>{errors.SellerRole}</div>}
              </div>
            </div>
          </div>
          {/* end::Input group */}
         {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fw-bold'>
              {intl.formatMessage({id: 'CONTROL_SELLERS.PRODUCT_ASSIGNMENT'})}
            </label>

            {brandProductsList.map((item: ProductsForBrand) => (
              <div
                className='form-check form-switch form-switch-sm form-check-custom form-check-solid mb-4'
                key={item.pro_id}
              >
                <input
                  className='form-check-input'
                  type='checkbox'
                  name={`product_${item.pro_id}`}
                  checked={formData.brandProducts.some(
                    (product) => product.pro_id === item.pro_id && product.status
                  )}
                  onChange={handleInputChange}
                  value={item.pro_id}
                />

                <label className='form-check-label'>{item.name}</label>
              </div>
            ))}
          </div>

          <div>
                <label className="mb-4">
                  {intl.formatMessage({id: 'CONTROL_SELLERS.NOTIFICATIONS'})}:
                </label>
            </div>
            <div className="d-flex mb-8">
                <label className="mr-8">
                  {intl.formatMessage({id: 'CONTROL_SELLERS.CLIENT_EMAIL'})}:
                </label>&nbsp;&nbsp;&nbsp;
                <div className="form-check form-switch">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id="mail_cliente"
                        name="mail_cliente"
                        defaultChecked
                        // onChange={handleInputChange}
                    />
                                 
                </div>
               

                &nbsp;&nbsp; | &nbsp;&nbsp;
                <label className="mr-8">
                  {intl.formatMessage({id: 'CONTROL_SELLERS.CLIENT_WHATSAPP'})}:
                </label>&nbsp;&nbsp;&nbsp;
                <div className="form-check form-switch">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id="whats_client"
                        name="whats_client"
                        defaultChecked
                        // onChange={handleInputChange}

                    />
                
                </div>
            </div>

           <div id="schedules">
                    <h3 className="mt-6 mb-3">
                      {intl.formatMessage({id: 'CONTROL_SELLERS.CALL_SCHEDULE'})}
                    </h3>
                    <span className="text-muted">
                      {intl.formatMessage({id: 'CONTROL_SELLERS.SCHEDULE_DESCRIPTION'})}
                    </span>

                    <div className="d-flex mt-4">
                        <label>
                            <input type="checkbox" className="form-check-input"/> {intl.formatMessage({id: 'GLOBAL.MONDAY'})}
                        </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        <label>
                            <input type="checkbox" className="form-check-input"/> {intl.formatMessage({id: 'GLOBAL.TUESDAY'})}
                        </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        <label>
                            <input type="checkbox" className="form-check-input"/> {intl.formatMessage({id: 'GLOBAL.WEDNESDAY'})}
                        </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        <label>
                            <input type="checkbox" className="form-check-input"/> {intl.formatMessage({id: 'GLOBAL.THURSDAY'})}
                        </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        <label>
                            <input type="checkbox" className="form-check-input"/> {intl.formatMessage({id: 'GLOBAL.FRIDAY'})}
                        </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        <label>
                            <input type="checkbox" className="form-check-input"/> {intl.formatMessage({id: 'GLOBAL.SATURDAY'})}
                        </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        <label>
                            <input type="checkbox" className="form-check-input"/> {intl.formatMessage({id: 'GLOBAL.SUNDAY'})}
                        </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>

                    <div className="d-flex mt-8">
                        <div className="col-6 me-3">
                            <div>
                                <label htmlFor="startHour">
                                  {intl.formatMessage({id: 'CONTROL_SELLERS.START_TIME'})}:
                                </label>
                            </div>

                            <div>
                                <input
                                    type="time"
                                    className="form-control form-control-solid"
                                    id="startHour"
                                    name="startHour"
                                    // value={formDataManual.startHour}
                                    // onChange={handleInputChange}
                                />
                            </div>
                        </div>

                        <div className="col-6 me-3">
                            <div>
                                <label htmlFor="endHour">
                                  {intl.formatMessage({id: 'CONTROL_SELLERS.END_TIME'})}:
                                </label>
                            </div>
                        
                            <div>
                                <input
                                    type="time"
                                    className="form-control form-control-solid"
                                    id="endHour"
                                    name="endHour"
                                    // value={formDataManual.endHour}
                                    // onChange={handleInputChange}
                                />
                            </div>
                        </div>

                    </div>
                </div>

          {/* end::Scroll */}

          {/* end::Actions */}
        </div>
      </form>
    </>
  )
}

export {SellerAddModalForm}
