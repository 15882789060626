//Estructura tabla tanstack

import React, {FC, useEffect, useState} from 'react'
import {
  Column,
  Table,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  FilterFn,
  ColumnDef,
  flexRender,
} from '@tanstack/react-table'
import {RankingInfo, rankItem} from '@tanstack/match-sorter-utils'
import {Modal, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {TwlService, TwlServiceForm, TwlServiceFormErrors} from '../interfaces/TwlioServices'
import {KTIcon} from '../../../../../_metronic/helpers'
import {DropdownExportTwlServices} from '../../../../../_metronic/partials/content/dropdown/DropdownExportTwlServices'
import {TwlioServicesAddModal} from '../../modals/TwlioServicesAddModal'
import {LoadingAnimation} from '../../../../utils/components/loading/LoadingAnimation'
import {useIntl} from 'react-intl'

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>
  }
  interface FilterMeta {
    itemRank: RankingInfo
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)

  // Store the itemRank info
  addMeta({
    itemRank,
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

export const TwlioServicesMainTable: FC = () => {
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()
  //1. Definimos el nombre de la función que se va a exportar: LeadsTable | TemplatesTable CosasTable etc

  const renderTooltip = (props) => (
    <Tooltip {...props}>{intl.formatMessage({id: 'GLOBAL.COPY_RECORD_ID'})}</Tooltip>
  )
  const initialTwlServicesFormValues: TwlServiceForm = {
    friendlyName: '',
    serviceId: '',
    accTk: '',
    wabNumbers: [],
    accSid: '',
    serviceType: '',
  }

  const initialErrors: TwlServiceFormErrors = {
    friendlyName: '',
    serviceId: '',
    accTk: '',
    wabNumbers: [],
    accSid: '',
    serviceType: '',
  }

  const initialTwlServicesValue: TwlService[] = [
    {
      friendlyName: '',
      serviceId: '',
      wabNumbers: [],
      accTk: '',
      accSid: '',
      serviceType: 'leads',
    },
  ]

  const [formValues, setFormValues] = useState<TwlServiceForm>(initialTwlServicesFormValues)
  const [errors, setErrors] = useState<TwlServiceFormErrors>(initialErrors)
  const [phoneNumbersValues, setPhoneNumbersValues] = useState<string>('') // Para el input de los teléfonos
  const [errorWab, setErrorWab] = useState<string[]>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = useState('')
  const [dataToExport, setDataToExport] = useState({}) // Definimos variables para la exportación de la data a formato hoja de calculos
  const [twlioServicesList, setTwlioServicesList] = useState<TwlService[]>(initialTwlServicesValue) //2. Debemos definir un cosasList y su respectivo setCosasList, junto con su interfaz <Cosas[]>
  const [showModal, setShowModal] = useState(false)
  //Constante para el manejo de estado de la animacion de carga
  const [isLoading, setIsLoading] = useState<boolean>(true)

  //Abrir modal para crear un nuevo servicio
  const handleToOpenModalForNewService = (): void => {
    setShowModal(true)
  }

  //Cerrar modal para crear un nuevo servicio
  const handleToCloseModalForNewService = (): void => {
    setShowModal(false)
  }

  const isValidPhoneNumber = (phoneNumber: string): boolean => {
    const phoneRegex = /^\+?\d+$/
    return phoneRegex.test(phoneNumber)
  }

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target
    setPhoneNumbersValues(value)
    const numbers = value.split(',').map((num) => num.trim())
    const validNumbers = numbers.filter(isValidPhoneNumber)

    setFormValues((prevValues) => ({
      ...prevValues,
      wabNumbers: validNumbers,
    }))
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const {name, value} = e.target
    setFormValues({
      ...formValues,
      [name]: value,
    })
  }

  const validateWabNumbers = () => {
    const numbers = phoneNumbersValues.split(',').map((num) => num.trim())
    const invalidNumbers = numbers.filter((num) => !isValidPhoneNumber(num))

    if (phoneNumbersValues.trim() === '') {
      setErrorWab([intl.formatMessage({id: 'WHATSAPP.TWLIO.SERVICES_INFO_ENTER_NUMBER'})])
    } else if (invalidNumbers.length > 0) {
      setErrorWab(
        invalidNumbers.map(
          (num) =>
            `${intl.formatMessage({id: 'WHATSAPP.TWLIO.SERVICES_INFO_INVALID_NUMBERS'})}: ${num}`
        )
      )
    } else {
      setErrorWab([])
    }
  }

  const validateInputs = () => {
    validateWabNumbers()

    const newErrors: TwlServiceFormErrors = {
      friendlyName: formValues.friendlyName
        ? ''
        : intl.formatMessage({id: 'WHATSAPP.TWLIO.SERVICES_INFO_SERVICE_NAME_REQUIRED'}),
      serviceId: formValues.serviceId
        ? ''
        : intl.formatMessage({id: 'WHATSAPP.TWLIO.SERVICES_INFO_SERVICE_ID_REQUIRED'}),
      accTk: formValues.accTk
        ? ''
        : intl.formatMessage({
            id: 'WHATSAPP.TWLIO.SERVICES_INFO_SERVICE_ACCESS_TOKEN_VALUE_REQUIRED',
          }),
      accSid: formValues.accSid
        ? ''
        : intl.formatMessage({id: 'WHATSAPP.TWLIO.SERVICES_INFO_SERVICE_ACCESS_TOKEN_ID_REQUIRED'}),
      serviceType: formValues.serviceType
        ? ''
        : intl.formatMessage({id: 'WHATSAPP.TWLIO.SERVICES_INFO_SERVICE_TYPE_REQUIRED'}),
      wabNumbers: errorWab,
    }
    setErrors(newErrors)

    const isValidForm = Object.values(newErrors).every((error) => error.length === 0)
    if (isValidForm) {
      // Procesar el formulario si es válido
      console.log('Formulario válido. Datos a enviar:', formValues)
    } else {
      console.error('Formulario inválido. Errores encontrados:', newErrors)
    }
  }

  const handleCopyToClipboard = (text: string) => {
    // Intentar copiar el texto al portapapeles
    navigator.clipboard.writeText(text).catch((error) => {
      console.error('Error al copiar al portapapeles:', error)
      alert('Error al copiar al portapapeles. Por favor, inténtelo de nuevo.')
    })
  }

  //Función para definir la data que se va a exportar
  const handleSetDataToExport = () => {
    const dataToExportV = table.getRowModel().flatRows.map((row) => row.original)
    setDataToExport(dataToExportV)
    console.log(dataToExport)
  }

  // Esta es la funcion en donde haremos la peticion para consumir los registros del objeto en cuestión
  const getTwlioServicesList = () => {
    //3. Se define la función que define la data, o hará la petición para traer los registros getLeadsLit | getTemplatesList | getCosasList

    setTimeout(() => {
      setIsLoading(false)
      const newTwlioServicesData: TwlService[] = [
        {
          friendlyName: 'Nombre amigable del servicio',
          serviceId: 'ASDAS-JEW31J',
          wabNumbers: ['+5212341234', '+5211112222'],
          accTk: 'aquiriraunaccestokenbastantelargo',
          accSid: 'aquiiraunaccessidbastantelargoigual',
          serviceType: 'leads',
        },
        {
          friendlyName: 'Nombre amigable del servicio 2',
          serviceId: 'ASDAS-JEW309',
          wabNumbers: ['+5212341240', '+5211112224'],
          accTk: 'aquiriraunaccestokenbastantelarg0',
          accSid: 'aquiiraunaccessidbastantelargoigua1',
          serviceType: 'leads',
        },
      ]
      setTwlioServicesList(newTwlioServicesData) // 3.2 Tomando como referencia los puntos anteriores, se toma la variable con los datos del punto 3.1 para definir el state de la const del punto 2
    }, 2500)
  }

  //Disparamos la funcion con un useEffect
  useEffect(() => {
    getTwlioServicesList() //4. Se ejecuta la función del punto 3 cuando se renderiza el componente
  }, [])

  const columns = React.useMemo<ColumnDef<TwlService, any>[]>( //5 Se vuelve a definir la interfaz del objeto como prop de las columnas de la tabla
    () => [
      {
        header: intl.formatMessage({id: 'WHATSAPP.TWLIO.SERVICES_TABLE_TITLE'}), // 5.1 Se pone algun titulo o header a mostrar: Historial de cambios globales | Tabla de Plantillas | etc
        footer: (props) => props.column.id,
        columns: [
          {
            accessorKey: 'serviceId', //5.2 Por lo regular la primera columna es para mostrar el id del registro y que funcione con copiado y tooltip
            cell: (info) => (
              <OverlayTrigger placement='top' overlay={renderTooltip}>
                <button
                  className='btn btn-light-primary'
                  onClick={() => handleCopyToClipboard(info.getValue())}
                >
                  {info.getValue()}
                </button>
              </OverlayTrigger>
            ),
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.TABLE_COLUMN_ID'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'friendlyName',
            cell: (info) => info.getValue(),
            header: () => (
              <span>{intl.formatMessage({id: 'GLOBAL.TABLE_COLUMN_FRIENDLY_NAME'})}</span>
            ),
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'wabNumbers',
            header: intl.formatMessage({id: 'GLOBAL.TABLE_COLUMN_PHONE_NUMBERS'}),
            Cell: ({row}) => {
              // Obtener los números de teléfono como una lista separada por comas
              const phoneNumbers = row.original.wabNumbers.join(', ')
              return <span>{phoneNumbers}</span>
            },
          },
          {
            accessorKey: 'accTk',
            cell: (info) => (
              <OverlayTrigger placement='top' overlay={renderTooltip}>
                <button
                  className='btn btn-light-info'
                  onClick={() => handleCopyToClipboard(info.getValue())}
                >
                  {info.getValue()}
                </button>
              </OverlayTrigger>
            ),
            header: () => (
              <span>{intl.formatMessage({id: 'GLOBAL.TABLE_COLUMN_ACCESS_TOKEN'})}</span>
            ),
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'accSid',
            cell: (info) => (
              <OverlayTrigger placement='top' overlay={renderTooltip}>
                <button
                  className='btn btn-light-warning'
                  onClick={() => handleCopyToClipboard(info.getValue())}
                >
                  {info.getValue()}
                </button>
              </OverlayTrigger>
            ),
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.TABLE_COLUMN_ACCESS_ID'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'serviceType',
            cell: (info) => info.getValue(),
            header: () => (
              <span>{intl.formatMessage({id: 'GLOBAL.TABLE_COLUMN_SERVICE_TYPE'})}</span>
            ),
            footer: (props) => props.column.id,
          },
        ],
      },
    ],
    []
  )

  const table = useReactTable({
    data: twlioServicesList, //6. Se definen los datos finales de la tabla con el seteo del punto 3.2
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  })

  React.useEffect(() => {
    if (table.getState().columnFilters[1]?.id === 'name') {
      if (table.getState().sorting[0]?.id !== 'name' || table.getState().sorting[0]?.desc) {
        table.setSorting([{id: 'name', desc: false}])
      }
    }
  }, [table.getState().columnFilters[1]?.id])

  return (
    <>
      <div className='p-2'>
        <div className='card-header pt-6 pb-6'>
          <div className='d-flex justify-content-between'>
            <DebouncedInput //7. Filtrado global
              value={globalFilter ?? ''}
              onChange={(value) => setGlobalFilter(String(value))}
              className='form-control form-control-solid w-250px ps-14 me-3'
              placeholder={intl.formatMessage({id: 'GLOBAL.TABLE_SEARCH_RECORD'})}
            />

            <button //8. Botón que muestra el dropdown para exportar datos
              type='button'
              className='btn btn-primary d-flex align-items-center me-3'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
              onClick={handleSetDataToExport}
              disabled={isLoading}
            >
              <KTIcon iconType='outline' iconName='file-down' className='fs-2 me-2' />{' '}
              <span>{intl.formatMessage({id: 'GLOBAL.DATA_EXPORT'})}</span>
            </button>

            <DropdownExportTwlServices dataToExport={dataToExport} title={'Twlio-services'} />
          </div>
          <button
            className='btn btn-primary'
            onClick={() => handleToOpenModalForNewService()}
            disabled={isLoading}
          >
            {intl.formatMessage({id: 'WHATSAPP.TWLIO.SERVICES_ADD'})}
          </button>
        </div>
        <div className='h-2' />

        {isLoading && (
          <div className='py-12'>
            <LoadingAnimation
              alignment='center'
              label={intl.formatMessage({id: 'WHATSAPP.TWLIO.SERVICES_INFO_GET_RECORDS'})}
              size='lg'
            />
          </div>
        )}

        {!isLoading && (
          <>
            <div>
              <div className='card-body table-responsive'>
                <table className='table table-row-bordered gs-3 gy-4 gx-12'>
                  <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr
                        key={headerGroup.id}
                        className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'
                      >
                        {headerGroup.headers.map((header) => {
                          return (
                            <th key={header.id} colSpan={header.colSpan}>
                              {header.isPlaceholder ? null : (
                                <>
                                  <div
                                    {...{
                                      className: header.column.getCanSort()
                                        ? 'cursor-pointer select-none'
                                        : '',
                                      onClick: header.column.getToggleSortingHandler(),
                                    }}
                                  >
                                    {flexRender(
                                      header.column.columnDef.header,
                                      header.getContext()
                                    )}
                                    {{
                                      asc: ' 🔼',
                                      desc: ' 🔽',
                                    }[header.column.getIsSorted() as string] ?? null}
                                  </div>
                                  {header.column.getCanFilter() ? (
                                    <div>
                                      <Filter column={header.column} table={table} />
                                    </div>
                                  ) : null}
                                </>
                              )}
                            </th>
                          )
                        })}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {table.getRowModel().rows.map((row) => {
                      return (
                        <tr key={row.id}>
                          {row.getVisibleCells().map((cell) => {
                            return (
                              <td key={cell.id}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </td>
                            )
                          })}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>

              <div className='h-2' />

              <div className='card-footer'>
                <div className='d-flex justify-content-between gap-2'>
                  <div id='SwitchPages'>
                    <button
                      className='btn btn-primary border rounded p-1'
                      onClick={() => table.setPageIndex(0)}
                      disabled={!table.getCanPreviousPage()}
                    >
                      {'<<'}
                    </button>
                    <button
                      className='btn btn-primary border rounded p-1'
                      onClick={() => table.previousPage()}
                      disabled={!table.getCanPreviousPage()}
                    >
                      {'<'}
                    </button>
                    <button
                      className='btn btn-primary border rounded p-1'
                      onClick={() => table.nextPage()}
                      disabled={!table.getCanNextPage()}
                    >
                      {'>'}
                    </button>
                    <button
                      className='btn btn-primary border rounded p-1'
                      onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                      disabled={!table.getCanNextPage()}
                    >
                      {'>>'}
                    </button>
                  </div>
                  <div id='PagesFinder' className='text-center'>
                    <span className='flex items-center gap-1'>
                      {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_PAGE'})}
                      <strong>
                        &nbsp;{table.getState().pagination.pageIndex + 1}{' '}
                        {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_PAGE_OF'})}{' '}
                        {table.getPageCount()}&nbsp;
                      </strong>
                      | {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_GO_TO_PAGE'})}:
                      <input
                        type='number'
                        defaultValue={table.getState().pagination.pageIndex + 1}
                        onChange={(e) => {
                          const page = e.target.value ? Number(e.target.value) - 1 : 0
                          table.setPageIndex(page)
                        }}
                        className='form-control form-control-solid w-16'
                      />
                    </span>
                  </div>

                  <div id='showFiles' className='text-center'>
                    <select
                      className='form-select form-select-solid'
                      value={table.getState().pagination.pageSize}
                      onChange={(e) => {
                        table.setPageSize(Number(e.target.value))
                      }}
                    >
                      {[10, 20, 30, 40, 50].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_SHOW_PART_ONE'})} {pageSize}{' '}
                          {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_SHOW_PART_TWO'})}
                        </option>
                      ))}
                    </select>
                    <p>
                      {table.getPrePaginationRowModel().rows.length}{' '}
                      {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_TOTAL_RECORDS'})}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {/* Modal para agregar un servicio */}
        <Modal
          show={showModal}
          onHide={handleToCloseModalForNewService}
          scrollable
          centered
          className='modal-lg'
        >
          <Modal.Header closeButton onClick={handleToCloseModalForNewService}>
            <Modal.Title>
              <h1>{intl.formatMessage({id: 'WHATSAPP.TWLIO.SERVICES_ADD'})}</h1>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TwlioServicesAddModal
              formValues={formValues}
              setFormValues={setFormValues}
              errors={errors}
              handleInputChange={handleInputChange}
              handlePhoneNumberChange={handlePhoneNumberChange}
              phoneNumbersValues={phoneNumbersValues}
              errorWab={errorWab}
            />
          </Modal.Body>

          <Modal.Footer>
            <button
              className='btn btn-sm btn-secondary'
              onClick={() => {
                handleToCloseModalForNewService()
              }}
            >
              {intl.formatMessage({id: 'GLOBAL.CANCEL'})}
            </button>

            <button className='btn btn-sm btn-primary' type='submit' onClick={validateInputs}>
              {intl.formatMessage({id: 'GLOBAL.SAVE'})}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  )
}

function Filter({column, table}: {column: Column<any, unknown>; table: Table<any>}) {
  const intl = useIntl()
  //1. Definimos el nombre de la función que se va a exportar: LeadsTable | TemplatesTable CosasTable etc

  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id)

  const columnFilterValue = column.getFilterValue()

  const sortedUniqueValues = React.useMemo(
    () =>
      typeof firstValue === 'number'
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  )

  return typeof firstValue === 'number' ? (
    <div>
      <div className='flex space-x-2'>
        <DebouncedInput
          type='number'
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[0] ?? ''}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [value, old?.[1]])}
          placeholder={`Min ${
            column.getFacetedMinMaxValues()?.[0] ? `(${column.getFacetedMinMaxValues()?.[0]})` : ''
          }`}
          className='w-24 border shadow rounded'
        />
        <DebouncedInput
          type='number'
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[1] ?? ''}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [old?.[0], value])}
          placeholder={`Max ${
            column.getFacetedMinMaxValues()?.[1] ? `(${column.getFacetedMinMaxValues()?.[1]})` : ''
          }`}
          className='w-24 border shadow rounded'
        />
      </div>
      <div className='h-1' />
    </div>
  ) : (
    <>
      <datalist id={column.id + 'list'}>
        {sortedUniqueValues.slice(0, 5000).map((value: any) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <DebouncedInput
        type='text'
        value={(columnFilterValue ?? '') as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`${intl.formatMessage({id: 'GLOBAL.TABLE_SEARCH_THREE_DOTS'})} (${column.getFacetedUniqueValues().size})`}
        className='w-36 border shadow rounded'
        list={column.id + 'list'}
      />
      <div className='h-1' />
    </>
  )
}

// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return <input {...props} value={value} onChange={(e) => setValue(e.target.value)} />
}
