import { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { sessionExpired } from '../../../utils/functions/_Functions';
import { UserContext } from '../../../app-context/user-context/UserContext';
import { sellersListByClient } from '../../control-sellers/sellers/services/_SellersListApi';
import { GroupsListByClient } from '../../control-sellers/sellers-groups/services/_GroupSellersListApi';
import { UserForAssign } from '../interfaces/rolesTypes';
import { Modal } from 'react-bootstrap';
import { KTIcon } from '../../../../_metronic/helpers';
import { rolesStatusFalse, rolesStatusTrue } from '../services/_RolesStatusApi';
import { AlertInfo } from '../../../utils/interfaces/_InterfacesTypes';

function ModalEditRol({ rolData, rolDataEdit, setRolDataEdit}) {
    const groupsAssociated = rolData.assign.groupsAssociated;
    const usersAssociated = rolData.assign.usersAssociated;
    const rolId = rolData.assign.role_id;

    const [alertInfo, setAlertInfo] = useState<AlertInfo>({
        text: '',
        variant: 'primary',
      })

    console.log(groupsAssociated)

    const { user, token, updateToken } = useContext(UserContext);
    const clientId = user.cli_id;
    const userToken = token;

    const intl = useIntl();

    const [usersOptions, setUsersOptions] = useState<UserForAssign[]>([]);
    const [groupsOptions, setGroupsOptions] = useState<UserForAssign[]>([]);
    const [users, setUsers] = useState<string[]>(usersAssociated.map(user => user.usrId));
    const [groups, setGroups] = useState<string[]>(groupsAssociated.map(user => user.groupId));
    const [rolName, setRolName] = useState<string>(rolData.assign.role_name);
    const [rolStatus, setRolStatus] = useState<boolean>(rolData.assign.status);
    const [showModalStatus, setShowModalStatus] = useState(false)

    const closeModalStatus = () => {
        setShowModalStatus(false);
        setRolStatus(!rolStatus)
        setAlertInfo({
            text: '',
            variant: '',
        });
      }

      const closeModalStatusSuccesfully = () => {
        setShowModalStatus(false);
        setAlertInfo({
            text: '',
            variant: '',
        });
      }

      useEffect(() => {
        const fetchData = async () => {
            try {
                let dataReceived, userTokenNew;
                if (groupsAssociated.length === 0) {
                    dataReceived = await sellersListByClient(userToken, clientId);
                    userTokenNew = dataReceived.token;
                    const sellersListReceived = dataReceived.data;
                    const mappedSellers = sellersListReceived.map((key) => ({
                        id: key.uid,
                        name: key.usrData.usr_name
                    }));
                    setUsersOptions(mappedSellers);
                    setRolDataEdit(prevRolDataEdit => ({
                        ...prevRolDataEdit,
                        updatedUsers: users,
                    }));
                    // console.log('hola')
                    updateToken(userTokenNew);
                } else {
                    dataReceived = await GroupsListByClient(userToken, clientId);
                    userTokenNew = dataReceived.token;
                    const groupsListReceived = dataReceived.data;
                    const mappedGroups = groupsListReceived.map((key) => ({
                        id: key.group_id,
                        name: key.friendly_name
                    }));
                    setGroupsOptions(mappedGroups);
                    setRolDataEdit(prevRolDataEdit => ({
                        ...prevRolDataEdit,
                        updatedGroups: groups,
                    }));
                }
                // console.log('adios')
                updateToken(userTokenNew);
            } catch (error) {
                console.error('Error fetching data:', error);
                sessionExpired(intl.formatMessage({ id: "GLOBAL.ALERT.SESSION_EXPIRED" }));
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
    
                if (groupsAssociated.length === 0) {
                    setRolDataEdit(prevRolDataEdit => ({
                        ...prevRolDataEdit,
                        updatedUsers: users,
                    }));
                } else {
                    setRolDataEdit(prevRolDataEdit => ({
                        ...prevRolDataEdit,
                        updatedGroups: groups,
                    }));
                }
    }, [groupsAssociated, groups, users])

    const handleUserSwitchChange = (userId: string, checked: boolean) => {
        if (!rolStatus) return;
        setUsers(prevUsers => {
            const updatedUsers = checked ? [...prevUsers, userId] : prevUsers.filter(id => id !== userId);
            // Aquí se establece groups a un arreglo vacío
            setGroups([]);
            return updatedUsers;
        });
    };
    
    const handleGroupSwitchChange = (groupId: string, checked: boolean) => {
        if (!rolStatus) return;
        setGroups(prevGroups => {
            const updatedGroups = checked ? [...prevGroups, groupId] : prevGroups.filter(id => id !== groupId);
            // Aquí se establece users a un arreglo vacío
            setUsers([]);
            return updatedGroups;
        });
    };

    const handleSwitchChange = () => {
        setShowModalStatus(true)
        setRolStatus(!rolStatus);
      };

      const confirmStatusChange = async () => {
        if(rolStatus === false) {
            try {
                const {
                  data: jsonData,
                  code,
                  msg,
                } = await rolesStatusFalse(userToken, rolId)

               console.log(jsonData)
          
                if (code === 401 && msg === 'Token no valido' ) {
                  sessionExpired(intl.formatMessage({id: 'GLOBAL.ALERT.SESSION_EXPIRED'}))
                } 
                else if (code === 400 && msg === 'El rol que quieres desactivar, tiene campañas asignadas:') {
                    const campaigns = jsonData.data;
                    const campaignNames = campaigns.map(campaign => campaign.name);
                    const campaignNamesString = campaignNames.join(', ');
                    setAlertInfo({
                        text: intl.formatMessage(
                            {id: 'ASSIGNS.DEACTIVATE_ROLE_WITH_CAMPAIGNS'},
                            {campaignNames: campaignNamesString}
                        ), 
                        variant: 'danger'
                      })
                  } else {
                    console.log(code)
                    setAlertInfo({
                        text: code === 200 ? intl.formatMessage({id: 'ASSIGNS.STATUS_EDITED_SUCCESS'}) : intl.formatMessage({id: 'GLOBAL.ALERT_SOMETHING_WENT_WRONG'}),
                        variant: code === 200 ? 'success' : 'danger',
                      })
                      if (code === 200) {
                        setTimeout(() => {
                            setAlertInfo({
                                text: '',
                                variant: '',
                            });
                            closeModalStatusSuccesfully();
                        }, 3000);
                    }
                  }    
              } catch (error) {
                console.log()
                setAlertInfo({
                  text: intl.formatMessage({id: 'GLOBAL.ALERT_ERROR_AXIOS_REQUEST'}),
                  variant: 'danger',
                })
              }
        } else {
            console.log('El status estaba false')
            try {
                const {
                  data: jsonData,
                  code,
                  msg,
                } = await rolesStatusTrue(userToken, rolId)
          
                if (code === 401 && msg === 'Token no valido' ) {
                  sessionExpired(intl.formatMessage({id: 'GLOBAL.ALERT.SESSION_EXPIRED'}))
                } 
                else if (code === 400 && msg === 'El rol que quieres desactivar, tiene campañas asignadas:') {
                    const campaigns = jsonData.data;
               const campaignNames = campaigns.map(campaign => campaign.cam_id);
               const campaignNamesString = campaignNames.join(', ');
                    setAlertInfo({
                        text: intl.formatMessage(
                            {id: 'ASSIGNS.DEACTIVATE_ROLE_WITH_CAMPAIGNS'},
                            {campaignNames: campaignNamesString}
                        ),
                        variant: 'danger'
                      })
                  } else {
                    setAlertInfo({
                        text: code === 200 ? intl.formatMessage({id: 'ASSIGNS.STATUS_EDITED_SUCCESS'}) : intl.formatMessage({id: 'GLOBAL.ALERT_SOMETHING_WENT_WRONG'}),
                        variant: code === 200 ? 'success' : 'danger',
                      })
                      if (code === 200) {
                        setTimeout(() => {
                            setAlertInfo({
                                text: '',
                                variant: '',
                            });
                            closeModalStatusSuccesfully();
                        }, 3000);
                    }
                  }    
              } catch (error) {
                setAlertInfo({
                  text: intl.formatMessage({id: 'GLOBAL.ALERT_ERROR_AXIOS_REQUEST'}),
                  variant: 'danger',
                })
              }
        }
        
      }

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newName = event.target.value; 
        setRolName(newName); 
    
        setRolDataEdit(prevRolDataEdit => ({
            ...prevRolDataEdit,
            rolName: newName, 
        }));
    };

    return (
        
        <div className='mb-5'>
            
                <input
                type='text'
                name='rolName'
                value={rolName}
                onChange={handleNameChange}
                placeholder={intl.formatMessage({id: 'ASSIGNS.ENTER_NAME'})}
                className='form-control form-control-solid mb-5 mb-lg-0'
                />
                <label className='fw-bold fs-6 mb-2 mt-2'>{intl.formatMessage({id: 'ASSIGNS.STATUS'})}</label>
                <div className='form-check form-switch form-check-custom form-check-solid mb-5'>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                checked={rolStatus}
                                onChange={handleSwitchChange} 
                            />
                </div>
                <Modal show={showModalStatus} className='modal-lg'>
                <div className="modal-content border border-warning">
      <Modal.Header>
      <Modal.Title>
                      <h1>{intl.formatMessage({id: 'ASSIGNS.CONFIRM_STATUS'})}</h1>
                    </Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <div className='alert alert-primary d-flex align-items-center' role='danger'>
            <KTIcon iconName='information-4' className='fs-4x' />
            <div className='ml-6'>
            {intl.formatMessage({id: 'ASSIGNS.STATUS_CHANGE_WARNING'})}
            </div>
          </div>
      </Modal.Body>
      {alertInfo.text && (
                    <div className={`alert alert-${alertInfo.variant} align-items-center mt-3 mx-10 text-center`} role='alert'>
                      <div>{alertInfo.text}</div>
                    </div>
                  )}
      <Modal.Footer>
        <button className='btn btn-sm btn-primary' onClick={confirmStatusChange} disabled={alertInfo.variant === 'danger'}>
          {intl.formatMessage({id: 'GLOBAL.CONFIRM'})}
        </button>
        <button className='btn btn-sm btn-secondary' onClick={closeModalStatus}>
          {intl.formatMessage({id: 'GLOBAL.CANCEL'})}
        </button>
      </Modal.Footer>
      </div>
    </Modal>
                
            {/* Renderizar input de rol y otros elementos según sea necesario */}
            {groupsAssociated.length === 0 ? (
                <div className='mb-5'>
                    <p>{intl.formatMessage({id: 'ASSIGNS.CHOOSE_SELLERS'})}:</p>
                    {usersOptions.map((user) => (
                        <div key={user.id} className='form-check form-switch form-check-custom form-check-solid'>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                id={`userSwitch${user.id}`}
                                value={user.id}
                                checked={users.includes(user.id)}
                                onChange={(e) => handleUserSwitchChange(user.id, e.target.checked)}
                                disabled={!rolStatus} 
                            />
                            <label className='form-check-label' htmlFor={`userSwitch${user.id}`}>
                                {user.name}
                            </label>
                        </div>
                    ))}

                </div>
            ) : (
                <div className='mb-5'>
                    <p>{intl.formatMessage({id: 'ASSIGNS.CHOOSE_GROUPS'})}:</p>
                    {groupsOptions.map((group) => (
                        <div key={group.id} className='form-check form-switch form-check-custom form-check-solid'>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                id={`groupSwitch${group.id}`}
                                value={group.id}
                                checked={groups.includes(group.id)}
                                onChange={(e) => handleGroupSwitchChange(group.id, e.target.checked)}
                                disabled={!rolStatus} 
                            />
                            <label className='form-check-label' htmlFor={`groupSwitch${group.id}`}>
                                {group.name}
                            </label>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export { ModalEditRol };

