// Importamos hooks de react que usaremos para este componente
import { useState } from "react";
// Importamos libreria para manejo de lenguaje 
import { useIntl } from "react-intl";

// ---------------------------------------------------------------------------------------

// Importamos las interfaces de productos que usaremos en este componente
import { Step4Props } from "../../interfaces/productsTypes";

// Iniciamos funcion para construir el componete Step4
function Step4({ onNext, handlePrev, prevData, setStep4Data }: Step4Props) {
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl();

  const [productCampaigns, setProductCampaigns] = useState(prevData?.productCampaigns || []);
  
  // const muestraArray = () => {
  //   console.log('campañas: ', productCampaigns);
  // }

  const handleChange = (value: string) => {
    const index = productCampaigns.indexOf(value);

    if (index !== -1) {
      const updatedCampaigns = [...productCampaigns];
      updatedCampaigns.splice(index, 1);
      setProductCampaigns(updatedCampaigns);
    } else {
      setProductCampaigns([...productCampaigns, value]);
    }

    // muestraArray();

  };

  const handleNext = () => {
    onNext({ productCampaigns });

    setStep4Data({ productCampaigns }); // Actualiza el estado en el componente principal
  };
 
    return(
      <div>
        <h2 className='modal-title mb-4'>{intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.LABEL_CAMAUTO'})}</h2>
        <form>
          <div className='d-flex mb-8'>
            <i className='bi bi-info-circle text-blue-100'></i>
            <span className='card-label d-block text-blue-100 ml-6'>{intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.CAMAUTO.ADVICE_L1'})}
            <br />
            {intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.CAMAUTO.ADVICE_L2'})}
            <br />
            {intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.CAMAUTO.ADVICE_L3'})}
            <br />
            {intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.CAMAUTO.ADVICE_L4'})}
            <br />
            {intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.CAMAUTO.ADVICE_L5'})}
            <br />
            {intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.CAMAUTO.ADVICE_L6'})}
            </span>
          </div>
          <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid mb-6'>
              <input
                className='form-check-input'
                type='checkbox'
                value='leadGenFace'
                // name='leadGenFace'
                defaultChecked={false}
                onChange={(e) => handleChange(e.target.value)}
              />
              <label className='form-check-label'>Lead Gen | Facebook</label>
              
            </div>

            <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid mb-6'>
              <input
                className='form-check-input'
                type='checkbox'
                value='leadGenInsta'
                // name='leadGenInsta'
                defaultChecked={false}
                onChange={(e) => handleChange(e.target.value)}

              />
              <label className='form-check-label'>Lead Gen | Instagram</label>
              
            </div>

            <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid mb-6'>
              <input
                className='form-check-input'
                type='checkbox'
                value='landingGoogle'
                // name='landingGoogle'
                defaultChecked={false}
                onChange={(e) => handleChange(e.target.value)}

              />
              <label className='form-check-label'>Landing | Google Ads</label>
              
            </div>

            <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid mb-8'>
              <input
                className='form-check-input'
                type='checkbox'
                value='visitaWalk'
                // name='visitaWalk'
                defaultChecked={false}
                onChange={(e) => handleChange(e.target.value)}

              />
              <label className='form-check-label'>WalkIn | {intl.formatMessage({id: 'PRODUCTS.ON_SITE_VISIT'})}</label>
              
            </div>

            <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid mb-8'>
              <input
                className='form-check-input'
                type='checkbox'
                value='refRef'
                // name='refRef'
                defaultChecked={false}
                onChange={(e) => handleChange(e.target.value)}

              />
              <label className='form-check-label'>{intl.formatMessage({id: 'PRODUCTS.REFERENCED'})}</label>
              
            </div>
        </form>
        {/* <button onClick={handleNext}>Siguiente</button> */}
        <button className="btn btn-light btn-sm mt-6 me-2" onClick={handlePrev}>{intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.BUTTONLAST'})}</button>
        
        <button className="btn btn-primary btn-sm mt-6" onClick={handleNext}>{intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.BUTTONNEXT'})}</button>

      </div>
    )
};

export {Step4};