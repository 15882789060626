import {FC} from 'react'
import {useIntl} from 'react-intl'

const ModalDcemNew: FC = () => {
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  return (
    <>
      <div className='px-7'>
        <div className='mb-5'>
          <div>
            <label className='fs-6 fw-bold required'>URL</label>
            <input
              required
              type='url'
              name='shortedLink'
              className='form-control form-control-solid mb-3 mb-lg-0 required'
              placeholder={intl.formatMessage({id: 'LINKDCEM.PASTE_URL_TO_SHORTEN'})}
            />
          </div>
        </div>

        <div className='mb-5'>
          <div>
            <label className='fs-6 fw-bold required'>
              {intl.formatMessage({id: 'GLOBAL.NAME'})}
            </label>
            <input
              required
              type='mail'
              name='nameLink'
              className='form-control form-control-solid mb-3 mb-lg-0 required'
              placeholder={intl.formatMessage({id: 'LINKDCEM.ASSIGN_NAME_TO_LINK'})}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export {ModalDcemNew}
