import {FC, useState, useContext} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'

type Props = {
  titleAdvice: string
  descriptionAdvice: string
  showModalAdvice: boolean
  setShowModalAdvice: React.Dispatch<React.SetStateAction<boolean>>
  openModalAdvice: () => void
  closeModalAdvice: () => void
  handleFormSubmit: () => void
}

const ModalAdviceDefaultSize: FC<Props> = ({
  titleAdvice,
  descriptionAdvice,
  showModalAdvice,
  setShowModalAdvice,
  openModalAdvice,
  closeModalAdvice,
  handleFormSubmit,
}) => {
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  return (
    <>
      <Modal className='modal-default' centered show={showModalAdvice}>
        <Modal.Body className='bg-gray-400'>
          <div className='alert alert-dismissible bg-light-warning border border-warning mb-4'>
            <div className='d-flex flex-column flex-sm-row p-3'>
              <span className='svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0 text-dark'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-exclamation-triangle-fill'
                  viewBox='0 0 16 16'
                >
                  <path d='M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2' />
                </svg>
              </span>
              <div className='d-flex flex-column text-primary pe-0 pe-sm-10'>
                {/* <h5 className='mb-1'>Activar producto</h5> */}
                <span className='text-dark'>
                  <h4 className='mb-4'>{titleAdvice}</h4>
                  <div className='d-flex'>
                    <p className='fw-bold'>{descriptionAdvice}</p>
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-end'>
            <div className='mt-4 me-4 '>
              <button
                className='btn btn-sm btn-primary'
                type='submit'
                onClick={() => {
                  closeModalAdvice()
                  handleFormSubmit()
                }}
              >
                {intl.formatMessage({id: 'GLOBAL.INPUT.ACCEPT'})}
              </button>
            </div>
            <div className='mt-4'>
              <button
                className='btn btn-sm btn-secondary'
                type='button'
                onClick={() => {
                  closeModalAdvice()
                }}
              >
                {intl.formatMessage({id: 'GLOBAL.CANCEL'})}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export {ModalAdviceDefaultSize}
