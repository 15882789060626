import * as React from 'react';
import {ReactElement, useState} from 'react'

import {Step2Props} from '../../interfaces/clientsTypes'

import {Step2Errors} from '../../interfaces/clientsTypes'

import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css' // Asegúrate de importar también los estilos
import '../../../../../_metronic/assets/styles/PhoneInput.css'
import { allowedCountries } from '../../../../utils/constants/_Constants'
import { useIntl } from 'react-intl';

function Step2({onNext, handlePrev, prevData, setStep2Data}: Step2Props) {
  const intl = useIntl();
  const [clientName, setClientName] = useState(prevData?.clientName || '')
  const [clientRfc, setClientRfc] = useState(prevData?.clientRfc || '')
  const [clientPhone, setClientPhone] = useState(prevData?.clientPhone || '')
  const [clientIndustry, setClientIndustry] = useState(prevData?.clientIndustry || '')
  const [clientSize, setClientSize] = useState(prevData?.clientSize || '')
  const [clientGiro, setClientGiro] = useState(prevData?.clientGiro || '')
  const [clientEmployeeNumber, setClientEmployeeNumber] = useState(
    prevData?.clientEmployeeNumber || ''
  )

  const [errors, setErrors] = useState<Step2Errors>({
    clientName: '',
    clientRfc: '',
    clientPhone: '',
    clientIndustry: '',
    clientSize: '',
    clientGiro: '',
    clientEmployeeNumber: '',
  })

  const validateInputs = () => {
    const newErrors: Step2Errors = {
      clientName: !clientName ? intl.formatMessage({id: 'CLIENTS.NAME_REQUIRED'}) : '',
      clientRfc: !clientRfc ? intl.formatMessage({id: 'CLIENTS.RFC_REQUIRED'}) : '',
      clientPhone: !clientPhone ? intl.formatMessage({id: 'CLIENTS.PHONE_REQUIRED'}) : '',
      clientIndustry: !clientIndustry || clientIndustry === '0' ? intl.formatMessage({id: 'CLIENTS.INDUSTRY_REQUIRED'}) : '',
      clientSize: !clientSize || clientSize === '0' ? intl.formatMessage({id: 'CLIENTS.SIZE_REQUIRED'}) : '',
      clientGiro: !clientGiro ? intl.formatMessage({id: 'CLIENTS.GIRO_REQUIRED'}) : '',
      clientEmployeeNumber: !clientEmployeeNumber ? intl.formatMessage({id: 'CLIENTS.EMPLOYEE_NUMBER_REQUIRED'}) : '',
    }

    // Aquí actualizamos el estado errors con los nuevos mensajes de error
    setErrors(newErrors)

    console.log(newErrors)

    return Object.values(newErrors).every((error) => error === '')
  }

  const handleNext = () => {
    if (validateInputs()) {
      onNext({
        clientName,
        clientRfc,
        clientPhone,
        clientIndustry,
        clientSize,
        clientGiro,
        clientEmployeeNumber,
      })

      setStep2Data({
        clientName,
        clientRfc,
        clientPhone,
        clientIndustry,
        clientSize,
        clientGiro,
        clientEmployeeNumber,
      })

      console.log(clientIndustry)
    }
  }

  return (
    <div>
      <h3>{intl.formatMessage({id: 'CLIENTS.CLIENT'})}</h3>
      <span className='text-muted'>
        {intl.formatMessage({id: 'CLIENTS.ENTER_DATA_INSTRUCTIONS'})}
      </span>
      <form>
        <div className='mt-4'>
          <input
            type='text'
            id='clientName'
            placeholder={intl.formatMessage({id: 'CLIENTS.ENTER_CLIENT_NAME'})}
            name='clientname'
            className='form-control '
            value={clientName}
            onChange={(e) => setClientName(e.target.value)}
          />
          <div className='text-danger mb-6'>{errors.clientName}</div>
        </div>

        <div className='d-flex mt-4'>
          <div className='col-6 me-2'>
            <input
              type='text'
              id='clientRcf'
              placeholder='RFC'
              className='form-control'
              value={clientRfc}
              onChange={(e) => setClientRfc(e.target.value)}
            />
            <div className='text-danger mb-6'>{errors.clientRfc}</div>
          </div>

          <div className='col-6'>
            <PhoneInput
              placeholder={intl.formatMessage({id: 'CLIENTS.ENTER_PHONE_NUMBER'})}
              className='form-control  input-test'
              name='phoneuser'
              value={clientPhone}
              onChange={(e) => setClientPhone(e?.toString() || '')}
              countryCallingCodeEditable={false}
              defaultCountry='MX' // Especifica el país por defecto si lo deseas
              international
              autoComplete='off'
              countries={allowedCountries}
            />
            <div className='text-danger mb-6'>{errors.clientPhone}</div>
          </div>
        </div>

        <h3>{intl.formatMessage({id: 'CLIENTS.COMPANY_TYPE'})}</h3>
        <div className='d-flex mt-4'>
          <div className='col-6 me-2'>
            <select
              className='form-select'
              id='clientIndustry'
              value={clientIndustry}
              onChange={(e) => setClientIndustry(e.target.value)}
            >
              <option value='0'>{intl.formatMessage({id: 'CLIENTS.SELECT_INDUSTRY'})}</option>
              <option value="Technology">{intl.formatMessage({id: 'CLIENTS.INDUSTRY_IT'})}</option>
              <option value="Finance">{intl.formatMessage({id: 'CLIENTS.INDUSTRY_FINANCE'})}</option>
              <option value="Management Consulting">{intl.formatMessage({id: 'CLIENTS.INDUSTRY_MANAGEMENT_CONSULTING'})}</option>
              <option value="Manufacturing">{intl.formatMessage({id: 'CLIENTS.INDUSTRY_MANUFACTURING'})}</option>
              <option value="Healthcare">{intl.formatMessage({id: 'CLIENTS.INDUSTRY_HEALTHCARE'})}</option>
              <option value="Retail">{intl.formatMessage({id: 'CLIENTS.INDUSTRY_RETAIL'})}</option>
              <option value="Education">{intl.formatMessage({id: 'CLIENTS.INDUSTRY_EDUCATION'})}</option>
              <option value="Media">{intl.formatMessage({id: 'CLIENTS.INDUSTRY_MEDIA'})}</option>
              <option value="Agriculture">{intl.formatMessage({id: 'CLIENTS.INDUSTRY_AGRICULTURE'})}</option>
              <option value="Construction">{intl.formatMessage({id: 'CLIENTS.INDUSTRY_CONSTRUCTION'})}</option>
              <option value="Energy">{intl.formatMessage({id: 'CLIENTS.INDUSTRY_ENERGY'})}</option>
              <option value="Transportation">{intl.formatMessage({id: 'CLIENTS.INDUSTRY_TRANSPORTATION'})}</option>
              <option value="Professional Services">{intl.formatMessage({id: 'CLIENTS.INDUSTRY_PROFESSIONAL_SERVICES'})}</option>
              <option value="Tourism">{intl.formatMessage({id: 'CLIENTS.INDUSTRY_TOURISM'})}</option>
              <option value="Automotive">{intl.formatMessage({id: 'CLIENTS.INDUSTRY_AUTOMOTIVE'})}</option>
              <option value="Business Consulting">{intl.formatMessage({id: 'CLIENTS.INDUSTRY_BUSINESS_CONSULTING'})}</option>
              <option value="Real Estate">{intl.formatMessage({id: 'CLIENTS.INDUSTRY_REAL_ESTATE'})}</option>
              <option value="Arts & Entertainment">{intl.formatMessage({id: 'CLIENTS.INDUSTRY_ARTS_ENTERTAINMENT'})}</option>
              <option value="Food Industry">{intl.formatMessage({id: 'CLIENTS.INDUSTRY_FOOD'})}</option>
            </select>
            <div className='text-danger mb-6'>{errors.clientIndustry}</div>
          </div>

          <div className='col-6'>
            <select
              className='form-select'
              id='clientSize'
              value={clientSize}
              onChange={(e) => setClientSize(e.target.value)}
            >
              <option value='0'>{intl.formatMessage({id: 'CLIENTS.SIZE'})}</option>
              <option value='Micro'>{intl.formatMessage({id: 'CLIENTS.SIZE_MICRO'})}</option>
              <option value='Mediana'>{intl.formatMessage({id: 'CLIENTS.SIZE_MEDIUM'})}</option>
              <option value='PYME'>{intl.formatMessage({id: 'CLIENTS.SIZE_SME'})}</option>
              <option value='Grande'>{intl.formatMessage({id: 'CLIENTS.SIZE_LARGE'})}</option>
            </select>
            <div className='text-danger mb-6'>{errors.clientSize}</div>
          </div>
        </div>

        <div className='d-flex mt-4'>
          <div className='col-6 me-2'>
            <input
              type='text'
              placeholder={intl.formatMessage({id: 'CLIENTS.BUSINESS_LINE'})}
              id='clientGiro'
              className='form-control'
              value={clientGiro}
              onChange={(e) => setClientGiro(e.target.value)}
            />
            <div className='text-danger mb-6'>{errors.clientGiro}</div>
          </div>

          <div className='col-6'>
            <input
              type='number'
              id='clientEmployeeNumber'
              placeholder={intl.formatMessage({id: 'CLIENTS.EMPLOYEE_NUMBER'})}
              className='form-control'
              value={clientEmployeeNumber}
              onChange={(e) => setClientEmployeeNumber(e.target.value)}
            />
            <div className='text-danger mb-6'>{errors.clientEmployeeNumber}</div>
          </div>
        </div>
      </form>

      <button className='btn btn-light btn-sm mt-6 me-2' onClick={handlePrev}>
        {intl.formatMessage({id: 'CLIENTS.PREVIOUS'})}
      </button>

      <button className='btn btn-primary btn-sm mt-6' onClick={handleNext}>
        {intl.formatMessage({id: 'CLIENTS.NEXT'})}
      </button>
    </div>
  )
}

export {Step2}
