import {DRG_STANDAR_MODULES, DRG_USERS_TYPES} from '../../routing/constants/_Contstants'
import {IscoreByDay} from '../../standard-modules/reports/each-report/campaigns/interfaces/campaignsReportTypes'
import {LeadByDay} from '../../standard-modules/reports/each-report/campaigns/interfaces/campaignsReportTypes'
import {CombinedDate} from '../../standard-modules/reports/each-report/campaigns/interfaces/campaignsReportTypes'

// Función para limpiar y recargar con alert
export const sessionExpired = (messageAlert: string, path?: string | undefined) => {
  // Borra el localStorage
  localStorage.removeItem('miData')
  localStorage.removeItem('clientData')

  //Validación para redireccionar ruta despues del nuevo login
  if (path) {
    localStorage.setItem('actualPath', path)
  } else {
    localStorage.setItem('actualPath', window.location.pathname)
  }

  // Muestra el alert
  alert(messageAlert)

  // Recarga la página después de mostrar el alert
  window.location.reload()
}

// Funcion para formatear fechas en formato textual en ingles
// *** Recibe cadena en formato ISO 8601: 2024-07-17T12:03:32.000Z ***
export const dateFormatterTextualEn = (date: string | null): string => {
  let isoDate: Date | null = null

  // Asegúrate de que la fecha esté en un formato ISO 8601 compatible
  if (date !== null) {
    try {
      isoDate = new Date(date.replace(' ', 'T'))
      if (isNaN(isoDate.getTime())) {
        throw new Error('Invalid date')
      }
    } catch (error) {
      console.error('Error parsing date:', error)
      return 'Invalid date'
    }
  } else {
    return 'Invalid date'
  }

  // Configurar opciones para el formato
  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'UTC', // Cambia esto según tu zona horaria
  }

  const formattedDate = isoDate.toLocaleDateString('en-US', options)
  return formattedDate
}

// export const dateFormatterTextualEn = (date: string | null): string => {

//   let isoDate
//   // Asegúrate de que la fecha esté en un formato ISO 8601 compatible
//   if(date !== null){
//     isoDate = new Date(date.replace(' ', 'T'))

//   }else{
//     isoDate = ''
//   }

//   // Configurar opciones para el formato
//   const options: Intl.DateTimeFormatOptions = {
//     month: 'short',
//     day: 'numeric',
//     year: 'numeric',
//     timeZone: 'UTC', // Cambia esto según tu zona horaria
//   }

//   const formattedDate = isoDate.toLocaleDateString('en-US', options)

//   return formattedDate

//   // Return example
//   // Ejemplo de salida:
//   // Nov 23, 2023
// }

// Funcion para formatear fechas a variante de formato ISO: 8601

// *** Recibe Date en formato RFC2822: Sat Aug 10 2024 23:59:59 GMT-0600 (hora estándar central)
export const dateFormatterISO8601Variant = (date: Date): string => {
  // Obtener los componentes de la fecha y hora en formato UTC
  const year: number = date.getUTCFullYear()
  const month: string = String(date.getUTCMonth() + 1).padStart(2, '0')
  const day: string = String(date.getUTCDate()).padStart(2, '0')
  const hours: string = String(date.getUTCHours()).padStart(2, '0')
  const minutes: string = String(date.getUTCMinutes()).padStart(2, '0')
  const seconds: string = String(date.getUTCSeconds()).padStart(2, '0')
  const milliseconds: string = String(date.getUTCMilliseconds()).padStart(3, '0')

  // Construir la cadena en formato ISO 8601 con fracciones de segundo y zona horaria
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}+00:00`

  // Return example
  // Ejemplo de salida:
  // 2024-08-11T05:59:59.000+00:00
}

// Funcion para formatear fechas en formato textual en ingles
// *** Recibe cadena en formato RFC2822: Sat Aug 10 2024 23:59:59 GMT-0600 (hora estándar central)
export const dateFormatterTextualEnTwo = (date: string | Date): string => {
  const rfc2822Date = new Date(date)

  const options: Intl.DateTimeFormatOptions = {month: 'short', day: 'numeric', year: 'numeric'}

  return rfc2822Date.toLocaleDateString('en-US', options)
  // Return example
  // Ejemplo de salida:
  //  Aug 10, 2024
}

// Funcion para formatear fechas en formato textual en ingles
// *** Recibe cadena en formato ISO 8601: 2024-07-17T12:03:32.000Z ***
export const dateFormatterTextualEnExtended = (date: string): string => {
  // Asegúrate de que la fecha esté en un formato ISO 8601 compatible
  const isoDate = new Date(date.replace(' ', 'T'))

  // Configurar opciones para la fecha
  const optionsDate: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'UTC', // Cambia esto según tu zona horaria
  }

  // Configurar opciones para la hora
  const optionsTime: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false, // Cambia a true si prefieres formato 12 horas
    timeZone: 'UTC', // Cambia esto según tu zona horaria
  }

  const formattedDate = isoDate.toLocaleDateString('en-US', optionsDate)
  const formattedTime = isoDate.toLocaleTimeString('en-US', optionsTime)

  return `${formattedDate} ${formattedTime}`

  // Return example
  // Ejemplo de salida:
  // Nov 23, 2023 12:00:00
}

// Funcion para formatear fechas en formato ISO 8601
// *** Recibe cadena en formato de fecha y hora extendido: YYYY-MM-DD: 2024-08-02
export const dateFormatterISO8601 = (date: string): string => {
  const dateToformat = new Date(date)
  return dateToformat.toISOString()

  // Return example
  // Ejemplo de salida
  // 2024-07-17T12:03:32.000Z
}

// Funcion para formatear fechas a formato de fecha y tiempo extendido
// *** Recibe cadena en formato: ISO 8601: 2024-07-17T12:03:32.000Z ***
export const dateFormatterDateTimeExtended = (date: string | undefined): string => {
  let dateToFormat: any

  if (date) {
    dateToFormat = new Date(date)
  }

  const pad = (num: number): string => (num < 10 ? '0' : '') + num

  const year = dateToFormat.getFullYear()
  const month = pad(dateToFormat.getMonth() + 1) // Los meses van de 0 a 11
  const day = pad(dateToFormat.getDate())

  const hours = pad(dateToFormat.getHours())
  const minutes = pad(dateToFormat.getMinutes())
  const seconds = pad(dateToFormat.getSeconds())

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`

  // Return example
  // Ejemplo de salida
  // 2024-06-03 19:31:20
}

// Funcion para contar los objetos de un array
export const countArrayObjects = (arrayItem: string[]) => {
  if (Array.isArray(arrayItem) && arrayItem.length > 0) {
    return arrayItem.length
  } else {
    return 0
  }
}

// Funcion para obtener el archivo para el logo de campaña
export const getFileLogoForCampaign = (camChannel: string) => {
  if (camChannel === 'Google Ads') {
    return 'Google Ads.png'
  } else if (camChannel === 'Instagram') {
    return 'Instagram.png'
  } else if (camChannel === 'Facebook' || camChannel === 'Facebook Ads') {
    return 'Facebook Ads.png'
  } else if (camChannel === 'Walk-in') {
    return 'Walk-in.png'
  } else if (camChannel === 'Referenciado') {
    return 'Referenciado.png'
  } else {
    return 'logo_logo-dragon.png'
  }
}

export const setPageTitle = (title: string): void => {
  document.title = title
}

// Funcion para borrar los caracteres antes del guion bajo de la cadena entrante
export const fileNameCleaner = (fileName: string) => {
  const indexUnderscore = fileName.indexOf('_')

  const cleannedString = fileName.substring(indexUnderscore + 1)

  return cleannedString
  //Return example
  //String incoming: head_filename.png
  //Cleanned string: filename.png
}

//Función para darle formato al texto y quitar el guión
//Api digital - Active -> Api digital
export const formatTextMIddleDash = (text: string) => {
  // Dividir el texto en dos partes en función del guion
  const parts = text.split(' - ')
  // Tomar la primera parte, que es el nombre del cliente
  const formattedText = parts[0]
  return formattedText
}

//--------------------------------------------------------------------

interface NewObject {
  [key: string]: string
}

// Funcion para convertir un objeto JSON a un string
export const convertObjectsToText = (objectIncoming: NewObject) => {
  if (objectIncoming === undefined || objectIncoming === null) {
    return ''
  } else {
    // Convertir el objeto en un array de tuplas [clave, valor]
    const objectEntries = Object.entries(objectIncoming)

    // Mapear sobre el array resultante
    const jsonStrings = objectEntries.map(([key, value]) => `${key} - ${JSON.stringify(value)}`)

    return jsonStrings.join('\n')
  }
}

// Funcion para contar cuantas keys tiene un objeto
export const countKeys = (objectIncoming: NewObject) => {
  if (objectIncoming === undefined || objectIncoming === null) {
    return ''
  } else {
    return Object.keys(objectIncoming).length
  }
}

// Función para mapear valores numéricos o booleanos a palabras
// La Api solo retorna o un nivel de acceso o un estatus true para evangelista, pero no ambos, entonces solo existen esas 3 opciones
// Administrador, gerente o Evangelista
export const mapAccessLevelToWord = (lvl: number): string => {
  switch (lvl) {
    case 5:
      return 'Administrador'
    case 3:
      return 'Gerente'
    default:
      return 'Evangelista'
  }
}

export const mapUserStatusToWord = (status: boolean): string => {
  switch (status) {
    case true:
      return 'Activo'
    case false:
      return 'Inactivo'
  }
}

export const mapProductStatusToWord = (status: number): string => {
  switch (status) {
    case 0:
      return 'Inactivo'
    case 1:
      return 'Activo'
    case 2:
      return 'Por desactivar'
    case 2:
      return 'Por activar'
    default:
      return 'Desconocido'
  }
}

//Funcion que permite recortar una cadena despues de los 50 caracteres si es que los suéra en tamaño
export const shortenString = (fullString: string): string => {
  if (fullString.length <= 50) {
    return fullString
  } else {
    return `${fullString.substring(0, 50)}...`
  }
}

//Funcion para extraer los valores de un campo especifico de una serie de objetos dentro de algun arreglo
interface Item {
  [key: string]: any
}

export const dataExtract = <T extends Item>(array: T[], field: keyof T): T[keyof T][] => {
  return array.map((item) => item[field])
  // return { valores };
}

// export interface Score {
//     date: string;
//     iscore: number;
// }

export const equalizeArraysRangesIscore = (
  range1: IscoreByDay[],
  range2: IscoreByDay[],
  defaultDate: string,
  defaultIscore: number | null = null
): [IscoreByDay[], IscoreByDay[]] => {
  const size1 = range1.length
  const size2 = range2.length

  if (size1 === size2) {
    return [range1, range2]
  }

  const difference = Math.abs(size1 - size2)
  const filler = Array(difference).fill({date: defaultDate, iscore: defaultIscore})

  if (size1 < size2) {
    range1 = [...range1, ...filler]
  } else {
    range2 = [...range2, ...filler]
  }

  return [range1, range2]
}

export const equalizeArraysRangesLead = (
  range1: LeadByDay[],
  range2: LeadByDay[],
  defaultDate: string,
  defaultLead: number | null = null
): [LeadByDay[], LeadByDay[]] => {
  const size1 = range1.length
  const size2 = range2.length

  if (size1 === size2) {
    return [range1, range2]
  }

  const difference = Math.abs(size1 - size2)
  const filler = Array(difference).fill({date: defaultDate, leads: defaultLead})

  if (size1 < size2) {
    range1 = [...range1, ...filler]
  } else {
    range2 = [...range2, ...filler]
  }

  return [range1, range2]
}

const extractDayMonth = (date: string): string => {
  const parts = date.split('-')
  return parts.length === 3 ? `${parts[0]}-${parts[1]}` : 'No disponible'
}

export const combinateDatesIscore = (
  range1: IscoreByDay[],
  range2: IscoreByDay[]
): CombinedDate[] => {
  const size1 = range1.length
  const size2 = range2.length

  const maxSize = Math.max(size1, size2)
  const result: CombinedDate[] = []

  for (let i = 0; i < maxSize; i++) {
    const date1 = i < size1 ? extractDayMonth(range1[i].date) : ''
    const date2 = i < size2 ? extractDayMonth(range2[i].date) : ''
    result.push({date: `${date1}|${date2}`})
  }

  return result
}

export const combinateDatesLead = (range1: LeadByDay[], range2: LeadByDay[]): CombinedDate[] => {
  const size1 = range1.length
  const size2 = range2.length

  const maxSize = Math.max(size1, size2)
  const result: CombinedDate[] = []

  for (let i = 0; i < maxSize; i++) {
    const date1 = i < size1 ? extractDayMonth(range1[i].date) : 'No disponible'
    const date2 = i < size2 ? extractDayMonth(range2[i].date) : 'No disponible'
    result.push({date: `${date1}|${date2}`})
  }

  return result
}

// Funcion para copiar texto al porta papeles
export const handleCopyToClipboard = (text: string): void => {
  navigator.clipboard.writeText(text).catch((error) => {
    console.error('Error al copiar al portapapeles:', error)
    alert('Error al copiar al portapapeles. Por favor, inténtelo de nuevo.')
  })
}
/**
 * Formatea un número eliminando ceros decimales innecesarios.
 * Si el número es entero, se devuelve sin decimales.
 * Si el número tiene decimales significativos, se conservan hasta dos decimales.
 *
 * @param {number} num - El número a formatear.
 * @returns {string} - El número formateado como cadena de texto.
 */
export const formatNumber = (num: number): string => {
  // Verificar si la entrada es un número y no es NaN (Not-a-Number)
  if (typeof num !== 'number' || isNaN(num)) {
    return 'Entrada no válida' // Retorna un mensaje de error si la entrada no es un número válido
  }

  // Limitar el número a dos decimales usando toFixed(2)
  // toFixed(2) convierte el número a una cadena con hasta dos decimales
  // parseFloat() convierte la cadena de vuelta a un número eliminando ceros decimales innecesarios
  // toString() convierte el número resultante de vuelta a una cadena para la salida
  return parseFloat(num.toFixed(1)).toString()
}

type Permissions = Record<string, Record<number, boolean>>;

const PERMISSIONS: Record<string, Permissions> = {
  //*************** ADMIN USER ********************/
  [DRG_USERS_TYPES.ADMIN.value]: {
    [DRG_STANDAR_MODULES.USERS.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.LEADS.value]: { 1: true, 2: true, 3: true, 4: false },
    [DRG_STANDAR_MODULES.REASIGN.value]: { 1: true, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.EXPORT_LEADS.value]: { 1: true, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.SPAM.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.RECORDINGS.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.HELP_CENTER.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.REPORTS.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.REPORT_CRM.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.BRANDS.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.PRODUCTS.value]: { 1: true, 2: true, 3: true, 4: false },
    [DRG_STANDAR_MODULES.ASSIGNS.value]: { 1: true, 2: true, 3: true, 4: false },
    [DRG_STANDAR_MODULES.LINK_DCEM.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.SHARE_ACCESS.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.ROLES_PRIVILEGES.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.CLIENTS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.CAMPAIGNS.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.USERS_GROUPS.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.CHATWAB.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.ACCOUNT_MANAGEMENT.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.LOGS.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.DASHBOARD.value]: { 1: false, 2: true, 3: false, 4: false },
  },
  //*************** SUPER ADMIN USER ********************/
  [DRG_USERS_TYPES.SUPER_ADMIN.value]: {
    [DRG_STANDAR_MODULES.USERS.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.LEADS.value]: { 1: true, 2: true, 3: true, 4: false },
    [DRG_STANDAR_MODULES.REASIGN.value]: { 1: true, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.EXPORT_LEADS.value]: { 1: true, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.SPAM.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.RECORDINGS.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.HELP_CENTER.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.REPORTS.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.REPORT_CRM.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.BRANDS.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.PRODUCTS.value]: { 1: true, 2: true, 3: true, 4: false },
    [DRG_STANDAR_MODULES.ASSIGNS.value]: { 1: true, 2: true, 3: true, 4: false },
    [DRG_STANDAR_MODULES.LINK_DCEM.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.SHARE_ACCESS.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.ROLES_PRIVILEGES.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.CLIENTS.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.CAMPAIGNS.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.USERS_GROUPS.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.CHATWAB.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.ACCOUNT_MANAGEMENT.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.LOGS.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.DASHBOARD.value]: { 1: false, 2: true, 3: false, 4: false },
  },
  //*************** SELLER DC USER ********************/
  [DRG_USERS_TYPES.SELLER_DC.value]: {
    [DRG_STANDAR_MODULES.USERS.value]: { 1: false, 2: true, 3: true, 4: false },
    [DRG_STANDAR_MODULES.LEADS.value]: { 1: true, 2: true, 3: true, 4: false },
    [DRG_STANDAR_MODULES.REASIGN.value]: { 1: true, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.EXPORT_LEADS.value]: { 1: true, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.SPAM.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.RECORDINGS.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.HELP_CENTER.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.REPORTS.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.REPORT_CRM.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.BRANDS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.PRODUCTS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.ASSIGNS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.LINK_DCEM.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.SHARE_ACCESS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.ROLES_PRIVILEGES.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.CLIENTS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.CAMPAIGNS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.USERS_GROUPS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.CHATWAB.value]: { 1: true, 2: true, 3: true, 4: false },
    [DRG_STANDAR_MODULES.ACCOUNT_MANAGEMENT.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.LOGS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.DASHBOARD.value]: { 1: false, 2: true, 3: false, 4: false },
  },
  //*************** ADMIN DC USER ********************/
  [DRG_USERS_TYPES.ADMIN_DC.value]: {
    [DRG_STANDAR_MODULES.USERS.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.LEADS.value]: { 1: true, 2: true, 3: true, 4: false },
    [DRG_STANDAR_MODULES.REASIGN.value]: { 1: true, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.EXPORT_LEADS.value]: { 1: true, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.SPAM.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.RECORDINGS.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.HELP_CENTER.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.REPORTS.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.REPORT_CRM.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.BRANDS.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.PRODUCTS.value]: { 1: true, 2: true, 3: true, 4: false },
    [DRG_STANDAR_MODULES.ASSIGNS.value]: { 1: true, 2: true, 3: true, 4: false },
    [DRG_STANDAR_MODULES.LINK_DCEM.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.SHARE_ACCESS.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.ROLES_PRIVILEGES.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.CLIENTS.value]: { 1: false, 2: true, 3: true, 4: false },
    [DRG_STANDAR_MODULES.CAMPAIGNS.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.USERS_GROUPS.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.CHATWAB.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.ACCOUNT_MANAGEMENT.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.LOGS.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.DASHBOARD.value]: { 1: false, 2: true, 3: false, 4: false },
  },
  //*************** DEVELOPER USER ********************/
  [DRG_USERS_TYPES.DEVELOPER.value]: {
    [DRG_STANDAR_MODULES.USERS.value]: { 1: true, 2: true, 3: true, 4: false },
    [DRG_STANDAR_MODULES.LEADS.value]: { 1: true, 2: true, 3: true, 4: false },
    [DRG_STANDAR_MODULES.REASIGN.value]: { 1: true, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.EXPORT_LEADS.value]: { 1: true, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.SPAM.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.RECORDINGS.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.HELP_CENTER.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.REPORTS.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.REPORT_CRM.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.BRANDS.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.PRODUCTS.value]: { 1: true, 2: true, 3: true, 4: false },
    [DRG_STANDAR_MODULES.ASSIGNS.value]: { 1: true, 2: true, 3: true, 4: false },
    [DRG_STANDAR_MODULES.LINK_DCEM.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.SHARE_ACCESS.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.ROLES_PRIVILEGES.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.CLIENTS.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.CAMPAIGNS.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.USERS_GROUPS.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.CHATWAB.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.ACCOUNT_MANAGEMENT.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.LOGS.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.DASHBOARD.value]: { 1: false, 2: true, 3: false, 4: false },
  },
  //*************** MANAGER USER ********************/
  [DRG_USERS_TYPES.MANAGER.value]: {
    [DRG_STANDAR_MODULES.USERS.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.LEADS.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.REASIGN.value]: { 1: true, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.EXPORT_LEADS.value]: { 1: true, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.SPAM.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.RECORDINGS.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.HELP_CENTER.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.REPORTS.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.REPORT_CRM.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.BRANDS.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.PRODUCTS.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.ASSIGNS.value]: { 1: true, 2: true, 3: true, 4: false },
    [DRG_STANDAR_MODULES.LINK_DCEM.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.SHARE_ACCESS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.ROLES_PRIVILEGES.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.CLIENTS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.CAMPAIGNS.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.USERS_GROUPS.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.CHATWAB.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.ACCOUNT_MANAGEMENT.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.LOGS.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.DASHBOARD.value]: { 1: false, 2: true, 3: false, 4: false },
  },
  //*************** SELLER USER ********************/
  [DRG_USERS_TYPES.SELLER.value]: {
    [DRG_STANDAR_MODULES.USERS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.LEADS.value]: { 1: true, 2: true, 3: true, 4: false },
    [DRG_STANDAR_MODULES.REASIGN.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.EXPORT_LEADS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.SPAM.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.RECORDINGS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.HELP_CENTER.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.REPORTS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.REPORT_CRM.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.BRANDS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.PRODUCTS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.ASSIGNS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.LINK_DCEM.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.SHARE_ACCESS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.ROLES_PRIVILEGES.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.CLIENTS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.CAMPAIGNS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.USERS_GROUPS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.CHATWAB.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.ACCOUNT_MANAGEMENT.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.LOGS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.DASHBOARD.value]: { 1: false, 2: false, 3: false, 4: false },
  },
  //*************** PREMIUM SELLER USER ********************/
  [DRG_USERS_TYPES.PREMIUM_SELLER.value]: {
    [DRG_STANDAR_MODULES.USERS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.LEADS.value]: { 1: true, 2: true, 3: true, 4: false },
    [DRG_STANDAR_MODULES.REASIGN.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.EXPORT_LEADS.value]: { 1: true, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.SPAM.value]: { 1: true, 2: true, 3: true, 4: true },
    [DRG_STANDAR_MODULES.RECORDINGS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.HELP_CENTER.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.REPORTS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.REPORT_CRM.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.BRANDS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.PRODUCTS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.ASSIGNS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.LINK_DCEM.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.SHARE_ACCESS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.ROLES_PRIVILEGES.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.CLIENTS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.CAMPAIGNS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.USERS_GROUPS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.CHATWAB.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.ACCOUNT_MANAGEMENT.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.LOGS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.DASHBOARD.value]: { 1: false, 2: false, 3: false, 4: false },
  },
  //*************** MARKETING USER ********************/
  [DRG_USERS_TYPES.MARKETING.value]: {
    [DRG_STANDAR_MODULES.USERS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.LEADS.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.REASIGN.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.EXPORT_LEADS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.SPAM.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.RECORDINGS.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.HELP_CENTER.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.REPORTS.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.REPORT_CRM.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.BRANDS.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.PRODUCTS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.ASSIGNS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.LINK_DCEM.value]: { 1: true, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.SHARE_ACCESS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.ROLES_PRIVILEGES.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.CLIENTS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.CAMPAIGNS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.USERS_GROUPS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.CHATWAB.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.ACCOUNT_MANAGEMENT.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.LOGS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.DASHBOARD.value]: { 1: false, 2: false, 3: false, 4: false },
  },
  //*************** CRM USER ********************/
  [DRG_USERS_TYPES.CRM.value]: {
    [DRG_STANDAR_MODULES.USERS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.LEADS.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.REASIGN.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.EXPORT_LEADS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.SPAM.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.RECORDINGS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.HELP_CENTER.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.REPORTS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.REPORT_CRM.value]: { 1: false, 2: true, 3: false, 4: false },
    [DRG_STANDAR_MODULES.BRANDS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.PRODUCTS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.ASSIGNS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.LINK_DCEM.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.SHARE_ACCESS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.ROLES_PRIVILEGES.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.CLIENTS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.CAMPAIGNS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.USERS_GROUPS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.CHATWAB.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.ACCOUNT_MANAGEMENT.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.LOGS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.DASHBOARD.value]: { 1: false, 2: false, 3: false, 4: false },
  },
  //*************** ASISTENTE USER ********************/
  [DRG_USERS_TYPES.ASISTENTE.value]: {
    [DRG_STANDAR_MODULES.USERS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.LEADS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.REASIGN.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.EXPORT_LEADS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.SPAM.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.RECORDINGS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.HELP_CENTER.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.REPORTS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.REPORT_CRM.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.BRANDS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.PRODUCTS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.ASSIGNS.value]: { 1: true, 2: true, 3: true, 4: false },
    [DRG_STANDAR_MODULES.LINK_DCEM.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.SHARE_ACCESS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.ROLES_PRIVILEGES.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.CLIENTS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.CAMPAIGNS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.USERS_GROUPS.value]: { 1: true, 2: true, 3: true, 4: false },
    [DRG_STANDAR_MODULES.CHATWAB.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.ACCOUNT_MANAGEMENT.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.LOGS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.DASHBOARD.value]: { 1: false, 2: false, 3: false, 4: false },
  },
  //*************** CAPTURISTA USER ********************/
  [DRG_USERS_TYPES.CAPTURISTA.value]: {
    [DRG_STANDAR_MODULES.USERS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.LEADS.value]: { 1: true, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.REASIGN.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.EXPORT_LEADS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.SPAM.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.RECORDINGS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.HELP_CENTER.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.REPORTS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.REPORT_CRM.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.BRANDS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.PRODUCTS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.ASSIGNS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.LINK_DCEM.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.SHARE_ACCESS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.ROLES_PRIVILEGES.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.CLIENTS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.CAMPAIGNS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.USERS_GROUPS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.CHATWAB.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.ACCOUNT_MANAGEMENT.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.LOGS.value]: { 1: false, 2: false, 3: false, 4: false },
    [DRG_STANDAR_MODULES.DASHBOARD.value]: { 1: false, 2: false, 3: false, 4: false },
  },
};
/**
 * This function returns a boolean value based on the type of operation, module, 
 * and user type, according to the roles and privileges set in the PERMISSIONS constant.
 * @param module Actual module
 * @param userType User type
 * @param actionType Action type: 1) Create, 2) Read, 3) Update, 4) Delete/Turn off
 * @returns boolean
 */
export const canAccessFeature = (module: string, userType: string, actionType: number): boolean => {
  return PERMISSIONS[userType]?.[module]?.[actionType] ?? false;
};
