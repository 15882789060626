import React from 'react';
import { Nav, Container, Row, Col, Button } from 'react-bootstrap';
import { RolNew } from '../../services/_RolesNewApi';
import { useContext, useState } from 'react';
import { UserContext } from '../../../../app-context/user-context/UserContext';
import { sessionExpired } from '../../../../utils/functions/_Functions';
import { AlertInfo } from '../../../../utils/interfaces/_InterfacesTypes';
import { useIntl } from "react-intl";

function Step5Rol({ data, handlePrev, close, updateRolesList}) {
  

     //Variables con el estado de la alerta da confirmacion o error
  const [alertInfo, setAlertInfo] = useState<AlertInfo>({
    text: '',
    variant: 'primary',
  })

  const { user, token } = useContext(UserContext);
    const cli_id = user.cli_id;
    const userToken = token;

  const intl = useIntl();


console.log(data.schedules)

function getDayName(index) {
    const days = [
      intl.formatMessage({id: 'GLOBAL.MONDAY'}),
      intl.formatMessage({id: 'GLOBAL.TUESDAY'}),
      intl.formatMessage({id: 'GLOBAL.WEDNESDAY'}),
      intl.formatMessage({id: 'GLOBAL.THURSDAY'}),
      intl.formatMessage({id: 'GLOBAL.FRIDAY'}),
      intl.formatMessage({id: 'GLOBAL.SATURDAY'}),
      intl.formatMessage({id: 'GLOBAL.SUNDAY'})
    ];
    return days[index];
  }

  const handleFormSubmit = async () => {
    const roleName = data.rolName;
    const users = data.users;
    const groups = data.groups;
    const proIds = data.products;
    const camIds = data.campaigns;
    const brandId = data.brands;
    const schedules = data.schedules;

    console.log(groups)
    console.log(proIds)

    try {

        const {data: jsonData, code, msg} = await RolNew(
            cli_id,
            roleName, 
            users,
            groups, 
            proIds,
            camIds,
            brandId,
            schedules,
            userToken
        );

        console.log(msg)

        if ( code === 401 && msg === 'Token no valido' ){

            sessionExpired(intl.formatMessage({ id: "GLOBAL.ALERT.SESSION_EXPIRED" }));
            return;

        } if (code === 400 && msg) {
          // Iterar sobre las claves del objeto msg
          Object.keys(msg).forEach(key => {
              // Verificar si la clave comienza con "schedules"
              if (key.startsWith("schedules")) {
                  // Obtener el índice del día desde la clave
                  const index = parseInt(key.split("[")[1].split("]")[0]);
                  // Acceder al objeto de error de horario correspondiente
                  const scheduleError = msg[key];
                  // Verificar si el mensaje es "No se pueden empalmar los horarios"
                  if (scheduleError && scheduleError.msg === intl.formatMessage({id: 'ASSIGNS.OVERLAPPING_SCHEDULES_ERROR'})) {
                      // Obtener los horarios que se empalman
                      const conflictingSchedules = scheduleError.value.map(schedule => `${schedule.start}-${schedule.end}`).join(", ");
                      // Crear el mensaje de alerta con la información del día y los horarios que se empalman
                      const alertMessage = `${intl.formatMessage({id: 'ASSIGNS.OVERLAPPING_SCHEDULES_ERROR_MESSAGE'})} ${[
                        intl.formatMessage({id: 'GLOBAL.MONDAY'}),
                        intl.formatMessage({id: 'GLOBAL.TUESDAY'}),
                        intl.formatMessage({id: 'GLOBAL.WEDNESDAY'}),
                        intl.formatMessage({id: 'GLOBAL.THURSDAY'}),
                        intl.formatMessage({id: 'GLOBAL.FRIDAY'}),
                        intl.formatMessage({id: 'GLOBAL.SATURDAY'}),
                        intl.formatMessage({id: 'GLOBAL.SUNDAY'})
                      ][index]}: ${conflictingSchedules}`;
                      // console.log('Entra aquí');
                      setAlertInfo({
                          text: alertMessage,
                          variant: 'danger',
                      });
                  }
                  if (scheduleError && scheduleError.msg === intl.formatMessage({id: 'ASSIGNS.START_TIME_GREATER_ERROR'})) {
                    // Obtener los horarios que se empalman
                    const conflictingSchedules = scheduleError.value.map(schedule => `${schedule.start}-${schedule.end}`).join(", ");
                    // Crear el mensaje de alerta con la información del día y los horarios que se empalman
                    const alertMessage = `${intl.formatMessage({id: 'ASSIGNS.START_TIME_GREATER_ERROR_MESSAGE'})} ${[
                      intl.formatMessage({id: 'GLOBAL.MONDAY'}),
                      intl.formatMessage({id: 'GLOBAL.TUESDAY'}),
                      intl.formatMessage({id: 'GLOBAL.WEDNESDAY'}),
                      intl.formatMessage({id: 'GLOBAL.THURSDAY'}),
                      intl.formatMessage({id: 'GLOBAL.FRIDAY'}),
                      intl.formatMessage({id: 'GLOBAL.SATURDAY'}),
                      intl.formatMessage({id: 'GLOBAL.SUNDAY'})
                    ][index]}: ${conflictingSchedules}`;
                    // console.log('Entra aquí');
                    setAlertInfo({
                        text: alertMessage,
                        variant: 'danger',
                    });
                }
              }
          });
      } else {

            setAlertInfo({
                text: code === 200 ? intl.formatMessage({id: 'ASSIGNS.ROLE_CREATED_SUCCESS'}) : intl.formatMessage({id: 'GLOBAL.ALERT_SOMETHING_WENT_WRONG'}),
                variant: code === 200 ? 'success' : 'danger',
              })

              setTimeout(() => {
                
                if (code === 200) {
                  setAlertInfo({
                    text: '',
                    variant: '',
                  });
                  close();
                  updateRolesList();
                }
              }, 3000);

        }

    } catch (error) {
        
        // console.error("Error en el guardado del producto", error);
        setAlertInfo({
            text: intl.formatMessage({id: 'GLOBAL.ALERT_ERROR_AXIOS_REQUEST'}),
            variant: 'danger',
        });

    }

};

  return (
    <>
      <div>
        <h2>{intl.formatMessage({id: 'ASSIGNS.ROLE_SUMMARY'})}</h2>
        <div>
          <h3>{intl.formatMessage({id: 'ASSIGNS.ROLE_DETAILS'})}</h3>
          <Container>
            <Row>
              <Col>
                <p>{intl.formatMessage({id: 'ASSIGNS.ROLE_NAME'})}: {data.rolName}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>{intl.formatMessage({id: 'ASSIGNS.ROLE_USERS'})}: {data.groupsName.length === 0 && data.usersName.length > 0 ? data.usersName.join(', ') : data.groupsName.join(', ')}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                {data.productsName.length === 0 && data.brandsName.length === 0 && (
                  <p>{intl.formatMessage({id: 'ASSIGNS.CAMPAIGNS'})}: {data.campaignsName.join(', ')}</p>
                )}
                {data.productsName.length === 0 && data.campaignsName.length === 0 && (
                  <p>{intl.formatMessage({id: 'ASSIGNS.BRAND'})}: {data.brandsName}</p>
                )}
                {data.productsName.length > 0 && (
                  <p>{intl.formatMessage({id: 'ASSIGNS.PRODUCT'})}: {data.productsName.join(', ')}</p>
                )}
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <h3>{intl.formatMessage({id: 'ASSIGNS.SCHEDULES'})}</h3>
          <Container>
          {data.schedules.map((daySchedule, index) => (
            <div key={index}>
              <h4>{intl.formatMessage({id: 'ASSIGNS.DAY'})}: {getDayName(index)}</h4>
              {daySchedule.map((hito, hitoIndex) => (
                <div key={hitoIndex}>
                  <p>{intl.formatMessage({id: 'ASSIGNS.START'})}: {hito.start}</p>
                  <p>{intl.formatMessage({id: 'ASSIGNS.END'})}: {hito.end}</p>
                </div>
              ))}
            </div>
          ))}
          </Container>
        </div>
      </div>

      <button className='btn btn-light btn-sm mt-6 me-2' onClick={handlePrev}>
        {intl.formatMessage({id: 'GLOBAL.PREVIOUS'})}
      </button>

      <button type="button" className="btn btn-primary btn-sm mt-6" onClick={(e) => { handleFormSubmit();}}>
        {intl.formatMessage({id: 'ASSIGNS.SAVE_ROLE'})}
      </button>
      {alertInfo.text && (
                    <div className={`alert alert-${alertInfo.variant} align-items-center mt-3 mx-5 text-center`} role='alert'>
                      <div>{alertInfo.text}</div>
                    </div>
                  )}
    </>
  );
}

export default Step5Rol;
