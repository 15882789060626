//WHatsappNav

import React, {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../../../../../_metronic/helpers'
import {WhatsappLeadDetail, Whatsapp, Lead, LeadDetailInfo} from '../../../../interfaces/leadsTypes'
import {LoadingAnimation} from '../../../../../../utils/components/loading/LoadingAnimation'
import {useIntl} from 'react-intl'

type Props = {
  leadData: LeadDetailInfo | undefined
  currentNavSelected: string
}

export const WhatsappNav: FC<Props> = ({leadData, currentNavSelected}) => {
  const whatsappLeadInitialData: WhatsappLeadDetail = {
    whatsapps: [],
    whatsappsCount: 0,
  }
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  //Constante para el manejo de estado de la animacion de carga
  const [isLoading, setIsLoading] = useState<boolean>(true)

  //Constante para el manejo de estado del mensaje post carga de registros
  const [infoMessageLoading, setInfoMessageLoading] = useState<string>('')

  const [whatsappLeadDetail, setWhatsappLeadDetail] =
    useState<WhatsappLeadDetail>(whatsappLeadInitialData)

  const [sellerInteractionsCount, setSellerInteractionsCount] = useState<number>(0)

  //Funcion para verificar el tamaño del arreglo de whatsapps
  const checkWhatsappsListLength = () => {
    //Seteamos mensaje informativo
    whatsappLeadDetail.whatsapps.length === 0 &&
      setInfoMessageLoading(intl.formatMessage({id: 'LEADS.NO_WA_LOG_FOUND'}))
  }

  const getWhatsapps = async () => {
    // Simulación de la respuesta de la API

    setTimeout(() => {
      try {
        const whatsappLeadData: WhatsappLeadDetail = {
          whatsapps: [
            {
              whatsappLeadId: '123',
              whatsappDateAdd: '2024-05-01 12:12:00',
              whatsappName: 'Usuario',
              whatsappMail: 'usuario@example.com',
              whatsappPhone: '+123456789',
              whatsappChannel: 'Canal',
              whatsappInput: 'Input',
              whatsappUserOrAgentId: '456',
              whatsappLeadOrSeller: 'Lead',
              whatsappNumeration: 1,
              whatsappStatus: 'Activo',
              whatsappMessage: 'Hola, ¿cómo estás?',
            },
            {
              whatsappLeadId: '123',
              whatsappDateAdd: '2024-05-01 12:13:00',
              whatsappName: 'Vendedor',
              whatsappMail: 'vendedor@example.com',
              whatsappPhone: '+123456789',
              whatsappChannel: 'Canal',
              whatsappInput: 'Input',
              whatsappUserOrAgentId: '456',
              whatsappLeadOrSeller: 'Seller',
              whatsappNumeration: 2,
              whatsappStatus: 'Activo',
              whatsappMessage: 'Bien y ¿tú?',
            },
            {
              whatsappLeadId: '123',
              whatsappDateAdd: '2024-05-01 12:14:00',
              whatsappName: 'Usuario',
              whatsappMail: 'usuario@example.com',
              whatsappPhone: '+123456789',
              whatsappChannel: 'Canal',
              whatsappInput: 'Input',
              whatsappUserOrAgentId: '456',
              whatsappLeadOrSeller: 'Lead',
              whatsappNumeration: 3,
              whatsappStatus: 'Activo',
              whatsappMessage: '¿Qué estás haciendo?',
            },
            {
              whatsappLeadId: '123',
              whatsappDateAdd: '2024-05-01 12:15:00',
              whatsappName: 'Usuario',
              whatsappMail: 'usuario@example.com',
              whatsappPhone: '+123456789',
              whatsappChannel: 'Canal',
              whatsappInput: 'Input',
              whatsappUserOrAgentId: '456',
              whatsappLeadOrSeller: 'Lead',
              whatsappNumeration: 4,
              whatsappStatus: 'Activo',
              whatsappMessage: 'Nada, solo trabajando.',
            },
            {
              whatsappLeadId: '123',
              whatsappDateAdd: '2024-05-01 12:16:00',
              whatsappName: 'Vendedor',
              whatsappMail: 'vendedor@example.com',
              whatsappPhone: '+123456789',
              whatsappChannel: 'Canal',
              whatsappInput: 'Input',
              whatsappUserOrAgentId: '456',
              whatsappLeadOrSeller: 'Seller',
              whatsappNumeration: 5,
              whatsappStatus: 'Activo',
              whatsappMessage: 'Necesito tu información de contacto.',
            },
            {
              whatsappLeadId: '123',
              whatsappDateAdd: '2024-05-01 12:17:00',
              whatsappName: 'Usuario',
              whatsappMail: 'usuario@example.com',
              whatsappPhone: '+123456789',
              whatsappChannel: 'Canal',
              whatsappInput: 'Input',
              whatsappUserOrAgentId: '456',
              whatsappLeadOrSeller: 'Lead',
              whatsappNumeration: 6,
              whatsappStatus: 'Activo',
              whatsappMessage: 'Te la enviaré ahora mismo.',
            },
            {
              whatsappLeadId: '123',
              whatsappDateAdd: '2024-05-01 12:18:00',
              whatsappName: 'Vendedor',
              whatsappMail: 'vendedor@example.com',
              whatsappPhone: '+123456789',
              whatsappChannel: 'Canal',
              whatsappInput: 'Input',
              whatsappUserOrAgentId: '456',
              whatsappLeadOrSeller: 'Seller',
              whatsappNumeration: 7,
              whatsappStatus: 'Activo',
              whatsappMessage: 'Gracias.',
            },
            {
              whatsappLeadId: '123',
              whatsappDateAdd: '2024-05-01 12:19:00',
              whatsappName: 'Usuario',
              whatsappMail: 'usuario@example.com',
              whatsappPhone: '+123456789',
              whatsappChannel: 'Canal',
              whatsappInput: 'Input',
              whatsappUserOrAgentId: '456',
              whatsappLeadOrSeller: 'Lead',
              whatsappNumeration: 8,
              whatsappStatus: 'Activo',
              whatsappMessage: '¿Recibiste la información?',
            },
            {
              whatsappLeadId: '123',
              whatsappDateAdd: '2024-05-01 12:20:00',
              whatsappName: 'Vendedor',
              whatsappMail: 'vendedor@example.com',
              whatsappPhone: '+123456789',
              whatsappChannel: 'Canal',
              whatsappInput: 'Input',
              whatsappUserOrAgentId: '456',
              whatsappLeadOrSeller: 'Seller',
              whatsappNumeration: 9,
              whatsappStatus: 'Activo',
              whatsappMessage: 'Sí, la recibí correctamente.',
            },
            {
              whatsappLeadId: '123',
              whatsappDateAdd: '2024-05-01 12:21:00',
              whatsappName: 'Usuario',
              whatsappMail: 'usuario@example.com',
              whatsappPhone: '+123456789',
              whatsappChannel: 'Canal',
              whatsappInput: 'Input',
              whatsappUserOrAgentId: '456',
              whatsappLeadOrSeller: 'Lead',
              whatsappNumeration: 10,
              whatsappStatus: 'Activo',
              whatsappMessage: 'Perfecto, gracias.',
            },
          ],
          whatsappsCount: 10,
        }

        // Ordenar whatsapps por whatsappNumeration
        whatsappLeadData.whatsapps.sort((a, b) => a.whatsappNumeration - b.whatsappNumeration)

        setWhatsappLeadDetail(whatsappLeadData)
        setIsLoading(false)
        //Verificamos el tamaño del arreglo de whatsapps disparando la funcion
        checkWhatsappsListLength()

        // Contar interacciones del vendedor ('Seller')
        const sellerInteractions = whatsappLeadData.whatsapps.filter(
          (whatsapp) => whatsapp.whatsappLeadOrSeller === 'Seller'
        )
        setSellerInteractionsCount(sellerInteractions.length)
      } catch (error) {
        console.log('Error obteniedo las conversaciones: ', error)
        //Ocultamos la animacion de carga
        setIsLoading(false)
        //Seteamos mensaje informativo
        setInfoMessageLoading(intl.formatMessage({id: 'GLOBAL.ALERT_ERROR_AXIOS_REQUEST'}))
      }
    }, 2000)
  }

  useEffect(() => {
    getWhatsapps()
  }, [])

  return (
    <>
      <div className='container ml-15 mt-4 py-12' id='whatsapp-container'>
        {isLoading && (
          <div className='py-12'>
            <LoadingAnimation
              alignment='center'
              label={intl.formatMessage({ id: 'LEADS.WA_LOADING' })}
              size='lg'
            />
          </div>
        )}

        {!isLoading && whatsappLeadDetail.whatsapps.length > 0 && (
          <>
            <div>
              <span className='h5 mb-1'>
                {whatsappLeadDetail.whatsappsCount}{' '}
                {intl.formatMessage({id: 'LEADS.WA_LEAD_INTERACTIONS'})}
              </span>
              <span></span>
              <div>
                <div className='mb-2 mt-2 border-bottom border-dark mb-3'>
                  <div className='d-flex align-items-center mb-2'>
                    <KTIcon iconType='outline' iconName='user-square' className='fs-2 me-1' />
                    <span className='fs-5 text-gray-900 text-hover-primary'>
                      {intl.formatMessage({id: 'LEADS.WA_SELLER_INTERACTIONS_ONE'})}{' '}
                      {sellerInteractionsCount}{' '}
                      {intl.formatMessage({id: 'LEADS.WA_SELLER_INTERACTIONS_TWO'})}
                    </span>
                  </div>
                  <div className='d-flex align-items-center mb-3'>
                    <KTIcon
                      iconType='outline'
                      iconName='notification-on'
                      className='fs-2 me-1 text-success'
                    />
                    <span className='fs-5 text-gray-900 text-hover-primary'>
                      {intl.formatMessage({id: 'LEADS.WA_QA_SURVEY_SENT'})}
                    </span>
                  </div>
                </div>
              </div>
              <div className='mb-2 mt-2'>
                {whatsappLeadDetail.whatsapps.map((whatsapp: Whatsapp, index: number) => (
                  <div
                    key={whatsapp.whatsappLeadId}
                    className={`d-flex align-items-center ${
                      whatsapp.whatsappLeadOrSeller === 'Lead'
                        ? 'justify-content-start'
                        : 'justify-content-end'
                    } mb-10`}
                  >
                    <div className='d-flex flex-column'>
                      <div className='d-flex align-items-center mb-2'>
                        <KTIcon
                          iconType='outline'
                          iconName={`${
                            whatsapp.whatsappLeadOrSeller === 'Lead'
                              ? 'profile-circle'
                              : 'user-square'
                          }`}
                          className='fs-2 me-1'
                        />
                        <div>
                          <span className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'>
                            {whatsapp.whatsappName}
                          </span>
                          <span className='text-muted fs-5'>
                            {new Date(whatsapp.whatsappDateAdd).toLocaleTimeString([], {
                              hour: 'numeric',
                              minute: 'numeric',
                            })}
                          </span>
                        </div>
                      </div>
                      <div
                        className={`p-5 rounded ${
                          whatsapp.whatsappLeadOrSeller === 'Lead'
                            ? 'bg-light-info'
                            : 'bg-light-primary'
                        } text-dark fw-bold mw-lg-400px text-start`}
                        data-kt-element='message-text'
                      >
                        {whatsapp.whatsappMessage}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}

        {/* //Si isLoading es diferente a 'true' y el arreglo de whatsapps es iguala '0' mostramos mensaje de error */}
        {!isLoading && whatsappLeadDetail.whatsapps.length === 0 && (
          <div className='py-12'>
            <p>{infoMessageLoading}</p>
          </div>
        )}
      </div>
    </>
  )
}
