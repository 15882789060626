// Importamos constantes para realizar la peticion a la API de marcas
import { apiDragon, DragonMainApi, EPCampaignsMediumList } from "../../../utils/constants/_Constants";

// ---------------------------------------------------------------------------------------

// Importamos funcion para obtener codigos de respuesta del servidor
import { apiGetDeletePetitions } from "../../../../_metronic/helpers/apis";

// Funcion para realizar la peticion a la API de listado de medios de campaña por tipo de campaña
export async function campaignsMediumList(userToken: string, connectionType: string) {

    try {

        const data = await apiGetDeletePetitions(apiDragon, `${DragonMainApi+EPCampaignsMediumList+connectionType}`, {

            /* Los parametros se mandan como undefined 
            ya que estos se envian solo en peticiones de eliminacion 'delete' */
            undefined
        }, {

            'Content-Type': 'application/json',
            'x-token': userToken

        },
        
        'get');

        const jsonDataCampaignMediumList = data;

        return jsonDataCampaignMediumList;

    } catch (error) {
        console.error('Error en la peticion: ', error)
        throw error;

    }

}

// Funcion para realizar la peticion a la API de listado de medios
export async function allChannelsList(userToken: string) {

    try {

        const data = await apiGetDeletePetitions(apiDragon, `${DragonMainApi+EPCampaignsMediumList}`, {

            /* Los parametros se mandan como undefined 
            ya que estos se envian solo en peticiones de eliminacion 'delete' */
            undefined
        }, {

            'Content-Type': 'application/json',
            'x-token': userToken

        },
        
        'get');

        const jsonDataMediumList = data;

        return jsonDataMediumList;

    } catch (error) {

        throw error;

    }

}