import React, {FC, useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {DropdownExportLogs} from '../../../../../../_metronic/partials/content/dropdown/DropdownExportLogs'
import {
  Column,
  Table,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  FilterFn,
  ColumnDef,
  flexRender,
} from '@tanstack/react-table'
import {RankingInfo, rankItem} from '@tanstack/match-sorter-utils'
import {Button, Dropdown, DropdownButton, Modal, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {KTIcon} from '../../../../../../_metronic/helpers'
import {LoadingAnimation} from '../../../../../utils/components/loading/LoadingAnimation'
import {LeadsCrm, LeadData, Lead} from '../interfaces/crmReportTypes'
import {useIntl} from 'react-intl'

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>
  }
  interface FilterMeta {
    itemRank: RankingInfo
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)

  // Store the itemRank info
  addMeta({
    itemRank,
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

type Props = {
  cardSelected: number | undefined
}

export const CrmReportResponsesTable: FC<Props> = ({cardSelected}) => {
  console.log('renderizamos componente tabla')

  const intl = useIntl()

  const handleCopyToClipboard = (text: string) => {
    // Intentar copiar el texto al portapapeles
    navigator.clipboard.writeText(text).catch((error) => {
      console.error('Error al copiar al portapapeles:', error)
      alert('Error al copiar al portapapeles. Por favor, inténtelo de nuevo.')
    })
  }

  const renderTooltip = (props) => <Tooltip {...props}>Copiar id del registro</Tooltip>

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = useState('')
  const [dataToExport, setDataToExport] = useState({})

  const [leadsList, setLeadsList] = useState<LeadsCrm[]>([])

  //Constante para el manejo de estado de la animacion de carga
  const [isLoading, setIsLoading] = useState<boolean>(true)
  //Constante para el manejo de estado del mensaje post carga de registros
  const [infoMessageLoading, setInfoMessageLoading] = useState<string>('')

  //Función para definir la data que se va a exportar
  const handleSetData = () => {
    const dataToExport = table.getRowModel().flatRows.map((row) => row.original)
    setDataToExport(dataToExport)
  }

  //Funcion para navegar a la apgina de detalle de lead pasando el objeto lead
  const navigate = useNavigate()
  const toLeadDetail = (lead) => {
    navigate('/lead-detail/', {state: lead})
  }

  const checkLeadsListLength = () => {
    leadsList.length === 0 && setInfoMessageLoading('No se encontro ningún lead')
  }

  const getLeadsList = () => {
    setTimeout(() => {
      try {
        const newLeadsList = [
          {
            id: '0004',
            leadId: '234547',
            apiName: 'SalesUp!',
            estatus: '1',
            dataSent: '',
            apiId: 'P-0F1CE5C8-6EEE-4749-9040-053763827F21',
            apiMsg: 'Ok',
            apiResp:
              '[{"code":0,"msg":"Ok","details":[{"tkContacto":"P-680F1AD9-F1C5-4F88-BE5C-EA29D0CBF2B2"}]}]',
            dateAdd: '2024-04-30',
            productName: 'I.T.',
            productId: 'POLL-6723-MC',
            campaignName: 'Referenciado',
            campaignId: 'CMA-50355-LO',
            channelName: 'Facebook Ads',
            channelId: 'FB-CH-01',
          },
          {
            id: '0003',
            leadId: '234546',
            apiName: 'SalesUp!',
            estatus: '1',
            dataSent: '',
            apiId: 'P-0F1CE5C8-6EEE-4749-9040-053763827F21',
            apiMsg: 'Ok',
            apiResp:
              '[{"code":0,"msg":"Ok","details":[{"tkContacto":"P-680F1AD9-F1C5-4F88-BE5C-EA29D0CBF2B2"}]}]',
            dateAdd: '2024-04-30',
            productName: 'MKT',
            productId: 'POLL-9845-MC',
            campaignName: 'Pruebas MKT',
            campaignId: 'CMA-43534-LO',
            channelName: 'Facebook Ads',
            channelId: 'FB-CH-01',
          },
          {
            id: '0002',
            leadId: '234544',
            apiName: 'SalesUp!',
            estatus: '1',
            dataSent: '',
            apiId: 'P-0F1CE5C8-6EEE-4749-9040-053763827F21',
            apiMsg: 'Ok',
            apiResp:
              '[{"code":0,"msg":"Ok","details":[{"tkContacto":"P-680F1AD9-F1C5-4F88-BE5C-EA29D0CBF2B2"}]}]',
            dateAdd: '2024-04-24',
            productName: 'MKT',
            productId: 'POLL-9845-MC',
            campaignName: 'Pruebas MKT',
            campaignId: 'CMA-43534-LO',
            channelName: 'Facebook Ads',
            channelId: 'FB-CH-01',
          },
          {
            id: '0001',
            leadId: '234543',
            apiName: 'SalesUp!',
            estatus: '0',
            dataSent: '',
            apiId: 'P-0F1CE5C8-6EEE-4749-9040-053763827F21',
            apiMsg:
              'Upnify Timeout (60s): Operation timed out after 60002 milliseconds with 0 bytes received',
            apiResp:
              'Upnify Timeout (60s): Operation timed out after 60002 milliseconds with 0 bytes received',
            dateAdd: '2024-04-23',
            productName: 'I.T.',
            productId: 'POLL-6723-MC',
            campaignName: 'Gallardetes',
            campaignId: 'CMA-50355-LO',
            channelName: 'Facebook Ads',
            channelId: 'FB-CH-01',
          },
        ]

        if (cardSelected === 4) {
          const leadsPositiveResponse = newLeadsList.filter((lead) => lead.estatus === '1')
          setLeadsList(leadsPositiveResponse)
        } else {
          const leadsNegativeResponse = newLeadsList.filter((lead) => lead.estatus === '0')
          setLeadsList(leadsNegativeResponse)
        }

        setIsLoading(false)
        checkLeadsListLength()
      } catch (error) {
        console.log(intl.formatMessage({id: 'REPORT.CRM.ERROR_GETTING_LEADS'}), error)
        setInfoMessageLoading(intl.formatMessage({id: 'GLOBAL.REQUEST_ERROR'}))
      }
    }, 2000)
  }

  useEffect(() => {
    getLeadsList()
  }, [])

  const columns = React.useMemo<ColumnDef<LeadsCrm, any>[]>(
    () => [
      {
        header: 'Registro de leads recibidos',
        footer: (props) => props.column.id,
        columns: [
          {
            accessorKey: 'leadId',
            cell: (info) => (
              <button
                className='btn btn-light-primary'
                onClick={() => {
                  toLeadDetail(info.row.original)
                }}
              >
                {info.getValue()}
              </button>
            ),
            header: () => <span>Id</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'dateAdd',
            cell: (info) => info.getValue(),
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.DATE'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'apiName',
            cell: (info) => (
              <div>
                <p>{info.row.original.apiName}</p>
              </div> // Mostrar teléfono y WhatsApp separados por /
            ),
            filterValue: (rows, id, filterValue) => {
              return rows.filter((row) => row.original.apiName.includes(filterValue))
            },
            header: () => <span>CRM</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'apiMsg',
            cell: (info) => (
              <div>
                <p>{info.row.original.apiMsg}</p>
              </div> // Mostrar teléfono y WhatsApp separados por /
            ),
            filterValue: (rows, id, filterValue) => {
              return rows.filter((row) => row.original.apiMsg.includes(filterValue))
            },
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.MESSAGE'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'productName',
            cell: (info) => (
              <div>
                <p>{info.row.original.productName}</p>
              </div> // Mostrar teléfono y WhatsApp separados por /
            ),
            filterValue: (rows, id, filterValue) => {
              return rows.filter((row) => row.original.productName.includes(filterValue))
            },
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.PRODUCT'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'campaignName', // Nueva clave de acceso para la combinación de teléfono y WhatsApp
            cell: (info) => (
              <div>
                <p>{info.row.original.campaignName}</p>
              </div> // Mostrar teléfono y WhatsApp separados por /
            ),
            filterValue: (rows, id, filterValue) => {
              return rows.filter((row) => row.original.campaignName.includes(filterValue))
            },
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.CAMPAIGN'})}</span>,
            footer: (props) => props.column.id,
          },
        ],
      },
    ],
    []
  )

  const table = useReactTable({
    data: leadsList,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  })

  React.useEffect(() => {
    if (table.getState().columnFilters[1]?.id === 'name') {
      if (table.getState().sorting[0]?.id !== 'name' || table.getState().sorting[0]?.desc) {
        table.setSorting([{id: 'name', desc: false}])
      }
    }
  }, [table.getState().columnFilters[1]?.id])

  return (
    <>
      <div className='card card-custom'>
        {isLoading && (
          <div className='py-12'>
            <LoadingAnimation
              alignment='center'
              label={intl.formatMessage({id: 'LEADS.GENERATING_TABLE'})}
              size='lg'
            />
          </div>
        )}

        {!isLoading && leadsList.length > 0 && (
          <div className='p-2'>
            <div className='card-header pt-6 pb-6'>
              <div className='d-flex'>
                <DebouncedInput
                  value={globalFilter ?? ''}
                  onChange={(value) => setGlobalFilter(String(value))}
                  className='form-control form-control-solid w-250px ps-14 me-3'
                  placeholder={intl.formatMessage({id: 'GLOBAL.TABLE_SEARCH_RECORD'})}
                />

                <button
                  type='button'
                  className='btn btn-sm btn-primary me-3'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  onClick={handleSetData}
                >
                  <KTIcon iconType='outline' iconName='file-down' className='fs-2 me-2' />{' '}
                  <span>{intl.formatMessage({id: 'GLOBAL.DATA_EXPORT'})}</span>
                </button>

                <DropdownExportLogs dataToExport={dataToExport} title={'Logs'} />
              </div>
            </div>
            <div className='h-2' />
            <div className='card-body table-responsive'>
              <table className='table table-row-bordered gs-3 gy-4 gx-12'>
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr
                      key={headerGroup.id}
                      className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'
                    >
                      {headerGroup.headers.map((header) => {
                        return (
                          <th key={header.id} colSpan={header.colSpan}>
                            {header.isPlaceholder ? null : (
                              <>
                                <div
                                  {...{
                                    className: header.column.getCanSort()
                                      ? 'cursor-pointer select-none'
                                      : '',
                                    onClick: header.column.getToggleSortingHandler(),
                                  }}
                                >
                                  {flexRender(header.column.columnDef.header, header.getContext())}
                                  {{
                                    asc: ' 🔼',
                                    desc: ' 🔽',
                                  }[header.column.getIsSorted() as string] ?? null}
                                </div>
                                {header.column.getCanFilter() ? (
                                  <div>
                                    <Filter column={header.column} table={table} />
                                  </div>
                                ) : null}
                              </>
                            )}
                          </th>
                        )
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row) => {
                    return (
                      <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => {
                          return (
                            <td key={cell.id}>
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>

            <div className='h-2' />

            <div className='card-footer'>
              <div className='d-flex justify-content-between gap-2'>
                <div id='SwitchPages'>
                  <button
                    className='btn btn-primary border rounded p-1'
                    onClick={() => table.setPageIndex(0)}
                    disabled={!table.getCanPreviousPage()}
                  >
                    {'<<'}
                  </button>
                  <button
                    className='btn btn-primary border rounded p-1'
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                  >
                    {'<'}
                  </button>
                  <button
                    className='btn btn-primary border rounded p-1'
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                  >
                    {'>'}
                  </button>
                  <button
                    className='btn btn-primary border rounded p-1'
                    onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                    disabled={!table.getCanNextPage()}
                  >
                    {'>>'}
                  </button>
                </div>

                <div id='PagesFinder' className='text-center'>
                  {' '}
                  <span className='flex items-center gap-1'>
                    {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_PAGE'})}{' '}
                    <strong>
                      {table.getState().pagination.pageIndex + 1}{' '}
                      {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_PAGE_OF'})}{' '}
                      {table.getPageCount()}
                    </strong>{' '}
                    | {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_GO_TO_PAGE'})}:
                    <input
                      type='number'
                      defaultValue={table.getState().pagination.pageIndex + 1}
                      onChange={(e) => {
                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                        table.setPageIndex(page)
                      }}
                      className='form-control form-control-solid w-16'
                    />
                  </span>
                </div>

                <div id='showFiles' className='text-center'>
                  <select
                    className='form-select form-select-solid'
                    value={table.getState().pagination.pageSize}
                    onChange={(e) => {
                      table.setPageSize(Number(e.target.value))
                    }}
                  >
                    {[10, 20, 30, 40, 50].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_SHOW_PART_ONE'})} {pageSize}{' '}
                        {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_SHOW_PART_TWO'})}
                      </option>
                    ))}
                  </select>
                  <p>
                    {table.getPrePaginationRowModel().rows.length}{' '}
                    {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_TOTAL_RECORDS'})}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        {!isLoading && leadsList.length === 0 && (
          <div className='py-12 px-10'>
            <p>{infoMessageLoading}</p>
          </div>
        )}
      </div>
    </>
  )
}

function Filter({column, table}: {column: Column<any, unknown>; table: Table<any>}) {
  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id)
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()
  const columnFilterValue = column.getFilterValue()

  const sortedUniqueValues = React.useMemo(
    () =>
      typeof firstValue === 'number'
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  )

  return typeof firstValue === 'number' ? (
    <div>
      <div className='flex space-x-2'>
        <DebouncedInput
          type='number'
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[0] ?? ''}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [value, old?.[1]])}
          placeholder={`Min ${
            column.getFacetedMinMaxValues()?.[0] ? `(${column.getFacetedMinMaxValues()?.[0]})` : ''
          }`}
          className='w-24 border shadow rounded'
        />
        <DebouncedInput
          type='number'
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[1] ?? ''}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [old?.[0], value])}
          placeholder={`Max ${
            column.getFacetedMinMaxValues()?.[1] ? `(${column.getFacetedMinMaxValues()?.[1]})` : ''
          }`}
          className='w-24 border shadow rounded'
        />
      </div>
      <div className='h-1' />
    </div>
  ) : (
    <>
      <datalist id={column.id + 'list'}>
        {sortedUniqueValues.slice(0, 5000).map((value: any) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <DebouncedInput
        type='text'
        value={(columnFilterValue ?? '') as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`${intl.formatMessage({id: 'GLOBAL.TABLE_SEARCH_THREE_DOTS'})} (${column.getFacetedUniqueValues().size})`}
        className='w-36 border shadow rounded'
        list={column.id + 'list'}
      />
      <div className='h-1' />
    </>
  )
}

// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return <input {...props} value={value} onChange={(e) => setValue(e.target.value)} />
}
