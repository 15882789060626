import { apiDragon, DragonMainApi, EPGradesSetupList } from "../constants/_Constants";

import { apiGetDeletePetitions } from "../../../_metronic/helpers/apis";

export async function gradesSetupListByProduct(userToken: string, productId: string) {
    try {

        const data = await apiGetDeletePetitions(apiDragon, `${DragonMainApi+EPGradesSetupList+productId}`, {
            undefined
        }, {
            'Content-Type': 'application/json',
            'x-token': userToken
        }, 'get')

        const jsonDataGradesSetupList = data
        console.log(jsonDataGradesSetupList)
        return jsonDataGradesSetupList;

    } catch (error) {
        console.error('Error obteniendo la configuracion de calificaciones: ', error)
        throw error;
    }
}