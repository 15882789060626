import {ChangeEvent, FC, useContext, useEffect, useState} from 'react'
import {Lead} from '../interfaces/leadsTypes'
import {UserContext} from '../../../app-context/user-context/UserContext'
import {sellersListByCampaign} from '../../control-sellers/sellers/services/_SellersListApi'
import {sessionExpired} from '../../../utils/functions/_Functions'
import {Modal} from 'react-bootstrap'
import {leadReassign} from '../services/_LeadReassignApi'
import {AlertInfo} from '../../../utils/interfaces/_InterfacesTypes'
import {FormDataReasignLead} from '../interfaces/leadsTypes'
import {SellerList} from '../../../utils/interfaces/_InterfacesTypes'
import {ModalAdviceDefaultSize} from '../../../utils/components/modals/ModalAdviceDefaultSize'
import {useIntl} from 'react-intl'

type Props = {
  lead: Lead
  getLeadsByClient: () => void
  closeModalLeadReasign: () => void
}

const ModalReasignLead: FC<Props> = ({lead, getLeadsByClient, closeModalLeadReasign}) => {
  const initialFormDataReasignLead: FormDataReasignLead = {
    leadId: lead.lead_id,
    usrAssigned: '',
    reassignReasson: '',
  }
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  const [formDataReasignLead, setFormDataReassignLead] = useState<FormDataReasignLead>(
    initialFormDataReasignLead
  )

  const [showModalAdvice, setShowModalAdvice] = useState<boolean>(false)

  const openModalAdvice = () => {
    setShowModalAdvice(true)
  }

  const closeModalAdvice = () => {
    setShowModalAdvice(false)
  }

  const [alertInfo, setAlertInfo] = useState<AlertInfo>({
    text: '',
    variant: 'primary',
  })

  //Variables para manejo de estado de errores en inputs del formulario
  const [errors, setErrors] = useState<{[key: string]: string | undefined}>({})

  //Algunas constantes desde el userContext
  const {user, token, updateToken} = useContext(UserContext)
  const clientId: string = user.cli_id
  const userToken: string = token

  const [sellersList, setSellersList] = useState<SellerList[]>([])

  console.log(lead.cam_id)

  const getSellersListByCampaign = async (): Promise<void> => {
    try {
      const {data: jsonData, code, msg} = await sellersListByCampaign(userToken, lead.cam_id)

      if (code === 401 && msg === 'Token no valido') {
        sessionExpired(intl.formatMessage({id: 'GLOBAL.SESSION_EXPIRED'}))
        return
      } else {
        const sellersListReceived: SellerList[] = jsonData.data

        const userTokenNew: string = jsonData.token

        updateToken(userTokenNew)

        console.log({sellersListReceived})

        const sellerOption = sellersListReceived.map((seller: SellerList) => {
          return {
            user_id: seller.user_id,
            usr_name: seller.usr_name,
          }
        })

        setSellersList(sellerOption)
      }
    } catch (error) {
      console.log('Error obteniendo el listado de vendedores: ', error)
    }
  }

  const handleSellerSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const {name, value} = e.target

    // if (value !== '') {
    //     setErrors((prevErrors) => ({
    //         ...prevErrors,
    //         userAssigned: undefined,
    //     }))
    // }

    // if (value === '') {
    //     setErrors((prevErrors) => ({
    //         ...prevErrors,
    //         userAssigned: 'Debes seleccionar un nuevo vendedor',
    //     }))
    // }

    setFormDataReassignLead((prevData) => ({
      ...prevData,
      usrAssigned: value,
    }))
  }

  const handleReason = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const {name, value} = e.target

    console.log(value)

    if (value !== '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        reassignReasson: undefined,
      }))
    }

    if (value === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        reassignReasson: intl.formatMessage({id: 'GLOBAL.INFO_COMPLETE_THIS_FIELD'}),
      }))
    }

    setFormDataReassignLead((prevData) => ({
      ...prevData,
      reassignReasson: value,
    }))
  }

  const handleFormSubmit = async (): Promise<void> => {
    console.log('se ha enviado el form')

    const leadId: string = formDataReasignLead.leadId
    const usrAssigned: string = formDataReasignLead.usrAssigned
    const reassignReasson: string = formDataReasignLead.reassignReasson

    try {
      const {
        data: jsonData,
        code,
        msg,
      } = await leadReassign(userToken, leadId, usrAssigned, reassignReasson)

      if (code === 401 && msg === 'Token no valido') {
        sessionExpired('Ha expirado la sesion')
        return
      } else {
        setAlertInfo({
          text:
            code === 200
              ? intl.formatMessage({id: 'LEADS.REASSIGN_SUCCESS'})
              : intl.formatMessage({id: 'LEADS.REASSIGN_FAILURE'}),
          variant: code === 200 ? 'success' : 'danger',
        })

        setTimeout(() => {
          setAlertInfo({
            text: '',
            variant: '',
          })
          closeModalLeadReasign()
          setFormDataReassignLead(initialFormDataReasignLead)
          setErrors({})
          getLeadsByClient()
        }, 3000)
      }
    } catch (error) {
      setAlertInfo({
        text: intl.formatMessage({id: 'GLOBAL.ALERT_ERROR_AXIOS_REQUEST'}),
        variant: 'danger',
      })
      closeModalLeadReasign()
      setFormDataReassignLead(initialFormDataReasignLead)
      setErrors({})
    }
  }

  const checkFormToSubmit = () => {
    // if (formDataReasignLead.usrAssigned === '') {
    //     setErrors((prevErrors) => ({
    //         ...prevErrors,
    //         userAssigned: 'Debes seleccionar un nuevo vendedor'
    //     }))
    //     return
    // }

    if (formDataReasignLead.reassignReasson === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        reassignReasson: intl.formatMessage({id: 'LEADS.REASSIGN_REASON_REQUIRED'}),
      }))
      return
    }

    if (formDataReasignLead.usrAssigned === '') {
      openModalAdvice()
    } else {
      handleFormSubmit()
    }
  }

  useEffect(() => {
    getSellersListByCampaign()
  }, [lead])

  return (
    <>
      <Modal.Header
        closeButton
        onClick={() => {
          closeModalLeadReasign()
          setFormDataReassignLead(initialFormDataReasignLead)
          setErrors({})
        }}
      >
        <Modal.Title>
          <h1>{intl.formatMessage({id: 'LEADS.LEAD_REASSIGN'})}</h1>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className='alert alert-dismissible bg-light-warning border border-warning mb-4'>
          <div className='d-flex flex-column flex-sm-row p-3'>
            <span className='svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0 text-dark'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                fill='currentColor'
                className='bi bi-exclamation-triangle-fill'
                viewBox='0 0 16 16'
              >
                <path d='M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2' />
              </svg>
            </span>
            <div className='d-flex flex-column text-primary pe-0 pe-sm-10'>
              {/* <h5 className='mb-1'>Activar producto</h5> */}
              <span className='text-dark'>
                <h4 className='mb-4'>{intl.formatMessage({id: 'LEADS.REASSIGN_CONFIRMATION'})}</h4>
                <div className='d-flex'>
                  <p className='fw-bold'>
                    {intl.formatMessage({id: 'LEADS.INFO_CARD_LEAD_NAME'})}:{' '}
                  </p>
                  &nbsp;&nbsp;
                  <p>{lead.lead_data.name}</p>
                </div>
                <div className='d-flex'>
                  <span className='fw-bold'>
                    {intl.formatMessage({id: 'LEADS.INFO_CARD_LEAD_EMAIL'})}:{' '}
                  </span>
                  &nbsp;&nbsp;
                  <p>{lead.lead_data.lead_mail}</p>
                </div>
                <div className='d-flex'>
                  <span className='fw-bold'>
                    {intl.formatMessage({id: 'LEADS.INFO_CARD_LEAD_PHONE'})}:{' '}
                  </span>
                  &nbsp;&nbsp;
                  <p>{lead.lead_data.lead_phone}</p>
                </div>
                <div className='d-flex'>
                  <span className='fw-bold'>
                    {intl.formatMessage({id: 'LEADS.CURRENT_SELLER'})}:{' '}
                  </span>
                  &nbsp;&nbsp;
                  <p>{lead.seller_name}</p>
                  {/* <span className='font-weight-bold'><strong>Vendedor: </strong></span>Sergio Castaneda{' '} */}
                </div>
              </span>
            </div>
          </div>
        </div>

        <div className='mb-5'>
          <label className='fs-6 fw-bold'>
            {intl.formatMessage({id: 'LEADS.SELECT_NEW_SELLER'})}
          </label>
          <select
            className='form-select form-select-solid'
            // className={`form-select form-select-solid mb-3 mb-lg-0 ${errors.userAssigned && 'is-invalid'}`}
            name='userAssigned'
            value={formDataReasignLead.usrAssigned}
            onChange={(e) => {
              handleSellerSelect(e)
            }}
          >
            <option value=''>--{intl.formatMessage({id: 'LEADS.ACTIVE_SELLERS'})}--</option>
            {sellersList.map((seller: SellerList) => (
              <option key={seller.user_id} value={seller.user_id}>
                {seller.usr_name}
              </option>
            ))}
          </select>
          {/* {errors.userAssigned && <div className='invalid-feedback'>{errors.userAssigned}</div>} */}
        </div>

        <div className='mb-5'>
          <div>
            <label className='fs-6 fw-bold required'>
              {intl.formatMessage({id: 'GLOBAL.REASON'})}
            </label>
            <textarea
              placeholder={intl.formatMessage({id: 'LEADS.REASSIGN_REASON_PLACEHOLDER'})}
              className={`form-control form-control-solid mb-3 mb-lg-0 ${errors.reassignReasson && 'is-invalid'}`}
              name='reassignReasson'
              value={formDataReasignLead.reassignReasson}
              rows={2}
              onChange={(e) => {
                handleReason(e)
              }}
            />
            {errors.reassignReasson && (
              <div className='invalid-feedback'>{errors.reassignReasson}</div>
            )}
          </div>
        </div>

        {alertInfo.text && (
          <div className='px-20'>
            <div
              className={`alert alert-${alertInfo.variant} d-flex align-items-center justify-content-center text-center`}
              role='alert'
            >
              <div>{alertInfo.text}</div>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          className='btn btn-sm btn-primary'
          type='submit'
          onClick={() => {
            checkFormToSubmit()
          }}
        >
          {intl.formatMessage({id: 'GLOBAL.REASSIGN'})}
        </button>

        <button
          className='btn btn-sm btn-secondary'
          type='button'
          onClick={() => {
            closeModalLeadReasign()
            setFormDataReassignLead(initialFormDataReasignLead)
            setErrors({})
          }}
        >
          {intl.formatMessage({id: 'GLOBAL.CANCEL'})}
        </button>
      </Modal.Footer>

      <ModalAdviceDefaultSize
        titleAdvice={intl.formatMessage({id: 'LEADS.NO_SELLER_SELECTED'})}
        descriptionAdvice={intl.formatMessage({id: 'LEADS.AUTO_ASSIGN_DESCRIPTION'})}
        showModalAdvice={showModalAdvice}
        setShowModalAdvice={setShowModalAdvice}
        openModalAdvice={openModalAdvice}
        closeModalAdvice={closeModalAdvice}
        handleFormSubmit={handleFormSubmit}
      />
    </>
  )
}

export {ModalReasignLead}
