import { YearlyProductsReport } from './aux-components/yearlyTabs/YearlyProductsReport'
import { YearlySellersReport } from './aux-components/yearlyTabs/YearlySellersReport'
import { YearlyChannelsReport } from './aux-components/yearlyTabs/YearlyChannelsReport'
import { sessionExpired } from '../../../../utils/functions/_Functions'
import { productsListAssignedByUser } from '../../../products/services/_ProductsListApi'
import { sellersListByClient } from '../../../control-sellers/sellers/services/_SellersListApi'
import { allChannelsList } from '../../../campaigns/services/_CampaignsMediumListApi'
import { UserContext } from '../../../../app-context/user-context/UserContext'
import { useContext, useState, useEffect } from 'react'
import { Product, Seller, UsrData, Channel } from './interfaces/yearlyReportTypes'
import { useIntl } from 'react-intl'

export const YearlyReport = () => {

  //Algunas variables desde el usercontext
  const { user, token, updateToken } = useContext(UserContext)
  const clientId = user.cli_id
  const userToken = token
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  const [productsList, setProductsList] = useState<Product[]>([])
  const [sellersList, setSellersList] = useState<Seller[]>([])
  const [channelsList, setChannelsList] = useState<Channel[]>([])
  const [isSessionExpired, setIsSessionExpired] = useState(false)

  const getProductsByUser = async (): Promise<void> => {

    if (isSessionExpired) return

    try {
      const { data: jsonData, code, msg } = await productsListAssignedByUser(userToken)

      // if (code === 401 && msg === 'Token no valido') {
        // sessionExpired('La sesion ha expirado')
        // setIsSessionExpired(true)
        // return

      // } else {
        const productsListReceived = jsonData.data
        const userTokenNew = jsonData.token

        updateToken(userTokenNew)

        const productsItem = productsListReceived.map((product: Product) => {
          return {
            pro_id: product.pro_id,
            name: product.name,
          }
        })

        setProductsList(productsItem)

      // }

    } catch (error) {
      console.log('Error obteniendo el listado de productos: ', error)
    }
  }

  const getSellersByClient = async (): Promise<void> => {
    try {
      const { data: jsonData, code, msg } = await sellersListByClient(userToken, clientId)

      // if (code === 401 && msg === 'Token no valido') {
        // sessionExpired('La sesion ha expirado')
        // return
      // } else {
        const sellersListReceived = jsonData.data
        const userTokenNew = jsonData.token

        console.log({ sellersListReceived })

        updateToken(userTokenNew)

        const sellersItem = sellersListReceived.map((seller: Seller) => {

          const usrData: UsrData = {
            usr_name: seller.usrData.usr_name,
          }

          return {
            uid: seller.uid,
            usrData,
          }
        })

        setSellersList(sellersItem)

      // }

    } catch (error) {
      console.log('Error obteniendo el listado de vendedores: ', error)
    }
  }

  const getChannelsByClient = async (): Promise<void> => {
    try {
      const { data: jsonData, code, msg } = await allChannelsList(userToken)

      // if (code === 401 && msg === 'Token no valido') {
        // sessionExpired('La sesion ha expirado')
        // return
      // } else {
        const channelsListReceived = jsonData.data
        const userTokenNew = jsonData.token

        console.log({ channelsListReceived })

        updateToken(userTokenNew)

        const channelsItem = channelsListReceived.map((channel: Channel) => {
          return {
            name: channel.name,
            bu: channel.bu,
            channel_id: channel.channel_id

          }
        })

        setChannelsList(channelsItem)

      // }

    } catch (error) {
      console.log('Error obteniendo el listado de canales: ', error)
    }
  }

  useEffect(() => {
    getProductsByUser()
    getSellersByClient()
    getChannelsByClient()
  }, [])

  return (
    <>
      <div className='container-fluis'>
        <div className='row justify-content-center mb-2'>
          <div className='col-md-12'>
            <div className='d-flex flex-wrap flex-stack mb-6'>
              <h1 className='my-2 d-flex flex-wrap flex-stack'>
                {intl.formatMessage({id: 'REPORTS.YEARLY.ANNUAL_REPORT'})}
              </h1>
            </div>
          </div>
        </div>

        <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6 d-flex justify-content-center'>
          <li className='nav-item'>
            <a className='nav-link fs-3 active' data-bs-toggle='tab' href='#kt_tab_pane_4'>
              {intl.formatMessage({id: 'REPORTS.YEARLY.REPORT_BY_PRODUCT'})}
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link fs-3' data-bs-toggle='tab' href='#kt_tab_pane_5'>
              {intl.formatMessage({id: 'REPORTS.YEARLY.REPORT_BY_CHANNEL'})}
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link fs-3' data-bs-toggle='tab' href='#kt_tab_pane_6'>
              {intl.formatMessage({id: 'REPORTS.YEARLY.REPORT_BY_SELLER'})}
            </a>
          </li>
        </ul>

        <div className='tab-content' id='myTabContent'>
          <div className='tab-pane py-5 fade show active' id='kt_tab_pane_4' role='tabpanel'>
            <YearlyProductsReport productsList={productsList} />
          </div>
          <div className='tab-pane py-5 fade' id='kt_tab_pane_5' role='tabpanel'>
            <YearlyChannelsReport channelsList={channelsList} />
          </div>
          <div className='tab-pane py-5 fade' id='kt_tab_pane_6' role='tabpanel'>
            <YearlySellersReport productsList={productsList} sellersList={sellersList} channelsList={channelsList} />
          </div>
        </div>

      </div>
    </>
  )
}
