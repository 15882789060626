// Importamos las constantes para realizar la peticion a la API de campañas
import { DragonMainApi, EPAutoCampaignNew, EPManualCampaignNew, apiDragon } from "../../../utils/constants/_Constants";

// ---------------------------------------------------------------------------------------

// Importamos funcion para obtener codigos de respuesta del servidor
import { apiPetitions } from "../../../../_metronic/helpers/apis";

// Funcion para realizar la peticion a la API de creacion de campañas automaticas
export async function autoCampaignsNew(cli_id: string, productId: string, automaticsCampaigns: string[], userToken: string){
    try {
        const response = await apiPetitions(apiDragon,`${DragonMainApi + EPAutoCampaignNew}`, {

            cliId: cli_id,
            proId: productId,
            automaticsCampaigns,

        }, {

            'Content-Type': 'application/json',
            'x-token': userToken,

        },

        'post');

        const jsonDataAutoCampaignsNew = response;

        return jsonDataAutoCampaignsNew;

    } catch (error) {

        throw error;

    }

}

// Funcion para realizar la peticion a la API de creacion de campañas manuales
export async function manualCampaignsNew(
    productId: string, 
    roleId: string,
    channelId: string, 
    connectionType: string, 
    medium: string, 
    name: string, 
    mailTemplate: string, 
    channelCrmId: string, 
    startHour: string, 
    endHour: string, 
    quality: boolean, 
    wabMessages: string,
    wabBot: string,
    setOption: string,
    crmId: string, 
    crmSetup: number,
    iscore: number, 
    camPhone: string,
    wabLinkMsg: string,
    clientId: string, 
    userToken: string) {

    try {

        const response = await apiPetitions(apiDragon,`${DragonMainApi + EPManualCampaignNew}`, {
            
            proId: productId,
            roleId,
            channelId,
            connectionType,
            medium,
            name,
            mailTemplate,
            channelCrmId,
            startHour,
            endHour,
            quality,
            wabMessages,
            wabBot,
            setOption,
            crmId,
            crmSetup,
            iscore,
            camPhone,
            wabLinkMsg,
            cliId: clientId,

        }, {

            'Content-Type': 'application/json',
            'x-token': userToken,

        },
        
        'post');

        const jsonDataManualCampaignsNew = response;

        console.log(jsonDataManualCampaignsNew)

        return jsonDataManualCampaignsNew;

    } catch (error) {

        throw error;

    }

}