import { ClientsNew } from "../../services/_ClientsNewApi";
import { UserContext } from "../../../../app-context/user-context/UserContext";
import React, { useContext, useState, useEffect } from 'react';
import { useIntl } from "react-intl";
import { sessionExpired } from "../../../../utils/functions/_Functions";

function Step4({data, handlePrev, onClose}){

    const { user, token, updateToken} = useContext(UserContext);
    const clientId = user.cli_id;
    const userToken = token;

    //Variable para el uso de diccionario de lenguajes
    const intl = useIntl();

    const [alertInfo, setAlertInfo] = useState({
      text: '',
      variant: 'primary',
    });

    const [loading, setLoading] = useState(false);

    const handleFormSubmit = async () => {

        

        const cli_name = data.clientName;
        const cli_rfc = data.clientRfc;
        const cli_phone = data.clientPhone;
        const cli_employes = data.clientEmployeeNumber;
        const cli_industry = data.clientIndustry;
        const cli_class = data.clientSize;
        const sub_industry = data.clientGiro;
        const cli_id = clientId;
        const name = data.clientUserName;
        const phone = data.clientCallPhone;
        const whatsapp = data.clientWhatsPhone;
        const mail = data.clientUserMail;
        const pasword = data.clientPass1
        const validPassword = data.clientPass2;
        const name_brand = data.brandName;
        const pro_name = data.productName;
        const pro_price = data.productPrice;
        const pro_type = data.productType;
        const pro_country = data.productCountry;
        const pro_state = data.productState;
        const pro_city = data.productCity;
        const pro_zone = data.productZone;
        const pro_mails = data.productMailsUsers

        console.log(cli_name, cli_rfc, cli_phone, cli_employes, cli_industry, cli_class, sub_industry, cli_id, name, phone, whatsapp, mail, pasword, validPassword, name_brand, pro_name, pro_price, pro_type, pro_country, pro_state, pro_city, pro_zone, pro_mails)
       

        try {

        setLoading(true); // Inicia la animación de carga
      
        // Llamar a la función que ejecuta el servicio del editar vendedor
         const {data: jsonData, code, msg} = await ClientsNew(cli_name, cli_rfc, cli_phone, cli_employes, cli_industry, cli_class, sub_industry, cli_id, name, phone, whatsapp, mail, pasword, validPassword, name_brand, pro_name, pro_price, pro_type, pro_country, pro_state, pro_city, pro_zone, pro_mails, userToken);

         
        const userTokenNew = jsonData.token;
        updateToken(userTokenNew);

         setAlertInfo({
          text: code === 200
          ? '¡Marca creada correctamente!' : '¡Algo salió mal!',
          variant: code === 200 ? 'success' : 'danger',
        });

        console.log(msg)

        setTimeout(() => {
          setLoading(false); // Detiene la animación de carga
          if (code === 200) {
            setAlertInfo({
              text: '',
              variant: '',
            });
            onClose();
          }
        }, 3000);

         // Validación del mensaje y llamada a la función limpiarYRecargar
        if (msg === 'Token no valido') {
          sessionExpired(intl.formatMessage({ id: "GLOBAL.ALERT.SESSION_EXPIRED" }));
        }
      
        }  catch (error) {
          console.error("Error en el crear cliente", error);
          setAlertInfo({
            text: 'Error en la solicitud Axios',
            variant: 'danger',
          });
        }

    };

    return(
        <div>
        <h3>{intl.formatMessage({id: 'CLIENTS.CAMPAIGNS'})}</h3>
            <span className="text-muted">{intl.formatMessage({id: 'CLIENTS.AUTOMATIC_CAMPAIGN_CREATION'})}</span><br />
            <span className="text-muted">{intl.formatMessage({id: 'CLIENTS.ADDITIONAL_CAMPAIGNS_REMINDER'})}</span>

            <div className="d-flex justify-content-start mt-6">
                <button className="btn btn-light btn-sm me-2" onClick={handlePrev}>
                {intl.formatMessage({id: 'CLIENTS.PREVIOUS'})}
                </button>

                <button type="button" className="btn btn-primary btn-sm" onClick={handleFormSubmit} disabled={loading}>
                {intl.formatMessage({id: 'CLIENTS.SAVE_CHANGES'})}
                </button>
            </div>
            {alertInfo.text && (
                <div className={`alert alert-${alertInfo.variant} align-items-center mt-3`} role='alert'>
                  <div>{alertInfo.text}</div>
                </div>
            )}
        </div>
    )
}

export{Step4};