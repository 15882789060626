// Importamos hooks y aux de React y helpers de metronic y bootstrap
import {FC, useEffect, useRef} from 'react'
import {useThemeMode} from '../../../../../../_metronic/partials'
import {getCSS, getCSSVariableValue} from '../../../../../../_metronic/assets/ts/_utils'

// ---------------------------------------------------------------------------------------

// Importamos componentes de Apexcharts
import ApexCharts, {ApexOptions} from 'apexcharts'
import { useIntl } from 'react-intl'

// Propiedades que debe recibir este componente
type Props = {
  originalLeads: number[]
  duplicateLeads: number[]
  totalLeads: number[]
  datesData: string[]
  productIdSelected: string
}

// Montamos componente de la grafica
const LeadsReportChart: FC<Props> = ({
  originalLeads,
  duplicateLeads,
  totalLeads,
  datesData,
  productIdSelected,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, productIdSelected])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height: number = parseInt(getCSS(chartRef.current, 'height'))
    const serie_data1: number[] = originalLeads
    const serie_data2: number[] = duplicateLeads
    const serie_data3: number[] = totalLeads
    const serie_date: string[] = datesData

    const chart: ApexCharts = new ApexCharts(
      chartRef.current,
      getChartOptions(height, serie_data1, serie_data2, serie_data3, serie_date)
    )

    if (chart) {
      chart.render()
    }

    return chart
  }
  function getChartOptions(
    height: number,
    serie_data1: number[],
    serie_data2: number[],
    serie_data3: number[],
    serie_date: string[]
  ): ApexOptions {
    const labelColor: string = getCSSVariableValue('--bs-gray-500')
    const borderColor: string = getCSSVariableValue('--bs-gray-200')
    const baseColor: string = getCSSVariableValue('--bs-primary')
    const secondaryColor: string = getCSSVariableValue('--bs-gray-300')

    const color2: string = getCSSVariableValue('--bs-warning')
    const color2Light: string = getCSSVariableValue('--bs-warning-light')

    const color1: string = getCSSVariableValue('--bs-success')
    const color1Light: string = getCSSVariableValue('--bs-success-light')

    const color3: string = getCSSVariableValue('--bs-primary')
    const color3Light: string = getCSSVariableValue('--bs-primary-light')

    // Reemplazo de textos estáticos
    const series: ApexOptions['series'] = [
      {
        name: intl.formatMessage({id: 'REPORTS.LEADS.ORIGINALS'}),
        data: serie_data1,
      },
      {
        name: intl.formatMessage({id: 'REPORTS.LEADS.DUPLICATES'}),
        data: serie_data2,
      },
      {
        name: intl.formatMessage({id: 'REPORTS.LEADS.TOTAL_LEADS'}),
        data: serie_data3,
      },
    ]

    return {
      series: series,
      chart: {
        fontFamily: 'inherit',
        type: 'area',
        height: height,
        stacked: false,
        toolbar: {
          show: true,
          tools: {
            download: true, // Mostrar el botón de descarga
            selection: true, // Mostrar el botón de selección
            zoom: true, // Mostrar el botón de zoom
            zoomin: true, // Mostrar el botón de zoom-in
            zoomout: true, // Mostrar el botón de zoom-out
            pan: true, // Mostrar el botón de paneo
            reset: true, // Mostrar el botón de reseteo
          },
          export: {
            // Configuración personalizada para la exportación
            csv: {
              filename: 'leads-data', // Nombre de archivo para la exportación CSV
            },
            svg: {
              filename: 'leads-data', // Nombre de archivo para la exportación SVG
            },
            png: {
              filename: 'leads-data', // Nombre de archivo para la exportación PNG
            },
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '30%',
          borderRadius: 5,
        },
      },
      legend: {
        show: true,
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        colors: [color1, color2, color3],
        curve: 'smooth',
        show: true,
        width: 2,
      },
      xaxis: {
        categories: serie_date,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      fill: {
        type: 'gradient',
        opacity: 2,
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: '12px',
        },
        y: {
          formatter: function (val) {
            return 'Leads' + val
          },
        },
      },
      colors: [color1, color2, color3],
      // colors: [color1Light, color2Light, color3Light],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    }
  }

  return (
    <div className='card-xxl-stretch mb-5 mb-xl-10'>
      <div className=''>
        <div ref={chartRef} id='kt_charts_widget_1_chart' style={{height: '350px'}} />
      </div>
    </div>
  )
}

export {LeadsReportChart}
