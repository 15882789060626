import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { sessionExpired } from '../../../../utils/functions/_Functions';
import { productsListByClient } from '../../../products/services/_ProductsListApi';
import { brandsListByClient } from '../../../brands/services/_BrandsListApi';
import { UserContext } from '../../../../app-context/user-context/UserContext';
import { campaignsListForClient } from '../../../campaigns/services/_CampaignsListApi';
import { ProductOption } from '../../interfaces/rolesTypes';
import { BrandOption } from '../../interfaces/rolesTypes';
import { CampaignOption } from '../../interfaces/rolesTypes';
import { Step2Errors } from '../../interfaces/rolesTypes';

// Aqui almacenaremos los nombres y id dependiendo de la seleccion en la ventana anterior, si e hizo por marca, producto y campaña, si es por vendedor

function Step2Rol({ data, onNext, handlePrev, prevData, setStep2Data }) {
  const [products, setProducts] = useState<string[]>(prevData?.products || []);
  const [brands, setBrands] = useState<string>(prevData?.brands || '');
  const [campaigns, setCampaigns] = useState<string[]>(prevData?.campaigns || []);
  const [productsName, setProductsName] = useState<string[]>(prevData?.productsName || []);
  const [brandsName, setBrandsName] = useState<string>(prevData?.brandsName || '');
  const [campaignsName, setCampaignsName] = useState<string[]>(prevData?.campaignsName || []);
  const assignType = data.assignType;

  const [errors, setErrors] = useState<Step2Errors>({
    products: [],
    brands: [],
    campaigns: []
  })

  const [productOptions, setProductOptions] = useState<ProductOption[]>([]);
  const [brandOptions, setBrandOptions] = useState<BrandOption[]>([]);
  const [campaignOptions, setCampaignOptions] = useState<CampaignOption[]>([]);
  const { user, token, updateToken } = useContext(UserContext);
  const clientId = user.cli_id;
  const userToken = token;

  const intl = useIntl();

  const validateInputs = () => {
    let newErrors: Step2Errors = {
      products: '',
      brands: '',
      campaigns: ''
    };
  
    if (assignType === 'Producto') {
      newErrors = {
        ...newErrors,
        products: !products.length ? intl.formatMessage({id: 'ASSIGNS.CHOOSE_AT_LEAST_ONE_PRODUCT'}) : '',
        brands: '', // Limpiar el error de marcas si no es relevante
        campaigns: '' // Limpiar el error de campañas si no es relevante'
      };
    } else if (assignType === 'Marca') {
      newErrors = {
        ...newErrors,
        products: '', // Limpiar el error de productos si no es relevante
        brands: !brands.length ? intl.formatMessage({id: 'ASSIGNS.CHOOSE_A_BRAND'}) : '',
        campaigns: '' // Limpiar el error de campañas si no es relevante
      };
    } else if (assignType === 'Campaña') {
      newErrors = {
        ...newErrors,
        products: '', // Limpiar el error de productos si no es relevante
        brands: '', // Limpiar el error de marcas si no es relevante
        campaigns: !campaigns.length ? intl.formatMessage({id: 'ASSIGNS.CHOOSE_AT_LEAST_ONE_CAMPAIGN'}) : ''
      };
    }
  
    // Aquí actualizamos el estado errors con los nuevos mensajes de error
    setErrors(newErrors);
  
    return Object.values(newErrors).every((error) => error === '');
  };

  const handleNext = () => {
    if (validateInputs()) {
      onNext({
        products,
        brands,
        campaigns,
        productsName,
        brandsName,
        campaignsName
      });

      setStep2Data({
        products,
        brands,
        campaigns,
        productsName,
        brandsName,
        campaignsName
      });
    }
  };

  // Funcion que guarda los id de cada producto que se selecciona para el rol
  const handleProductSwitchChange = (productId: string, productName: string) => {
    if (products.includes(productId)) {
      setProducts(products.filter(id => id !== productId));
  
      // Remover el nombre correspondiente al ID deseleccionado
      setProductsName(productsName.filter(name => name !== productName));
    } else {
      setProducts([...products, productId]);
  
      // Agregar el nombre correspondiente al ID seleccionado
      setProductsName([...productsName, productName]);
    }
  };

  // Funcion que guarda el id de la marca que se selecciona para el rol
  const handleBrandDropdownChange = (selectedBrandId: string, selectedBrandName: string) => {
    setBrands(selectedBrandId);
    setBrandsName(selectedBrandName);
  };

  // Funcion que guarda los id de cada campaña que se selecciona para el rol
  const handleCampaignSwitchChange = (campaignId: string, campaignName: string) => {
    console.log(campaignId)
    if (campaigns.includes(campaignId)) {
      setCampaigns(campaigns.filter(id => id !== campaignId));
  
      // Remover solo el nombre correspondiente al ID deseleccionado
      setCampaignsName(campaignsName.filter(name => name !== campaignName));

      console.log(campaignsName)
    } else {
      setCampaigns([...campaigns, campaignId]);
  
      // Agregar solo el nombre correspondiente al ID seleccionado
      setCampaignsName([...campaignsName, campaignName]);
      console.log(campaignsName)
    }
  };

  // Este useEffect se ejecuta cada que se carga esta vita y toma los valores del paso anterior tomando la validacion de la seleccion que hizo, depende de la seleccion es el servicio que se ejecutará
  useEffect(() => {
    const fetchData = async () => {
      if (assignType === 'Producto') {
        try {
          const { data: jsonData, code, msg } = await productsListByClient(userToken, clientId);
          
          if (code === 401 && msg === 'Token no valido') {
            sessionExpired(`${intl.formatMessage({ id: "GLOBAL.ALERT.SESSION_EXPIRED" })} - productsregisters on brands`);
            return;
          } else {
            const productsListReceived = jsonData.data;
            const userTokenNew = await jsonData.token;
      
            const productsSwitches = productsListReceived.map((product) => ({
              pro_id: product.pro_id,
              name: product.name,
            }));
      
            setProductOptions(productsSwitches);
            updateToken(userTokenNew);
          }
        } catch (error) {
          console.error('Error al obtener los productos: ', error);
        }
      } else if (assignType === 'Marca') {
        try {
          const { data: jsonData, code, msg }= await brandsListByClient(userToken, clientId);
          
          if ( code === 401 && msg === 'Token no valido' ) {
            sessionExpired(intl.formatMessage({ id: "GLOBAL.ALERT.SESSION_EXPIRED" }));
            return;
          } else {
            const brandsListReceived = jsonData.data;
            const userTokenNew = jsonData.token;
        
            const brandsItems = brandsListReceived.map((brand) => ({
              bid: brand.bid,
              name: brand.name,
            }));
      
            setBrandOptions(brandsItems);
          }
        } catch(error) {
          console.error("Error receiving brands: ", error);
        }
      }
      else if (assignType === 'Campaña') {
        try {
          const { data: jsonData, code, msg }= await campaignsListForClient(userToken, clientId);
          
          if ( code === 401 && msg === 'Token no valido' ) {
            sessionExpired(intl.formatMessage({ id: "GLOBAL.ALERT.SESSION_EXPIRED" }));
            return;
          } else {
            const campaignsListReceived = jsonData.data;
            const userTokenNew = jsonData.token;

            console.log(campaignsListReceived)
        
            const campaignsItem = campaignsListReceived.map((campaign) => ({
              cid: campaign.cam_id,
              name: campaign.name,
            }));
      
            setCampaignOptions(campaignsItem);
          }
        } catch(error) {
          console.error("Error receiving brands: ", error);
        }
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {assignType === 'Producto' && (
        <div className='mb-5'>
          <div className='text-danger mb-6'>{errors.products}</div>
          <p>{intl.formatMessage({id: 'ASSIGNS.CHOOSE_ONE_OR_MORE_PRODUCTS'})}:</p>
          {productOptions.map((product) => (
            <div key={product.pro_id} className='form-check form-switch form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                type='checkbox'
                id={`productSwitch${product.pro_id}`}
                value={product.pro_id}
                checked={products.includes(product.pro_id)}
                onChange={(e) => {handleProductSwitchChange (e.target.value, product.name)} }
              />
              <label className='form-check-label' htmlFor={`productSwitch${product.pro_id}`}>
                {product.name}
              </label>
            </div>
          ))}
        </div>
      )}

      {assignType === 'Campaña' && (
        <div className='mb-5'>
          <div className='text-danger mb-6'>{errors.campaigns}</div>
          <p>{intl.formatMessage({id: 'ASSIGNS.CHOOSE_ONE_OR_MORE_CAMPAIGNS'})}:</p>
          {campaignOptions.map((campaign) => (
            <div key={campaign.cid} className='form-check form-switch form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                type='checkbox'
                id={`productSwitch${campaign.cid}`}
                value={campaign.cid}
                checked={campaigns.includes(campaign.cid)}
                onChange={(e) => {handleCampaignSwitchChange(e.target.value, campaign.name)}}
              />
              <label className='form-check-label' htmlFor={`productSwitch${campaign.cid}`}>
                {campaign.name}
              </label>
            </div>
          ))}
        </div>
      )}

      {assignType === 'Marca' && (
        <div className='mb-5'>
          <select
            id='brandDropdown'
            className='form-select'
            value={brands}
            onChange={(e) => {
              const selectedBrandId = e.target.value;
              const selectedBrandName = brandOptions.find(brand => brand.bid === selectedBrandId)?.name || ''; // Obtener el nombre de la marca seleccionada
              handleBrandDropdownChange(selectedBrandId, selectedBrandName);
            }}
          >
            <option value='0'>{intl.formatMessage({id: 'ASSIGNS.SELECT_A_BRAND'})}</option>
            {brandOptions.map((brand) => (
              <option key={brand.bid} value={brand.bid}>
                {brand.name}
              </option>
            ))}
          </select>
          <div className='text-danger mb-6'>{errors.brands}</div>
        </div>
      )}

      <button className='btn btn-light btn-sm mt-6 me-2' onClick={handlePrev}>
        {intl.formatMessage({id: 'GLOBAL.PREVIOUS'})}
      </button>

      <button className='btn btn-primary btn-sm mt-6' onClick={handleNext}>
        {intl.formatMessage({id: 'GLOBAL.NEXT'})}
      </button>
    </>
  );
}

export { Step2Rol };
