import {FC} from 'react'
import {KTIcon} from '../../../../../../_metronic/helpers'
import {CampaignReport} from '../interfaces/campaignsReportTypes'
import {formatNumber} from '../../../../../utils/functions/_Functions'
import {useIntl} from 'react-intl' 

type Props = {
  campaignReportItemRTwo: CampaignReport
}

const LeadCountsItemRangeTwo: FC<Props> = ({campaignReportItemRTwo}) => {
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()
  return (
    <>
      <div className='d-flex flex-wrap justify-content-center'>
        <div className='border border-gray-300 border-solid rounded min-w-115px py-4 px-4 me-8 mb-3'>
          <div className='d-flex align-items-center'>
            <KTIcon iconName='toggle-on' className='fs-3 text-info me-2' />
            <div className='fs-2 fw-bolder me-2'>{campaignReportItemRTwo.lead.totalLeads}</div>
            <div className='fw-bold fs-6 text-gray-400'>{intl.formatMessage({id: 'REPORT.CAMPAIGN.TOTAL_LEADS'})}</div>
          </div>
        </div>
        <div className='border border-gray-300 border-solid rounded min-w-115px py-4 px-4 me-8 mb-3'>
          <div className='d-flex align-items-center'>
            <KTIcon iconName='toggle-on' className='fs-3 text-warning me-2' />
            <div className='fs-2 fw-bolder me-2'>
              {formatNumber(campaignReportItemRTwo.iscore.iscoreAverage)}
            </div>
            <div className='fw-bold fs-6 text-gray-400'>{intl.formatMessage({id: 'REPORT.CAMPAIGN.ISCORE_AVERAGE'})}</div>
          </div>
        </div>
      </div>
    </>
  )
}

export {LeadCountsItemRangeTwo}
