import { apiDragon, DragonMainApi, EPLeadDetailCallsInfo } from "../../../utils/constants/_Constants";

import { apiGetDeletePetitions } from "../../../../_metronic/helpers/apis";

export async function callsRegistersLeadDetail(userToken, leadId) {
    try {
        const data = await apiGetDeletePetitions(apiDragon, `${DragonMainApi+EPLeadDetailCallsInfo+leadId}`, {
            undefined
        }, {
            'Content-Type': 'application/json',
            'x-token': userToken 
        }, 'get') 

        const jsonDataCallsRegisters = data 
        console.log(jsonDataCallsRegisters)
        return jsonDataCallsRegisters

    }catch (error){
        console.error('Error obteniendo los registros de llamadas: ', error);
        throw error;
    }
}