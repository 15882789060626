import React, {FC, useState} from 'react'
import * as Yup from 'yup'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {ResetPassword} from '../services/_ResetPaswword'
import {sessionExpired} from '../../utils/functions/_Functions'

const RecoverPassword: FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false)
  //Constante para el manejo de estado del mensaje post carga de registros
  const [infoMessageLoading, setInfoMessageLoading] = useState<string>('')
  const [showInfo, setShowInfo] = useState<boolean>(false)
  const navigate = useNavigate() // Hook para redirección
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  // Definir el esquema de validación usando Yup
  const validationSchema = Yup.object({
    email: Yup.string()
      .email(intl.formatMessage({id: 'GLOBAL.ALERT.INVALID.EMAIL'}))
      .required(intl.formatMessage({id: 'GLOBAL.ALERT.REQUIRED.EMAIL'})),
  })
  // Configurar Formik
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: (values) => {
      // Simular una solicitud y mostrar el modal
      let mail = values.email
      getRecoverPw(mail)
      //setShowModal(true)
    },
  })

  const handleToCloseModalForNewService = () => {
    setShowModal(false)
    navigate('/login') // Redirigir después de cerrar el modal
  }

  //Solicitudes post
  const getRecoverPw = async (mail: string): Promise<void> => {
    try {
      const {jsonData, jsonStatus: code} = await ResetPassword(mail)

      const {msg, data} = jsonData

      console.log('msg', msg)
      if (code === 401 && jsonData.msg === 'Token no valido') {
        sessionExpired(intl.formatMessage({id: 'GLOBAL.SESSION_EXPIRED'}))
        return
      } else if (
        code === 200 &&
        msg === 'Se ha enviado un correo con instrucciones para restablecer la contraseña'
      ) {
        //Mostrar modal
        setShowModal(true)
        return
      } else if (
        code === 400 &&
        msg === 'No existe un usuario con el correo electrónico proporcionado'
      ) {
        //Mostrar mensaje
        setInfoMessageLoading(intl.formatMessage({id: 'GLOBAL.REQUEST_ERROR'}))
        setShowInfo(true)
      }
    } catch (error) {
      console.error('Hubo un error en la petición: ', error)
      setInfoMessageLoading(intl.formatMessage({id: 'GLOBAL.REQUEST_ERROR'}))
      setShowInfo(true)
    }
  }
  React.useEffect(() => {
    if (infoMessageLoading != '') {
      setTimeout(() => {
        setShowInfo(false)
        setInfoMessageLoading('')
      }, 4000)
    }
  }, [infoMessageLoading])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-11'>
        <img
          className='mb-4'
          src='https://storage.googleapis.com/dragon-bucket-prod/app/img/LogoLogin.png'
          alt='icon'
        />

        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-2'>
          {intl.formatMessage({id: 'AUTH.PASSWORD.FORGOT_PASSWORD.TITLE'})}
        </h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-500 fw-semibold fs-6'>
          {intl.formatMessage({id: 'AUTH.PASSWORD.FORGOT_PASSWORD.SUBTITLE'})}
        </div>
        {/* end::Link */}
      </div>

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>
          {intl.formatMessage({id: 'GLOBAL.EMAIL'})}
        </label>
        <input
          type='email'
          placeholder='user@domain.com'
          className='form-control bg-transparent'
          autoComplete='off'
          name='email'
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.email && formik.errors.email ? (
          <div className='text-danger'>{formik.errors.email}</div>
        ) : null}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button
          type='submit'
          id='kt_password_reset_submit'
          className='btn btn-primary me-4'
        >
          <span className='indicator-label'>{intl.formatMessage({id: 'GLOBAL.SEND'})}</span>
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
          >
            {intl.formatMessage({id: 'GLOBAL.CANCEL'})}
          </button>
        </Link>
        
      </div>
      {/* end::Form group */}
      {showInfo && (
          <div
            className='alert alert-warning d-flex align-items-center  text-center mt-3'
            role='alert'
          >
            <div>{infoMessageLoading}</div>
          </div>
        )}
      {/* Modal */}
      <Modal
        show={showModal}
        onHide={handleToCloseModalForNewService}
        scrollable
        centered
        className='modal-md'
      >
        <Modal.Body>
          <div className='text-center'>
            {intl.formatMessage({id: 'AUTH.MODAL.FORGOT_PASSWORD.EMAIL.SEND'})}
          </div>
        </Modal.Body>

        <Modal.Footer className='justify-content-center'>
          <button
            className='btn btn-primary btn-sm'
            type='button'
            onClick={handleToCloseModalForNewService}
          >
            {intl.formatMessage({id: 'GLOBAL.INPUT.ACCEPT'})}
          </button>
        </Modal.Footer>
      </Modal>
    </form>
  )
}

export {RecoverPassword}
