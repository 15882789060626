import {FC, useState} from 'react'
import {KTIcon} from '../../../../../../_metronic/helpers'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {Modal} from 'react-bootstrap'
import {QaCountsReport} from '../interfaces/qaReportTypes'
import {QaReportGeneralTable} from '../tables/QaReportGeneralTable'
import {useIntl} from 'react-intl'

type Props = {
  qaReportItem: QaCountsReport
}

const LeadCountsItem: FC<Props> = ({qaReportItem}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false)
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  const [cardTitleSelected, setCardTitleSelected] = useState<string>('')
  const [cardSelected, setCardSelected] = useState<number>(0)

  const [showModal, setShowModal] = useState<boolean>(false)

  const openModal = (title: string) => {
    setShowModal(true)
    setCardTitleSelected(title)
  }

  const closeModal = (title: string) => {
    setShowModal(false)
    setCardTitleSelected(title)
  }

  // const openModal2 = (title: string) => {
  //     setShowModal2(true)
  //     setCardTitleSelected(title)
  // }

  // const closeModal2 = (title: string) => {
  //     setShowModal2(false)
  //     setCardTitleSelected(title)
  // }

  const handleMouseEnter = (card: number) => {
    console.log('entra efecto hover')
    setIsHovered(true)
    setCardSelected(card)
  }

  const handleMouseLeave = (card: number) => {
    console.log('sale efecto hover')
    setIsHovered(false)
    setCardSelected(card)
  }

  return (
    <>
      <div className='d-flex flex-wrap justify-content-center'>
        {/* Begin: Total de leads recibidos */}
        <OverlayTrigger placement='top' overlay={<Tooltip>Ver leads recibidos</Tooltip>}>
          <div
            onMouseEnter={() => {
              handleMouseEnter(1)
            }}
            onMouseLeave={() => {
              handleMouseLeave(0)
            }}
            onClick={() => {
              openModal('Leads recibidos')
            }}
            className={`border border-gray-300 border-solid rounded min-w-115px py-4 px-4 me-8 mb-3 ${isHovered && cardSelected === 1 ? 'bg-gray-200' : ''} cursor-pointer`}
          >
            <div className='d-flex align-items-center'>
              <KTIcon iconName='toggle-on' className='fs-3 text-primary me-2' />
              <div className='fs-2 fw-bolder me-2'>{qaReportItem.totalLeadsReceived}</div>
              <div className='fw-bold fs-6 text-gray-400'>
                {intl.formatMessage({id: 'REPORTS.QUALITY.LEADS_RECEIVED'})}
              </div>
            </div>
          </div>
        </OverlayTrigger>
        {/* End: Total de leads recibidos */}

        {/* Begin: Total de leads enviados a CRM */}
        <OverlayTrigger
          placement='top'
          overlay={<Tooltip>{intl.formatMessage({id: 'REPORTS.QUALITY.VIEW_QA_SURVEYS'})}</Tooltip>}
        >
          <div
            onMouseEnter={() => {
              handleMouseEnter(2)
            }}
            onMouseLeave={() => {
              handleMouseLeave(0)
            }}
            onClick={() => {
              openModal('Encuestas QA enviadas')
            }}
            className={`border border-gray-300 border-solid rounded min-w-115px py-4 px-4 me-8 mb-3 ${isHovered && cardSelected === 2 ? 'bg-gray-200' : ''} cursor-pointer`}
          >
            <div className='d-flex align-items-center'>
              <KTIcon iconName='toggle-on' className='fs-3 text-info me-2' />
              <div className='fs-2 fw-bolder me-2'>{qaReportItem.totalQaSended}</div>
              <div className='fw-bold fs-6 text-gray-400'>
                {intl.formatMessage({id: 'REPORTS.QUALITY.QA_SURVEYS_SENT'})}
              </div>
            </div>
          </div>
        </OverlayTrigger>
        {/* End. Total de leads enviados a CRM */}

        {/* Begin: Total de leads no enviados a CRM */}
        <OverlayTrigger
          placement='top'
          overlay={
            <Tooltip>{intl.formatMessage({id: 'REPORTS.QUALITY.VIEW_POSITIVE_RESPONSES'})}</Tooltip>
          }
        >
          <div
            onMouseEnter={() => {
              handleMouseEnter(3)
            }}
            onMouseLeave={() => {
              handleMouseLeave(0)
            }}
            onClick={() => {
              openModal(intl.formatMessage({id: 'REPORTS.QUALITY.POSITIVE_RESPONSES'}))
            }}
            className={`border border-gray-300 border-solid rounded min-w-115px py-4 px-4 me-8 mb-3 ${isHovered && cardSelected === 3 ? 'bg-gray-200' : ''} cursor-pointer`}
          >
            <div className='d-flex align-items-center'>
              <KTIcon iconName='toggle-on' className='fs-3 text-warning me-2' />
              <div className='fs-2 fw-bolder me-2'>{qaReportItem.totalPositiveQaResponse}</div>
              <div className='fw-bold fs-6 text-gray-400'>
                {intl.formatMessage({id: 'REPORTS.QUALITY.POSITIVE_RESPONSES'})}
              </div>
            </div>
          </div>
        </OverlayTrigger>
        {/* End: Total de leads no enviados a CRM */}

        {/* Begin: Total de leads con respuesta positiva */}
        <OverlayTrigger
          placement='top'
          overlay={
            <Tooltip>{intl.formatMessage({id: 'REPORTS.QUALITY.VIEW_NEGATIVE_RESPONSES'})}</Tooltip>
          }
        >
          <div
            onMouseEnter={() => {
              handleMouseEnter(4)
            }}
            onMouseLeave={() => {
              handleMouseLeave(0)
            }}
            onClick={() => {
              openModal(intl.formatMessage({id: 'REPORTS.QUALITY.NEGATIVE_RESPONSES'}))
            }}
            className={`border border-gray-300 border-solid rounded min-w-115px py-4 px-4 me-8 mb-3 ${isHovered && cardSelected === 4 ? 'bg-gray-200' : ''} cursor-pointer`}
          >
            <div className='d-flex align-items-center'>
              <KTIcon iconName='toggle-on' className='fs-3 text-success me-2' />
              <div className='fs-2 fw-bolder me-2'>{qaReportItem.totalNegativeQaResponse}</div>
              <div className='fw-bold fs-6 text-gray-400'>
                {intl.formatMessage({id: 'REPORTS.QUALITY.NEGATIVE_RESPONSES'})}
              </div>
            </div>
          </div>
        </OverlayTrigger>
        {/* End: Total de leads con respuesta positiva */}

        {/* Begin: Total de leads con respuesta negativa */}
        <OverlayTrigger
          placement='top'
          overlay={
            <Tooltip>{intl.formatMessage({id: 'REPORTS.QUALITY.VIEW_NO_RESPONSE_LEADS'})}</Tooltip>
          }
        >
          <div
            onMouseEnter={() => {
              handleMouseEnter(5)
            }}
            onMouseLeave={() => {
              handleMouseLeave(0)
            }}
            onClick={() => {
              openModal(intl.formatMessage({id: 'REPORTS.QUALITY.NO_RESPONSE'}))
            }}
            className={`border border-gray-300 border-solid rounded min-w-115px py-4 px-4 me-8 mb-3 ${isHovered && cardSelected === 5 ? 'bg-gray-200' : ''} cursor-pointer`}
          >
            <div className='d-flex align-items-center'>
              <KTIcon iconName='toggle-on' className='fs-3 text-danger me-2' />
              <div className='fs-2 fw-bolder me-2'>{qaReportItem.totalNoQaResponse}</div>
              <div className='fw-bold fs-6 text-gray-400'>
                {intl.formatMessage({id: 'REPORTS.QUALITY.NO_LEADS_RESPONSE'})}
              </div>
            </div>
          </div>
        </OverlayTrigger>
        {/* End: Total de leads con respuesta negativa */}
      </div>

      <Modal
        show={showModal}
        className='modal-xl'
        scrollable
        onHide={() => {
          closeModal('')
        }}
      >
        <Modal.Header
          closeButton
          onClick={() => {
            closeModal('')
          }}
        >
          <Modal.Title>
            <h1>{cardTitleSelected}</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <QaReportGeneralTable cardSelected={cardSelected} />
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            type='reset'
            className='btn btn-sm btn-secondary'
            onClick={() => {
              closeModal('')
            }}
          >
            {intl.formatMessage({id: 'GLOBAL.CLOSE'})}
          </button>
        </Modal.Footer>
      </Modal>
      {/* 
            <Modal
                show={showModal2}
                className="modal-xl"
                scrollable
                onHide={() => {
                    closeModal2('')
                }}
            >
                <Modal.Header
                    closeButton
                    onClick={() => {
                        closeModal2('')
                    }}
                >
                    <Modal.Title>
                        <h1>{cardTitleSelected}</h1>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>hola modal 2</>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="reset"
                        className="btn tn-sm btn-secondary"
                        onClick={() => {
                            closeModal2('')
                        }}
                    >
                        Cerrar
                    </button>
                </Modal.Footer>
            </Modal> */}
    </>
  )
}

export {LeadCountsItem}
