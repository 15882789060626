//Estructura tabla tanstack

import React, {FC, useEffect, useState} from 'react'
import {
  Column,
  Table,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  FilterFn,
  ColumnDef,
  flexRender,
} from '@tanstack/react-table'
import {RankingInfo, rankItem} from '@tanstack/match-sorter-utils'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {YearlyReportChannel, YearlyReportChart} from '../interfaces/yearlyReportTypes'
import {KTIcon} from '../../../../../../_metronic/helpers'
import {YearlyReportGlobalChart} from '../charts/YearlyReportGlobalChart'
import {setPageTitle} from '../../../../../utils/functions/_Functions'
import {DragonCem} from '../../../../../utils/constants/_Constants'
import {LoadingAnimation} from '../../../../../utils/components/loading/LoadingAnimation'
import {useIntl} from 'react-intl'

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>
  }
  interface FilterMeta {
    itemRank: RankingInfo
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)

  // Store the itemRank info
  addMeta({
    itemRank,
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

type Props = {
  selectedIdChannel: number | string
  selectedYear: string
  duplicates: boolean
}

export const YearlyReportChannelTable: FC<Props> = ({
  selectedIdChannel,
  selectedYear,
  duplicates,
}) => {
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  console.log(selectedYear)
  console.log(duplicates)

  const initialYearlyChannelListData: YearlyReportChannel[] = [
    {
      product: '',
      medium: '',
      january: 0,
      february: 0,
      march: 0,
      april: 0,
      may: 0,
      june: 0,
      july: 0,
      august: 0,
      september: 0,
      october: 0,
      november: 0,
      december: 0,
      total: 0,
    },
  ]

  const initialYearlyChartData: YearlyReportChart = {
    dataPerMonth: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    Months: [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octumbre',
      'Noviembre',
      'Diciembre',
    ],
  }

  const renderTooltip = (props) => <Tooltip {...props}>Copiar id del registro</Tooltip>

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = useState('')
  // Constante para el manejo de la exportación de la información de la tabla
  const [dataToExport, setDataToExport] = useState({})
  // Constante para el manejo del estado para deshabilitar o habilitar botones
  const [disabledButtons, setDisabledButtons] = useState<boolean>(true)
  // Constante para el manejo de estado de la animacion de carga
  const [isLoading, setIsLoading] = useState<boolean>(false)
  // Lista de los meses
  const months: (keyof YearlyReportChannel)[] = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
  ]
  // Constante para el manejo inicial de la data de la tabla
  const [yearlyChannelList, setYearlyChannelList] = useState<YearlyReportChannel[]>(
    initialYearlyChannelListData
  )

  // Constante para el manejo inicial de la data de la gráfica
  const [yearlyChannelChartData, setYearlyChannelChartData] =
    useState<YearlyReportChart>(initialYearlyChartData)

  //Función para definir la data que se va a exportar
  const handleSetData = () => {
    const dataToExport = table.getRowModel().flatRows.map((row) => row.original)
    setDataToExport(dataToExport)
  }

  // Función para redefinir la data a su estadio inicial
  const getInitialYearlyChannelList = () => {
    setTimeout(() => {
      setIsLoading(false)
      setDisabledButtons(true)
      const initialYearlyChannelList: YearlyReportChannel[] = initialYearlyChannelListData

      setYearlyChannelList(initialYearlyChannelList)
      console.log(yearlyChannelList.length)
      setYearlyChannelChartData(initialYearlyChartData) // ESTÁ BUGEADO, revisar el tema de las validaciones con los inputs de canal y de año
    }, 2000)
  }

  // Función para obtener el total de un mes específico
  const getMonthlyTotal = (data: YearlyReportChannel[], month: keyof YearlyReportChannel) => {
    return data.reduce((accumulator, currentItem) => accumulator + Number(currentItem[month]), 0)
  }

  // Función para obtener y definir la data de la tabla según el canal
  const getYearlyChannelList = () => {
    setTimeout(() => {
      setIsLoading(false)
      setDisabledButtons(false)
      let newYearlyChannelList

      switch (selectedIdChannel) {
        case '0':
          newYearlyChannelList = [
            {
              product: 'Product A',
              january: 34,
              february: 67,
              march: 45,
              april: 56,
              may: 78,
              june: 89,
              july: 23,
              august: 45,
              september: 67,
              october: 78,
              november: 90,
              december: 12,
              total: 684,
              medium: 'Facebook Ads',
            },
            {
              product: 'Product B',
              january: 45,
              february: 56,
              march: 67,
              april: 78,
              may: 89,
              june: 90,
              july: 12,
              august: 34,
              september: 56,
              october: 78,
              november: 89,
              december: 90,
              total: 784,
              medium: 'Google Ads',
            },
            {
              product: 'Product C',
              january: 23,
              february: 45,
              march: 67,
              april: 34,
              may: 56,
              june: 78,
              july: 89,
              august: 90,
              september: 12,
              october: 34,
              november: 56,
              december: 78,
              total: 662,
              medium: 'Instagram Ads',
            },
            {
              product: 'Product D',
              january: 56,
              february: 78,
              march: 89,
              april: 12,
              may: 34,
              june: 56,
              july: 78,
              august: 89,
              september: 90,
              october: 12,
              november: 34,
              december: 56,
              total: 674,
              medium: 'Website',
            },
            {
              product: 'Product E',
              january: 67,
              february: 89,
              march: 90,
              april: 12,
              may: 34,
              june: 56,
              july: 78,
              august: 89,
              september: 90,
              october: 12,
              november: 34,
              december: 56,
              total: 707,
              medium: 'Google Ads',
            },
            {
              product: 'Product F',
              january: 45,
              february: 78,
              march: 90,
              april: 67,
              may: 89,
              june: 23,
              july: 45,
              august: 67,
              september: 89,
              october: 12,
              november: 23,
              december: 34,
              total: 662,
              medium: 'Facebook Ads',
            },
            {
              product: 'Product G',
              january: 78,
              february: 34,
              march: 56,
              april: 23,
              may: 45,
              june: 67,
              july: 89,
              august: 90,
              september: 12,
              october: 23,
              november: 45,
              december: 67,
              total: 629,
              medium: 'Instagram Ads',
            },
            {
              product: 'Product H',
              january: 23,
              february: 45,
              march: 67,
              april: 89,
              may: 12,
              june: 34,
              july: 56,
              august: 78,
              september: 90,
              october: 23,
              november: 45,
              december: 67,
              total: 629,
              medium: 'Google Ads',
            },
            {
              product: 'Product I',
              january: 67,
              february: 34,
              march: 56,
              april: 12,
              may: 45,
              june: 78,
              july: 89,
              august: 90,
              september: 23,
              october: 45,
              november: 67,
              december: 89,
              total: 695,
              medium: 'Website',
            },
            {
              product: 'Product J',
              january: 45,
              february: 67,
              march: 78,
              april: 89,
              may: 90,
              june: 12,
              july: 34,
              august: 56,
              september: 78,
              october: 90,
              november: 12,
              december: 23,
              total: 674,
              medium: 'Facebook Ads',
            },
            {
              product: 'Product K',
              january: 89,
              february: 90,
              march: 12,
              april: 23,
              may: 45,
              june: 67,
              july: 78,
              august: 90,
              september: 12,
              october: 34,
              november: 56,
              december: 78,
              total: 674,
              medium: 'Instagram Ads',
            },
            {
              product: 'Product L',
              january: 34,
              february: 56,
              march: 78,
              april: 90,
              may: 12,
              june: 23,
              july: 45,
              august: 67,
              september: 78,
              october: 89,
              november: 90,
              december: 12,
              total: 664,
              medium: 'Google Ads',
            },
          ]
          break
        case 'Channel-Z0J53P9Z':
        case 'Channel-00S2JBND':
        case 'Channel-L0F54P6Z':
        case 'Channel-C0K63T0C':
        case 'Channel-O08062UX':
          newYearlyChannelList = [
            {
              product: 'Product A',
              january: 34,
              february: 67,
              march: 45,
              april: 56,
              may: 78,
              june: 89,
              july: 23,
              august: 45,
              september: 67,
              october: 78,
              november: 90,
              december: 12,
              total: 684,
              medium: 'Facebook Ads',
            },
            {
              product: 'Product B',
              january: 45,
              february: 56,
              march: 67,
              april: 78,
              may: 89,
              june: 90,
              july: 12,
              august: 34,
              september: 56,
              october: 78,
              november: 89,
              december: 90,
              total: 784,
              medium: 'Google Ads',
            },
            {
              product: 'Product C',
              january: 23,
              february: 45,
              march: 67,
              april: 34,
              may: 56,
              june: 78,
              july: 89,
              august: 90,
              september: 12,
              october: 34,
              november: 56,
              december: 78,
              total: 662,
              medium: 'Instagram Ads',
            },
            {
              product: 'Product D',
              january: 56,
              february: 78,
              march: 89,
              april: 12,
              may: 34,
              june: 56,
              july: 78,
              august: 89,
              september: 90,
              october: 12,
              november: 34,
              december: 56,
              total: 674,
              medium: 'Website',
            },
            {
              product: 'Product E',
              january: 67,
              february: 89,
              march: 90,
              april: 12,
              may: 34,
              june: 56,
              july: 78,
              august: 89,
              september: 90,
              october: 12,
              november: 34,
              december: 56,
              total: 707,
              medium: 'Google Ads',
            },
          ]
          break
        case 'Channel-O0W6H3FB':
        case 'Channel-O0KMUMFI':
        case 'Channel-G00J7O8E':
        case 'Channel-O0GRCBMV':
        case 'Channel-W0CWODMO':
          newYearlyChannelList = []
          setDisabledButtons(true)
          break
        default:
          newYearlyChannelList = [
            {
              product: 'Product F',
              january: 45,
              february: 78,
              march: 90,
              april: 67,
              may: 89,
              june: 23,
              july: 45,
              august: 67,
              september: 89,
              october: 12,
              november: 23,
              december: 34,
              total: 662,
              medium: 'Facebook Ads',
            },
            {
              product: 'Product G',
              january: 78,
              february: 34,
              march: 56,
              april: 23,
              may: 45,
              june: 67,
              july: 89,
              august: 90,
              september: 12,
              october: 23,
              november: 45,
              december: 67,
              total: 629,
              medium: 'Instagram Ads',
            },
            {
              product: 'Product H',
              january: 23,
              february: 45,
              march: 67,
              april: 89,
              may: 12,
              june: 34,
              july: 56,
              august: 78,
              september: 90,
              october: 23,
              november: 45,
              december: 67,
              total: 629,
              medium: 'Google Ads',
            },
            {
              product: 'Product I',
              january: 67,
              february: 34,
              march: 56,
              april: 12,
              may: 45,
              june: 78,
              july: 89,
              august: 90,
              september: 23,
              october: 45,
              november: 67,
              december: 89,
              total: 695,
              medium: 'Website',
            },
            {
              product: 'Product J',
              january: 45,
              february: 67,
              march: 78,
              april: 89,
              may: 90,
              june: 12,
              july: 34,
              august: 56,
              september: 78,
              october: 90,
              november: 12,
              december: 23,
              total: 674,
              medium: 'Facebook Ads',
            },
            {
              product: 'Product K',
              january: 89,
              february: 90,
              march: 12,
              april: 23,
              may: 45,
              june: 67,
              july: 78,
              august: 90,
              september: 12,
              october: 34,
              november: 56,
              december: 78,
              total: 674,
              medium: 'Instagram Ads',
            },
            {
              product: 'Product L',
              january: 34,
              february: 56,
              march: 78,
              april: 90,
              may: 12,
              june: 23,
              july: 45,
              august: 67,
              september: 78,
              october: 89,
              november: 90,
              december: 12,
              total: 664,
              medium: 'Google Ads',
            },
          ]
          break
      }

      setYearlyChannelList(newYearlyChannelList) // 3.2 Tomando como referencia los puntos anteriores, se toma la variable con los datos del punto 3.1 para definir el state de la const del punto 2

      // Calcular el total para cada mes y almacenarlo en un array
      const monthlyTotals = months.map((month) => getMonthlyTotal(newYearlyChannelList, month))

      // Crear nueva data para el chart
      const newYearlyChartData: YearlyReportChart = {
        dataPerMonth: monthlyTotals,
        Months: initialYearlyChartData.Months, // Puedes reutilizar los nombres de los meses
      }

      // Actualizar el estado con la nueva data
      setYearlyChannelChartData(newYearlyChartData)
    }, 2500)
  }

  // Función para obtener los datos filtrados de la tabla y actualizar la información que será para la gráfica
  const handleFilteredChange = () => {
    const filteredData = table.getFilteredRowModel().rows.map((row) => row.original)
    console.log(filteredData)

    // Calcular el total para cada mes y almacenarlo en un array
    const monthlyTotals = months.map((month) => getMonthlyTotal(filteredData, month))

    // Crear nueva data para el chart
    const newYearlyChartData: YearlyReportChart = {
      dataPerMonth: monthlyTotals,
      Months: initialYearlyChartData.Months, // Puedes reutilizar los nombres de los meses
    }

    // Actualizar el estado con la nueva data
    setYearlyChannelChartData(newYearlyChartData)
  }

  // UseEffect inicial para cambiar el nombre en el navegador y definir los datos iniciales
  useEffect(() => {
    setPageTitle(
      `${intl.formatMessage({id: 'REPORTS.YEARLY.ANNUAL_REPORT_CHANNEL'})} | ${DragonCem}`
    )
    getInitialYearlyChannelList() //4. Se ejecuta la función del punto 3 cuando se renderiza el componente
  }, [])

  //Función para hacer la petición de información
  const fetchData = async () => {
    // Verificar si el producto seleccionado es válido (no vacío)
    if (selectedIdChannel && selectedYear) {
      try {
        setIsLoading(true)
        setDisabledButtons(true)
        await getYearlyChannelList()
      } catch (error) {
        console.error('Error al obtener el reporte de leads:', error)
        // Manejar errores apropiadamente, por ejemplo, mostrar un mensaje de error
      }
    } else {
      setDisabledButtons(true)
    }
  }

  // UseEffect para actualizar la información cada vez que se cambia un select
  useEffect(() => {
    setDisabledButtons(true)
    fetchData()
  }, [selectedIdChannel, selectedYear, duplicates])

  const columns = React.useMemo<ColumnDef<YearlyReportChannel, any>[]>( //5 Se vuelve a definir la interfaz del objeto como prop de las columnas de la tabla
    () => [
      {
        header: `Reporte anual por canales: Todos los canales`, // 5.1 Se pone algun titulo o header a mostrar: Historial de cambios globales | Tabla de Plantillas | etc
        footer: (props) => props.column.id,
        columns: [
          {
            accessorKey: 'product',
            cell: (info) => info.getValue(),
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.PRODUCT'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'medium',
            cell: (info) => info.getValue(),
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.MEDIUM'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'january',
            cell: (info) => info.getValue(),
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.JANUARY'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'february',
            cell: (info) => info.getValue(),
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.FEBRUARY'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'march',
            cell: (info) => info.getValue(),
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.MARCH'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'april',
            cell: (info) => info.getValue(),
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.APRIL'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'may',
            cell: (info) => info.getValue(),
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.MAY'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'june',
            cell: (info) => info.getValue(),
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.JUNE'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'july',
            cell: (info) => info.getValue(),
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.JULY'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'august',
            cell: (info) => info.getValue(),
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.AUGUST'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'september',
            cell: (info) => info.getValue(),
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.SEPTEMBER'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'october',
            cell: (info) => info.getValue(),
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.OCTOBER'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'november',
            cell: (info) => info.getValue(),
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.NOVEMBER'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'december',
            cell: (info) => info.getValue(),
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.DECEMBER'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'total',
            cell: (info) => info.getValue(),
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.TOTAL'})}</span>,
            footer: (props) => props.column.id,
          },
        ],
      },
    ],
    []
  )

  const table = useReactTable({
    data: yearlyChannelList, //6. Se definen los datos finales de la tabla con el seteo del punto 3.2
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  })

  useEffect(() => {
    handleFilteredChange()
  }, [table.getState().columnFilters, table.getState().globalFilter])

  React.useEffect(() => {
    if (table.getState().columnFilters[1]?.id === 'name') {
      if (table.getState().sorting[0]?.id !== 'name' || table.getState().sorting[0]?.desc) {
        table.setSorting([{id: 'name', desc: false}])
      }
    }
  }, [table.getState().columnFilters[1]?.id])

  return (
    <div className='col-xl-12'>
      {/* Gráfica */}
      <div className='row gy-10 gx-xl-12'>
        <div className='card col-xl-12 mb-3'>
          {/* Si NO está seleccionado el select del canal O no está seleccionado el select del año: */}
          {(!selectedIdChannel || !selectedYear) && (
            <div className='alert alert-warning d-flex align-items-center justify-content-center p-5 m-5'>
              <div className='d-flex flex-column align-items-center'>
                <h5 className='mb-1 text-center'>
                  {intl.formatMessage({id: 'REPORTS.YEARLY.SELECT_CHANNEL_YEAR'})}
                </h5>
              </div>
            </div>
          )}

          {/* Si SÍ está seleccionado el select del canal y el select del año y si isLoading es true: */}
          {selectedIdChannel && selectedYear && isLoading && (
            <div className='py-12'>
              <LoadingAnimation
                alignment='center'
                label={intl.formatMessage({id: 'REPORTS.YEARLY.LOADING_GRAPH'})}
                size='lg'
              />
            </div>
          )}

          {/* Si SÍ está seleccionado el select del canal y el select del año y la cantidad de registros es 0, y si isLoading es false: */}
          {selectedIdChannel && selectedYear && yearlyChannelList.length === 0 && !isLoading && (
            <div className='alert alert-warning d-flex align-items-center justify-content-center p-5 m-5'>
              <div className='d-flex flex-column align-items-center'>
                <h5 className='mb-1 text-center'>
                  {intl.formatMessage({id: 'REPORTS.YEARLY.NO_RECORDS'})}
                </h5>
              </div>
            </div>
          )}

          {/* Si SÍ está seleccionado el select del canal y el select del año y la cantidad de registros es mayor a 0, y si isLoading es false:*/}
          {selectedIdChannel && selectedYear && yearlyChannelList.length > 0 && !isLoading && (
            <YearlyReportGlobalChart
              className='card-xxl-stretch mb-5 mb-xl-10'
              serieData={yearlyChannelChartData.dataPerMonth}
              serieDates={yearlyChannelChartData.Months}
            />
          )}
        </div>
      </div>
      <div className='row gy-10 gx-xl-12'>
        <div className='card card-custom px-8 py-10 h-100'>
          <div className='p-2'>
            {/* Si NO está seleccionado el select del canal O no está seleccionado el select del año: */}
            {(!selectedIdChannel || !selectedYear) && (
              <div className='alert alert-warning d-flex align-items-center justify-content-center p-5 m-5'>
                <div className='d-flex flex-column align-items-center'>
                  <h5 className='mb-1 text-center'>
                    {intl.formatMessage({id: 'REPORTS.YEARLY.SELECT_CHANNEL_YEAR'})}
                  </h5>
                </div>
              </div>
            )}

            {/* Si SÍ está seleccionado el select del canal y el select del año y si isLoading es true: */}
            {selectedIdChannel && selectedYear && isLoading && (
              <div className='py-12'>
                <LoadingAnimation
                  alignment='center'
                  label={intl.formatMessage({id: 'REPORTS.YEARLY.LOADING_TABLE'})}
                  size='lg'
                />
              </div>
            )}
            {/* Si SÍ está seleccionado el select del canal y el select del año y la cantidad de registros es 0, y si isLoading es false: */}
            {selectedIdChannel && selectedYear && yearlyChannelList.length === 0 && !isLoading && (
              <div className='alert alert-warning d-flex align-items-center justify-content-center p-5 m-5'>
                <div className='d-flex flex-column align-items-center'>
                  <h5 className='mb-1 text-center'>
                    {intl.formatMessage({id: 'REPORTS.YEARLY.NO_RECORDS'})}
                  </h5>
                </div>
              </div>
            )}
            {/* Si SÍ está seleccionado el select del canal y el select del año y la cantidad de registros es mayor a 0, y si isLoading es false:*/}
            {selectedIdChannel && selectedYear && yearlyChannelList.length > 0 && !isLoading && (
              <div id='forTable'>
                <div className='card-header pt-6 pb-6'>
                  <div className='d-flex justify-content-between'>
                    <DebouncedInput //7. Filtrado global
                      value={globalFilter ?? ''}
                      onChange={(value) => setGlobalFilter(String(value))}
                      className={`form-control form-control-solid w-250px ps-14 me-3 ${
                        disabledButtons ? 'disabled' : ''
                      }`}
                      placeholder={intl.formatMessage({id: 'GLOBAL.TABLE_SEARCH_RECORD'})}
                      disabled={disabledButtons}
                    />

                    <button //8. Botón que muestra el dropdown para exportar datos
                      type='button'
                      className={`btn btn-primary d-flex align-items-center me-3 ${
                        disabledButtons ? 'disabled' : ''
                      }`}
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                      onClick={handleSetData}
                      disabled={disabledButtons}
                    >
                      <KTIcon iconType='outline' iconName='file-down' className='fs-2 me-2' />{' '}
                      <span>Exportar datos</span>
                    </button>

                    {/* <DropdownExportLogs dataToExport={dataToExport} title={'Logs'} /> //9. Aquí se llama el componente exportador de registros (debe crearse con cada tabla nueva)*/}
                  </div>
                </div>
                <div className='h-2' />
                <div className='card-body table-responsive'>
                  <table className='table table-row-bordered gs-3 gy-4 gx-12'>
                    <thead>
                      {table.getHeaderGroups().map((headerGroup) => (
                        <tr
                          key={headerGroup.id}
                          className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'
                        >
                          {headerGroup.headers.map((header) => {
                            return (
                              <th key={header.id} colSpan={header.colSpan}>
                                {header.isPlaceholder ? null : (
                                  <>
                                    <div
                                      {...{
                                        className: header.column.getCanSort()
                                          ? 'cursor-pointer select-none'
                                          : '',
                                        onClick: header.column.getToggleSortingHandler(),
                                      }}
                                    >
                                      {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                      )}
                                      {{
                                        asc: ' 🔼',
                                        desc: ' 🔽',
                                      }[header.column.getIsSorted() as string] ?? null}
                                    </div>
                                    {header.column.getCanFilter() ? (
                                      <div>
                                        <Filter column={header.column} table={table} />
                                      </div>
                                    ) : null}
                                  </>
                                )}
                              </th>
                            )
                          })}
                        </tr>
                      ))}
                    </thead>
                    <tbody>
                      {table.getRowModel().rows.map((row) => {
                        return (
                          <tr key={row.id}>
                            {row.getVisibleCells().map((cell) => {
                              return (
                                <td key={cell.id}>
                                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                              )
                            })}
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>

                <div className='h-2' />

                <div className='card-footer'>
                  <div className='d-flex justify-content-between gap-2'>
                    <div id='SwitchPages'>
                      <button
                        className='btn btn-primary border rounded p-1'
                        onClick={() => table.setPageIndex(0)}
                        disabled={!table.getCanPreviousPage()}
                      >
                        {'<<'}
                      </button>
                      <button
                        className='btn btn-primary border rounded p-1'
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                      >
                        {'<'}
                      </button>
                      <button
                        className='btn btn-primary border rounded p-1'
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                      >
                        {'>'}
                      </button>
                      <button
                        className='btn btn-primary border rounded p-1'
                        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                        disabled={!table.getCanNextPage()}
                      >
                        {'>>'}
                      </button>
                    </div>

                    <div id='PagesFinder' className='text-center'>
                      {' '}
                      <span className='flex items-center gap-1'>
                        {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_PAGE'})}{' '}
                        <strong>
                          {table.getState().pagination.pageIndex + 1}{' '}
                          {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_PAGE_OF'})}{' '}
                          {table.getPageCount()}
                        </strong>{' '}
                        | {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_GO_TO_PAGE'})}:
                        <input
                          type='number'
                          defaultValue={table.getState().pagination.pageIndex + 1}
                          onChange={(e) => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            table.setPageIndex(page)
                          }}
                          className='form-control form-control-solid w-16'
                        />
                      </span>
                    </div>

                    <div id='showFiles' className='text-center'>
                      <select
                        className='form-select form-select-solid'
                        value={table.getState().pagination.pageSize}
                        onChange={(e) => {
                          table.setPageSize(Number(e.target.value))
                        }}
                      >
                        {[10, 20, 30, 40, 50].map((pageSize) => (
                          <option key={pageSize} value={pageSize}>
                            {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_SHOW_PART_ONE'})}{' '}
                            {pageSize}{' '}
                            {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_SHOW_PART_TWO'})}
                          </option>
                        ))}
                      </select>
                      <p>
                        {table.getPrePaginationRowModel().rows.length}{' '}
                        {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_TOTAL_RECORDS'})}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

function Filter({column, table}: {column: Column<any, unknown>; table: Table<any>}) {
  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id)
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()
  const columnFilterValue = column.getFilterValue()

  const sortedUniqueValues = React.useMemo(
    () =>
      typeof firstValue === 'number'
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  )

  return typeof firstValue === 'number' ? (
    <div>
      <div className='flex space-x-2'>
        <DebouncedInput
          type='number'
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[0] ?? ''}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [value, old?.[1]])}
          placeholder={`Min ${
            column.getFacetedMinMaxValues()?.[0] ? `(${column.getFacetedMinMaxValues()?.[0]})` : ''
          }`}
          className='w-24 border shadow rounded'
        />
        <DebouncedInput
          type='number'
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[1] ?? ''}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [old?.[0], value])}
          placeholder={`Max ${
            column.getFacetedMinMaxValues()?.[1] ? `(${column.getFacetedMinMaxValues()?.[1]})` : ''
          }`}
          className='w-24 border shadow rounded'
        />
      </div>
      <div className='h-1' />
    </div>
  ) : (
    <>
      <datalist id={column.id + 'list'}>
        {sortedUniqueValues.slice(0, 5000).map((value: any) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <DebouncedInput
        type='text'
        value={(columnFilterValue ?? '') as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`${intl.formatMessage({id: 'GLOBAL.TABLE_SEARCH_THREE_DOTS'})} (${column.getFacetedUniqueValues().size})`}
        className='w-36 border shadow rounded'
        list={column.id + 'list'}
      />
      <div className='h-1' />
    </>
  )
}

// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return <input {...props} value={value} onChange={(e) => setValue(e.target.value)} />
}
