import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { Hito } from "../interfaces/rolesTypes";
import { useIntl } from "react-intl";

function HitosHour({ day, schedules, setSchedules, prevData }) {
    const intl = useIntl();
    const indexDay = [
        intl.formatMessage({id: 'GLOBAL.MONDAY'}),
        intl.formatMessage({id: 'GLOBAL.TUESDAY'}),
        intl.formatMessage({id: 'GLOBAL.WEDNESDAY'}),
        intl.formatMessage({id: 'GLOBAL.THURSDAY'}),
        intl.formatMessage({id: 'GLOBAL.FRIDAY'}),
        intl.formatMessage({id: 'GLOBAL.SATURDAY'}),
        intl.formatMessage({id: 'GLOBAL.SUNDAY'})
    ].indexOf(day);
    const [rowCount, setRowCount] = useState(prevData?.schedules[indexDay]?.length > 0 ? prevData?.schedules[indexDay]?.length : 1);
    const [storedSchedules, setStoredSchedules] = useState(prevData || {});
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        setStoredSchedules(prevData);
    }, []);

    const generateTimeOptions = (milestoneSelected = "00:00"): JSX.Element[] => {
        let options: JSX.Element[] = [];
        let hour = 0; // Hora inicial (07:00 a.m.)
        let minute = 0; // Minuto inicial

        for (let i = 0; i < 48; i++) {
            let hourString = hour < 10 ? "0" + hour : hour.toString();
            let minuteString = minute === 0 ? "00" : minute.toString();

            options.push(
                <option key={i} value={`${hourString}:${minuteString}`}>
                    {`${hourString}:${minuteString}`}
                </option>
            );

            minute += 30;

            if (minute === 60) {
                minute = 0;
                hour++;
            }

            if (hour === 24) {
                hour = 0;
            }
        }

        return options;
    };

    const validateTime = (startTime: string, endTime: string): string | null => {
        const start = new Date(`2024-01-01T${startTime}`);
        const end = new Date(`2024-01-01T${endTime}`);
        if (endTime === '00:00') {
            return null; // No devolver mensaje de error si es 00:00
        }
        if (end <= start) {
            return intl.formatMessage({id: 'ASSIGNS.END_TIME_MUST_BE_GREATER'});
        }
        return null;
    };

    const handleAddRow = () => {
        if (rowCount < 12) {
            setRowCount(prevCount => prevCount + 1);
        }
    };

    const handleRemoveRow = () => {
        if (rowCount > 1) {
            setRowCount(prevCount => prevCount - 1);
        }
    };

    const handleSaveHito = () => {
        const newHito: Hito[] = [];
        const errors: { [key: string]: string } = {};

        for (let i = 0; i < rowCount; i++) {
            const startElement = document.getElementById(`start${indexDay}-${i}`) as HTMLSelectElement;
            const endElement = document.getElementById(`end${indexDay}-${i}`) as HTMLSelectElement;

            if (startElement && endElement) {
                const startTime = startElement.value;
                const endTime = endElement.value;

                const validationError = validateTime(startTime, endTime);
                if (validationError) {
                    errors[`row-${i}`] = validationError;
                }

                newHito.push({ start: startTime, end: endTime });
            } else {
                console.error(`Elemento start${i} o end${i} no encontrado en el DOM`);
            }
        }

        setValidationErrors(errors);

        if (Object.keys(errors).length === 0) {
            const updatedSchedules = [...schedules];
            if (!updatedSchedules[indexDay]) {
                updatedSchedules[indexDay] = [];
            }
            updatedSchedules[indexDay] = newHito;
            setSchedules(updatedSchedules);
            console.log(schedules)
        }
    };

    const handleTimeChange = () => {
        handleSaveHito();
    };

    return (
        <Container>
            <Form.Label>{intl.formatMessage({id: 'ASSIGNS.SELECT_SCHEDULE_RANGE'})}</Form.Label>
            <Row className="mb-10">
                <Col className="d-flex justify-content-start">
                    <Button variant="danger" className="rounded-circle p-2" style={{ fontSize: '0.75rem' }} onClick={handleRemoveRow}>
                        <span className="d-flex align-items-center">
                            <i className="fas fa-minus"></i>
                        </span>
                    </Button>
                </Col>
                <Col className="d-flex justify-content-end">
                    <Button variant="primary" className="rounded-circle p-2" style={{ fontSize: '0.75rem' }} onClick={handleAddRow}>
                        <span className="d-flex align-items-center">
                            <i className="fas fa-plus"></i>
                        </span>
                    </Button>
                </Col>
            </Row>
            {[...Array(rowCount)].map((_, index) => (
                <React.Fragment key={index}>
                    <Row>
                        <Col>
                            <Form.Group controlId={`start${indexDay}-${index}`}>
                                <Form.Label>{intl.formatMessage({id: 'ASSIGNS.STARTS'})}:</Form.Label>
                                <Form.Control as="select" onChange={handleTimeChange} value={storedSchedules.schedules[indexDay]?.[index]?.start}>
                                    {generateTimeOptions(storedSchedules.schedules[indexDay]?.[index]?.start)}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId={`end${indexDay}-${index}`}>
                                <Form.Label>{intl.formatMessage({id: 'ASSIGNS.ENDS'})}:</Form.Label>
                                <Form.Control as="select" onChange={handleTimeChange} value={storedSchedules.schedules[indexDay]?.[index]?.end}>
                                    {generateTimeOptions(storedSchedules.schedules[indexDay]?.[index]?.end)}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    {validationErrors[`row-${index}`] && (
                        <Row>
                            <Col>
                                <p className="text-danger">{validationErrors[`row-${index}`]}</p>
                            </Col>
                        </Row>
                    )}
                </React.Fragment>
            ))}
        </Container>
    );
}

export { HitosHour };
