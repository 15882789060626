// Importamos hooks de react
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'

interface ModalActiveUnactiveProductProps {
  product: any
}

export const ModalActiveUnactiveProduct: React.FC<ModalActiveUnactiveProductProps> = ({
  product,
}) => {
  const [Body, setBody] = useState<any>()
  const intl = useIntl()

  console.log(product)

  const handleShowBody = () => {
    if (product.status === 1) {
      setBody(
        <div>
          <div className='alert alert-dismissible bg-light-warning border border-warning mb-4'>
            <div className='d-flex flex-column flex-sm-row p-3'>
              <span className='svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0 text-dark'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-exclamation-triangle-fill'
                  viewBox='0 0 16 16'
                >
                  <path d='M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2' />
                </svg>
              </span>
              <div className='d-flex flex-column text-primary pe-0 pe-sm-10'>
                <h5 className='mb-1'>
                  {intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.DEACTIVATE_PRODUCT'})}
                </h5>
                <span className='text-dark'>
                  <p>
                    {intl.formatMessage(
                      {id: 'ACCOUNT_MANAGEMENT.CONFIRM_DEACTIVATE_PRODUCT'},
                      {name: product.name, id: product.pro_id}
                    )}
                  </p>
                  <p>{intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.AFFECTED_CAMPAIGNS'})}</p>
                  <p>{intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.MENTION_REASON'})}</p>
                </span>
              </div>
            </div>
          </div>

          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>
              {intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.DEACTIVATION_PRODUCT_REASON'})}
            </label>
            <select className='form-select form-select-lg form-select-solid mb-4'>
              <option value='0'>
                --{intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.DEACTIVATION_PRODUCT_REASON'})}--
              </option>
              <option value='1'>{intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.REASON_ROI'})}</option>
              <option value='2'>
                {intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.REASON_STRATEGY'})}
              </option>
              <option value='3'>
                {intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.REASON_LIFECYCLE'})}
              </option>
              <option value='4'>
                {intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.REASON_REPLACEMENT'})}
              </option>
              <option value='5'>
                {intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.REASON_DEMAND'})}
              </option>
            </select>
            {/* <input
              className='form-control form-control-solid mb-4'
              placeholder='Motivo de desactivacion del producto'
            />
            <textarea
              value='Ingresa el motivo por el cual quieres desactivar este producto...'
              className='form-control form-control-solid'
            /> */}
          </div>
        </div>
      )
    } else {
      setBody(
        <div>
          <div className='alert alert-dismissible bg-light-warning border border-warning mb-4'>
            <div className='d-flex flex-column flex-sm-row p-3'>
              <span className='svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0 text-dark'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-exclamation-triangle-fill'
                  viewBox='0 0 16 16'
                >
                  <path d='M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2' />
                </svg>
              </span>
              <div className='d-flex flex-column text-primary pe-0 pe-sm-10'>
                <h5 className='mb-1'>
                  {intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.ACTIVATE_PRODUCT'})}
                </h5>
                <span className='text-dark'>
                  <p>
                    {intl.formatMessage(
                      {id: 'ACCOUNT_MANAGEMENT.CONFIRM_ACTIVATE_PRODUCT'},
                      {name: product.name}
                    )}
                  </p>
                  <p>{intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.AFFECTED_CAMPAIGNS'})}</p>
                </span>
              </div>
            </div>
          </div>

          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-4'>
              {intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.REACTIVATION_REASON'})}
            </label>
            <select className='form-select form-select-lg form-select-solid mb-4'>
              <option value='0'>
                --{intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.SELECT_REACTIVATION_REASON'})}--
              </option>
              <option value='1'>{intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.REASON_ROI'})}</option>
              <option value='2'>
                {intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.REASON_STRATEGY'})}
              </option>
              <option value='3'>
                {intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.REASON_LIFECYCLE'})}
              </option>
              <option value='4'>
                {intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.REASON_REPLACEMENT'})}
              </option>
              <option value='5'>
                {intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.REASON_DEMAND'})}
              </option>
            </select>
            {/* <input
              className='form-control form-control-solid mb-4'
              placeholder='Motivo de reactivacion del producto'
            />
            <textarea
              value='Ingresa el motivo por el cual quieres reactivar este producto...'
              className='form-control form-control-solid'
            /> */}
          </div>
        </div>
      )
    }
  }

  useEffect(() => {
    handleShowBody()
  }, [])

  return (
    <>
      <div>{Body}</div>
    </>
  )
}
