// Importamos hooks de react que usaremos para este componente
import React, { useState, useContext, useEffect } from 'react'
// Importamos libreria para manejo de lenguaje
import { useIntl } from 'react-intl'

// ---------------------------------------------------------------------------------------

// Importamos el contexto de usuario
import { UserContext } from '../../../../app-context/user-context/UserContext'

// ---------------------------------------------------------------------------------------

// Importamos la funcion campaignGeneralInfoEdit para conexion con API de campañas
import { campaignGeneralInfoEdit } from '../../services/_CampaignsEditApi'
// Importamos la funcion rolesListByClient para conexion con API de roles
import { rolesListByClient } from '../../../assigns/services/_RolesListApi'

// ---------------------------------------------------------------------------------------

// Importamos las interfaces globales que usaremos para este modulo
import { AlertInfo } from '../../../../utils/interfaces/_InterfacesTypes'
// Importamos las interfaces de roles que usaremos para este componente
import { Role } from '../../../assigns/interfaces/rolesTypes'

// ---------------------------------------------------------------------------------------

// Importamos funcion sessionExpired para vencimiento de sesion
import { sessionExpired } from '../../../../utils/functions/_Functions'

// Iniciamos funcion para construir componente GeneralTabForm
function GeneralTabForm({
  productId,
  formDataEditGral,
  setFormDataEditGral,
  cam_idCampaign,
  closeModalEdit,
  getCampaignsRegisters,
}) {
  console.log(formDataEditGral)

  //Variables desde el usercontext
  const { user, token, updateToken } = useContext(UserContext)
  const userToken = token
  const clientId = user.cli_id
  const cam_role = formDataEditGral.cam_role

  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  console.log(formDataEditGral)

  const [alertInfo, setAlertInfo] = useState<AlertInfo>({
    text: '',
    variant: 'primary',
  })

  const [rolesList, setRolesList] = useState<Role[]>([])

  const [selectedDays, setSelectedDays] = useState(formDataEditGral.days || [])

  const [detectDuplicatesAllTime, setDetectDuplicatesAllTime] = useState<boolean>(false)
  const [dupDays, setDupDays] = useState<number>(formDataEditGral.dupdays)

  console.log(formDataEditGral.days)

  const handleDuplicatesAlltime = () => {
    setDetectDuplicatesAllTime(!detectDuplicatesAllTime)

    const inputDays = document.getElementById('dupdays') as HTMLInputElement

    if (inputDays && !detectDuplicatesAllTime) {
      inputDays.value = '0'
      inputDays.disabled = true
      setFormDataEditGral((prevState) => ({
        ...prevState,
        dupdays: 0
      }))

    } else {
      inputDays.value = dupDays.toString()
      inputDays.disabled = false
      setFormDataEditGral((prevState) => ({
        ...prevState,
        dupdays: dupDays
      }))
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target

    const inputValue = type === 'checkbox' ? checked : value

    setFormDataEditGral((prevState) => ({
      ...prevState,
      [name]: inputValue,
    }))
  }

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target

    setFormDataEditGral((prevState) => ({
      ...prevState,
      [name]: value,
    }))

    console.log('nuevo rol: ', formDataEditGral.roleId)
  }

  // const handleDayChange = (e) => {
  //   const day = e.target.name
  //   const isChecked = e.target.checked

  //   let updatedDays
  //   if (isChecked) {
  //     updatedDays = [...formDataEditGral.days, day]
  //   } else {
  //     updatedDays = formDataEditGral.days.filter((selectedDay) => selectedDay !== day)
  //   }

  //   setFormDataEditGral((prevFormData) => ({
  //     ...prevFormData,
  //     days: updatedDays,
  //   }))

  //   setSelectedDays(updatedDays)
  // }

  // const days = [
  //   intl.formatMessage({ id: 'GLOBAL.MONDAY' }),
  //   intl.formatMessage({ id: 'GLOBAL.TUESDAY' }),
  //   intl.formatMessage({ id: 'GLOBAL.WEDNESDAY' }),
  //   intl.formatMessage({ id: 'GLOBAL.THURSDAY' }),
  //   intl.formatMessage({ id: 'GLOBAL.FRIDAY' }),
  //   intl.formatMessage({ id: 'GLOBAL.SATURDAY' }),
  //   intl.formatMessage({ id: 'GLOBAL.SUNDAY' }),
  // ]

  const handleFormSubmit = async () => {
    console.log('FormDataEditGral values:')
    console.log('status:', formDataEditGral.status)
    console.log('quality:', formDataEditGral.quality)
    console.log('cam_role:', formDataEditGral.cam_role)
    console.log('call:', formDataEditGral.call)
    console.log('duplicates:', formDataEditGral.duplicates)
    console.log('manual:', formDataEditGral.manual)
    console.log('dupdays:', formDataEditGral.dupdays)
    console.log('iscore:', formDataEditGral.iscore)

    const status: boolean = formDataEditGral.status
    const quality: boolean = formDataEditGral.quality
    const cam_role: string = formDataEditGral.cam_role
    const call: string = formDataEditGral.call
    const duplicates: number = formDataEditGral.duplicates 
    const manual: boolean = formDataEditGral.manual
    const dupdays: number = formDataEditGral.dupdays 
    const iscore: number = formDataEditGral.iscore

    try {
      const {
        data: jsonData,
        code,
        msg,
      } = await campaignGeneralInfoEdit(
        status,
        quality,
        cam_role,
        call,
        duplicates,
        manual,
        dupdays,
        iscore,
        cam_idCampaign,
        userToken
      )

      if (code === 401 && msg === 'Token no valido') {
        sessionExpired(intl.formatMessage({ id: 'GLOBAL.ALERT.SESSION_EXPIRED' }))
        return
      } else {
        setAlertInfo({
          text:
            code === 200
              ? intl.formatMessage({ id: 'CAMPAIGNS.MODAL_EDIT.SUCCESS_CAMPAIGN_EDITION' })
              : intl.formatMessage({ id: 'CAMPAIGNS.MODAL_EDIT.FAIL_CAMPAIGN_EDITION' }),
          variant: code === 200 ? 'success' : 'danger',
        })

        setTimeout(() => {
          setAlertInfo({
            text: '',
            variant: '',
          })
          closeModalEdit()
          getCampaignsRegisters()
        }, 3000)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getRolesRegisters = async () => {
    try {
      const { data: jsonData, code, msg } = await rolesListByClient(userToken, clientId)

      if (code === 401 && msg === 'token no valido') {
        sessionExpired(intl.formatMessage({ id: 'GLOBAL.ALERT.SESSION_EXPIRED' }))
        return
      } else {
        const rolesListReceived = jsonData.assigns

        console.log(rolesListReceived)

        const userTokenNew = jsonData.token

        updateToken(userTokenNew)

        setRolesList(rolesListReceived)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getRolesRegisters()
  }, [])

  return (
    <>
      <div className='d-flex mb-8'>
        <label className='mr-8'>{intl.formatMessage({ id: 'GLOBAL.STATUS' })}: </label>
        &nbsp;&nbsp;&nbsp;
        <div className='form-check form-switch'>
          <input
            className='form-check-input'
            type='checkbox'
            id='status'
            name='status'
            checked={formDataEditGral.status}
            onChange={handleInputChange}
          />
          <label className='form-check-label' htmlFor='status'>
            {intl.formatMessage({ id: 'GLOBAL.OFF_ON' })}
          </label>
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <label className='mr-8'>{intl.formatMessage({ id: 'GLOBAL.QUALITY' })}: </label>
        &nbsp;&nbsp;&nbsp;
        <div className='form-check form-switch'>
          <input
            className='form-check-input'
            type='checkbox'
            id='quality'
            name='quality'
            checked={formDataEditGral.quality}
            onChange={handleInputChange}
          />
          <label className='form-check-label' htmlFor='quality'>
            {intl.formatMessage({ id: 'GLOBAL.OFF_ON' })}
          </label>
        </div>
      </div>

      <div>
        <label className='mb-3'>{intl.formatMessage({ id: 'CAMPAIGNS.CAMPAIGN_ROLE' })}: </label>
        <div className='d-flex'>
          <div className='col-6 me-2'>
            <span className='text-muted'>
              {intl.formatMessage({ id: 'CAMPAIGNS.SELECT_ROLES_HINT' })}
            </span>

          </div>
          <div className='col-6'>
            <select
              className='form-select form-select-solid'
              name='cam_role'
              value={formDataEditGral.cam_role}
              onChange={handleSelectChange}
            >
              <option value=''> {cam_role} </option>
              {/* <option value={formDataEditGral.cam_role}>{formDataEditGral.cam_role}</option> */}
              {rolesList.map((role, index) => (
                <option key={index} id={role.role_id} value={role.role_id}>
                  {role.role_name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className='d-flex'>
        <div className='col-4 me-2'>
          <label className='mb-6 mt-6'>
            Llamada:
          </label>
          <div className='form-check mb-4'>
            <input
              className='form-check-input'
              type='radio'
              name='call'
              id='moreQuality'
              value='Mayor Calidad'
              checked={formDataEditGral.call === 'Mayor Calidad'}
              onChange={handleInputChange}
            />
            <label className='form-check-label' htmlFor='moreQuality'>
              {'Mayor calidad'}
            </label>
          </div>
          <div className='form-check mb-4'>
            <input
              className='form-check-input'
              type='radio'
              name='call'
              id='moreVolume'
              value='Mayor Volumen'
              checked={formDataEditGral.call === 'Mayor Volumen'}
              onChange={handleInputChange}
            />
            <label className='form-check-label' htmlFor='moreVolume'>
              {'Mayor volumen'}
            </label>
          </div>
          <div className='form-check mb-4'>
            <input
              className='form-check-input'
              type='radio'
              name='call'
              id='noCall'
              value='Sin llamada'
              checked={formDataEditGral.call === 'Sin llamada'}
              onChange={handleInputChange}
            />
            <label className='form-check-label' htmlFor='noCall'>
              {'Sin llamada'}
            </label>
          </div>
        </div>

        <div className='col-4 me-2'>
          <label className='mb-6 mt-6'>
            {intl.formatMessage({ id: 'CAMPAIGNS.WHATSAPP.DUPLICATE_HANDLING' })}:
          </label>
          <div className='form-check mb-4'>
            <input
              className='form-check-input'
              type='radio'
              name='duplicates'
              id='campaign'
              value={1}
              checked={formDataEditGral.duplicates === 1}
              onChange={handleInputChange}

            />
            <label className='form-check-label' htmlFor='campaign'>
              {intl.formatMessage({ id: 'GLOBAL.CAMPAIGN' })}
            </label>
          </div>
          <div className='form-check mb-4'>
            <input
              className='form-check-input'
              type='radio'
              name='duplicates'
              id='product'
              value={2}
              checked={formDataEditGral.duplicates === 2}
              onChange={handleInputChange}
            />
            <label className='form-check-label' htmlFor='product'>
              {intl.formatMessage({ id: 'GLOBAL.PRODUCT' })}
            </label>
          </div>
          <div className='form-check mb-4'>
            <input
              className='form-check-input'
              type='radio'
              name='duplicates'
              id='brand'
              value={3}
              checked={formDataEditGral.duplicates === 3}
              onChange={handleInputChange}
            />
            <label className='form-check-label' htmlFor='brand'>
              {intl.formatMessage({ id: 'GLOBAL.BRAND' })}
            </label>
          </div>
        </div>

        <div className='col-4'>
          <label className='mr-8 mt-6 mb-6'>Permitir leads manuales: </label>
          &nbsp;&nbsp;&nbsp;
          <div className='form-check form-switch'>
            <input
              className='form-check-input'
              type='checkbox'
              id='toggleSwitchManual'
              name='manual'
              checked={formDataEditGral.manual}
              onChange={handleInputChange}
            />
            <label className='form-check-label' htmlFor='toggleSwitchManual'>
              {intl.formatMessage({ id: 'GLOBAL.OFF_ON' })}
            </label>
          </div>
        </div>

      </div>

      <div className='d-flex mb-6 mt-8'>
        <div className='col-4'>
          <label>
            {intl.formatMessage({ id: 'CAMPAIGNS.WHATSAPP.DUPLICATE_DAYS' })}:
          </label>
        </div>

        <div className='col-3 me-4'>
          <input
            type='number'
            className='form-control form-control-solid'
            id='dupdays'
            name='dupdays'
            defaultValue={formDataEditGral.dupdays}
            onChange={handleInputChange}
          />
        </div>

        <div className='col-5'>
          <div className='d-flex'>
            <label className='mb-4'>
              {intl.formatMessage({ id: 'CAMPAIGNS.WHATSAPP.DETECT_DUPLICATES' })}:
            </label>
            &nbsp;
            <input
              type='checkbox'
              className='form-check-input'
              checked={detectDuplicatesAllTime}
              onChange={handleDuplicatesAlltime}
            />
          </div>
        </div>

      </div>

      <div className='d-flex'>
        <div className='col-2'>
          <label className='mb-4'>Iscore:</label>
        </div>
        <div className='col-3 me-4'>
          <input
            type='number'
            className='form-control form-control-solid'
            name='iscore'
            id='iscore'
            value={formDataEditGral.iscore}
            onChange={handleInputChange}
            max={20}
          />
        </div>
        <div className='col-6'>
          <label className='mb-4'>
            {intl.formatMessage({ id: 'CAMPAIGNS.WHATSAPP.MAX_SCORE' })}            
          </label>
        </div>
      </div>

      {alertInfo.text && (
        <div className={`alert alert-${alertInfo.variant} d-flex align-items-center`} role='alert'>
          <div>{alertInfo.text}</div>
        </div>
      )}

      <button type='submit' className='btn btn-primary btn-sm mt-8' onClick={handleFormSubmit}>
        {intl.formatMessage({ id: 'GLOBAL.SEND' })}
      </button>
    </>
  )
}

export { GeneralTabForm }
