// Importamos hooks y aux de React y helpers de metronic y bootstrap
import { FC } from "react";
import { OverlayTrigger, Tooltip, TooltipProps } from "react-bootstrap";
import { useIntl } from "react-intl";

// Propiedades que debe recibir el componente
type Props = {
    duplicateLeads: number;
    originalLeads: number;
    totalLeads: number;
}

// Montamos componente
const LeadsCampaignsProgress: FC<Props> = ({
    duplicateLeads,
    originalLeads,
    totalLeads
}) => {
    //Variable para el uso de diccionario de lenguajes
    const intl = useIntl()
    // Calcula los porcentajes de los valores de los leads
    const duplicatePercentage: number = (duplicateLeads / totalLeads) * 100;
    const originalPercentage: number = (originalLeads / totalLeads) * 100;

    // Constantes para tooltips de botones
    const renderTooltipDuplicates = (props: TooltipProps): JSX.Element => (
        <Tooltip {...props}>{intl.formatMessage({id: 'REPORTS.LEADS.DUPLICATE_LEADS'})}: {duplicatePercentage.toFixed(2)}%</Tooltip>
    )
    const renderTooltipOriginals = (props: TooltipProps): JSX.Element => (
        <Tooltip {...props}>{intl.formatMessage({id: 'REPORTS.LEADS.ORIGINAL_LEADS'})}: {originalPercentage.toFixed(2)}%</Tooltip>
    )

    return (
        <div className="progress h-4px w-100 mt-2">
            <OverlayTrigger placement='top' overlay={renderTooltipDuplicates}>

                <div
                    className="progress-bar bg-warning"
                    role="progressbar"
                    style={{ width: `${duplicatePercentage}%` }}
                    aria-valuenow={duplicatePercentage}
                    aria-valuemin={0}
                    aria-valuemax={100}
                >
                    {/* Duplicados: {duplicatePercentage.toFixed(2)}% */}
                </div>
            </OverlayTrigger>
            <OverlayTrigger placement='top' overlay={renderTooltipOriginals}>

                <div
                    className="progress-bar bg-success"
                    role="progressbar"
                    style={{ width: `${originalPercentage}%` }}
                    aria-valuenow={originalPercentage}
                    aria-valuemin={0}
                    aria-valuemax={100}
                >

                    {/* Originales: {originalPercentage.toFixed(2)}% */}
                </div>
            </OverlayTrigger>
        </div>
    );
};

export default LeadsCampaignsProgress;
