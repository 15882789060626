// Importamos hooks de react que usaremos para este componente
import { useState, ChangeEvent  } from "react"
// Importamos libreria para manejo de lenguaje 
import { useIntl } from "react-intl";

// ---------------------------------------------------------------------------------------

// Importamos interfaces de productos que usaremos en este componente
import { Step1Props } from "../../interfaces/productsTypes";

import { Step1Errors } from "../../interfaces/productsTypes";

// Iniciamos funcion para construir el componete Step1
function Step1({onNext, prevData, setStep1Data}: Step1Props){
    //Variable para el uso de diccionario de lenguajes
    const intl = useIntl();

    const [productName, setProductName] = useState(prevData?.productName || '');
    const [productMails, setProductMails] = useState(prevData?.productMails || []);
    const [productType, setProductType] = useState(prevData?.productType || '');
    const [productLegals, setProductLegals] = useState(prevData?.productLegals || '');
    const [productPrize, setProductPrize] = useState(prevData?.productPrize || 0);

    const [errors, setErrors] = useState<Step1Errors>({
        productName: '',
        productMails: '',
        productType: '',
        productLegals: '',
        productPrize: '',
      })

      const validateInputs = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
        // Inicializa los errores con valores predeterminados
        const newErrors: Step1Errors = {
            productName: !productName ? intl.formatMessage({id: 'PRODUCTS.STEP1.ERROR_NAME_REQUIRED'}) : '',
            productMails: productMails.length === 0
                ? intl.formatMessage({id: 'PRODUCTS.STEP1.ERROR_EMAIL_REQUIRED'})
                : productMails.some(email => !emailRegex.test(email.trim()))
                    ? intl.formatMessage({id: 'PRODUCTS.STEP1.ERROR_INVALID_EMAILS'})
                    : '',
            productType: productType === ''
                ? intl.formatMessage({id: 'PRODUCTS.STEP1.ERROR_SELECT_PRODUCT_TYPE'})
                : '',
            productLegals: !productLegals ? intl.formatMessage({id: 'PRODUCTS.STEP1.ERROR_LEGAL_LINK_REQUIRED'}) : '',
            productPrize: productPrize <= 0 ? intl.formatMessage({id: 'PRODUCTS.STEP1.ERROR_PRICE_GREATER_THAN_ZERO'}) : ''
        };
    
        setErrors(newErrors)

        console.log(newErrors)
    
        return Object.values(newErrors).every((error) => error === '')
    } 

    // Funcion para pasar y cambiar el estado de los datos con los nuevos valores
    const handleNext = () => {
        if(validateInputs()) {
        onNext({
            productName, 
            productMails, 
            productType, 
            productLegals, 
            productPrize
        });
        
        setStep1Data({ 
            productName, 
            productMails, 
            productType, 
            productLegals, 
            productPrize
        });
        }
    };

    const handleMailsChange = (e: ChangeEvent<HTMLInputElement>) => {
        // Dividir la cadena de correos electrónicos por comas y actualizar el estado
        const mailsArray = e.target.value.split(',').map(email => email.trim());
        setProductMails(mailsArray);
    };

    return (
        <div>
            <h2 className="modal-title mb-4">{intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.LABEL_PROINFO'})}</h2>
            <form>
                <div className="d-flex">
                    <div className="col-6 me-2">
                        <label className="form-label fw-bold" htmlFor="productName">{intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.INPUTNAME'})}:</label>
                        <input 
                            type="text"
                            id="productName"
                            className="form-control form-control-solid mb-6"
                            placeholder={intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.INPUTNAME_PLACEHOLDER'})}
                            value={productName}
                            onChange={(e) => setProductName(e.target.value)}
                        />
                        <div className='text-danger mb-6'>{errors.productName}</div>
                    </div>

                    <div className="col-6 me-2">
                        <label className="form-label fw-bold" htmlFor="productPrize">{intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.INPUTPRIZE'})}</label>
                        <input 
                            type="number"
                            id="productPrize"
                            className='form-control form-control-solid mb-6'
                            placeholder={intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.INPUTPRIZE_PLACEHOLDER'})}
                            value={productPrize}
                            onChange={(e) => setProductPrize(e.target.value)}
                        />
                        <div className='text-danger mb-6'>{errors.productPrize}</div>
                    </div>
                </div>
                

                <label className="form-label fw-bold" htmlFor="productMails">{intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.INPUTMAILS'})}:</label>
                <input 
                    type="text"
                    id="productMails"
                    className="form-control form-control-solid mb-1"
                    placeholder={intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.INPUTMAILS_PLACEHOLDER'})}
                    value={productMails.join(',')}
                    onChange={handleMailsChange}
                />
                <div className='text-danger mb-6'>{errors.productMails}</div>
                <span className='text-muted'>{intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.INPUTMAILS_SPAN'})}</span>

                <div className="d-flex mt-6">
                    <div className="col-6 me-2">
                        <label className="form-label fw-bold" htmlFor="productType">{intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.SELECTTYPEPRODUCT'})}:</label>
                        <select 
                            id="productType" 
                            className="form-select form-select-solid mb-6"
                            value={productType} 
                            onChange={(e) => setProductType(e.target.value)}>
                            <option value="">--{intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.SELECTTYPEPRODUCT_OPTION1'})}--</option>
                            <option value="Consultorios">{intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.PRODUCT_TYPE_CONSULTORIOS'})}</option>
                            <option value="Desarrollo inmibiliario">{intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.PRODUCT_TYPE_DESARROLLO_INMOBILIARIO'})}</option>
                            <option value="App Digital">{intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.PRODUCT_TYPE_APP_DIGITAL'})}</option>
                            <option value="Empresas">{intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.PRODUCT_TYPE_EMPRESAS'})}</option>
                            <option value="Corporation">{intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.PRODUCT_TYPE_CORPORATION'})}</option>
                            <option value="Corretaje Inmobiliario">{intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.PRODUCT_TYPE_CORRETAJE_INMOBILIARIO'})}</option>
                            <option value="Agencia de Autos">{intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.PRODUCT_TYPE_AGENCIA_AUTOS'})}</option>
                            <option value="Casas y Departamentos">{intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.PRODUCT_TYPE_CASAS_DEPARTAMENTOS'})}</option>
                            <option value="Asesoria Legal">{intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.PRODUCT_TYPE_ASESORIA_LEGAL'})}</option>
                            <option value="Agencia">{intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.PRODUCT_TYPE_AGENCIA'})}</option>
                            <option value="Corporativo">{intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.PRODUCT_TYPE_CORPORATIVO'})}</option>
                            <option value="Oficinas">{intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.PRODUCT_TYPE_OFICINAS'})}</option>
                            <option value="Casas">{intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.PRODUCT_TYPE_CASAS'})}</option>
                            <option value="Departamentos">{intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.PRODUCT_TYPE_DEPARTAMENTOS'})}</option>
                            <option value="Terrenos">{intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.PRODUCT_TYPE_TERRENOS'})}</option>
                            <option value="Procesador de Pagos">{intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.PRODUCT_TYPE_PROCESADOR_PAGOS'})}</option>
                            <option value="Gimnasios">{intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.PRODUCT_TYPE_GIMNASIOS'})}</option>
                            <option value="Fintech">{intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.PRODUCT_TYPE_FINTECH'})}</option>
                            <option value="Lojas">{intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.PRODUCT_TYPE_LOJAS'})}</option>
                        </select>
                        <div className='text-danger mb-6'>{errors.productType}</div>
                    </div>

                    <div className="col-6">
                    <label className="form-label fw-bold" htmlFor="productLegals">{intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.INPUTLEGACY'})}</label>
                    <input 
                        type="url"
                        id="productLegals"
                        className="form-control form-control-solid mb-6"
                        placeholder={intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.INPUTLEGACY_PLACEHOLDER'})}
                        value={productLegals}
                        onChange={(e) => setProductLegals(e.target.value)} 
                    />
                    <div className='text-danger mb-6'>{errors.productLegals}</div>
                    </div>
                </div>
            </form>
            {/* <button onClick={handleNext}>Siguiente</button> */}
            <button className="btn btn-primary btn-sm mt-6" onClick={handleNext}>{intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.BUTTONNEXT'})}</button>

        </div>
    )
}

export {Step1}