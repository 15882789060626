import {FC, useState, useMemo, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {Lead, LeadData} from '../../../../leads/interfaces/leadsTypes'
import {
  Column,
  Table,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  FilterFn,
  ColumnDef,
  flexRender,
} from '@tanstack/react-table'
import {RankingInfo, rankItem} from '@tanstack/match-sorter-utils'
import {Button, Dropdown, DropdownButton, Modal, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {KTIcon} from '../../../../../../_metronic/helpers'
import {LoadingAnimation} from '../../../../../utils/components/loading/LoadingAnimation'
import {DropdownExportLogs} from '../../../../../../_metronic/partials/content/dropdown/DropdownExportLogs'
import {DropdownExportGral} from '../../../../../../_metronic/partials/content/dropdown/DropdownExportGral'
import {LeadsRegistersExport} from '../../../../../utils/components/export-dropdowns/leads/LeadsRegistersExport'
import {useIntl} from 'react-intl'

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>
  }
  interface FilterMeta {
    itemRank: RankingInfo
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)

  // Store the itemRank info
  addMeta({
    itemRank,
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

type Props = {
  leadsList: Lead[]
  title: string
}

export const LeadsRegistersTable: FC<Props> = ({leadsList, title}) => {
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  console.log('renderizamos componente tabla')

  const renderTooltip = (props) => <Tooltip {...props}>Copiar id del registro</Tooltip>

  const [dataToExport, setDataToExport] = useState<Lead[]>(leadsList)
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = useState<string>('')

  //Funcion para navegar a la apgina de detalle de lead pasando el objeto lead
  const navigate = useNavigate()
  const toLeadDetail = (lead) => {
    navigate(`/lead-detail?leadId=${lead}`)
  }

  const columns = useMemo<ColumnDef<Lead, any>[]>(
    () => [
      {
        header: 'Registro de leads',
        footer: (props) => props.column.id,
        columns: [
          {
            accessorKey: 'lead_id',
            cell: (info) => (
              <button
                className='btn btn-light-primary'
                onClick={() => {
                  toLeadDetail(info.row.original.lead_id)
                }}
              >
                {info.getValue()}
              </button>
            ),
            header: () => <span>Id</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'lead_date',
            cell: (info) => info.getValue(),
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.DATE'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'pro_name',
            cell: (info) => (
              <>
                <p className='fw-bold'>{info.row.original.pro_name}</p>
                <p>{info.row.original.cam_name}</p>
              </>
            ),
            filterValue: (rows, id, filterValue) => {
              return rows.filter((row) => row.original.pro_name.includes(filterValue))
            },
            header: () => (
              <span>
                `${intl.formatMessage({id: 'GLOBAL.PRODUCT'})} | $
                {intl.formatMessage({id: 'GLOBAL.CAMPAIGN'})}`
              </span>
            ),
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'lead_data.name',
            cell: (info) => (
              <>
                <p className='fw-bold'>{info.row.original.lead_data.name}</p>
                <p>{info.row.original.lead_data.lead_mail}</p>
                <p>{info.row.original.lead_data.lead_phone}</p>
              </>
            ),
            filterValue: (rows, id, filterValue) => {
              return rows.filter((row) => row.original.lead_data.name.includes(filterValue))
            },
            header: () => <span>{intl.formatMessage({id: 'REPORTS.GRADES.LEAD_DATA'})}</span>,
            footer: (props) => props.column.id,
          },
        ],
      },
    ],
    []
  )

  const table = useReactTable({
    data: leadsList,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  })

  useEffect(() => {
    if (table.getState().columnFilters[1]?.id === 'name') {
      if (table.getState().sorting[0]?.id !== 'name' || table.getState().sorting[0]?.desc) {
        table.setSorting([{id: 'name', desc: false}])
      }
    }
  }, [table.getState().columnFilters[1]?.id])

  const handleSetData = () => {
    const dataToExport: Lead[] = table.getRowModel().flatRows.map((row) => row.original)
    setDataToExport(dataToExport)
  }

  return (
    <>
      <div className='card card-custom'>
        <div className='p-2'>
          <div className='card-header pt-6 pb-6'>
            <div className='d-flex'>
              <DebouncedInput
                value={globalFilter ?? ''}
                onChange={(value) => setGlobalFilter(String(value))}
                className='form-control form-control-solid w-250px me-3'
                placeholder={intl.formatMessage({id: 'GLOBAL.TABLE_SEARCH_RECORD'})}
              />

              <LeadsRegistersExport
                dataToExport={dataToExport}
                title={title}
                handleSetData={handleSetData}
                disabled={false}
                size='btn-default'
              />
            </div>
          </div>
          <div className='h-2' />
          <div className='card-body table-responsive'>
            <table className='table table-row-bordered gs-3 gy-4 gx-12'>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'
                  >
                    {headerGroup.headers.map((header) => {
                      return (
                        <th key={header.id} colSpan={header.colSpan}>
                          {header.isPlaceholder ? null : (
                            <>
                              <div
                                {...{
                                  className: header.column.getCanSort()
                                    ? 'cursor-pointer select-none'
                                    : '',
                                  onClick: header.column.getToggleSortingHandler(),
                                }}
                              >
                                {flexRender(header.column.columnDef.header, header.getContext())}
                                {{
                                  asc: ' 🔼',
                                  desc: ' 🔽',
                                }[header.column.getIsSorted() as string] ?? null}
                              </div>
                              {header.column.getCanFilter() ? (
                                <div>
                                  <Filter column={header.column} table={table} />
                                </div>
                              ) : null}
                            </>
                          )}
                        </th>
                      )
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td key={cell.id}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>

          <div className='h-2' />

          <div className='card-footer'>
            <div className='d-flex justify-content-between gap-2'>
              <div id='SwitchPages'>
                <button
                  className='btn btn-primary border rounded p-1'
                  onClick={() => table.setPageIndex(0)}
                  disabled={!table.getCanPreviousPage()}
                >
                  {'<<'}
                </button>
                <button
                  className='btn btn-primary border rounded p-1'
                  onClick={() => table.previousPage()}
                  disabled={!table.getCanPreviousPage()}
                >
                  {'<'}
                </button>
                <button
                  className='btn btn-primary border rounded p-1'
                  onClick={() => table.nextPage()}
                  disabled={!table.getCanNextPage()}
                >
                  {'>'}
                </button>
                <button
                  className='btn btn-primary border rounded p-1'
                  onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                  disabled={!table.getCanNextPage()}
                >
                  {'>>'}
                </button>
              </div>

              <div id='PagesFinder' className='text-center'>
                {' '}
                <span className='flex items-center gap-1'>
                  {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_PAGE'})}{' '}
                  <strong>
                    {table.getState().pagination.pageIndex + 1}{' '}
                    {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_PAGE_OF'})} {table.getPageCount()}
                  </strong>{' '}
                  | {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_GO_TO_PAGE'})}:
                  <input
                    type='number'
                    defaultValue={table.getState().pagination.pageIndex + 1}
                    onChange={(e) => {
                      const page = e.target.value ? Number(e.target.value) - 1 : 0
                      table.setPageIndex(page)
                    }}
                    className='form-control form-control-solid w-16'
                  />
                </span>
              </div>

              <div id='showFiles' className='text-center'>
                <select
                  className='form-select form-select-solid'
                  value={table.getState().pagination.pageSize}
                  onChange={(e) => {
                    table.setPageSize(Number(e.target.value))
                  }}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_SHOW_PART_ONE'})} {pageSize}{' '}
                      {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_SHOW_PART_TWO'})}
                    </option>
                  ))}
                </select>
                <p>
                  {table.getPrePaginationRowModel().rows.length}{' '}
                  {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_TOTAL_RECORDS'})}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function Filter({column, table}: {column: Column<any, unknown>; table: Table<any>}) {
  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id)
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()
  const columnFilterValue = column.getFilterValue()

  const sortedUniqueValues = useMemo(
    () =>
      typeof firstValue === 'number'
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  )

  return typeof firstValue === 'number' ? (
    <div>
      <div className='flex space-x-2'>
        <DebouncedInput
          type='number'
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[0] ?? ''}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [value, old?.[1]])}
          placeholder={`Min ${
            column.getFacetedMinMaxValues()?.[0] ? `(${column.getFacetedMinMaxValues()?.[0]})` : ''
          }`}
          className='w-24 border shadow rounded'
        />
        <DebouncedInput
          type='number'
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[1] ?? ''}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [old?.[0], value])}
          placeholder={`Max ${
            column.getFacetedMinMaxValues()?.[1] ? `(${column.getFacetedMinMaxValues()?.[1]})` : ''
          }`}
          className='w-24 border shadow rounded'
        />
      </div>
      <div className='h-1' />
    </div>
  ) : (
    <>
      <datalist id={column.id + 'list'}>
        {sortedUniqueValues.slice(0, 5000).map((value: any) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <DebouncedInput
        type='text'
        value={(columnFilterValue ?? '') as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`${intl.formatMessage({id: 'GLOBAL.TABLE_SEARCH_THREE_DOTS'})} (${column.getFacetedUniqueValues().size})`}
        className='w-36 border shadow rounded'
        list={column.id + 'list'}
      />
      <div className='h-1' />
    </>
  )
}

// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return <input {...props} value={value} onChange={(e) => setValue(e.target.value)} />
}
