//Importar hooks de react
import React, {useEffect, useState} from 'react'
//Importacion de animación de carga
import {LoadingAnimation} from '../../../../../../utils/components/loading/LoadingAnimation'
//Importacion de Tabla de interaccion por llamada
import {InteractionReportCallTable} from '../../tables/InteractionReportCallTable'
import {useFilterProductData} from '../hooks/useFilterProductDate'
import InteractionCallReportChart from '../../charts/InteractionCallReportChart'
import {ReportApiCall, ReportUserDropdown} from '../../interfaces/interactionsReportsTypes'
import { useIntl } from 'react-intl'

export const InteractionCallReport: React.FC = () => {
  //Constante para anejo de errores
  const [errorMessage, setErrorMessage] = useState<string>('')

  //Constante para el manejo del estado que almacena los datos de la tabla

  const {
    printFilterDataProduct,
    startDate,
    endDate,
    selectedIdProduct,
    infoMessageLoading,
    selectedNameProduct,
    userToken,
    productsList,
  } = useFilterProductData()
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()
  //Constante para el manejo de estado de los datos para la gráfica
  const [dataInteractionCall, setDataInteractionCall] = useState<Array<object>>([])
  //Constante para el manejo de estado de los datos para la gráfica
  const [rowsInTable, setRowsInTable] = useState<ReportApiCall[]>([])

  //Constante para manejar el estado del creador de la llamada
  const [callCreator, setCallCreator] = useState<string>(intl.formatMessage({id: 'REPORTS.INTERACTIONS.AUTOMATIC'}))

  //Constantes para el manejo del estado de creado por
  const [labelCreadoPor, setLabelCreadoPor] = useState<ReportUserDropdown>({
    id: '1',
    texto: intl.formatMessage({id: 'REPORTS.INTERACTIONS.AUTOMATIC_CALL'}), 
    value: intl.formatMessage({id: 'REPORTS.INTERACTIONS.AUTOMATIC'}),
    descripcion: intl.formatMessage({id: 'REPORTS.INTERACTIONS.AUTOMATIC_CALL_DESCRIPTION'}), 
    color: '#1BC5BD',
  })

  //Constante para el manejo de estado de la animacion de carga
  const [isLoading, setIsLoading] = useState<boolean>(false)

  //Animacion de cargando antes de mostrar la tabla y gráfica
  useEffect(() => {
    setIsLoading(true)
    setErrorMessage('')
    setTimeout(() => {
      setIsLoading(false)
    }, 4000)
  }, [selectedIdProduct, productsList, startDate, endDate, labelCreadoPor, callCreator])

  const handleChangeCallCreator = (e: React.ChangeEvent<HTMLSelectElement>) => {
    // Obtener el texto de la opción seleccionada directamente desde el evento
    const selectedOptionText = e.target.options[e.target.selectedIndex].text
    setCallCreator(selectedOptionText)
  }

  return (
    <>
      {printFilterDataProduct()}
      <div className='row gx-2 mb-6'>
        <div className='row justify-content-start'>
          <div className='col-auto'>
            <div className='input-group mb-3'>
              <label className='input-group-text'>{intl.formatMessage({id: 'REPORTS.INTERACTIONS.CREATED_BY'})}</label>
              <select
                className='form-select'
                id='inputGroupSelect01'
                defaultValue={'1'}
                onChange={handleChangeCallCreator}
              >
                <option value='1'>{intl.formatMessage({id: 'REPORTS.INTERACTIONS.AUTOMATIC'})}</option>
                <option value='2'>{intl.formatMessage({id: 'REPORTS.INTERACTIONS.PROSPECT'})}</option>
                <option value='3'>{intl.formatMessage({id: 'REPORTS.INTERACTIONS.ADVISOR'})}</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className='row gx-2 mb-6'>
        <div className='col-xl-12'>
          <div className='card card-custom px-8 py-10 h-100'>
            <div className='row-justify-content-center mb-2'>
              <div className='col-md-12'>
                {!selectedIdProduct && (
                  <div
                    className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                    role='alert'
                  >
                    <h5 className='mb-1 text-center'>{intl.formatMessage({id: 'REPORTS.INTERACTIONS.SELECT_PRODUCT'})}</h5>
                  </div>
                )}
                {/* {selectedIdProduct && (
                  <>
                  <div className='d-flex  justify-content-center'>
                  <h5 className='card-label me-4 d-flex flex-wrap flex-stack '>
                  <span className='text-dark text-sm font-weight-bolder mt-3'>
                  Interacciones por llamada:
                        </span>
                        &nbsp;&nbsp;
                        <span className='text-muted mt-3'> {selectedNameProduct}</span>
                      </h5>
                    </div>
                    <div className='d-flex mb-4 justify-content-center'>
                      <span className='card-label me-4 d-flex flex-wrap flex-stack'>
                        <i className='bi bi-dot fs-1' style={{color: labelCreadoPor.color}}></i>
                        {labelCreadoPor.texto}
                      </span>
                    </div>
                  </>
                )} */}
                {selectedIdProduct && (
                  <>
                    <div className='d-flex  justify-content-center'>
                      <h5 className='card-label me-4 d-flex flex-wrap flex-stack '>
                        <span className='text-dark text-sm font-weight-bolder mt-3'>
                          {labelCreadoPor.texto}:
                        </span>
                        &nbsp;&nbsp;
                        <span className='text-muted mt-3'>
                          <em>{labelCreadoPor.descripcion}</em>
                        </span>
                      </h5>
                    </div>
                    <div className='d-flex mb-4 justify-content-center'>
                      <span className='card-label me-4 d-flex flex-wrap flex-stack'>
                        {/* <i className='bi bi-dot fs-1' style={{color: labelCreadoPor.color}}></i> */}
                        {selectedNameProduct}
                      </span>
                    </div>
                  </>
                )}
                {!isLoading && errorMessage && (
                  <div
                    className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                    role='alert'
                  >
                    <h5 className='mb-1 text-center'>{errorMessage}</h5>
                  </div>
                )}
                {isLoading && selectedIdProduct && (
                  <div className='py-6'>
                    <LoadingAnimation alignment='center' label={intl.formatMessage({id: 'REPORTS.INTERACTIONS.GENERATING_GRAPH'})} size='lg' />
                  </div>
                )}
                {!errorMessage && !isLoading && productsList.length > 0 && selectedIdProduct && (
                  <InteractionCallReportChart
                    rowsInTable={rowsInTable}
                    arrInteracciones={dataInteractionCall}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Begin Contenido tablas al seleccionar un producto */}
      <div className='row gx-2 mb-6'>
        <div className='col-xl-12'>
          <div className='card card-custom px-8 py-10 h-100'>
            <div className='row justify-content-center mb-2'>
              <div className='col-md-12'>
                {!selectedIdProduct && (
                  <>
                    <div
                      className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                      role='alert'
                    >
                      <h5 className='mb-1 text-center'>{intl.formatMessage({id: 'REPORTS.INTERACTIONS.SELECT_PRODUCT'})}</h5>
                    </div>
                  </>
                )}

                {isLoading && selectedIdProduct && (
                  <div className='py-6'>
                    <LoadingAnimation alignment='center' label={intl.formatMessage({id: 'REPORTS.INTERACTIONS.GENERATING_TABLE'})} size='lg' />
                  </div>
                )}

                {!errorMessage && !isLoading && productsList.length > 0 && selectedIdProduct && (
                  <div className='tab-content' id='myTabContent'>
                    <div
                      className='tab-pane py-5 fade show active'
                      id='kt_tab_pane_4'
                      role='tabpanel'
                    >
                      <InteractionReportCallTable
                        userToken={userToken}
                        productId={selectedIdProduct}
                        startDate={startDate}
                        endDate={endDate}
                        category={callCreator}
                        infoMessageLoading={(data) => setErrorMessage(data)}
                        rowsInTable={(data) => setRowsInTable(data)}
                        arrDataInteractionCall={(data) => setDataInteractionCall(data)}
                      />
                    </div>
                  </div>
                )}

                {!isLoading && errorMessage && (
                  <div
                    className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                    role='alert'
                  >
                    <h5 className='mb-1 text-center'>{errorMessage}</h5>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End */}
    </>
  )
}
