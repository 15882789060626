import {FC, useState} from 'react'
import {KTIcon} from '../../../../../../_metronic/helpers'
import {OverlayTrigger} from 'react-bootstrap'
import {Tooltip} from 'react-bootstrap'
import {GradeReport} from '../interfaces/gradesReportTypes'
import {Modal} from 'react-bootstrap'
import {GradesReportCountsTable} from '../tables/GradesReportCountsTable'
import {useIntl} from 'react-intl'

type Props = {
  gradesReportItemByProduct: GradeReport
  selectedIdProduct: any
  selectedIdCampaign: any
  startDate: any
  endDate: any
}

const LeadCountsItem: FC<Props> = ({
  gradesReportItemByProduct,
  selectedIdProduct,
  selectedIdCampaign,
  startDate,
  endDate,
}) => {
  console.log({gradesReportItemByProduct})
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  const [isHovered, setIsHovered] = useState<boolean>(false)

  const [cardTitleSelected, setCardTitleSelected] = useState<string>('')
  const [cardSelected, setCardSelected] = useState<string>('')

  const [showModal, setShowModal] = useState<boolean>(false)

  const openModal = (title: string) => {
    setShowModal(true)
    setCardTitleSelected(title)
  }

  const closeModal = (title: string) => {
    setShowModal(false)
    setCardTitleSelected(title)
  }

  const handleMouseEnter = (card: string) => {
    console.log('entra efecto hover')
    setIsHovered(true)
    setCardSelected(card)
  }

  const handleMouseLeave = (card: string) => {
    console.log('sale efecto hover')
    setIsHovered(false)
    setCardSelected(card)
  }

  return (
    <>
      <div className='d-flex flex-wrap justify-content-center'>
        {/* Begin: Total de leads recibidos */}
        {/* <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Ver leads sin calificar</Tooltip>}
                > */}
        <div
          // onMouseEnter={() => {
          //     handleMouseEnter(1)
          // }}

          // onMouseLeave={() => {
          //     handleMouseLeave(0)
          // }}

          // onClick={() => {
          //     openModal('Leads sin calificar')
          // }}

          // className="border border-gray-300 border-solid rounded min-w-115px py-4 px-4 me-8 mb-3 cursor-pointer"
          className={`border border-gray-300 border-solid rounded min-w-115px py-4 px-4 me-8 mb-3`}
        >
          <div className='d-flex align-items-center'>
            <KTIcon iconName='toggle-on' className='fs-3 text-success me-2' />
            <div className='fs-2 fw-bolder me-2'>{gradesReportItemByProduct.totalLeads}</div>
            <div className='fw-bold fs-6 text-gray-400'>
              {intl.formatMessage({id: 'REPORT.GRADES.LEADS_RECEIVED'})}
            </div>
          </div>
        </div>
        {/* </OverlayTrigger> */}
        {/* End: Total de leads recibidos */}

        {/* Begin: Total de leads calificados */}
        <OverlayTrigger
          placement='top'
          overlay={
            <Tooltip>{intl.formatMessage({id: 'REPORT.GRADES.VIEW_QUALIFIED_LEADS'})}</Tooltip>
          }
        >
          <div
            onMouseEnter={() => {
              handleMouseEnter('noDuplicate')
            }}
            onMouseLeave={() => {
              handleMouseLeave('')
            }}
            onClick={() => {
              openModal(intl.formatMessage({id: 'REPORT.GRADES.LEADS_QUALIFIED'}))
            }}
            className={`border border-gray-300 border-solid rounded min-w-115px py-4 px-4 me-8 mb-3 ${isHovered && cardSelected === 'noDuplicate' ? 'bg-gray-200' : ''} cursor-pointer`}
          >
            <div className='d-flex align-items-center'>
              <KTIcon iconName='toggle-on' className='fs-3 text-success me-2' />
              <div className='fs-2 fw-bolder me-2'>
                {gradesReportItemByProduct.totalLeadsNoDuplicate}
              </div>
              <div className='fw-bold fs-6 text-gray-400'>
                {intl.formatMessage({id: 'REPORT.GRADES.LEADS_QUALIFIED'})}
              </div>
            </div>
          </div>
        </OverlayTrigger>

        {/* End: Total de leads calificados */}

        {/* Begin: Total de calificaciones */}
        <OverlayTrigger
          placement='top'
          overlay={
            <Tooltip>{intl.formatMessage({id: 'REPORT.GRADES.VIEW_GRADES_RECORDS'})}</Tooltip>
          }
        >
          <div
            onMouseEnter={() => {
              handleMouseEnter('gradesCount')
            }}
            onMouseLeave={() => {
              handleMouseLeave('')
            }}
            onClick={() => {
              openModal(intl.formatMessage({id: 'REPORT.GRADES.GRADES_MADE'}))
            }}
            className={`border border-gray-300 border-solid rounded min-w-115px py-4 px-4 me-8 mb-3 ${isHovered && cardSelected === 'gradesCount' ? 'bg-gray-200' : ''} cursor-pointer`}
          >
            <div className='d-flex align-items-center'>
              <KTIcon iconName='toggle-on' className='fs-3 text-success me-2' />
              <div className='fs-2 fw-bolder me-2'>
                {gradesReportItemByProduct.totalGradesCount}
              </div>
              <div className='fw-bold fs-6 text-gray-400'>
                {intl.formatMessage({id: 'REPORT.GRADES.GRADES_MADE'})}
              </div>
            </div>
          </div>
        </OverlayTrigger>

        {/* End: Total de calificaciones */}

        {/* Begin: Total de leads calificados más de una vez */}
        <OverlayTrigger
          placement='top'
          overlay={
            <Tooltip>
              {intl.formatMessage({id: 'REPORT.GRADES.VIEW_QUALIFIED_MULTIPLE_LEADS'})}
            </Tooltip>
          }
        >
          <div
            onMouseEnter={() => {
              handleMouseEnter('multipleGrades')
            }}
            onMouseLeave={() => {
              handleMouseLeave('')
            }}
            onClick={() => {
              openModal(intl.formatMessage({id: 'REPORT.GRADES.LEADS_QUALIFIED_MULTIPLE'}))
            }}
            className={`border border-gray-300 border-solid rounded min-w-115px py-4 px-4 me-8 mb-3 ${isHovered && cardSelected === 'multipleGrades' ? 'bg-gray-200' : ''} cursor-pointer`}
          >
            <div className='d-flex align-items-center'>
              <KTIcon iconName='toggle-on' className='fs-3 text-success me-2' />
              <div className='fs-2 fw-bolder me-2'>
                {gradesReportItemByProduct.totalMultipleGradesLeads}
              </div>
              <div className='fw-bold fs-6 text-gray-400'>
                {intl.formatMessage({id: 'REPORT.GRADES.LEADS_QUALIFIED_MULTIPLE'})}
              </div>
            </div>
          </div>
        </OverlayTrigger>
        {/* End: Total de leads calificados más de una vez*/}

        {/* Begin: Total de leads sin calificar */}
        <OverlayTrigger
          placement='top'
          overlay={
            <Tooltip>{intl.formatMessage({id: 'REPORT.GRADES.VIEW_UNRATED_LEADS'})}</Tooltip>
          }
        >
          <div
            onMouseEnter={() => {
              handleMouseEnter('unratedGrades')
            }}
            onMouseLeave={() => {
              handleMouseLeave('')
            }}
            onClick={() => {
              openModal(intl.formatMessage({id: 'REPORT.GRADES.LEADS_UNRATED'}))
            }}
            className={`border border-gray-300 border-solid rounded min-w-115px py-4 px-4 me-8 mb-3 ${isHovered && cardSelected === 'unratedGrades' ? 'bg-gray-200' : ''} cursor-pointer`}
          >
            <div className='d-flex align-items-center'>
              <KTIcon iconName='toggle-on' className='fs-3 text-success me-2' />
              <div className='fs-2 fw-bolder me-2'>
                {gradesReportItemByProduct.totalUnratedLeads}
              </div>
              <div className='fw-bold fs-6 text-gray-400'>
                {intl.formatMessage({id: 'REPORT.GRADES.LEADS_UNRATED'})}
              </div>
            </div>
          </div>
        </OverlayTrigger>
        {/* End: Total de leads sin calificar */}
      </div>

      <Modal
        show={showModal}
        onHide={() => {
          closeModal('')
        }}
        className='modal-xl'
        scrollable
      >
        <Modal.Header
          closeButton
          onClick={() => {
            closeModal('')
          }}
        >
          <Modal.Title>
            <h1>{cardTitleSelected}</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            {/* <h4>Renderiza tabla de {cardTitleSelected}</h4> */}
            <GradesReportCountsTable
              cardSelected={cardSelected}
              gradesReportItemByProduct={gradesReportItemByProduct}
              selectedIdProduct={selectedIdProduct}
              selectedIdCampaign={selectedIdCampaign}
              startDate={startDate}
              endDate={endDate}
            />
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            type='reset'
            className='btn btn-sm btn-secondary'
            onClick={() => {
              closeModal('')
            }}
          >
            Cerrar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export {LeadCountsItem}
