import {FC, useEffect, useState} from 'react'
import {Channel} from '../../interfaces/yearlyReportTypes'
import {YearlyReportChannelTable} from '../../tables/YearlyReportChannelTable'
import {YearlyReportGlobalChart} from '../../charts/YearlyReportGlobalChart'
import {useIntl} from 'react-intl'

type Props = {
  channelsList: Channel[]
}

export const YearlyChannelsReport: FC<Props> = ({channelsList}) => {
  // console.log({channelsList})
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()
  // Manejador del Id del canal
  const [selectedIdChannel, setSelectedIdChannel] = useState('')
  // Manejador del nombre del canal
  const [channelName, setChannelName] = useState('')
  //Manejador del select de los años
  const [selectedYear, setSelectedYear] = useState('2024')
  //Manejador del check de los duplicados
  const [duplicates, setSDuplicates] = useState(true)

  // Función para cambiar el state del canal
  const handleChannelChange = async (channelId) => {
    // console.log('channelId:', channelId)

    if (channelId === '0') {
      // console.log('channelId es 0')
      setSelectedIdChannel(channelId)
      setChannelName('Todos los canales')
      return // Salir de la función después de manejar el caso '0'
    }

    const selectedChannel = channelsList.find((channel) => channel.channel_id === channelId)
    // console.log('selectedChannel:', selectedChannel?.name)

    if (selectedChannel) {
      setSelectedIdChannel(channelId)
      setChannelName(selectedChannel.name) // Guardar el nombre del canal seleccionado
    } else {
      // console.log('Selected channel es vacío o undefined')
      setSelectedIdChannel('') // Limpiar el canal seleccionado si no se encuentra
      setChannelName('') // Limpiar el nombre del canal
    }
  }

  // Función para cambiar el state del año
  const handleYearChange = async (yearValue) => {
    setSelectedYear(yearValue)
  }

  // Función para cambiar el state de los duplicados
  const handleDuplicatesChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setSDuplicates(event.target.checked)
  }

  return (
    <>
      <div className='container-fluid'>
        <div className='row justify-content-left mb-4'>
          <div className='col-md-12'>
            <div className='d-flex flex-wrap flex-stack mb-6'>
              <h3 className='my-2 d-flex flex-wrap flex-stack'>
                {channelName && selectedYear ? (
                  <>
                    <div className='mb-2'>
                      {intl.formatMessage({id: 'REPORTS.INTERACTIONS.FILTERED_BY'})}:
                      <span className='ms-2 fs-3 text-gray-500 fw-bold'>{channelName}</span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='mb-2'>
                      {intl.formatMessage({id: 'REPORTS.YEARLY.SELECT_CHANNEL_YEAR'})}
                    </div>
                  </>
                )}
              </h3>
              <div className='d-flex flex-wrap flex-stack align-items-center my-2'>
                <div className='w-280px me-4'>
                  <select
                    name='channel'
                    data-control='selectchannel'
                    data-hidden-search='true'
                    className='form-select form-select-solid form-select-lg'
                    onChange={(e) => handleChannelChange(e.target.value)}
                    value={selectedIdChannel}
                  >
                    <option value=''>
                      {' '}
                      {intl.formatMessage({id: 'REPORTS.YEARLY.SELECT_CHANNEL'})}{' '}
                    </option>
                    <option value='0'>
                      {' '}
                      {intl.formatMessage({id: 'REPORTS.YEARLY.ALL_CHANNELS'})}{' '}
                    </option>
                    {channelsList.map((channel: Channel) => (
                      <option key={channel.channel_id} value={channel.channel_id}>
                        {channel.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className='w-100px me-4'>
                  <select
                    name='year'
                    data-control='selectyear'
                    data-hidden-search='true'
                    className='form-select form-select-solid form-select-lg'
                    onChange={(e) => handleYearChange(e.target.value)}
                    value={selectedYear}
                  >
                    <option value='2024'>2024</option>
                    <option value='2023'>2023</option>
                  </select>
                </div>

                <div
                  className='form-check form-switch form-check-custom form-check-solid mb-6'
                  key='poo'
                >
                  <label className='form-check-label fs-4'>
                    {intl.formatMessage({id: 'REPORTS.YEARLY.DUPLICATES'})}
                  </label>
                  &nbsp;&nbsp;&nbsp;
                  <input
                    className='form-check-input'
                    type='checkbox'
                    checked={duplicates}
                    onChange={handleDuplicatesChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row gx-2 mb-6'>
          <YearlyReportChannelTable
            selectedIdChannel={selectedIdChannel}
            selectedYear={selectedYear}
            duplicates={duplicates}
          />
        </div>
      </div>
    </>
  )
}
