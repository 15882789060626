// Importamos constantes para realizar la peticion a la API de campañas
import { DragonMainApi, EPCampaignGeneralEdit, EPCampaignConnectEdit, EPCampaignWhatsappNotifyEdit, apiDragon } from "../../../utils/constants/_Constants";

// ----------------------------------------------------------------------------------------

// Importamos funcion para obtener codigos de respuesta del servidor
import { apiPetitions } from "../../../../_metronic/helpers/apis";

// Funcion para realizar la peticion a la API de edicion de campaña en la tab general
export async function campaignGeneralInfoEdit(
    status: boolean, 
    quality: boolean, 
    cam_role: string, 
    call: string,
    duplicates: number,
    manual: boolean,
    dupdays: number,
    iscore: number,
    campaign_id: string, 
    userToken: string
) {

    try {

        const data = await apiPetitions(apiDragon, `${DragonMainApi + EPCampaignGeneralEdit + campaign_id}`, {
            status,
            quality,
            // cam_role,
            call,
            duplicates,
            manual,
            dupdays,
            iscore          

        }, {

            'Content-Type': 'application/json',
            'x-token': userToken

        },

            'put');

        const jsonDataCampaignGralInfoEdit = data;

        return jsonDataCampaignGralInfoEdit;

    } catch (error) {
        throw error;
    }

}

// Funcion para realizar la peticion a la API de edicion de campaña en la tab connectivity
export async function campaignConnectInfoEdit(
    crm: string,
    channel_crm_id: string,
    crm_id: string,
    mail_template: string,
    set_option: string,
    userToken: string,
    campaign_id: string,
    // mail_template: string, 
    // wab_bot: string, 
    // crm_id: string, 
    // grade_options: any, 
    // userToken: string, 
    // campaign_id: string
) {

    try {

        const data = await apiPetitions(apiDragon, `${DragonMainApi + EPCampaignConnectEdit + campaign_id}`, {
            crm,
            channel_crm_id,
            crm_id,
            mail_template,
            set_option,
            // mail_template,
            // wab_bot,
            // crm_id,
            // grade_options,

        }, {

            'Content-Type': 'application/json',
            'x-token': userToken

        },

            'put');

        const jsonDataCampaignConnectEdit = data;

        return jsonDataCampaignConnectEdit;

    } catch (error) {
        throw error;
    }

}

// Funcion para realizar la peticion a la API de edicion de campaña en la tab notify
export async function campaignNotifyInfoEdit(
    wab_bot: string,
    wab_message: string,
    mail_lead: boolean,
    whats_lead: boolean,
    userToken: string,
    cam_idCampaign: string
) {

    try {

        const data = await apiPetitions(apiDragon, `${DragonMainApi + EPCampaignWhatsappNotifyEdit + cam_idCampaign}`, {
            wab_bot,
            wab_message,
            mail_lead,
            whats_lead,

        }, {

            'Content-Type': 'application/json',
            'x-token': userToken

        },

            'put');

        const jsonDataCampaignNotifyEdit = data;

        return jsonDataCampaignNotifyEdit;

    } catch (error) {
        throw (error)
    }

}