import React, { useContext, useEffect, useState } from 'react'
import { WabaBot, WabaTemplate } from '../../../../utils/interfaces/_InterfacesTypes'
import { UserContext } from '../../../../app-context/user-context/UserContext'
import { LoadingAnimation } from '../../../../utils/components/loading/LoadingAnimation'
import { wabaTemplatesListByProduct } from '../../../../utils/services/_WabaTemplatesApi'
import { useIntl } from 'react-intl'
import { FormDataEditWhatsappNotify } from '../../interfaces/campaignsTypes'
import { campaignNotifyInfoEdit } from '../../services/_CampaignsEditApi'
import { sessionExpired } from '../../../../utils/functions/_Functions'
import { AlertInfo } from '../../../../utils/interfaces/_InterfacesTypes'

interface TwilioContentResponse {
  gotit: boolean;
  body?: string;
  content?: string;
}

export const WhatsappNotifyTabForm = ({ 
  productId, 
  twlCredentials, 
  formDataEditWhatsappNotify, 
  setFormDataEditWhatsappNotify,
  cam_idCampaign,
  closeModalEdit,
  getCampaignsRegisters,
}) => {
  const intl = useIntl()

  //Algunas variables desde el usercontext
  const { user, token, updateToken } = useContext(UserContext)
  const clientId = user.cli_id
  const userToken = token

  const [alertInfo, setAlertInfo] = useState<AlertInfo>({
    text: '',
    variant: 'primary',
  })

  const initialWabaTemplatesList: WabaTemplate = {
    templates: [
      {
        id_content: '',
        friendly_name: '',
      },
    ],
  }

  const initialWabaBotsList: WabaBot = {
    client_id: '',
    wab_bots: [
      {
        wab_bot_id: '',
        wab_bot_name: '',
        wab_bot_description: '',
      },
    ],
  }

  const [wabaTemplatesList, setWabaTemplatesList] = useState<WabaTemplate>(initialWabaTemplatesList)

  const [isLoading, setIsLoading] = useState<boolean>(true)

  const [valueWAB, setValueWAB] = useState<string>('HX2df10bb33831beaf776faa7dcdc8c4c5')
  const [wabaBotsList, setWabaBotsList] = useState<WabaBot>(initialWabaBotsList)

  const [isWabaBodyVisible, setIsWabaBodyVisible] = useState<boolean>(true)
  const [mainMessage, setMainMessage] = useState<string>("")
  // const [qualityMessage, setQualityMessage] = useState<string>("¡Hola *{{1}}* !En días anteriores solicitaste información acerca de *{{2}}* por lo que nos gustaría saber si se puso en contacto contigo el Asesor asignado y te respondió todas tus dudas de manera correcta:")

  const getWabaBots = () => {
    const wabaBotsListReceived: WabaBot = {
      client_id: 'API-DFGO-06YO',
      wab_bots: [
        {
          wab_bot_id: 'WBOT-API-001',
          wab_bot_name: 'botGralDragonCEM',
          wab_bot_description: '',
        },
        {
          wab_bot_id: 'WBOT-API-002',
          wab_bot_name: 'botGeneralbaahrPrueba',
          wab_bot_description: '',
        },
      ],
    }

    setWabaBotsList(wabaBotsListReceived)
  }

  const getWabaTemplates = async (): Promise<void> => {
    try {
      const { data: jsonData, code, msg } = await wabaTemplatesListByProduct(userToken, productId)

      const wabaTemplatesReceived = jsonData.templates
      const newUserToken: string = jsonData.token

      updateToken(newUserToken)

      console.log(wabaTemplatesReceived)

      setWabaTemplatesList({
        templates: wabaTemplatesReceived,
      })
    } catch (error) {
      console.error('Error obteniendo los waba templates: ', error)
    }
  }

  async function getBodyWabaTemplate(idWaba: React.ChangeEvent<HTMLSelectElement> | string | undefined): Promise<TwilioContentResponse> {
    setIsWabaBodyVisible(true)
    setIsLoading(true)

    let templateSid;

    if (typeof idWaba === 'string' || typeof idWaba === 'undefined') {
      templateSid = idWaba;
    } else {
      templateSid = idWaba.target.value;
    }

    // const templateSid = idWaba.target.value; // El valor seleccionado del dropdown

    const accountSid = twlCredentials.twl_call_accsid || '';
    const authToken = twlCredentials.twl_call_accat || '';

    console.log("Account SID:", accountSid);
    console.log("Auth Token:", authToken);
    console.log("Selected Template SID from Dropdown:", templateSid);

    if (!templateSid) {
      console.error('Template SID is null or undefined');
      setMainMessage('No existe información')
      return {
        gotit: false,
        content: 'No template selected'
      };
    }

    try {
      const response = await fetch(`https://content.twilio.com/v1/Content/${templateSid}`, {
        method: 'GET',
        headers: {
          'Authorization': `Basic ${btoa(`${accountSid}:${authToken}`)}`,
          'Content-Type': 'application/json'
        }
      });

      console.log("Response status:", response.status);
      console.log("Response headers:", response.headers);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const content = await response.json();
      console.log("Fetched content:", content);

      const bodyContent = content.types && content.types["twilio/quick-reply"]
        ? content.types["twilio/quick-reply"].body
        : 'No existe mensaje';

      console.log("Body content:", bodyContent);

      setMainMessage(bodyContent)
      // setQualityMessage('No se encontro mensaje quality')

      // setIsWabaBodyVisible(false)
      setIsLoading(false)
      return {
        gotit: true,
        body: bodyContent
      };

    } catch (error) {
      console.error('Error fetching content:', error);
      return {
        gotit: false,
        content: 'inexistent'
      };
    }
  }

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target 

    setFormDataEditWhatsappNotify((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target

    const inputValue = type === 'checkbox' ? checked : value

    setFormDataEditWhatsappNotify((prevState) => ({
      ...prevState,
      [name]: inputValue,
    }))
  }

  const handleFormSubmit = async () => {
    console.log('wab_bot:', formDataEditWhatsappNotify.wab_Bot)
    console.log('wab_messages:', formDataEditWhatsappNotify.wab_messages)
    console.log('mail_lead:', formDataEditWhatsappNotify.mail_lead)
    console.log('whats_lead:', formDataEditWhatsappNotify.whats_lead)

    const wab_bot: string = formDataEditWhatsappNotify.wab_bot 
    const wab_message: string = formDataEditWhatsappNotify.wab_messages
    const mail_lead: boolean = formDataEditWhatsappNotify.mail_lead 
    const whats_lead: boolean = formDataEditWhatsappNotify.whats_lead 

    try {
      const { 
        data: 
        jsonData, 
        code, 
        msg,
      } = await campaignNotifyInfoEdit(
        wab_bot,
        wab_message,
        mail_lead,
        whats_lead,
        userToken,
        cam_idCampaign
      )

      if (code === 401 && msg === 'Token no valido') {
        sessionExpired(intl.formatMessage({id: 'GLOBAL.ALERT.SESSION_EXPIRED'}))
        return
      }else{
        setAlertInfo({
          text: 
            code === 200
              ? 'Campaña editada correctamente'
              : 'No se pudo editar la campaña',
          variant: code === 200 ? 'success' : 'danger',
        })

        setTimeout(() => {
          setAlertInfo({
            text: '',
            variant: '',
          })
          closeModalEdit()
          getCampaignsRegisters()
        }, 3000)
      }
    } catch (error) {
      console.error('Error editando los datos de whatsapp de la campaña: ', error)
    }
  }

  useEffect(() => {
    getWabaTemplates()
    getBodyWabaTemplate(formDataEditWhatsappNotify.wab_messages)
    setTimeout(() => {
      setIsLoading(false)
    }, 3000)
  }, [])

  return (
    <>
      <label className='mb-6'>
        <strong>WhatsApp</strong>
      </label>

      <div className='d-flex mb-6'>
        <label className='col-5'>
          {intl.formatMessage({ id: 'CAMPAIGNS.WHATSAPP.CONFIG_LABEL' })}
        </label>
        <select
          className='form-select form-select-solid'
          name='wab_messages'
          value={formDataEditWhatsappNotify.wab_messages}
          id='wab_messages'
          onChange={(e) => {
            getBodyWabaTemplate(e)
            handleSelectChange(e)
          }}
        >
          {/* <option value="General DragonCEM">General DragonCEM</option>
                <option value="DragonCEM producto">DragonCEM producto</option> */}
          <option value=''>No tiene dato guardado</option>
          {wabaTemplatesList.templates.map((template, index) => (
            <option key={index} id={template.id_content} value={template.id_content}>
              {template.friendly_name}
            </option>
          ))}
        </select>
      </div>

      {isWabaBodyVisible && (
        <>
          {isLoading && (
            <LoadingAnimation
              alignment='center'
              label={intl.formatMessage({ id: 'CAMPAIGNS.WHATSAPP.LOADING_TEMPLATE' })}
              size='sm'
            />
          )}

          {!isLoading && (
            <>
              <div className='d-flex mb-6'>
                <label className='col-3'>
                  {intl.formatMessage({ id: 'CAMPAIGNS.WHATSAPP.MAIN_MESSAGE_LABEL' })}:
                </label>
                <textarea
                  id='mainmsg'
                  className='form-control form-control-solid text-muted'
                  value={mainMessage}
                  readOnly
                  rows={7}
                ></textarea>
              </div>
            </>
          )}
        </>
      )}

      <div className='d-flex mb-6'>
        <label className='col-3'>Bot: </label>
        <select
          className='form-select form-select-solid'
          name='wab_bot'
          id='wab_bot'
          value={formDataEditWhatsappNotify.wab_bot}
          onChange={(e) => {
            handleSelectChange(e)
          }}
        >
          {/* <option value="botGralPruebaDragon">botGralPruebaDragon</option>
                <option value="botGralenglish">botGralenglish</option> */}
          <option value={formDataEditWhatsappNotify.wab_bot}>{formDataEditWhatsappNotify.wab_bot}</option>
          {wabaBotsList.wab_bots.map((wabaBot, index) => (
            <option key={wabaBot.wab_bot_id} id={wabaBot.wab_bot_id} value={wabaBot.wab_bot_id}>
              {wabaBot.wab_bot_name}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label className='mb-6'>
          <strong>Notificaciones</strong>
        </label>
        <div className='d-flex mb-8'>
          <label className='mr-8'>Mail Lead: </label>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <div className='form-check form-switch'>
            <input
              className='form-check-input'
              type='checkbox'
              id='mail_lead'
              name='mail_lead'
              checked={formDataEditWhatsappNotify.mail_lead}
              onChange={handleInputChange}
            />
            <label className='form-check-label' htmlFor='mail_lead'>
              {intl.formatMessage({ id: 'GLOBAL.OFF_ON' })}
            </label>
          </div>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <label className='mr-8'>WhatsApp Lead: </label>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <div className='form-check form-switch'>
            <input
              className='form-check-input'
              type='checkbox'
              id='whats_lead'
              name='whats_lead'
              checked={formDataEditWhatsappNotify.whats_lead}
              onChange={handleInputChange}
            />
            <label className='form-check-label' htmlFor='whats_lead'>
              {intl.formatMessage({ id: 'GLOBAL.OFF_ON' })}
            </label>
          </div>
        </div>
      </div>

      {alertInfo.text && (
        <div className={`alert alert-${alertInfo.variant} d-flex align-items-center`} role='alert'>
          <div>{alertInfo.text}</div>
        </div>
      )}
      
      <button 
        type='submit' 
        className='mt-8 btn btn-primary btn-sm'
        onClick={handleFormSubmit}
      >
        {intl.formatMessage({ id: 'GLOBAL.SEND' })}
      </button>
    </>
  )
}
