import { Dropdown } from 'react-bootstrap';
import { useIntl } from 'react-intl';

export const DropdownItemExportCSV = ({ dataToExport, title }) => {
  console.log(dataToExport);
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()
  const handleToExportProductsAssignedDataCSV = () => {
    try {
      // Verificar si hay datos para exportar
      if (!dataToExport || !dataToExport.brandProducts) {
        console.error(intl.formatMessage({id: 'DROPDOWNS.NO_DATA_TO_EXPORT'}));
        return;
      }

      // Obtener el usuario y sus productos
      const { id, name, brandProducts } = dataToExport;

      // Crear una matriz para almacenar los datos del CSV
      const csvData = [
        [intl.formatMessage({id: 'DROPDOWNS.USER_ID'}),
          intl.formatMessage({id: 'DROPDOWNS.USER_NAME'}),
          intl.formatMessage({id: 'DROPDOWNS.PRODUCT_ID'}),
          intl.formatMessage({id: 'DROPDOWNS.PRODUCT_NAME'}),
          intl.formatMessage({id: 'DROPDOWNS.ASSIGNMENT_DATE'}),],
      ];

      // Recorrer los productos del usuario y agregarlos a la matriz del CSV
      brandProducts.forEach(product => {
        const productId = product.pro_id;
        const productName = product.name;
        csvData.push([id, name, productId, productName, '']);
      });

      // Convertir la matriz en formato CSV
      const csvContent = csvData.map(row => row.join(',')).join('\n');

      // Crear un enlace temporal para descargar el archivo CSV
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', intl.formatMessage({id: 'DROPDOWNS.EXPORTED_DATA_FILENAME'}, {title}) + '.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error('Error al generar el archivo CSV:', error);
    }
  };

  return (
    <Dropdown.Item>
      <span onClick={handleToExportProductsAssignedDataCSV}>
      {`${intl.formatMessage({id: 'DROPDOWNS.EXPORT_ASSIGNED_PRODUCTS'})} (.csv)`}
      </span>
    </Dropdown.Item>
  );
};
