import {useState, useContext, useEffect} from 'react'
import {KTIcon} from '../../../../../_metronic/helpers'
import {CampaignsReportLeadsChart} from './charts/CampaignsReportLeadsChart'
import {CampaignsReportIscoreChart} from './charts/CampaignsReportIscoreChart'
import 'react-dates/lib/css/_datepicker.css'
import '../../../../utils/assets/css/styles.css'
import {UserContext} from '../../../../app-context/user-context/UserContext'
import {CampaignReport, IscoreByDay, LeadByDay} from './interfaces/campaignsReportTypes'
import {campaignsListForProduct} from '../../../campaigns/services/_CampaignsListApi'
import {ProductList, CampaignList} from '../../../../utils/interfaces/_InterfacesTypes'
import {LoadingAnimation} from '../../../../utils/components/loading/LoadingAnimation'
import {formatNumber, setPageTitle} from '../../../../utils/functions/_Functions'
import {DragonCem} from '../../../../utils/constants/_Constants'
import {LeadCountsItemRangeOne} from './aux-components/LeadCountsItemRangeOne'
import {LeadCountsItemRangeTwo} from './aux-components/LeadCountsItemRangeTwo'
import moment from 'moment'
import {
  sessionExpired,
  dateFormatterISO8601Variant,
  dateFormatterTextualEnTwo,
} from '../../../../utils/functions/_Functions'
import {DateRangePickerFull} from '../../../../utils/components/date-pickers/DateRangePickerFull'
import {ProductsByUserDropdown} from '../../../../utils/components/dropdowns/ProductsByUserDropdown'
import {campaignsReportPetitionNoParams} from './services/_ReportCampaignsNoParams'
import {campaignsReportPetitionOnlyProduct} from './services/_ReportCampaignsOnlyProduct'
import {campaignsReportPetitionNoPeriods} from './services/_ReportCampaignsNoPeriods'
import {campaignsReportPetitionFulParams} from './services/_ReportCampaignsFullParams'
import {campaignsReportPetitionOnlyDates} from './services/_ReportCampaignsOnlyDates'
import {campaignsReportPetitionNoCampaign} from './services/_ReportCampaignsNoCampaign'
import { CampaignsByProductDropdown } from '../../../../utils/components/dropdowns/CampaignsByProductDropdown'
import { useIntl } from 'react-intl'

interface LeadEntry {
  date: string
  leads: number
}
const initialCampaignReportItems: CampaignReport = {
  iscore: {
    iscoreByDay: [],
    iscoreAverage: 0,
  },

  lead: {
    leadsByDay: [],
    totalLeads: 0,
  },
}

const newCampaignsCounts: CampaignReport = {
  iscore: {
    iscoreByDay: [
      {
        date: '01-april-2024',
        iscore: 34,
      },
      {
        date: '02-april-2024',
        iscore: 24,
      },
      {
        date: '03-april-2024',
        iscore: 28,
      },
    ],
    iscoreAverage: 28.66,
  },

  lead: {
    leadsByDay: [
      {
        date: '01-april-2024',
        leads: 34,
      },
      {
        date: '02-april-2024',
        leads: 58,
      },
      {
        date: '03-april-2024',
        leads: 19,
      },
    ],
    totalLeads: 111,
  },
}

const newCampaignsCounts2: CampaignReport = {
  iscore: {
    iscoreByDay: [
      {
        date: '01-September-2024',
        iscore: 4,
      },
      {
        date: '02-September-2024',
        iscore: 4,
      },
      //   {
      //     date: '03-September-2024',
      //     score: 4,
      //   },
    ],
    iscoreAverage: 4,
  },
  lead: {
    leadsByDay: [
      {
        date: '01-September-2024',
        leads: 1,
      },
      {
        date: '02-September-2024',
        leads: 3,
      },
      //   {
      //     date: '03-September-2024',
      //     leads: 4,
      //   },
    ],
    totalLeads: 4,
  },
}

const CampaignsReport = () => {
  //Algunas variables desde el usercontext
  const {user, token, updateToken} = useContext(UserContext)
  const clientId = user.cli_id
  const userToken = token
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  const initialCampaignReportItem: CampaignReport = {
    iscore: {
      iscoreByDay: [],
      iscoreAverage: 0,
    },
    lead: {
      leadsByDay: [],
      totalLeads: 0,
    },
  }

  // Valores iniciales para el rango de fechas
  const initialStartDate1: Date = new Date(new Date().setDate(new Date().getDate() - 30))
  const initialEndDate1: Date = new Date()

  // Constantes que usaremos para manejar las variables para los labels que muestran la fecha
  const [labelStartDate1, setLabelStartDate1] = useState<Date>(initialStartDate1)
  const [labelEndDate1, setLabelEndDate1] = useState<Date>(initialEndDate1)

  // Constantes que usaremos para manejar las fechas que enviaremos a la peticion
  const [startDate1, setStartDate1] = useState<string>(
    dateFormatterISO8601Variant(initialStartDate1)
  )
  const [endDate1, setEndDate1] = useState<string>(dateFormatterISO8601Variant(initialEndDate1))

  // -------------------------------------------------------------------------------------------------------------

  // Valores iniciales para el rango de fechas
  const initialStartDate2: Date = new Date(new Date().setDate(new Date().getDate() - 60))
  const initialEndDate2: Date = new Date(new Date().setDate(new Date().getDate() - 31))

  // Constantes que usaremos para manejar las variables para los labels que muestran la fecha
  const [labelStartDate2, setLabelStartDate2] = useState<Date>(initialStartDate2)
  const [labelEndDate2, setLabelEndDate2] = useState<Date>(initialEndDate2)

  // Constantes que usaremos para manejar las fechas que enviaremos a la peticion
  const [startDate2, setStartDate2] = useState<string>(
    dateFormatterISO8601Variant(initialStartDate2)
  )
  const [endDate2, setEndDate2] = useState<string>(dateFormatterISO8601Variant(initialEndDate2))

  // Manejador para la disponibilidad del dateRangePicker
  const [dataRangeState, setDataRangeState] = useState<boolean>(true)

  const [campaignReportItemROne, setCampaignReportItemROne] =
    useState<CampaignReport>(initialCampaignReportItem)
  const [campaignReportItemRTwo, setCampaignReportItemRTwo] =
    useState<CampaignReport>(initialCampaignReportItem)

  const [productsList, setProductsList] = useState<ProductList[]>([])
  const [campaignsList, setCampaignsList] = useState<CampaignList[]>([])
  const [productsDropdownState, setProductsDropdownState] = useState<boolean>(false)

  const [selectedIdProduct, setSelectedIdProduct] = useState<string>('')
  const [selectedNameProduct, setSelectedNameProduct] = useState<string>('')

  const [selectedIdCampaign, setSelectedIdCampaign] = useState<string>('0')
  const [selectedNameCampaign, setSelectedNameCampaign] = useState<string>(intl.formatMessage({id: 'REPORT.CAMPAIGN.ALL_CAMPAIGNS'}))

  //Constante para el manejo de estado de la animacion de carga
  const [isLoading, setIsLoading] = useState<boolean>(false)

  //Constante para el manejo de estado del mensaje post carga de registros
  const [infoMessageLoading, setInfoMessageLoading] = useState<string>('')
  const [infoMessageLoading2, setInfoMessageLoading2] = useState<string>('')

  //Constante para el manejo de estado de la peticion
  const [peticionNumber, setPeticionNumber] = useState<number>(0)

  //Cambiar el estado del dropdown de campañas
  const [campaignDropdownState, setCampaignDropdownState] = useState<boolean>(true)
  const [comparativeCampaignDropdownState, setComparativeCampaignDropdownState] = useState<boolean>(true)

  const getCampaignsByProduct = async (productvalue: string): Promise<void> => {
    try {
      const {data: jsonData, code, msg} = await campaignsListForProduct(userToken, productvalue)

      const campaignsListReceived = jsonData.data

      const userTokenNew: string = jsonData.token

      updateToken(userTokenNew)

      const campaignItem: CampaignList[] = campaignsListReceived.map((campaign: CampaignList) => {
        return {
          cam_id: campaign.cam_id,
          name: campaign.name,
        }
      })

      setCampaignsList(campaignItem)
    } catch (error) {
      console.log('Ocurrio un error obteniendo las campañas del producto: ', error)
    }
  }

  const checkCountsCampaignItems = () => {
    if (
      campaignReportItemROne.iscore.iscoreByDay.length === 0 &&
      campaignReportItemROne.lead.leadsByDay.length === 0
    ) {
      setInfoMessageLoading(intl.formatMessage({id: 'REPORT.CAMPAIGN.NO_DATA_FOUND'}))
    } else {
      setInfoMessageLoading('')
    }
    if (
      campaignReportItemRTwo.iscore.iscoreByDay.length === 0 &&
      campaignReportItemRTwo.lead.leadsByDay.length === 0
    )
      setInfoMessageLoading2(intl.formatMessage({id: 'REPORT.CAMPAIGN.NO_DATA_FOUND'}))
    else {
      setInfoMessageLoading2('')
    }
  }

  //Función que se dispara al haber cambios en los dropdown de producto y campañas
  const handleProductOrCampaignSelected = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setIsLoading(true)

    let productIdSelected: string
    let campaignIdSelected: string

    if (e.target.name === 'productSelect') {
      productIdSelected = e.target.value

      if (productIdSelected) {
        setDataRangeState(false)
      } else {
        setDataRangeState(true)
      }

      if (e.target instanceof HTMLSelectElement) {
        setSelectedNameProduct(e.target.options[e.target.selectedIndex].text)
      }

      setSelectedIdProduct(productIdSelected)
      if (productIdSelected !== '' && productIdSelected !== '0') {
        getCampaignsByProduct(productIdSelected)
        setCampaignsList([])
        setSelectedIdCampaign('0')
        setSelectedNameCampaign(intl.formatMessage({id: 'REPORT.CAMPAIGN.ALL_CAMPAIGNS'}))
        setPeticionNumber(2) //Peticion 2: solo producto
      } else {
        setCampaignsList([])
        setSelectedIdCampaign('')
        setSelectedNameCampaign('')
        setPeticionNumber(1) //Peticion 1: sin parametros
      }
    } else if (e.target.name === 'campaign') {
      campaignIdSelected = e.target.value

      if (e.target instanceof HTMLSelectElement) {
        setSelectedNameCampaign(e.target.options[e.target.selectedIndex].text)
      }

      setSelectedIdCampaign(campaignIdSelected)
      if (
        startDate1.substring(0, 10) ===
          dateFormatterISO8601Variant(initialStartDate1).substring(0, 10) &&
        endDate1.substring(0, 10) ===
          dateFormatterISO8601Variant(initialEndDate1).substring(0, 10) &&
        startDate2.substring(0, 10) ===
          dateFormatterISO8601Variant(initialStartDate2).substring(0, 10) &&
        endDate2.substring(0, 10) ===
          dateFormatterISO8601Variant(initialEndDate2).substring(0, 10) &&
        campaignIdSelected !== '0'
      ) {
        setPeticionNumber(3) //Peticion 3: solo producto y campañas
      } else if (
        startDate1.substring(0, 10) ===
          dateFormatterISO8601Variant(initialStartDate1).substring(0, 10) &&
        endDate1.substring(0, 10) ===
          dateFormatterISO8601Variant(initialEndDate1).substring(0, 10) &&
        startDate2.substring(0, 10) ===
          dateFormatterISO8601Variant(initialStartDate2).substring(0, 10) &&
        endDate2.substring(0, 10) ===
          dateFormatterISO8601Variant(initialEndDate2).substring(0, 10) &&
        campaignIdSelected === '0'
      ) {
        setPeticionNumber(2) //Peticion 2: solo producto
      } else if (
        (campaignIdSelected === '0' &&
          startDate1.substring(0, 10) !==
            dateFormatterISO8601Variant(initialStartDate1).substring(0, 10)) ||
        endDate1.substring(0, 10) !==
          dateFormatterISO8601Variant(initialEndDate1).substring(0, 10) ||
        startDate2.substring(0, 10) !==
          dateFormatterISO8601Variant(initialStartDate2).substring(0, 10) ||
        endDate2.substring(0, 10) !== dateFormatterISO8601Variant(initialEndDate2).substring(0, 10)
      ) {
        setPeticionNumber(6) //Peticion 6: Sin campaña
      } else {
        setPeticionNumber(4) //Peticion 4: todos los parametros
      }
    }
  }

  const handleRangeDates = (startDate, endDate) => {
    const formattedStartDate = startDate.format('DD/MM/YYYY')
    const formattedEndDate = endDate.format('DD/MM/YYYY')

    // getCampaignsCounts(selectedIdProduct, selectedIdCampaign, startDate1, endDate1, startDate2, endDate2)
  }

  // Manejamos las acciones que queremos para cuando se interactue con las fechas del DateRange
  const handleDates1 = (): void => {
    setIsLoading(true)

    const startDateNew: string = dateFormatterISO8601Variant(labelStartDate1)
    const endDateNew: string = dateFormatterISO8601Variant(labelEndDate1)

    setStartDate1(startDateNew)
    setEndDate1(endDateNew)
    if (
      (selectedIdProduct === '0' || selectedIdProduct === '') &&
      (selectedIdCampaign === '' || selectedIdCampaign === '0')
    )
      setPeticionNumber(5)
    else setPeticionNumber(6)
  }

  // Manejamos las acciones que queremos para cuando se interactue con las fechas del DateRange
  const handleDates2 = (): void => {
    setIsLoading(true)
    const startDateNew: string = dateFormatterISO8601Variant(labelStartDate2)
    const endDateNew: string = dateFormatterISO8601Variant(labelEndDate2)

    setStartDate2(startDateNew)
    setEndDate2(endDateNew)
    if (
      (selectedIdProduct === '0' || selectedIdProduct === '') &&
      (selectedIdCampaign === '' || selectedIdCampaign === '0')
    )
      setPeticionNumber(5)
    else setPeticionNumber(6)
  }

  /************ FUNCIONES PARA LA APIs DE CAMPAÑAS *************/
  const getCampaignsReportData = async (peticion: number): Promise<void> => {
    try {
      const {data: jsonData, code, msg} = await fetchCampaignData(peticion)

      if (code === 401 && msg === 'Token no valido') {
        sessionExpired('La sesión ha caducado')
        return
      } else if (code === 200 && msg === 'Reporte de campañas con exito') {
        setIsLoading(false)
        const userTokenNew: string = jsonData.token
        updateToken(userTokenNew)
        //setCampaignReportItemRTwo(formatDates(jsonData.dataReportPeriodTwo))
        setCampaignReportItemROne(newCampaignsCounts)
        setCampaignReportItemRTwo(newCampaignsCounts2)

        //Validar las respuestas
        let itemROne = jsonData.dataReportPeriodOne
        let itemRTwo = jsonData.dataReportPeriodTwo

        if (itemROne.iscore.iscoreByDay.length === 0 && itemROne.lead.leadsByDay.length === 0) {
          setCampaignReportItemROne(initialCampaignReportItems)
        } else {
          // Formatear las fechas en el objeto data
          setCampaignReportItemROne(formatDates(itemROne))
        }
        if (itemRTwo.iscore.iscoreByDay.length === 0 && itemRTwo.lead.leadsByDay.length === 0) {
          setCampaignReportItemRTwo(initialCampaignReportItems)
        } else {
          // Formatear las fechas en el objeto data
          setCampaignReportItemRTwo(formatDates(jsonData.dataReportPeriodTwo))
        }
      } else if (
        code === 400 &&
        msg === 'Las fechas de inicio no pueden ser mayores a las fechas de fin'
      ) {
        setIsLoading(false)
        setInfoMessageLoading(intl.formatMessage({id: 'REPORT.CAMPAIGN.START_DATE_ERROR'}))
      }
    } catch (error) {
      console.error('Hubo un error en la petición: ', error)
      setInfoMessageLoading(intl.formatMessage({id: 'GLOBAL.REQUEST_ERROR'}))
      setInfoMessageLoading2(intl.formatMessage({id: 'GLOBAL.REQUEST_ERROR'}))
    }
  }
  // Ejemplo de función formatDates que retorna el objeto formateado
  const formatDates = (obj: CampaignReport): CampaignReport => {
    const newObj = {...obj}

    for (const key in newObj) {
      if (Array.isArray(newObj[key])) {
        newObj[key] = (newObj[key] as Array<IscoreByDay | LeadByDay>).map((item) => {
          if ('iscore' in item) {
            // Guarda de tipo para verificar la presencia de 'iscore'
            return {
              ...item,
              date: moment(item.date).format('DD-MMMM-YYYY'),
              iscore: formatNumber(item.iscore),
            }
          } else {
            return {
              ...item,
              date: moment(item.date).format('DD-MMMM-YYYY'),
            }
          }
        })
      } else if (typeof newObj[key] === 'object' && newObj[key] !== null) {
        newObj[key] = formatDates(newObj[key] as CampaignReport)
      }
    }

    return newObj
  }

  // Función que realiza la llamada a la API en función del valor de peticion
  async function fetchCampaignData(peticion: number) {
    switch (peticion) {
      case 1:
        return await campaignsReportPetitionNoParams(userToken, clientId)
      case 2:
        return await campaignsReportPetitionOnlyProduct(userToken, clientId, selectedIdProduct)
      case 3:
        return await campaignsReportPetitionNoPeriods(
          userToken,
          clientId,
          selectedIdProduct,
          selectedIdCampaign
        )
      case 4:
        return await campaignsReportPetitionFulParams(
          userToken,
          clientId,
          selectedIdProduct,
          selectedIdCampaign,
          startDate1,
          endDate2,
          startDate2,
          endDate2
        )
      case 5:
        return await campaignsReportPetitionOnlyDates(
          userToken,
          clientId,
          startDate1,
          endDate2,
          startDate2,
          endDate2
        )
      case 6:
        return await campaignsReportPetitionNoCampaign(
          userToken,
          clientId,
          selectedIdProduct,
          startDate1,
          endDate1,
          startDate2,
          endDate2
        )

      // Agrega más casos según sea necesario
      default:
        throw new Error(`No se reconoce la petición: ${peticion}`)
    }
  }

  useEffect(() => {
    setPageTitle(`${intl.formatMessage({id: 'REPORT.CAMPAIGN.REPORT_TITLE'})} | ${DragonCem}`)
  }, [])

  useEffect(() => {
    checkCountsCampaignItems()
  }, [campaignReportItemROne, campaignReportItemRTwo])

  useEffect(() => {
    if (peticionNumber != 0) getCampaignsReportData(peticionNumber)
  }, [selectedIdProduct, selectedIdCampaign, startDate1, endDate1, startDate2, endDate2])

  return (
    <>
      <div className='container-fluid'>
        <div className='row justify-content-center mb-2'>
          <div className='col-md-12'>
            <div className='d-flex flex-wrap flex-stack mb-6'>
              <h1 className='my-2 d-flex flex-wrap flex-stack'>{intl.formatMessage({id: 'REPORT.CAMPAIGN.REPORT_TITLE'})}</h1>
              <div className='d-flex flex-wrap flex-stack align-items-center my-2'>
                <div className='w-300px me-3'>
                  <ProductsByUserDropdown
                    updateToken={updateToken}
                    clientId={clientId}
                    userToken={userToken}
                    sessionExpired={sessionExpired}
                    productIdSelected={selectedIdProduct}
                    handleProductChange={handleProductOrCampaignSelected}
                    productsList={productsList}
                    setProductsList={setProductsList}
                    productsDropdownState={productsDropdownState}
                    dropdownClass='form-select form-select-sm form-select-solid mb-2 '
                    allProductsOption={true}
                    onlyActiveProducts={true}
                  />
                </div>
                <div className='w-300px'>
                  {/* <select
                    name='campaign'
                    data-control='selectCampaign'
                    data-hidden-search='true'
                    className='form-select form-select-solid form-select-sm'
                    value={selectedIdCampaign}
                    onChange={(e) => {
                      handleProductOrCampaignSelected(e)
                    }}
                    disabled={selectedIdProduct === '' || selectedIdProduct === '0'}
                  >
                    <option value='' disabled>
                      Selecciona una campaña
                    </option>
                    <option value='0'>{intl.formatMessage({id: 'REPORT.CAMPAIGN.ALL_CAMPAIGNS'})}</option>
                    {campaignsList.map((campaign: CampaignList) => (
                      <option key={campaign.cam_id} value={campaign.cam_id}>
                        {campaign.name}
                      </option>
                    ))}
                  </select> */}
                  <CampaignsByProductDropdown
                    updateToken={updateToken}
                    clientId={clientId}
                    userToken={userToken}
                    productIdSelected={selectedIdProduct}
                    selectedIdCampaign={selectedIdCampaign}
                    selectedIdCampaignOne={selectedIdCampaign}
                    campaignsList={campaignsList}
                    setCampaignsList={setCampaignsList}
                    campaignDropdownState={campaignDropdownState}
                    setCampaignDropdownState={setCampaignDropdownState}
                    comparativeCampaignDropdownState={comparativeCampaignDropdownState}
                    setComparativeCampaignDropdownState={setComparativeCampaignDropdownState}
                    dropdownClass='form-select form-select-solid form-select-sm'
                    dropdownName={'campaign'}
                    onlyActiveCampaigns={true}
                    handleCampaignSelected={handleProductOrCampaignSelected}
                    iscomparative={false}
                  />
                </div>
                
              </div>
            </div>
          </div>
        </div>

        <div className='row gx-2 mb-6'>
          <div className='col-xl-6'>
            <div className='card card-custom px-8 py-10 h-100'>
              <div className='row justify-content-center mb-2'>
                <div className='col-md-12'>
                  <div className='d-flex mb-4 justify-content-center'>
                    <h5 className='card-label me-4 d-flex flex-wrap flex-stack '>
                      <span className='text-dark text-sm font-weight-bolder mt-3'>
                        {intl.formatMessage({id: 'REPORT.CAMPAIGN.AVERAGE_RANGE_1'})}:
                      </span>
                      &nbsp;&nbsp;
                      <div className='d-flex'>
                        <button
                          type='button'
                          className='btn btn-sm btn-icon btn-secondary me-1'
                          disabled={dataRangeState}
                          data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-start'
                          data-kt-menu-flip='top-start'
                        >
                          <KTIcon iconName='calendar' className='fs-2' />
                        </button>

                        <button
                          type='button'
                          className='btn btn-sm btn-secondary disabled'
                          data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-end'
                          data-kt-menu-flip='top-end'
                        >
                          {dateFormatterTextualEnTwo(labelStartDate1) +
                            '  -  ' +
                            dateFormatterTextualEnTwo(labelEndDate1)}
                        </button>

                        {/* Componente con el DateRangePicker */}
                        <DateRangePickerFull
                          initialStartDate={initialStartDate1}
                          initialEndDate={initialEndDate1}
                          months={2}
                          handleDates={handleDates1}
                          setLabelStartDate={setLabelStartDate1}
                          setLabelEndDate={setLabelEndDate1}
                        />
                      </div>
                    </h5>
                  </div>

                  {!isLoading &&
                    infoMessageLoading &&
                    selectedIdProduct &&
                    campaignReportItemROne.iscore.iscoreByDay.length === 0 && (
                      <div
                        className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                        role='alert'
                      >
                        <h5 className='mb-1 text-center'>{infoMessageLoading}</h5>
                      </div>
                    )}
                  {!selectedIdProduct && (
                    <>
                      <div
                        className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                        role='alert'
                      >
                        <h5 className='mb-1 text-center'>{intl.formatMessage({id: 'REPORT.CAMPAIGN.SELECT_PRODUCT'})}</h5>
                      </div>
                    </>
                  )}

                  {isLoading && selectedIdProduct && (
                    <div className='py-6'>
                      <LoadingAnimation alignment='center' label={intl.formatMessage({id: 'REPORT.CAMPAIGN.LOADING_LEADS'})} size='lg' />
                    </div>
                  )}

                  {!isLoading &&
                    selectedIdProduct &&
                    campaignReportItemROne.iscore.iscoreByDay.length > 0 && (
                      <LeadCountsItemRangeOne campaignReportItemROne={campaignReportItemROne} />
                    )}
                </div>
              </div>
            </div>
          </div>

          <div className='col-xl-6'>
            <div className='card card-custom px-8 py-10 h-100'>
              <div className='row justify-content-center mb-2'>
                <div className='col-md-12'>
                  <div className='d-flex mb-4 justify-content-center'>
                    <h5 className='card-label me-4 d-flex flex-wrap flex-stack '>
                      <span className='text-dark text-sm font-weight-bolder mt-3'>
                        {intl.formatMessage({id: 'REPORT.CAMPAIGN.AVERAGE_RANGE_2'})}:
                      </span>
                      &nbsp;&nbsp;
                      <div className='d-flex'>
                        <button
                          type='button'
                          className='btn btn-sm btn-icon btn-secondary me-1'
                          disabled={dataRangeState}
                          data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-end'
                          data-kt-menu-flip='top-end'
                        >
                          <KTIcon iconName='calendar' className='fs-2' />
                        </button>

                        <button
                          type='button'
                          className='btn btn-sm btn-secondary disabled'
                          data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-end'
                          data-kt-menu-flip='top-end'
                        >
                          {dateFormatterTextualEnTwo(labelStartDate2) +
                            '  -  ' +
                            dateFormatterTextualEnTwo(labelEndDate2)}
                        </button>

                        {/* Componente con el DateRangePicker */}
                        <DateRangePickerFull
                          initialStartDate={initialStartDate2}
                          initialEndDate={initialEndDate2}
                          months={2}
                          handleDates={handleDates2}
                          setLabelStartDate={setLabelStartDate2}
                          setLabelEndDate={setLabelEndDate2}
                        />
                      </div>
                    </h5>
                  </div>
                  {!isLoading && infoMessageLoading2 && selectedIdProduct && (
                    <div
                      className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                      role='alert'
                    >
                      <h5 className='mb-1 text-center'>{infoMessageLoading2}</h5>
                    </div>
                  )}

                  {!selectedIdProduct && (
                    <>
                      <div
                        className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                        role='alert'
                      >
                        <h5 className='mb-1 text-center'>{intl.formatMessage({id: 'REPORT.CAMPAIGN.SELECT_PRODUCT'})}</h5>
                      </div>
                    </>
                  )}

                  {isLoading && selectedIdProduct && (
                    <div className='py-6'>
                      <LoadingAnimation alignment='center' label={intl.formatMessage({id: 'REPORT.CAMPAIGN.LOADING_LEADS'})} size='lg' />
                    </div>
                  )}

                  {!isLoading && selectedIdProduct && !infoMessageLoading2 && (
                    <LeadCountsItemRangeTwo campaignReportItemRTwo={campaignReportItemRTwo} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row gx-2 mb-6'>
          <div className='col-xl-12'>
            <div className='card card-custom px-8 py-10 h-100'>
              <div className='row-justify-content-center mb-2'>
                <div className='col-md-12'>
                  {selectedIdProduct && (
                    <div className='d-flex flex-wrap flex-stack mb-4 justify-content-center'>
                      <h5 className='card-label me-4 d-flex flex-wrap flex-stack'>
                        <span className='text-dark text-sm font-weight-bolder mt-3'>
                          {intl.formatMessage({id: 'REPORT.CAMPAIGN.TOTAL_LEADS_PER_DAY'})}:
                        </span>
                        &nbsp;&nbsp;
                        <span className='text-muted mt-3'>
                          {selectedNameProduct} - {selectedNameCampaign}
                        </span>
                      </h5>
                    </div>
                  )}

                  {!selectedIdProduct && (
                    <>
                      <div
                        className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                        role='alert'
                      >
                        <h5 className='mb-1 text-center'>{intl.formatMessage({id: 'REPORT.CAMPAIGN.SELECT_PRODUCT'})}</h5>
                      </div>
                    </>
                  )}

                  {isLoading && selectedIdProduct && (
                    <div className='py-6'>
                      <LoadingAnimation alignment='center' label={intl.formatMessage({id: 'REPORT.CAMPAIGN.LOADING_LEADS'})} size='lg' />
                    </div>
                  )}

                  {!isLoading &&
                    selectedIdProduct &&
                    (campaignReportItemROne.iscore.iscoreByDay.length > 0 ||
                      campaignReportItemRTwo.iscore.iscoreByDay.length > 0) && (
                      <CampaignsReportLeadsChart
                        campaignReportItemROne={campaignReportItemROne}
                        campaignReportItemRTwo={campaignReportItemRTwo}
                      />
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row gx-2 mb-6'>
          <div className='col-xl-12'>
            <div className='card card-custom px-8 py-10 h-100'>
              <div className='row-justify-content-center mb-2'>
                <div className='col-md-12'>
                  {selectedIdProduct && (
                    <div className='d-flex flex-wrap flex-stack mb-4 justify-content-center'>
                      <h5 className='card-label me-4 d-flex flex-wrap flex-stack'>
                        <span className='text-dark text-sm font-weight-bolder mt-3'>
                          {intl.formatMessage({id: 'REPORT.CAMPAIGN.AVERAGE_ISCORE_PER_DAY'})}:
                        </span>
                        &nbsp;&nbsp;
                        <span className='text-muted mt-3'>
                          {selectedNameProduct} - {selectedNameCampaign}
                        </span>
                      </h5>
                    </div>
                  )}

                  {!selectedIdProduct && (
                    <>
                      <div
                        className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                        role='alert'
                      >
                        <h5 className='mb-1 text-center'>{intl.formatMessage({id: 'REPORT.CAMPAIGN.SELECT_PRODUCT'})}</h5>
                      </div>
                    </>
                  )}

                  {isLoading && selectedIdProduct && (
                    <div className='py-6'>
                      <LoadingAnimation alignment='center' label={intl.formatMessage({id: 'REPORT.CAMPAIGN.LOADING_LEADS'})} size='lg' />
                    </div>
                  )}

                  {!isLoading &&
                    selectedIdProduct &&
                    (campaignReportItemROne.iscore.iscoreByDay.length > 0 ||
                      campaignReportItemRTwo.iscore.iscoreByDay.length > 0) && (
                      <CampaignsReportIscoreChart
                        campaignReportItemROne={campaignReportItemROne}
                        campaignReportItemRTwo={campaignReportItemRTwo}
                      />
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {CampaignsReport}