import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { setPageTitle } from '../../utils/functions/_Functions';
import { DragonCem } from '../../utils/constants/_Constants';
import { LinksDcemMainTable } from './tables/LinksDcemMainTable';
import { Modal } from 'react-bootstrap';
import { ModalDcemNew } from './modals/ModalDcemNew';

function LinksDcem() {

    const intl = useIntl();

    const [showModalNewLinkDcem, setShowModalNewLinkDcem] = useState<boolean>(false)

    const openModalLinkDcemNew = () => {
        setShowModalNewLinkDcem(true)
    }

    const closeModalLinkDcemNew = () => {
        setShowModalNewLinkDcem(false)
    }

    useEffect(() => {
        // Cambiar el título de la página al montar el componente
        setPageTitle(`${intl.formatMessage({ id: "LINKSDECM.TITLE" })} | ${DragonCem}`);
    }, [])

    return (
        <>
            <div className='container-fluid'>
                <div className='row justify-content-center'>
                    <div className='col-md-12'>
                        <h1 className='my-2'>{intl.formatMessage({ id: "LINKSDECM.TITLE" })}</h1>
                        <div className='card card-custom'>
                            <LinksDcemMainTable
                                openModalLinkDcemNew={openModalLinkDcemNew}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                show={showModalNewLinkDcem}
                onHide={closeModalLinkDcemNew}
                className='modal-defult'
            >
                <Modal.Header closeButton onClick={closeModalLinkDcemNew}>
                    <Modal.Title>
                        <h1>Nuevo Link DCEM</h1>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ModalDcemNew />
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type='submit'
                        className='btn btn-sm btn-primary'
                    >
                        {intl.formatMessage({id: 'GLOBAL.SAVE'})}
                    </button>
                    <button
                        type='reset'
                        className='btn btn-sm btn-secondary'
                        onClick={() => {
                            closeModalLinkDcemNew()
                        }}
                    >
                        {intl.formatMessage({id: 'GLOBAL.CANCEL'})}
                    </button>

                </Modal.Footer>
            </Modal>
        </>
    )
}

export { LinksDcem }