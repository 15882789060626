import React, {useContext, useEffect, useState} from 'react'
import {
  Column,
  Table,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  FilterFn,
  ColumnDef,
  flexRender,
} from '@tanstack/react-table'
import {RankingInfo, rankItem} from '@tanstack/match-sorter-utils'
import {SellerFormData, UserData} from '../Interfaces/sellersTypes'
import {sellersListByClient} from '../services/_SellersListApi'
import {UserContext} from '../../../../app-context/user-context/UserContext'
import {Dropdown, DropdownButton, Modal, OverlayTrigger} from 'react-bootstrap'
import {Tooltip} from 'react-bootstrap'
import {SellerEditModalForm} from '../modals/SellerEditModalForm'
import {AlertInfo} from '../../../../utils/interfaces/_InterfacesTypes'
import {sellersEdit} from '../services/_SellersEditApi'
import {canAccessFeature, mapUserStatusToWord, sessionExpired} from '../../../../utils/functions/_Functions'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../../_metronic/helpers'
import {productsListByClient} from '../../../products/services/_ProductsListApi'
import {ProductsForBrand} from '../../../brands/interfaces/brandsTypes'
import {SellerAddModalForm} from '../modals/SellerAddModalForm'
import {sellersNew} from '../services/_SellersNewApi'
import {DropdownExport} from '../../../../../_metronic/partials/content/dropdown/DropdownExport'
import {sellerStatusDeactivate} from '../services/_SellersDeactivateApi'
import {sellerStatusActivate} from '../services/_SellersActivateApi'
import {SellersHistoryTable} from './SellersHistoryTable'
import { ChartsWidgetCustom } from '../../../../../_metronic/partials/widgets'
import { DropdownItemExportXLSX } from '../../../../../_metronic/partials/content/dropdown/DropdownItemExportXLSX'
import { DropdownItemExportCSV } from '../../../../../_metronic/partials/content/dropdown/DropdownItemExportCSV'
import { DropdownItemExportJSON } from '../../../../../_metronic/partials/content/dropdown/DropdownItemExportJSON'
import { DRG_STANDAR_MODULES } from '../../../../routing/constants/_Contstants'

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>
  }
  interface FilterMeta {
    itemRank: RankingInfo
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)

  // Store the itemRank info
  addMeta({
    itemRank,
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

export function SellersTableComponent() {
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  // Estado inicial para el formulario de nuevo vendedor
  const initialFormNewSeller = {
    name: '',
    email: '',
    phone: '',
    whatsapp: '',
    newpassword: '',
    verifypassword: '',
    calendly: '',
    role: '',
    brandProducts: [],
  }

  const renderTooltip = (props) => <Tooltip {...props}>{intl.formatMessage({id: 'CONTROL_SELLERS.COPY_SELLER_ID'})}</Tooltip>

  const {user, token, updateToken} = useContext(UserContext)
  const clientId = user.cli_id
  const userToken = token
  const type = user.user_type

  const [data, setData] = React.useState<UserData[]>([])
  const [dataToExport, setDataToExport] = useState({}) // Definimos variables para la exportación de la data a formato hoja de calculos
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = React.useState('')
  const [selectedSeller, setSelectedSeller] = useState<UserData | null>(null)
  const [showModal, setShowModal] = useState(false)
  const [showModal2, setShowModal2] = useState(false)
  const [showModalUserStatus, setshowModalUserStatus] = useState(false)
  const [showModalUserLogs, setShowModalUserLogs] = useState(false)
  const [showModalActivityResume, setShowModalActivityResume] = useState(false)
  const [SellerData, setSellerData] = useState({})
  const [Body, setBody] = useState<any>()
  const [formData, setFormData] = useState<SellerFormData>(initialFormNewSeller)
  const [errors, setErrors] = useState<{[key: string]: string}>({})
  const data1 = [44, 55, 57, 56, 61, 58]
  const data2 = [76, 85, 101, 98, 87, 105]
  // Variables para guardar los productos que tiene el cliente
  const [brandProductsList, setBrandProductsList] = useState<ProductsForBrand[]>([])
  //Variables con el estado de la alerta da confirmacion o error
  const [alertInfo, setAlertInfo] = useState<AlertInfo>({
    text: '',
    variant: 'primary',
  })
  //Const con la información 'dummy (:v), para la exportación de productos asignados'
  const dataToExportTest =
    {
      "id": "0K3AM407-6EGK04WO",
      "name": "0K3AM407-V8SXCLM7",
      "usr_phone": "5523567896",
      "wha_phone": "+5259227221886",
      "crm_id": 0,
      "user_type": "DEVELOPER",
      "email": "puto12@dragoncem.com",
      "brandProducts": [
          {
              "pro_id": "0K3AM407-CFWX51BA",
              "name": "Partito de plastico",
              "status_assigned": false
          },
          {
              "pro_id": "0K3AM407-9OGT8JFM",
              "name": "Tejeringo",
              "status_assigned": false
          },
          {
              "pro_id": "0K3AM407-UUGH5PSP",
              "name": "TRUJO",
              "status_assigned": false
          },
      ],
      "status": "Activo"
  };

  //Funcion para resetear formulario
  const resetForm = (): void => {
    setFormData(initialFormNewSeller)
  }

  const openModalForNewSeller = (): void => {
    resetForm()
    setShowModal2(true)
    getProductsRegisters()
    setFormData((prevData) => ({
      ...prevData,
      brandProducts: initialFormNewSeller.brandProducts,
    }))
  }

  // Función para cerrar el modal de edición
  const closeModal = () => {
    setShowModal(false)
    setErrors({})
    resetForm()
  }

  const closeModalForNewSeller = (): void => {
    setShowModal2(false)
    resetForm()
  }

  // Función para manejar la edición de un vendedor, muestra el modal y obtiene los datos del vendedor seleccionado
  const handleEdit = (seller) => {
    console.log(seller)
    setShowModal(true)
    setSelectedSeller(seller)
  }

  //función para abrir el modal de edición de cambio de status
  const handleOpenModalForSellerStatus = (seller) => {
    console.log(seller)

    setSellerData(seller)

    if (seller.status == 'Activo') {
      setBody(
        <div>
          <div className='alert alert-dismissible bg-light-warning border border-warning mb-4'>
            <div className='d-flex flex-column flex-sm-row p-5'>
              <span className='svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0 text-dark'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-exclamation-triangle-fill'
                  viewBox='0 0 16 16'
                >
                  <path d='M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2' />
                </svg>
              </span>
              <div className='d-flex flex-column text-primary pe-0 pe-sm-10'>
                <h5 className='mb-1'>{intl.formatMessage({id: 'CONTROL_SELLERS.DEACTIVATE_USER'})}</h5>
                <span className='text-dark'>
                  <p>{intl.formatMessage({id: 'CONTROL_SELLERS.CONFIRM_DEACTIVATE'}, {name: seller.name})}</p>
                </span>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      setBody(
        <div>
          <div className='alert alert-dismissible bg-light-success border border-success mb-4'>
            <div className='d-flex flex-column flex-sm-row p-5'>
              <span className='svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0 text-dark'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-exclamation-triangle-fill'
                  viewBox='0 0 16 16'
                >
                  <path d='M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2' />
                </svg>
              </span>
              <div className='d-flex flex-column text-primary pe-0 pe-sm-10'>
                <h5 className='mb-1'>{intl.formatMessage({id: 'CONTROL_SELLERS.ACTIVATE_USER'})}</h5>
                <span className='text-dark'>
                  <p>{intl.formatMessage({id: 'CONTROL_SELLERS.CONFIRM_ACTIVATE'}, {name: seller.name})}</p>
                </span>
              </div>
            </div>
          </div>
        </div>
      )
    }
    setshowModalUserStatus(true)
  }

  //función para cerrar el modal de edición de cambio de status
  const handleCloseModalForSellerStatus = () => {
    setshowModalUserStatus(false)
  }

  // Función para determinar la clase del botón basado en el estatus
  const getStatusButtonClass = (status) => {
    return status === 'Activo' || status === true ? 'btn btn-light-success' : 'btn btn-light-danger'
  }

  //Aqui se debe disparar la funcion que hara el cambio de estatus de la marca
  const handleChangeSellerStatus = async (seller) => {
    console.log(seller.status)

    try {
      let response
      let petitionType
      if (seller.status == 'Activo') {
        response = await sellerStatusDeactivate(seller.id, userToken)
        petitionType = 'sellerStatusDeactivate'
        console.log('Se intentó desactivar')
      } else {
        response = await sellerStatusActivate(seller.id, userToken)
        petitionType = 'sellerStatusActivate'
        console.log('Se intentó activar')
      }

      const {data, code, msg} = response

      if (code === 401 && msg === 'Token no válido') {
        sessionExpired(intl.formatMessage({id: 'GLOBAL.ALERT.SESSION_EXPIRED'}))
        return
      }

      if (code === 202 && petitionType === 'sellerStatusDeactivate') {
        setAlertInfo({
          text: intl.formatMessage({id: 'CONTROL_SELLERS.USER_DEACTIVATED_SUCCESS'}),
          variant: 'success',
        })

        setTimeout(() => {
          setAlertInfo({
            text: '',
            variant: '',
          })
          getSellersRegisters()
          handleCloseModalForSellerStatus()
        }, 3000)
        return
      }

      if (code === 200 && petitionType === 'sellerStatusActivate') {
        setAlertInfo({
          text: intl.formatMessage({id: 'CONTROL_SELLERS.USER_ACTIVATED_SUCCESS'}),
          variant: 'success',
        })

        setTimeout(() => {
          setAlertInfo({
            text: '',
            variant: '',
          })
          getSellersRegisters()
          handleCloseModalForSellerStatus()
        }, 3000)
        return
      }
    } catch (error) {
      setAlertInfo({
        text: `${error}`,
        variant: 'danger',
      })
      setTimeout(() => {
        setAlertInfo({
          text: '',
          variant: '',
        })
      }, 3000)
      throw error
    }
  }

  const getSellersRegisters = async () => {
    try {
      // Obtener datos de vendedores
      const {data: jsonData, code, msg} = await sellersListByClient(userToken, clientId)
      if (code === 401 && msg === 'Token no valido') {
        sessionExpired(intl.formatMessage({id: 'GLOBAL.ALERT.SESSION_EXPIRED'}))
        return
      } else {
        const userTokenNew = await jsonData.token

        const sellersData = jsonData.data

        // Actualizar el token
        updateToken(userTokenNew)

        // Mapear los datos de los vendedores al formato de UserData
        const mappedSellers: UserData[] = sellersData.map((seller: any) => ({
          id: seller.uid,
          name: seller.usrData.usr_name,
          usr_phone: seller.usrData.usr_phone,
          wha_phone: seller.usrData.wha_phone,
          crm_id: seller.crm_id,
          user_type: seller.user_type,
          email: seller.usrData.usr_mail,
          brandProducts: seller.products_assigned,
          status: mapUserStatusToWord(seller.status),
        }))

        // Actualizar el estado de 'data' con los datos de los vendedores
        setData(mappedSellers)
        console.log(mappedSellers)
      }
    } catch (error) {
      console.error('Error fetching seller data:', error)
    }
  }

  // Función para manejar el envío del formulario de edición, aqui traigo todo lo que está escrito en los inputs por si se quiere editar algo
  const handleFormEditSubmit = async () => {
    // console.log(formData.brandProducts)
    // Limpiar errores antes de cada intento de envío
    setErrors({})

    if (!formData.name) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        SellerName: intl.formatMessage({id: 'CONTROL_SELLERS.REQUIRED_FIELD'}),
      }))
    }

    if (!formData.role) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        SellerRole: intl.formatMessage({id: 'CONTROL_SELLERS.REQUIRED_FIELD'}),
      }))
    }

    if (!formData.email) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        SellerMail: intl.formatMessage({id: 'CONTROL_SELLERS.REQUIRED_FIELD'}),
      }))
    }

    if (!formData.phone) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        SellerPhone: intl.formatMessage({id: 'CONTROL_SELLERS.REQUIRED_FIELD'}),
      }))
    }

    if (!formData.whatsapp) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        SellerWhatsApp: intl.formatMessage({id: 'CONTROL_SELLERS.REQUIRED_FIELD'}),
      }))
    }

    const name = formData.name
    const role = formData.role
    const email = formData.email
    const phone = formData.phone
    const whatsapp = formData.whatsapp
    const newpassword = formData.newpassword
    const verifypassword = formData.verifypassword
    const calendly = formData.calendly
    const status = formData.status
    const brandProducts = (formData.brandProducts as any[]).map((product) => ({
      idProduct: product.pro_id,
      status: product.status_assigned,
    }))

    console.log(status)
    let usrPrivilage = 0
    const id = selectedSeller?.id

    // console.log(formData.brandProducts)
    // console.log(brandProducts)

    if (type === 'DEVELOPER' || type === 'SUPER_ADMIN') {
      usrPrivilage = 1
    }

    // console.log(usrPrivilage)

    try {
      // console.log('Valores del formulario:', id);

      // Llamar a la función que ejecuta el servicio del editar vendedor
      const {
        data: jsonData,
        code,
        msg,
      } = await sellersEdit(
        id,
        role,
        usrPrivilage,
        name,
        phone,
        whatsapp,
        email,
        newpassword,
        verifypassword,
        calendly,
        status,
        brandProducts,
        userToken
      )

      setAlertInfo({
        text: code === 200 ? intl.formatMessage({id: 'CONTROL_SELLERS.USER_EDITED_SUCCESS'}) : intl.formatMessage({id: 'GLOBAL.ALERT_SOMETHING_WENT_WRONG'}),
        variant: code === 200 ? 'success' : 'danger',
      })

      // Validación del mensaje y llamada a la función limpiarYRecargar
      if (msg === 'Token no válido') {
        sessionExpired(intl.formatMessage({id: 'GLOBAL.ALERT.SESSION_EXPIRED'}))
      }

      setTimeout(() => {
        if (code === 200) {
          setAlertInfo({
            text: '',
            variant: '',
          })

          closeModal()
        }
      }, 3000)

      console.log(msg)
    } catch (error) {
      setAlertInfo({
        text: intl.formatMessage({id: 'GLOBAL.ALERT_ERROR_AXIOS_REQUEST'}),
        variant: 'danger',
      })
    }
    //   console.log(formData)
  }

  const handleFormNewSubmit = async (): Promise<void> => {
    // Limpiar errores antes de cada intento de envío
    setErrors({})

    if (!formData.name) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        SellerName: intl.formatMessage({id: 'CONTROL_SELLERS.REQUIRED_FIELD'}),
      }))
    }

    if (!formData.role) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        SellerRole: intl.formatMessage({id: 'CONTROL_SELLERS.REQUIRED_FIELD'}),
      }))
    }

    if (!formData.email) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        SellerMail: intl.formatMessage({id: 'CONTROL_SELLERS.REQUIRED_FIELD'}),
      }))
    }

    if (!formData.phone) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        SellerPhone: intl.formatMessage({id: 'CONTROL_SELLERS.REQUIRED_FIELD'}),
      }))
    }

    if (!formData.whatsapp) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        SellerWhatsApp: intl.formatMessage({id: 'CONTROL_SELLERS.REQUIRED_FIELD'}),
      }))
    }

    if (!formData.newpassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        SellerPassword: intl.formatMessage({id: 'CONTROL_SELLERS.REQUIRED_FIELD'}),
      }))
    }

    if (!formData.calendly) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        SellerCalendly: intl.formatMessage({id: 'CONTROL_SELLERS.REQUIRED_FIELD'}),
      }))
    }

    if (formData.newpassword !== formData.verifypassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        VerifyPassword: intl.formatMessage({id: 'CONTROL_SELLERS.PASSWORDS_NOT_MATCH'}),
      }))
      return
    }

    const name = formData.name
    const role = formData.role
    const email = formData.email
    const phone = formData.phone
    const whatsapp = formData.whatsapp
    const newpassword = formData.newpassword
    const verifypassword = formData.verifypassword
    const calendly = formData.calendly
    let usrPrivilage = 0
    const productsAssigned = formData.brandProducts.map((product) => ({
      idProduct: product.pro_id,
      status: product.status,
    }))

    if (type === 'DEVELOPER' || type === 'SUPER_ADMIN') {
      usrPrivilage = 1
    }

    // console.log(usrPrivilage)

    console.log(productsAssigned)

    try {
      // console.log(formData)

      const {
        data: jsonData,
        code,
        msg,
      } = await sellersNew(
        clientId,
        role,
        usrPrivilage,
        name,
        phone,
        whatsapp,
        email,
        newpassword,
        verifypassword,
        calendly,
        userToken,
        productsAssigned
      )

      setAlertInfo({
        text: code === 200 ? intl.formatMessage({id: 'CONTROL_SELLERS.USER_CREATED_SUCCESS'}) : intl.formatMessage({id: 'GLOBAL.ALERT_SOMETHING_WENT_WRONG'}),
        variant: code === 200 ? 'success' : 'danger',
      })

      // Validación del mensaje y llamada a la función limpiarYRecargar
      if (msg === 'Token no válido') {
        sessionExpired(intl.formatMessage({id: 'GLOBAL.ALERT.SESSION_EXPIRED'}))
      }

      setTimeout(() => {
        if (code === 200) {
          setAlertInfo({
            text: '',
            variant: '',
          })
          getSellersRegisters()
          closeModalForNewSeller()
          resetForm()
        }
      }, 3000)
    } catch (error) {
      setAlertInfo({
        text: intl.formatMessage({id: 'GLOBAL.ALERT_ERROR_AXIOS_REQUEST'}),
        variant: 'danger',
      })
    }
  }

  const getProductsRegisters = async () => {
    try {
      const {data: jsonData, code, msg} = await productsListByClient(userToken, clientId)
      const productsListReceived = jsonData.data

      if (code === 401 && msg === 'Token no válido') {
        sessionExpired(intl.formatMessage({id: 'GLOBAL.ALERT.SESSION_EXPIRED'}))
        return
      } else {
        const userTokenNew = await jsonData.token

        // console.log('actualiza token productsList (brands): ', userTokenNew)
        updateToken(userTokenNew)

        // console.log({productsListReceived});

        const productsSwitches = productsListReceived.map((product: ProductsForBrand) => {
          // console.log(productsListReceived)
          return {
            pro_id: product.pro_id,
            name: product.name,
          }
        })

        setBrandProductsList(productsSwitches)
      }
    } catch (error) {
      console.log('error de productos ', error)
    }
  }

  //Función para abrir el modal de Logs
  const handleOpenModalForSellersLogs = async (seller) => {
    setSellerData(seller)
    setShowModalUserLogs(true)
    console.log(seller)
  }

  //Función para cerrar el modal de Logs
  const handleCloseModalForSellerLogs = async () => {
    setShowModalUserLogs(false)
  }

  //Función para abrir el modal del resumen de actividad
  const handleOpenModalForSellersResumeActivity = async (seller) => {
    setShowModalActivityResume(true)
    console.log(seller)
  }

  //Función para cerrar el modal del resumen de actividad
  const handleFalseModalForSellersResumeActivity = async () => {
    setShowModalActivityResume(false)
  }

  // Obtener datos de vendedores al montar el componente
  useEffect(() => {
    if (!showModal) {
      setAlertInfo({
        text: '',
        variant: '',
      })
    }

    getSellersRegisters()
  }, [userToken, clientId, showModal]) // Ejecutar al montar el componente y cada vez que userToken o clientId cambien

  const columns = React.useMemo<ColumnDef<UserData, any>[]>(
    () => [
      {
        header: intl.formatMessage({id: 'GLOBAL.DATA'}),
        footer: (props) => props.column.id,
        columns: [
          {
            accessorKey: 'id',
            cell: (info) => (
              <OverlayTrigger placement='top' overlay={renderTooltip}>
                <button className='btn btn-light-primary'>{info.getValue()}</button>
              </OverlayTrigger>
            ),
            header: () => <span>Id</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'name',
            cell: (info) => info.getValue(),
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.NAME'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'usr_phone', // Nueva clave de acceso para la combinación de teléfono y WhatsApp
            cell: (info) => (
              <div>
                <p>{info.row.original.usr_phone}</p>
                <p>{info.row.original.wha_phone}</p>
              </div> // Mostrar teléfono y WhatsApp separados por /
            ),
            filterValue: (rows, id, filterValue) => {
              // Lógica de filtrado por teléfono
              return rows.filter((row) => row.original.usr_phone.includes(filterValue))
            },
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.PHONE'})} | Whatsapp</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'user_type',
            id: 'user_type',
            cell: (info) => info.getValue(),
            header: () => <span>{intl.formatMessage({id: 'CONTROL_SELLERS.USER_TYPE'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'email',
            id: 'email',
            cell: (info) => info.getValue(),
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.MAIL'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'status',
            id: 'Estatus',
            cell: ({row}) => (
              <button className={getStatusButtonClass(row.original.status)}>
                {row.original.status}
              </button>
            ),
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.STATUS'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'actions',
            cell: (info) => {
                console.log(info. row.original); // Aquí está el console.log
              return (
                <div className='d-flex align-items-center flex-column'>
                  <div className='mb-2'>
                    <DropdownButton
                      size='sm'
                      id={`dropdown-${info.row.original.id}`}
                      title={intl.formatMessage({id: 'GLOBAL.ACTIONS'})}
                    >
                      <Dropdown.Item onClick={() => handleEdit(info.row.original)} disabled={!canAccessFeature(DRG_STANDAR_MODULES.USERS.value, type, 3)}>
                        {intl.formatMessage({id: 'CONTROL_SELLERS.EDIT_USER'})}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => handleOpenModalForSellerStatus(info.row.original)}
                        disabled={!canAccessFeature(DRG_STANDAR_MODULES.USERS.value, type, 3)}
                      >
                        {intl.formatMessage({id: 'CONTROL_SELLERS.USER_STATUS_CHANGE'})}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => handleOpenModalForSellersLogs(info.row.original)}
                      >
                        {intl.formatMessage({id: 'LEADS.HISTORY'})}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => handleOpenModalForSellersResumeActivity(info.row.original)}
                      >
                        {intl.formatMessage({id: 'CONTROL_SELLERS.ACTIVITY_RESUME'})}
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>

                  <DropdownButton
                    size='sm'
                    id={`dropdown-${info.row.original.id}-`}
                    title={intl.formatMessage({id: 'GLOBAL.DATA_EXPORT'})}
                  >
                    <DropdownItemExportXLSX title={info.row.original.name} dataToExport={info.row.original}/>
                    <DropdownItemExportCSV title={info.row.original.name} dataToExport={info.row.original}/>
                    <DropdownItemExportJSON title={info.row.original.name} dataToExport={info.row.original}/>
                  </DropdownButton>
                </div>
              )
            },
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.ACTIONS'})}</span>,
            footer: (props) => props.column.id,
          },
        ],
      },
    ],
    []
  )
  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  })

  React.useEffect(() => {
    if (table.getState().columnFilters[1]?.id === 'name') {
      if (table.getState().sorting[0]?.id !== 'name' || table.getState().sorting[0]?.desc) {
        table.setSorting([{id: 'name', desc: false}])
      }
    }
  }, [table.getState().columnFilters[1]?.id])

  const handleSetData = () => {
    const dataToExport = table.getRowModel().flatRows.map((row) => row.original)
    setDataToExport(dataToExport)
    console.log(dataToExport);
  }

  return (
    <div className='p-2'>
      <div className='card-header pt-6 pb-6'>
        <div className='d-flex justify-content-between'>
          <DebouncedInput
            value={globalFilter ?? ''}
            onChange={(value) => setGlobalFilter(String(value))}
            className='form-control form-control-solid w-250px ps-14'
            placeholder={intl.formatMessage({id: 'CONTROL_SELLERS.SEARCH_SELLER'})}
          />

          <button
            type='button'
            className='btn btn-primary d-flex align-items-center me-3'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
            onClick={handleSetData}
          >
            <KTIcon iconType='outline' iconName='file-down' className='fs-2 me-2' />{' '}
            <span>{intl.formatMessage({id: 'GLOBAL.DATA_EXPORT'})}</span>
          </button>

          <DropdownExport dataToExport={dataToExport} title={'SellersData'} />
        </div>

        <div>
          {/* begin::Add user */}
          <button type='button' className='btn btn-primary me-3' onClick={openModalForNewSeller} disabled={!canAccessFeature(DRG_STANDAR_MODULES.USERS.value, type, 1)}>
            <KTIcon iconName='plus' className='fs-2' />
            {intl.formatMessage({id: 'CONTROL_SELLERS.ADD_SELLER'})}
          </button>
          {/* end::Add user */}
        </div>
      </div>

      <div className='h-2' />

      <div className='card-body table-responsive'>
        <table className='table table-row-bordered gs-3 gy-4 gx-12'>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'
              >
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <>
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: ' 🔼',
                              desc: ' 🔽',
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                          {header.column.getCanFilter() ? (
                            <div>
                              <Filter column={header.column} table={table} />
                            </div>
                          ) : null}
                        </>
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      <div className='h-2' />

      <div className='card-footer'>
        <div className='d-flex justify-content-between gap-2'>
          <div id='SwitchPages'>
            <button
              className='btn btn-primary border rounded p-1'
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              {'<<'}
            </button>
            <button
              className='btn btn-primary border rounded p-1'
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {'<'}
            </button>
            <button
              className='btn btn-primary border rounded p-1'
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              {'>'}
            </button>
            <button
              className='btn btn-primary border rounded p-1'
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              {'>>'}
            </button>
          </div>

          <div id='PagesFinder' className='text-center'>
            {' '}
            <span className='flex items-center gap-1'>
              {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_PAGE'})}{' '}
              <strong>
                {table.getState().pagination.pageIndex + 1} {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_PAGE_OF'})} {table.getPageCount()}
              </strong>{' '}
              | {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_GO_TO_PAGE'})}:
              <input
                type='number'
                defaultValue={table.getState().pagination.pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  table.setPageIndex(page)
                }}
                className='form-control form-control-solid w-16'
              />
            </span>
          </div>

          <div id='showFiles' className='text-center'>
            <select
              className='form-select form-select-solid'
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_SHOW_PART_ONE'})} {pageSize}{' '}
                  {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_SHOW_PART_TWO'})}
                </option>
              ))}
            </select>
            <p>
              {table.getPrePaginationRowModel().rows.length}{' '}
              {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_TOTAL_RECORDS'})}
            </p>
          </div>
        </div>
      </div>

      {/* <div>
        <button onClick={() => rerender()}>Force Rerender</button>
      </div> */}
      {/* <pre>{JSON.stringify(table.getState(), null, 2)}</pre>  Aquí se pone toda la información de las propiedades de la tabla*/}

      {/* Modal para agregar vendedor */}
      <Modal
        show={showModal2}
        onHide={closeModalForNewSeller}
        scrollable
        centered
        className='modal-lg'
      >
        <Modal.Header closeButton onClick={resetForm}>
          <Modal.Title>
            <h1>{intl.formatMessage({id: 'CONTROL_SELLERS.ADD_SELLER'})}</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SellerAddModalForm
            formData={formData}
            setFormData={setFormData}
            errors={errors}
            brandProductsList={brandProductsList}
          />
        </Modal.Body>
        {alertInfo.text && (
          <div
            className={`alert alert-${alertInfo.variant} d-flex align-items-center`}
            role='alert'
          >
            <div>{alertInfo.text}</div>
          </div>
        )}
        <Modal.Footer>
          <button
            className='btn btn-sm btn-secondary'
            onClick={() => {
              closeModalForNewSeller()
              resetForm()
            }}
          >
            {intl.formatMessage({id: 'GLOBAL.CANCEL'})}
          </button>

          <button className='btn btn-sm btn-primary' type='submit' onClick={handleFormNewSubmit}>
          {intl.formatMessage({id: 'GLOBAL.SAVE'})}
          </button>
        </Modal.Footer>
      </Modal>

      {/* Modal para editar vendedor */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        scrollable
        centered
        className='modal-lg'
      >
        <Modal.Header>
          <Modal.Title>
            <h1>{intl.formatMessage({id: 'CONTROL_SELLERS.EDIT_SELLER'})}</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Pasamos los datos del vendedor al formulario modal */}
          <SellerEditModalForm
            formData={formData}
            setFormData={setFormData}
            sellerData={selectedSeller}
            errors={errors}
          />
          {alertInfo.text && (
            <div
              className={`alert alert-${alertInfo.variant} d-flex align-items-center`}
              role='alert'
            >
              <div>{alertInfo.text}</div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-sm btn-secondary' onClick={closeModal}>
          {intl.formatMessage({id: 'GLOBAL.CANCEL'})}
          </button>
          <button className='btn btn-sm btn-primary' type='submit' onClick={handleFormEditSubmit}>
          {intl.formatMessage({id: 'GLOBAL.SAVE'})}
          </button>
        </Modal.Footer>
      </Modal>

      {/* Modal del cambio del status */}
      <Modal
        show={showModalUserStatus}
        onHide={handleCloseModalForSellerStatus}
        scrollable
        centered
        className='modal-lg'
      >
        <Modal.Header closeButton onClick={handleCloseModalForSellerStatus}>
          <Modal.Title>
            <h1>{intl.formatMessage({id: 'CONTROL_SELLERS.SELLER_STATUS_CHANGE'})}</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{Body}</Modal.Body>
        {alertInfo.text && (
          <div
            className={`alert alert-${alertInfo.variant} d-flex align-items-center`}
            role='alert'
          >
            <div>{alertInfo.text}</div>
          </div>
        )}
        <Modal.Footer>
          <button className='btn btn-sm btn-secondary' onClick={handleCloseModalForSellerStatus}>
          {intl.formatMessage({id: 'GLOBAL.CANCEL'})}
          </button>

          <button
            className='btn btn-sm btn-primary'
            type='submit'
            onClick={() => handleChangeSellerStatus(SellerData)}
          >
            {intl.formatMessage({id: 'GLOBAL.SAVE'})}
          </button>
        </Modal.Footer>
      </Modal>

      {/* Modal del historial */}
      <Modal
        show={showModalUserLogs}
        onHide={handleCloseModalForSellerLogs}
        scrollable
        centered
        className='modal-xl'
      >
        <Modal.Header closeButton onClick={handleCloseModalForSellerLogs}>
          <Modal.Title>
            <h1>{intl.formatMessage({id: 'LEADS.HISTORY'})}</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SellersHistoryTable SellerData={SellerData} />
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-sm btn-primary' onClick={handleCloseModalForSellerLogs}>
            Cerrar
          </button>
        </Modal.Footer>
      </Modal>

      {/* Modal del resumen de actividad */}
      <Modal
        show={showModalActivityResume}
        onHide={handleFalseModalForSellersResumeActivity}
        scrollable
        centered
        className='modal-xl'
      >
        <Modal.Header closeButton onClick={handleFalseModalForSellersResumeActivity}>
          <Modal.Title>
            <h1>{intl.formatMessage({id: 'CONTROL_SELLERS.ACTIVITY_RESUME'})}</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className='card-body px-0'>
              <div className='row'>
                <div className='col-12'>
                  <div className='row'>
                    <div className='col-3 border border-dark rounded mx-10 '>
                      <div className='d-flex justify-content-between flex-column pt-4 h-100'>
                        <div className='pb-5'>
                          <div className='d-flex flex-column flex-center mb-4'>
                            <div className='font-weight-bold text-dark font-size-sm pb-2'>
                            {intl.formatMessage({id: 'CONTROL_SELLERS.STATISTICS_OF'})}:
                            </div>
                            <span className='card-title font-weight-bolder font-size-h4 m-0 pb-1'>
                              <h5>{intl.formatMessage({id: 'AUTH.INPUT.USERNAME'})}</h5>
                            </span>
                          </div>
                          <div className='pt-1 pl-8'>
                            <div className='d-flex align-items-center pb-9'>
                              <div className='symbol symbol-45 symbol-success mr-2 border border-dark'>
                                <span className='symbol-label'>
                                  <span id='TotalLeads-tag'>0</span>
                                </span>
                              </div>
                              <div className='d-flex flex-column flex-grow-1 mx-2'>
                                <span className='mb-1 fw-bold'>{intl.formatMessage({id: 'CONTROL_SELLERS.ASSIGNED_LEADS'})}</span>
                              </div>
                            </div>

                            <div className='d-flex align-items-center pb-9'>
                              <div className='symbol symbol-45 symbol-success mr-2 border border-dark'>
                                <span className='symbol-label'>
                                  <span id='TotalLeads-tag'>0</span>
                                </span>
                              </div>
                              <div className='d-flex flex-column flex-grow-1 mx-2'>
                                <span className='mb-1 fw-bold text-danger'>{intl.formatMessage({id: 'CONTROL_SELLERS.REPEATED_LEADS'})}</span>
                              </div>
                            </div>

                            <div className='d-flex align-items-center pb-9'>
                              <div className='symbol symbol-45 symbol-success mr-2 border border-dark'>
                                <span className='symbol-label'>
                                  <span id='TotalLeads-tag'>0</span>
                                </span>
                              </div>
                              <div className='d-flex flex-column flex-grow-1 mx-2'>
                                <span className='fw-bold'>{intl.formatMessage({id: 'GLOBAL.CALLS'})}</span>
                                <span className='text-success fw-medium'>{intl.formatMessage({id: 'CONTROL_SELLERS.ANSWERED_CALLS'})}</span>
                              </div>
                            </div>

                            <div className='d-flex align-items-center pb-9'>
                              <div className='symbol symbol-45 symbol-success mr-2 border border-dark'>
                                <span className='symbol-label'>
                                  <span id='TotalLeads-tag'>0</span>
                                </span>
                              </div>
                              <div className='d-flex flex-column flex-grow-1 mx-2'>
                                <span className='fw-bold'>{intl.formatMessage({id: 'GLOBAL.CALLS'})}</span>
                                <span className='text-warning fw-medium'>{intl.formatMessage({id: 'CONTROL_SELLERS.UNANSWERED_CALLS'})}</span>
                              </div>
                            </div>

                            <div className='d-flex align-items-center pb-9'>
                              <div className='symbol symbol-45 symbol-success mr-2 border border-dark'>
                                <span className='symbol-label'>
                                  <span id='TotalLeads-tag'>0</span>
                                </span>
                              </div>
                              <div className='d-flex flex-column flex-grow-1 mx-2'>
                                <span className='fw-bold'>{intl.formatMessage({id: 'CONTROL_SELLERS.AVERAGE_SELLER_SCORE'})}</span>
                              </div>
                            </div>

                            <div className='d-flex align-items-center pb-9'>
                              <div className='symbol symbol-45 symbol-success mr-2 border border-dark'>
                                <span className='symbol-label'>
                                  <span id='TotalLeads-tag'>0</span>
                                </span>
                              </div>
                              <div className='d-flex flex-column flex-grow-1 mx-2'>
                                <span className='fw-bold'>{intl.formatMessage({id: 'CONTROL_SELLERS.AVERAGE_ISCORE'})}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-8 border border-dark rounded p-10'>
                      <div className='row mb-6'>
                        <div className='col-md-7'>
                          <h3 className='card-label pt-2'>{intl.formatMessage({id: 'CONTROL_SELLERS.LEADS_RECEIVED_BY_CHANNEL'})}</h3>
                        </div>
                        <div className='col-md-5 d-flex flex-row-reverse'>
                          <div className='form-group'>
                            <div className='input-group'>
                              <div className='input-group-prepend'>
                                <span className='input-group-text'>{intl.formatMessage({id: 'GLOBAL.DATES'})}</span>
                              </div>
                              <button
                                type='button'
                                className='btn btn-outline-secondary'
                                id='sellerdate-btn-graph'
                              ></button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id='SellerGraph'>
                        <ChartsWidgetCustom
                          className='card-xxl-stretch mb-5 mb-xl-10'
                          title='Leads'
                          subtitle=''
                          type='area'
                          dataA={data1}
                          dataB={data1}
                        />
                      </div>
                    </div>

                    <div className='col-12 p-10' id='SellerHistory'>
                    <ChartsWidgetCustom
                          className='border border-dark'
                          title={intl.formatMessage({id: 'CONTROL_SELLERS.LEADS_HISTORY'})}
                          subtitle={intl.formatMessage({id: 'CONTROL_SELLERS.LEADS_HISTORY_SUBTITLE'})}
                          type='bar'
                          dataA={data2}
                          dataB={data2}
                        />
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        {alertInfo.text && (
          <div
            className={`alert alert-${alertInfo.variant} d-flex align-items-center`}
            role='alert'
          >
            <div>{alertInfo.text}</div>
          </div>
        )}
        <Modal.Footer>
          <button
            className='btn btn-sm btn-secondary'
            onClick={handleFalseModalForSellersResumeActivity}
          >
            {intl.formatMessage({id: 'GLOBAL.CLOSE'})}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

function Filter({column, table}: {column: Column<any, unknown>; table: Table<any>}) {
  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id)
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()
  const columnFilterValue = column.getFilterValue()

  const sortedUniqueValues = React.useMemo(
    () =>
      typeof firstValue === 'number'
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  )

  return typeof firstValue === 'number' ? (
    <div>
      <div className='flex space-x-2'>
        <DebouncedInput
          type='number'
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[0] ?? ''}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [value, old?.[1]])}
          placeholder={`Min ${
            column.getFacetedMinMaxValues()?.[0] ? `(${column.getFacetedMinMaxValues()?.[0]})` : ''
          }`}
          className='w-24 border shadow rounded'
        />
        <DebouncedInput
          type='number'
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[1] ?? ''}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [old?.[0], value])}
          placeholder={`Max ${
            column.getFacetedMinMaxValues()?.[1] ? `(${column.getFacetedMinMaxValues()?.[1]})` : ''
          }`}
          className='w-24 border shadow rounded'
        />
      </div>
      <div className='h-1' />
    </div>
  ) : (
    <>
      <datalist id={column.id + 'list'}>
        {sortedUniqueValues.slice(0, 5000).map((value: any) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <DebouncedInput
        type='text'
        value={(columnFilterValue ?? '') as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`${intl.formatMessage({id: 'GLOBAL.TABLE_SEARCH_THREE_DOTS'})} (${column.getFacetedUniqueValues().size})`}
        className='w-36 border shadow rounded'
        list={column.id + 'list'}
      />
      <div className='h-1' />
    </>
  )
}

// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return <input {...props} value={value} onChange={(e) => setValue(e.target.value)} />
}

