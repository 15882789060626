//Este es el principal

import React from 'react'
import {Link} from 'react-router-dom'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {TwlioTemplatesMainTable} from './tables/TwlioTemplatesMainTable'
import {KTIcon} from '../../../../_metronic/helpers'
import {useIntl} from 'react-intl'

export const TwlioTemplates = () => {
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()
  const renderTooltip = (props) => (
    <Tooltip {...props}>
      {intl.formatMessage({id: 'WHATSAPP.TWLIO.TEMPLATES_ADD_TEMPLATE_MESSAGE'})}
    </Tooltip>
  )
  return (
    <>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='col-md-12'>
            <div className='d-flex flex-wrap flex-stack mb-6'>
              <h1 className='my-2'>{intl.formatMessage({id: 'WHATSAPP.TWLIO.TEMPLATES'})}</h1>
            </div>

            <p>{intl.formatMessage({id: 'WHATSAPP.TWLIO.SERVICES_DESCRIPTION'})}</p>
            <OverlayTrigger placement='bottom' overlay={renderTooltip}>
              <Link to={'/twlio-services/twlio-templates/AddTemplatesForm'}>
                <button className='btn btn-primary mb-3'>
                  {intl.formatMessage({id: 'WHATSAPP.TWLIO.TEMPLATES_NEW_TEMPLATE_MESSAGE'})}
                  <KTIcon iconName='black-right' />
                </button>
              </Link>
            </OverlayTrigger>
            <div className='card card-custom'>
              <TwlioTemplatesMainTable />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
