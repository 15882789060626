// Importamos constantes para realizar la peticion a la API de marcas
import {
  apiDragon,
  DragonMainApi,
  EPProductsListByClient,
  EPProductsListByBrand,
  EPOneProductInfo,
  EPProductsListAssignedByUser,
  EPProductsSharedListByClient,
  EPProductsAssignedListByBrands,
} from '../../../utils/constants/_Constants'

// Importamos funcion para get
import {apiGetDeletePetitions} from '../../../../_metronic/helpers/apis'

// Funcion para obtener el listado de productos por marca
export async function productsListByBrand(userToken: string, brand: string) {
  try {
    const data = await apiGetDeletePetitions(
      apiDragon,
      `${DragonMainApi + EPProductsListByBrand + brand}`,
      {
        /* Los parametros se mandan como undefined
            ya que estos se envian solo en peticiones de eliminacion 'delete' */
        undefined,
      },
      {
        'Content-Type': 'application/json',
        'x-token': userToken,
      },

      'get'
    )

    const jsonDataProductsList = data

    return jsonDataProductsList
  } catch (error) {
    console.error('Error obteniendo el listado de productos: ', error)
    throw error
  }
}

// Funcion para obtener el listado de productos por marca y asignacion a usuario
export async function productsAssignedByUserBrand(userToken: string, brand: string) {
  try {
    const data = await apiGetDeletePetitions(
      apiDragon,
      `${DragonMainApi + EPProductsAssignedListByBrands + brand}`,
      {
        undefined,
      },
      {
        'Content-Type': 'application/json',
        'x-token': userToken,
      },

      'get'
    )

    const jsonDataProductsAsignedList = data
    return jsonDataProductsAsignedList
  } catch (error) {
    console.error('Error obteniendo el listado de productos: ', error)
    throw error
  }
}

// Funcion para obtener listado de productos por cliente
export async function productsListByClient(userToken: string, clientId: string) {
  try {
    const data = await apiGetDeletePetitions(
      apiDragon,
      `${DragonMainApi + EPProductsListByClient + clientId}`,
      {
        /* Los parametros se mandan como undefined
            ya que estos se envian solo en peticiones de eliminacion 'delete' */
        undefined,
      },
      {
        'Content-Type': 'application/json',
        'x-token': userToken,
      },

      'get'
    )

    const jsonDataProductsList = data

    return jsonDataProductsList
  } catch (error) {
    console.error('Error obteniendo el listado de productos: ', error)
    throw error
  }
}

// Funcion para obtener listado de productos compartidos a un usuario desde otro cliente
export async function productsSharedListByClient(userToken: string, clientId: string) {
  try {
    const data = await apiGetDeletePetitions(
      apiDragon,
      `${DragonMainApi + EPProductsSharedListByClient + clientId}`,
      {
        undefined,
      },

      {
        'Content-Type': 'application/json',
        'x-token': userToken,
      },
      'get'
    )

    const jsonDataProductsList = data
    return jsonDataProductsList
  } catch (error) {
    console.error('Error obteniendo el listado de productos: ', error)
    throw error
  }
}

// Funcion para obtener listado de productos asignados a un usuario
export async function productsListAssignedByUser(userToken: string) {
  try {
    const data = await apiGetDeletePetitions(
      apiDragon,
      `${DragonMainApi + EPProductsListAssignedByUser}`,
      {
        undefined,
      },
      {
        'Content-Type': 'application/json',
        'x-token': userToken,
      },
      'get'
    )

    const jsonDataProductsListAssignedData = data

    return jsonDataProductsListAssignedData
  } catch (error) {
    console.error('Error obteniendo el listado de productos: ', error)
    throw error
  }
}

// Funcion para obtener informacion de un producto por su id
export async function productInfoById(userToken: string, productId: string) {
  try {
    const data = await apiGetDeletePetitions(
      apiDragon,
      `${DragonMainApi + EPOneProductInfo + productId}`,
      {
        /* Los parametros se mandan como undefined
            ya que estos se envian solo en éticiones de eliminacion 'delete' */
        undefined,
      },
      {
        'Content-Type': 'application/json',
        'x-token': userToken,
      },

      'get'
    )

    const jsonDataOneProduct = data

    return jsonDataOneProduct
  } catch (error) {
    console.error('Error obteniendo la informacion del producto: ', error)
    throw error
  }
}
