import React, {FC, useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {DropdownExportLogs} from '../../../../../../_metronic/partials/content/dropdown/DropdownExportLogs'
import {
  Column,
  Table,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  FilterFn,
  ColumnDef,
  flexRender,
} from '@tanstack/react-table'
import {RankingInfo, rankItem} from '@tanstack/match-sorter-utils'
import {Button, Dropdown, DropdownButton, Modal, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {KTIcon} from '../../../../../../_metronic/helpers'
import {Lead} from '../interfaces/qaReportTypes'
import {LoadingAnimation} from '../../../../../utils/components/loading/LoadingAnimation'
import {useIntl} from 'react-intl'

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>
  }
  interface FilterMeta {
    itemRank: RankingInfo
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)

  // Store the itemRank info
  addMeta({
    itemRank,
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

type Props = {
  cardSelected: number | undefined
}

export const QaReportGeneralTable: FC<Props> = ({cardSelected}) => {
  console.log('renderizamos componente tabla')

  const intl = useIntl()

  const handleCopyToClipboard = (text: string) => {
    // Intentar copiar el texto al portapapeles
    navigator.clipboard.writeText(text).catch((error) => {
      console.error('Error al copiar al portapapeles:', error)
      alert('Error al copiar al portapapeles. Por favor, inténtelo de nuevo.')
    })
  }

  const renderTooltip = (props) => <Tooltip {...props}>Copiar id del registro</Tooltip>

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = useState('')
  const [dataToExport, setDataToExport] = useState({})

  const [leadsList, setLeadsList] = useState<Lead[]>([])

  //Constante para el manejo de estado de la animacion de carga
  const [isLoading, setIsLoading] = useState<boolean>(true)
  //Constante para el manejo de estado del mensaje post carga de registros
  const [infoMessageLoading, setInfoMessageLoading] = useState<string>('')

  //Función para definir la data que se va a exportar
  const handleSetData = () => {
    const dataToExport = table.getRowModel().flatRows.map((row) => row.original)
    setDataToExport(dataToExport)
  }

  //Funcion para navegar a la apgina de detalle de lead pasando el objeto lead
  const navigate = useNavigate()
  const toLeadDetail = (lead) => {
    navigate('/lead-detail/', {state: lead})
  }

  const checkLeadsListLength = () => {
    leadsList.length === 0 && setInfoMessageLoading(intl.formatMessage({id: 'LEADS.INFO_LEAD_NOT_FOUND'}))
  }

  const getLeadsList = () => {
    setTimeout(() => {
      try {
        const newLeadsList = [
          {
            leadId: '234547',
            leadOriginal: '',
            dateAdd: '2024-04-30',
            productId: 'POLL-6723-MC',
            productName: 'I.T.',
            campaignId: 'CMA-50355-LO',
            campaignName: 'Referenciado',
            dcfs: ['dcf3', 'dcf4', 'dcf5'],
            leadData: {
              leadName: 'Pedro Higuain Gustavo',
              leadMail: 'pehigu@gmail.com',
              leadPhone: '5500110011',
            },
            leadComments: 'Llamada desde Angelopolis Puebla',
            region: 'PUEBLA',
            sellerId: 'API-324-NJD',
            sellerName: 'Jelsy Saul',
            assignType: 'Directa',
            leadGrade: '',
            leadGradeComment: '',
            leadIscore: 4,
            mailFlag: true,
            callFlag: false,
            whatsappFlag: true,
            qaflag: 1,
            crmFlag: true,
            sellerIscore: 4,
            medium: 'Offline',
            leadEndpoint: 'Zoho',
          },
          {
            leadId: '234546',
            leadOriginal: '',
            dateAdd: '2024-04-30',
            productId: 'POLL-9845-MC',
            productName: 'MKT',
            campaignId: 'CMA-43534-LO',
            campaignName: 'Pruebas MKT',
            dcfs: ['dcf1', 'dcf2'],
            leadData: {
              leadName: 'Daniela Romo',
              leadMail: 'daro@gmail.com',
              leadPhone: '5597360303',
            },
            leadComments: 'Llamada desde San pedro',
            region: 'MONTERREY',
            sellerId: 'API-867-NJD',
            sellerName: 'Mauricio Milstein',
            assignType: 'Directa',
            leadGrade: '',
            leadGradeComment: '',
            leadIscore: 12,
            mailFlag: true,
            callFlag: true,
            whatsappFlag: true,
            qaflag: 1,
            crmFlag: true,
            sellerIscore: 15,
            medium: 'Online',
            leadEndpoint: 'MailChimp',
          },
          {
            leadId: '234544',
            leadOriginal: '',
            dateAdd: '2024-04-24',
            productId: 'POLL-9845-MC',
            productName: 'MKT',
            campaignId: 'CMA-43534-LO',
            campaignName: 'Pruebas MKT',
            dcfs: ['dcf1', 'dcf2'],
            leadData: {
              leadName: 'Pedro fuentes',
              leadMail: 'pedrito@gmail.com',
              leadPhone: '5593930303',
            },
            leadComments: 'Llamada desde iztacalco CDMX',
            region: 'CDMX',
            sellerId: 'API-867-NJD',
            sellerName: 'Mauricio Milstein',
            assignType: 'Directa',
            leadGrade: '',
            leadGradeComment: '',
            leadIscore: 7,
            mailFlag: true,
            callFlag: true,
            whatsappFlag: true,
            qaflag: 2,
            crmFlag: true,
            sellerIscore: 5,
            medium: 'Online',
            leadEndpoint: 'MailChimp',
          },
          {
            leadId: '234543',
            leadOriginal: '',
            dateAdd: '2024-04-23',
            productId: 'POLL-6723-MC',
            productName: 'I.T.',
            campaignId: 'CMA-50355-LO',
            campaignName: 'Gallardetes',
            dcfs: ['dcf3', 'dcf4', 'dcf5'],
            leadData: {
              leadName: 'Daniela Gonzalez',
              leadMail: 'dangon@gmail.com',
              leadPhone: '5595464403',
            },
            leadComments: 'Llamada desde Pantitlan CDMX',
            region: 'CDMX',
            sellerId: 'API-324-NJD',
            sellerName: 'Jelsy Saul',
            assignType: 'Directa',
            leadGrade: '',
            leadGradeComment: '',
            leadIscore: 7,
            mailFlag: true,
            callFlag: false,
            whatsappFlag: true,
            qaflag: 2,
            crmFlag: true,
            sellerIscore: 5,
            medium: 'Offline',
            leadEndpoint: 'Zoho',
          },
          {
            leadId: '234542',
            leadOriginal: '',
            dateAdd: '2024-04-19',
            productId: 'POLL-2368-MC',
            productName: 'I.T.',
            campaignId: 'CMA-50355-LO',
            campaignName: 'LeadGen Facebook',
            dcfs: ['dcf3', 'dcf4', 'dcf5'],
            leadData: {
              leadName: 'Armando Díaz',
              leadMail: 'ardiaz@gmail.com',
              leadPhone: '557535555',
            },
            leadComments: 'Llamada desde Zapopan',
            region: 'GUADALAJARA ',
            sellerId: 'API-324-NJD',
            sellerName: 'Jelsy Saul',
            assignType: 'Directa',
            leadGrade: '',
            leadGradeComment: '',
            leadIscore: 7,
            mailFlag: true,
            callFlag: false,
            whatsappFlag: true,
            qaflag: 3,
            crmFlag: false,
            sellerIscore: 6,
            medium: 'Offline',
            leadEndpoint: 'Zoho',
          },
          {
            leadId: '234539',
            leadOriginal: '',
            dateAdd: '2024-04-19',
            productId: 'POLL-2368-MC',
            productName: 'I.T.',
            campaignId: 'CMA-50355-LO',
            campaignName: 'LeadGen Facebook',
            dcfs: ['dcf3', 'dcf4', 'dcf5'],
            leadData: {
              leadName: 'Luisa garcía',
              leadMail: 'lugar@gmail.com',
              leadPhone: '5510087697',
            },
            leadComments: 'Llamada desde iztacalco CDMX',
            region: 'CDMX ',
            sellerId: 'API-324-NJD',
            sellerName: 'Mauricio Milstein',
            assignType: 'Directa',
            leadGrade: '',
            leadGradeComment: '',
            leadIscore: 7,
            mailFlag: true,
            callFlag: false,
            whatsappFlag: true,
            qaflag: 3,
            crmFlag: false,
            sellerIscore: 6,
            medium: 'Offline',
            leadEndpoint: 'Zappier',
          },
        ]

        if (cardSelected === 1) {
          setLeadsList(newLeadsList)
        } else if (cardSelected === 2) {
          const leadsFiltered = newLeadsList.filter((lead) => lead.qaflag !== 0)
          setLeadsList(leadsFiltered)
        } else if (cardSelected === 3) {
          const leadsFiltered = newLeadsList.filter((lead) => lead.qaflag === 1)
          setLeadsList(leadsFiltered)
        } else if (cardSelected === 4) {
          const leadsFiltered = newLeadsList.filter((lead) => lead.qaflag === 2)
          setLeadsList(leadsFiltered)
        } else if (cardSelected === 5) {
          const leadsFiltered = newLeadsList.filter((lead) => lead.qaflag === 3)
          setLeadsList(leadsFiltered)
        }

        setIsLoading(false)
        checkLeadsListLength()
      } catch (error) {
        console.log('Error obteniendo el listado de leads: ', error)
        setInfoMessageLoading(intl.formatMessage({id: 'GLOBAL.REQUEST_ERROR'}))
      }
    }, 2000)
  }

  useEffect(() => {
    getLeadsList()
  }, [])

  const columns = React.useMemo<ColumnDef<Lead, any>[]>(
    () => [
      {
        header: intl.formatMessage({id: 'REPORTS.QUALITY.REGISTER_LEADS_RECEIVED'}),
        footer: (props) => props.column.id,
        columns: [
          {
            accessorKey: 'leadId',
            cell: (info) => (
              <button
                className='btn btn-light-primary'
                onClick={() => {
                  toLeadDetail(info.row.original)
                }}
              >
                {info.getValue()}
              </button>
            ),
            header: () => <span>Id</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'dateAdd',
            cell: (info) => info.getValue(),
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.DATE'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'leadName',
            cell: (info) => (
              <div>
                <p>{info.row.original.leadData.leadName}</p>
              </div> // Mostrar teléfono y WhatsApp separados por /
            ),
            filterValue: (rows, id, filterValue) => {
              return rows.filter((row) => row.original.leadName.includes(filterValue))
            },
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.NAME'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'leadMail',
            cell: (info) => (
              <div>
                <p>{info.row.original.leadData.leadMail}</p>
              </div> // Mostrar teléfono y WhatsApp separados por /
            ),
            filterValue: (rows, id, filterValue) => {
              return rows.filter((row) => row.original.leadName.includes(filterValue))
            },
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.MAIL'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'leadPhone',
            cell: (info) => (
              <div>
                <p>{info.row.original.leadData.leadPhone}</p>
              </div> // Mostrar teléfono y WhatsApp separados por /
            ),
            filterValue: (rows, id, filterValue) => {
              return rows.filter((row) => row.original.leadName.includes(filterValue))
            },
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.PHONE'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'productName', // Nueva clave de acceso para la combinación de teléfono y WhatsApp
            cell: (info) => (
              <div>
                <p>{info.row.original.productName}</p>
              </div> // Mostrar teléfono y WhatsApp separados por /
            ),
            filterValue: (rows, id, filterValue) => {
              return rows.filter((row) => row.original.productName.includes(filterValue))
            },
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.PRODUCT'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'campaignName', // Nueva clave de acceso para la combinación de teléfono y WhatsApp
            cell: (info) => (
              <div>
                <p>{info.row.original.campaignName}</p>
              </div> // Mostrar teléfono y WhatsApp separados por /
            ),
            filterValue: (rows, id, filterValue) => {
              return rows.filter((row) => row.original.productName.includes(filterValue))
            },
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.CAMPAIGN'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'sellerName',
            cell: (info) => info.getValue(),
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.SELLER'})}</span>,
            footer: (props) => props.column.id,
          },
        ],
      },
    ],
    []
  )

  const table = useReactTable({
    data: leadsList,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  })

  React.useEffect(() => {
    if (table.getState().columnFilters[1]?.id === 'name') {
      if (table.getState().sorting[0]?.id !== 'name' || table.getState().sorting[0]?.desc) {
        table.setSorting([{id: 'name', desc: false}])
      }
    }
  }, [table.getState().columnFilters[1]?.id])

  return (
    <>
      <div className='card card-custom'>
        {isLoading && (
          <div className='py-12'>
            <LoadingAnimation alignment='center' label='Generando tabla de leads...' size='lg' />
          </div>
        )}

        {!isLoading && leadsList.length > 0 && (
          <div className='p-2'>
            <div className='card-header pt-6 pb-6'>
              <div className='d-flex'>
                <DebouncedInput
                  value={globalFilter ?? ''}
                  onChange={(value) => setGlobalFilter(String(value))}
                  className='form-control form-control-solid w-250px ps-14 me-3'
                  placeholder={intl.formatMessage({id: 'GLOBAL.TABLE_SEARCH_RECORD'})}
                />

                <button
                  type='button'
                  className='btn btn-sm btn-primary me-3'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  onClick={handleSetData}
                >
                  <KTIcon iconType='outline' iconName='file-down' className='fs-2 me-2' />{' '}
                  <span>Exportar datos</span>
                </button>

                <DropdownExportLogs dataToExport={dataToExport} title={'Logs'} />
              </div>
            </div>
            <div className='h-2' />
            <div className='card-body table-responsive'>
              <table className='table table-row-bordered gs-3 gy-4 gx-12'>
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr
                      key={headerGroup.id}
                      className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'
                    >
                      {headerGroup.headers.map((header) => {
                        return (
                          <th key={header.id} colSpan={header.colSpan}>
                            {header.isPlaceholder ? null : (
                              <>
                                <div
                                  {...{
                                    className: header.column.getCanSort()
                                      ? 'cursor-pointer select-none'
                                      : '',
                                    onClick: header.column.getToggleSortingHandler(),
                                  }}
                                >
                                  {flexRender(header.column.columnDef.header, header.getContext())}
                                  {{
                                    asc: ' 🔼',
                                    desc: ' 🔽',
                                  }[header.column.getIsSorted() as string] ?? null}
                                </div>
                                {header.column.getCanFilter() ? (
                                  <div>
                                    <Filter column={header.column} table={table} />
                                  </div>
                                ) : null}
                              </>
                            )}
                          </th>
                        )
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row) => {
                    return (
                      <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => {
                          return (
                            <td key={cell.id}>
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>

            <div className='h-2' />

            <div className='card-footer'>
              <div className='d-flex justify-content-between gap-2'>
                <div id='SwitchPages'>
                  <button
                    className='btn btn-primary border rounded p-1'
                    onClick={() => table.setPageIndex(0)}
                    disabled={!table.getCanPreviousPage()}
                  >
                    {'<<'}
                  </button>
                  <button
                    className='btn btn-primary border rounded p-1'
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                  >
                    {'<'}
                  </button>
                  <button
                    className='btn btn-primary border rounded p-1'
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                  >
                    {'>'}
                  </button>
                  <button
                    className='btn btn-primary border rounded p-1'
                    onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                    disabled={!table.getCanNextPage()}
                  >
                    {'>>'}
                  </button>
                </div>

                <div id='PagesFinder' className='text-center'>
                  {' '}
                  <span className='flex items-center gap-1'>
                    {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_PAGE'})}{' '}
                    <strong>
                      {table.getState().pagination.pageIndex + 1}{' '}
                      {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_PAGE_OF'})}{' '}
                      {table.getPageCount()}
                    </strong>{' '}
                    | {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_GO_TO_PAGE'})}:
                    <input
                      type='number'
                      defaultValue={table.getState().pagination.pageIndex + 1}
                      onChange={(e) => {
                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                        table.setPageIndex(page)
                      }}
                      className='form-control form-control-solid w-16'
                    />
                  </span>
                </div>

                <div id='showFiles' className='text-center'>
                  <select
                    className='form-select form-select-solid'
                    value={table.getState().pagination.pageSize}
                    onChange={(e) => {
                      table.setPageSize(Number(e.target.value))
                    }}
                  >
                    {[10, 20, 30, 40, 50].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_SHOW_PART_ONE'})} {pageSize}{' '}
                        {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_SHOW_PART_TWO'})}
                      </option>
                    ))}
                  </select>
                  <p>
                    {table.getPrePaginationRowModel().rows.length}{' '}
                    {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_TOTAL_RECORDS'})}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        {!isLoading && leadsList.length === 0 && (
          <div className='py-12 px-10'>
            <p>{infoMessageLoading}</p>
          </div>
        )}
      </div>
    </>
  )
}

function Filter({column, table}: {column: Column<any, unknown>; table: Table<any>}) {
  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id)
    //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()
  const columnFilterValue = column.getFilterValue()

  const sortedUniqueValues = React.useMemo(
    () =>
      typeof firstValue === 'number'
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  )

  return typeof firstValue === 'number' ? (
    <div>
      <div className='flex space-x-2'>
        <DebouncedInput
          type='number'
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[0] ?? ''}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [value, old?.[1]])}
          placeholder={`Min ${
            column.getFacetedMinMaxValues()?.[0] ? `(${column.getFacetedMinMaxValues()?.[0]})` : ''
          }`}
          className='w-24 border shadow rounded'
        />
        <DebouncedInput
          type='number'
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[1] ?? ''}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [old?.[0], value])}
          placeholder={`Max ${
            column.getFacetedMinMaxValues()?.[1] ? `(${column.getFacetedMinMaxValues()?.[1]})` : ''
          }`}
          className='w-24 border shadow rounded'
        />
      </div>
      <div className='h-1' />
    </div>
  ) : (
    <>
      <datalist id={column.id + 'list'}>
        {sortedUniqueValues.slice(0, 5000).map((value: any) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <DebouncedInput
        type='text'
        value={(columnFilterValue ?? '') as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`${intl.formatMessage({id: 'GGLOBAL.TABLE_SEARCH_THREE_DOTS'})} (${column.getFacetedUniqueValues().size})`}
        className='w-36 border shadow rounded'
        list={column.id + 'list'}
      />
      <div className='h-1' />
    </>
  )
}

// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return <input {...props} value={value} onChange={(e) => setValue(e.target.value)} />
}
