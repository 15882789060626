import React, {useState, useEffect, useContext} from 'react'
import {useIntl} from 'react-intl'
import {sellersListByClient} from '../../sellers/services/_SellersListApi'
import {UserData} from '../../sellers/Interfaces/sellersTypes'
import {UserContext} from '../../../../app-context/user-context/UserContext'
import {sessionExpired} from '../../../../utils/functions/_Functions'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../../../_metronic/helpers'
import {AlertInfo} from '../../../../utils/interfaces/_InterfacesTypes'
import {groupStatusFalse, groupStatusTrue} from '../services/_GroupStatus'

function ModalEditGroup({groupData, setGroupData}) {
  const [sellers, setSellers] = useState<UserData[]>([])
  const {user, token, updateToken} = useContext(UserContext)
  const clientId = user.cli_id
  const userToken = token

  const [showModalStatus, setShowModalStatus] = useState(false)

  const [groupStatus, setGroupStatus] = useState<boolean>(groupData.status)

  const groupId = groupData.id

  const [alertInfo, setAlertInfo] = useState<AlertInfo>({
    text: '',
    variant: 'primary',
  })

  console.log(groupData)

  // Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  useEffect(() => {
    console.log(groupData.idManager)
    const getSellersRegisters = async () => {
      try {
        // Ejecutamos el servicio y obtenemos la data que nos regresa ese servicio
        const {data: jsonData, code, msg} = await sellersListByClient(userToken, clientId)
        if (code === 401 && msg === 'Token no valido') {
          sessionExpired(intl.formatMessage({id: 'GLOBAL.ALERT.SESSION_EXPIRED'}))
          return
        } else {
          const sellersListReceived = jsonData.data
          const userTokenNew = await jsonData.token

          // Actualizar el token
          updateToken(userTokenNew)

          // Establecer los vendedores con el estado según su presencia en groupData.users
          const mappedSellers = sellersListReceived.map((seller) => ({
            id: seller.uid,
            name: seller.usrData.usr_name,
            status: groupData.users.some((user) => user.usr_id === seller.uid),
          }))

          // Establecer los vendedores
          setSellers(mappedSellers)

          console.log(sellers)
        }
      } catch (error) {
        console.error('Error receiving sellers: ', error)
        sessionExpired(intl.formatMessage({id: 'GLOBAL.ALERT.SESSION_EXPIRED'}))
      }
    }

    getSellersRegisters()
  }, [])

  useEffect(() => {
    // Filtramos los vendedores con status true y obtenemos los IDs de los usuarios activos
    const activeUsersIds = sellers.filter((seller) => seller.status).map((seller) => seller.id)

    // Actualizamos groupData.users con los usuarios activos
    setGroupData((prevGroupData) => ({
      ...prevGroupData,
      users: activeUsersIds,
    }))
  }, [sellers, setGroupData])

  const renderSellersRows = () => {
    return sellers.map((seller) => (
      <div key={seller.id} className='fv-row mb-7'>
        <div className='form-check form-switch form-check-custom form-check-solid'>
          <input
            className='form-check-input me-3'
            type='checkbox'
            id={`flexSwitch${seller.id}`}
            checked={seller.status}
            onChange={(e) => handleInputChange(e, seller)}
          />
          <label className='fw-bolder' htmlFor={`flexSwitch${seller.id}`}>
            {seller.name}
          </label>
        </div>
      </div>
    ))
  }

  const handleInputChange = (e, seller) => {
    const {checked} = e.target

    // Actualizamos el estado de seller.status
    seller.status = checked

    // Creamos una copia del arreglo de vendedores
    const updatedSellers = [...sellers]

    // Buscamos el índice del vendedor en el arreglo actualizado
    const index = updatedSellers.findIndex((s) => s.id === seller.id)

    // Si encontramos el vendedor en el arreglo, actualizamos su estado
    if (index !== -1) {
      updatedSellers[index] = seller

      // Filtramos los vendedores con checked true
      const activeUsers = updatedSellers.filter((seller) => seller.status)

      // Actualizamos el estado de los vendedores
      setSellers(updatedSellers)

      // Actualizamos groupData.users con los usuarios activos
      setGroupData((prevGroupData) => ({
        ...prevGroupData,
        users: activeUsers.map((seller) => seller.id),
      }))
    }
  }

  const handleSwitchChange = () => {
    setShowModalStatus(true)
    setGroupStatus(!groupStatus)
  }

  const closeModalStatus = () => {
    setShowModalStatus(false)
    setGroupStatus(!groupStatus)
  }

  const closeModalStatusSuccesfully = () => {
    setShowModalStatus(false)
    setAlertInfo({
      text: '',
      variant: '',
    })
  }

  const confirmStatusChange = async () => {
    if (groupStatus === false) {
      try {
        const {data: jsonData, code, msg} = await groupStatusFalse(userToken, groupId)

        console.log(jsonData)

        if (code === 401 && msg === 'Token no valido') {
          sessionExpired(intl.formatMessage({id: 'GLOBAL.ALERT.SESSION_EXPIRED'}))
        } else {
          console.log(code)
          setAlertInfo({
            text: code === 200 ? intl.formatMessage({id: 'CONTROL_SELLERS.STATUS_EDITED_SUCCESS'}) : intl.formatMessage({id: 'GLOBAL.ALERT_SOMETHING_WENT_WRONG'}),
            variant: code === 200 ? 'success' : 'danger',
          })
          if (code === 200) {
            setTimeout(() => {
              setAlertInfo({
                text: '',
                variant: '',
              })
              closeModalStatusSuccesfully()
            }, 3000)
          }
        }
      } catch (error) {
        console.log()
        setAlertInfo({
          text: intl.formatMessage({id: 'GLOBAL.ALERT_ERROR_AXIOS_REQUEST'}),
          variant: 'danger',
        })
      }
    } else {
      console.log('El status estaba false')
      try {
        const {data: jsonData, code, msg} = await groupStatusTrue(userToken, groupId)

        if (code === 401 && msg === 'Token no valido') {
          sessionExpired(intl.formatMessage({id: 'GLOBAL.ALERT.SESSION_EXPIRED'}))
        } else {
          setAlertInfo({
            text: code === 200 ? intl.formatMessage({id: 'CONTROL_SELLERS.STATUS_EDITED_SUCCESS'}) : intl.formatMessage({id: 'GLOBAL.ALERT_SOMETHING_WENT_WRONG'}),
            variant: code === 200 ? 'success' : 'danger',
          })
          if (code === 200) {
            setTimeout(() => {
              setAlertInfo({
                text: '',
                variant: '',
              })
              closeModalStatusSuccesfully()
            }, 3000)
          }
        }
      } catch (error) {
        setAlertInfo({
          text: intl.formatMessage({id: 'GLOBAL.ALERT_ERROR_AXIOS_REQUEST'}),
          variant: 'danger',
        })
      }
    }
  }

  return (
    <form action='' id='uwu'>
      <div className='fv-row mb-7'>
        <label className='required fw-bold fs-6 mb-2'>
          {intl.formatMessage({id: 'GLOBAL.NAME'})}
        </label>
        <input
          type='text'
          className='form-control form-control-lg form-control-solid'
          value={groupData.name} // Mostrar el nombre del grupo
          onChange={(e) => setGroupData({...groupData, name: e.target.value})} // Manejar cambios en el nombre
        />
      </div>
      <select
        className='form-select form-select-lg form-select-solid'
        onChange={(e) => setGroupData({...groupData, manager: e.target.value})} // Manejar cambios en el manager
      >
        <option value={groupData.manager}>{groupData.managerName}</option>
        {sellers.map((seller) => (
          <option key={seller.id} value={seller.id}>
            {seller.name}
          </option>
        ))}
      </select>
      <div className='mb-2 mt-5'>
        <label className='fw-bold fs-6'>
          {intl.formatMessage({id: 'CONTROL_SELLERS.GROUP_STATUS'})}
        </label>
        <div className='form-check form-switch form-check-custom form-check-solid d-flex align-items-center'>
          <input
            className='form-check-input'
            type='checkbox'
            checked={groupStatus}
            onChange={handleSwitchChange}
          />
        </div>
      </div>

      <Modal show={showModalStatus} className='modal-md'>
        <div className='modal-content border border-warning'>
          <Modal.Header>
            <Modal.Title>
              <h1>{intl.formatMessage({id: 'CONTROL_SELLERS.CONFIRM_STATUS'})}</h1>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='alert alert-primary d-flex align-items-center' role='danger'>
              <KTIcon iconName='information-4' className='fs-4x' />
              <div className='ml-6'>
                {intl.formatMessage({id: 'CONTROL_SELLERS.CONFIRM_STATUS_MESSAGE'})}
              </div>
            </div>
          </Modal.Body>
          {alertInfo.text && (
            <div
              className={`alert alert-${alertInfo.variant} align-items-center mt-3 mx-10 text-center`}
              role='alert'
            >
              <div>{alertInfo.text}</div>
            </div>
          )}
          <Modal.Footer>
            <button className='btn btn-sm btn-primary' onClick={confirmStatusChange}>
              {intl.formatMessage({id: 'GLOBAL.CONFIRM'})}
            </button>
            <button className='btn btn-sm btn-secondary' onClick={closeModalStatus}>
              {intl.formatMessage({id: 'GLOBAL.CANCEL'})}
            </button>
          </Modal.Footer>
        </div>
      </Modal>
      <div className='fv-row mb-7'>
        <label className='required fw-bold fs-6 mb-2'>
          {intl.formatMessage({id: 'CONTROL_SELLERS.ACTIVE_USERS'})}
        </label>
        {renderSellersRows()}
      </div>
    </form>
  )
}

export default ModalEditGroup
