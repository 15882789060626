/* eslint-disable react/jsx-no-target-blank */

import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {UserContext} from '../../../../../app/app-context/user-context/UserContext'
import {useContext} from 'react'
import {KTIcon} from '../../../../helpers'
import {
  DRG_STANDAR_MODULES,
  DRG_USERS_TYPES,
} from '../../../../../app/routing/constants/_Contstants'
import {canAccessFeature} from '../../../../../app/utils/functions/_Functions'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {user, token, updateToken} = useContext(UserContext)
  const userType: string = user.user_type

  const dataFromContext = useContext(UserContext)
  const clientName = dataFromContext.user.cli_name

  return (
    <>
      {/* OPCION DASHBOARD */}
      {canAccessFeature(DRG_STANDAR_MODULES.DASHBOARD.value, userType, 1) ||
      canAccessFeature(DRG_STANDAR_MODULES.DASHBOARD.value, userType, 2) ||
      canAccessFeature(DRG_STANDAR_MODULES.DASHBOARD.value, userType, 3) ||
      canAccessFeature(DRG_STANDAR_MODULES.DASHBOARD.value, userType, 4) ? (
        <SidebarMenuItem
          to='/dashboard'
          icon='abstract-28'
          title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          fontIcon='bi-app-indicator'
        />
      ) : null}

      {/* OPCION SOPORTE */}
      {canAccessFeature(DRG_STANDAR_MODULES.HELP_CENTER.value, userType, 1) ||
      canAccessFeature(DRG_STANDAR_MODULES.HELP_CENTER.value, userType, 2) ||
      canAccessFeature(DRG_STANDAR_MODULES.HELP_CENTER.value, userType, 3) ||
      canAccessFeature(DRG_STANDAR_MODULES.HELP_CENTER.value, userType, 4) ? (
        <SidebarMenuItemWithSub
          to='/crafted/'
          title='Ayuda'
          icon='information-2'
          fontIcon='bi-info-circle'
        >
          <SidebarMenuItem
            to='/support'
            hasBullet={true}
            title={intl.formatMessage({id: 'MENU.SUPPORT'})}
          />
          <SidebarMenuItem to='/faq' hasBullet={true} title={'Faq'} />
          <SidebarMenuItem to='/manual' hasBullet={true} title={'manual'} />
        </SidebarMenuItemWithSub>
      ) : null}

      {/*OPCION ACCOUNT MANAGEMENT */}
      {canAccessFeature(DRG_STANDAR_MODULES.ACCOUNT_MANAGEMENT.value, userType, 1) ||
      canAccessFeature(DRG_STANDAR_MODULES.ACCOUNT_MANAGEMENT.value, userType, 2) ||
      canAccessFeature(DRG_STANDAR_MODULES.ACCOUNT_MANAGEMENT.value, userType, 3) ||
      canAccessFeature(DRG_STANDAR_MODULES.ACCOUNT_MANAGEMENT.value, userType, 4) ? (
        <SidebarMenuItem
          to='/account-management'
          icon='setting-4'
          title={intl.formatMessage({id: 'ACCOUNT_MANAGEMENT'})}
        />
      ) : null}

      <SidebarMenuItemWithSub
        to='/crafted/'
        title='WhatsApp'
        icon='whatsapp'
        fontIcon='bi-whatsapp'
      >
        <SidebarMenuItem
          to='/twlio-services/twlio-services'
          hasBullet={true}
          title={intl.formatMessage({id: 'MENU.SERVICES'})}
        />
        <SidebarMenuItem
          to='/twlio-services/twlio-templates'
          hasBullet={true}
          title={intl.formatMessage({id: 'MENU.TEMPLATES'})}
        />
      </SidebarMenuItemWithSub>

      {/* BANNER OPCIONES DEL CLIENTE EN SESION */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted fs-8 ls-1'>{clientName}</span>
        </div>
      </div>

      {/* OPCION LEADS */}
      {canAccessFeature(DRG_STANDAR_MODULES.LEADS.value, userType, 1) ||
      canAccessFeature(DRG_STANDAR_MODULES.LEADS.value, userType, 2) ||
      canAccessFeature(DRG_STANDAR_MODULES.LEADS.value, userType, 3) ||
      canAccessFeature(DRG_STANDAR_MODULES.LEADS.value, userType, 4) ? (
        <SidebarMenuItem
          to='/leads'
          icon='check-circle'
          title={intl.formatMessage({id: 'MENU.LEADS'})}
        />
      ) : null}

      {/* OPCION MARCAS */}
      {canAccessFeature(DRG_STANDAR_MODULES.BRANDS.value, userType, 1) ||
      canAccessFeature(DRG_STANDAR_MODULES.ASSIGNS.value, userType, 2) ||
      canAccessFeature(DRG_STANDAR_MODULES.ASSIGNS.value, userType, 3) ||
      canAccessFeature(DRG_STANDAR_MODULES.ASSIGNS.value, userType, 4) ? (
        <SidebarMenuItem
          to='/brands'
          icon='bookmark'
          title={intl.formatMessage({id: 'MENU.CLIENT.BRANDS'})}
        />
      ) : null}

      {/* OPCION PRODUCTOS */}
      {canAccessFeature(DRG_STANDAR_MODULES.PRODUCTS.value, userType, 1) ||
      canAccessFeature(DRG_STANDAR_MODULES.PRODUCTS.value, userType, 2) ||
      canAccessFeature(DRG_STANDAR_MODULES.PRODUCTS.value, userType, 3) ||
      canAccessFeature(DRG_STANDAR_MODULES.PRODUCTS.value, userType, 4) ? (
        <SidebarMenuItem
          to='/products'
          icon='bookmark-2'
          title={intl.formatMessage({id: 'MENU.CLIENT.PRODUCTS'})}
        />
      ) : null}

      {/* OPCION MENU VENDEDORES */}
      <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title={intl.formatMessage({id: 'MENU.SELLERS_CONTROL'})}
        icon='profile-circle'
        fontIcon='bi-person'
      >
        {canAccessFeature(DRG_STANDAR_MODULES.USERS.value, userType, 1) ||
        canAccessFeature(DRG_STANDAR_MODULES.USERS.value, userType, 2) ||
        canAccessFeature(DRG_STANDAR_MODULES.USERS.value, userType, 3) ||
        canAccessFeature(DRG_STANDAR_MODULES.USERS.value, userType, 4) ? (
          <SidebarMenuItem
            to='/sellers'
            hasBullet={true}
            title={intl.formatMessage({id: 'MENU.CLIENT.SELLERS'})}
          />
        ) : null}
        {canAccessFeature(DRG_STANDAR_MODULES.USERS_GROUPS.value, userType, 1) ||
        canAccessFeature(DRG_STANDAR_MODULES.USERS_GROUPS.value, userType, 2) ||
        canAccessFeature(DRG_STANDAR_MODULES.USERS_GROUPS.value, userType, 3) ||
        canAccessFeature(DRG_STANDAR_MODULES.USERS_GROUPS.value, userType, 4) ? (
          <SidebarMenuItem
            to='/group-sellers'
            hasBullet={true}
            title={intl.formatMessage({id: 'MENU.GROUPS'})}
          />
        ) : null}
      </SidebarMenuItemWithSub>

      {/* OPCION ASIGNACIONES */}
      {canAccessFeature(DRG_STANDAR_MODULES.BRANDS.value, userType, 1) ||
      canAccessFeature(DRG_STANDAR_MODULES.BRANDS.value, userType, 2) ||
      canAccessFeature(DRG_STANDAR_MODULES.BRANDS.value, userType, 3) ||
      canAccessFeature(DRG_STANDAR_MODULES.BRANDS.value, userType, 4) ? (
        <SidebarMenuItem
          to='/assigns'
          icon='switch'
          title={intl.formatMessage({id: 'MENU.CLIENT.ASSIGNS'})}
        />
      ) : null}

      {/* OPCION SHARE ACCESS */}
      {canAccessFeature(DRG_STANDAR_MODULES.SHARE_ACCESS.value, userType, 1) ||
      canAccessFeature(DRG_STANDAR_MODULES.SHARE_ACCESS.value, userType, 2) ||
      canAccessFeature(DRG_STANDAR_MODULES.SHARE_ACCESS.value, userType, 3) ||
      canAccessFeature(DRG_STANDAR_MODULES.SHARE_ACCESS.value, userType, 4) ? (
        <SidebarMenuItem
          to='/share-access'
          icon='share'
          title={intl.formatMessage({id: 'MENU.CLIENT.SHARE_ACCESS'})}
        />
      ) : null}

      {/* OPCION LINKS DCEM */}
      {canAccessFeature(DRG_STANDAR_MODULES.LINK_DCEM.value, userType, 1) ||
      canAccessFeature(DRG_STANDAR_MODULES.LINK_DCEM.value, userType, 2) ||
      canAccessFeature(DRG_STANDAR_MODULES.LINK_DCEM.value, userType, 3) ||
      canAccessFeature(DRG_STANDAR_MODULES.LINK_DCEM.value, userType, 4) ? (
        <SidebarMenuItem
          to='/linksdcem'
          icon='click'
          title={intl.formatMessage({id: 'MENU.MODULES.LINKS_DCEM'})}
          fontIcon='bi-layers'
        />
      ) : null}

      {/* OPCION LOGS */}
      {canAccessFeature(DRG_STANDAR_MODULES.LOGS.value, userType, 1) ||
      canAccessFeature(DRG_STANDAR_MODULES.LOGS.value, userType, 2) ||
      canAccessFeature(DRG_STANDAR_MODULES.LOGS.value, userType, 3) ||
      canAccessFeature(DRG_STANDAR_MODULES.LOGS.value, userType, 4) ? (
        <SidebarMenuItem
          to='/logs'
          icon='notepad-bookmark'
          title={intl.formatMessage({id: 'MENU.CLIENT.LOGS'})}
        />
      ) : null}

      {/* OPCION MENU REPORTES */}
      <SidebarMenuItemWithSub
        to=''
        title={intl.formatMessage({id: 'MENU.REPORTS'})}
        icon='chart-pie-4'
        fontIcon='bi-person'
      >
        {canAccessFeature(DRG_STANDAR_MODULES.REPORTS.value, userType, 2) && (
          <>
            <SidebarMenuItem to='/report-leads' hasBullet={true} title='Leads' />
            <SidebarMenuItem
              to='/grades-report'
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU.GRADES'})}
            />
            <SidebarMenuItem
              to='/quality-report'
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU.QUALITY'})}
            />

            <SidebarMenuItem
              to='/campaigns-report'
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU.CAMPAIGNS'})}
            />

            <SidebarMenuItem to='/iscore-report' hasBullet={true} title='iScore' />
            <SidebarMenuItem
              to='/interactions-report'
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU.INTERACTIONS'})}
            />
            <SidebarMenuItem
              to='/yearly-report'
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU.YEARLY'})}
            />
          </>
        )}
        {canAccessFeature(DRG_STANDAR_MODULES.REPORT_CRM.value, userType, 2) && (
          <SidebarMenuItem to='/crm-report' hasBullet={true} title='CRM' />
        )}
      </SidebarMenuItemWithSub>

      {/* BANNER OPCIONES DE MODULOS PLUS DEL CLIENTE EN SESION */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1 me-2'>
            {intl.formatMessage({id: 'MENU.MODULES'})}
          </span>
          <KTIcon iconName='lock-3' className='fs-1 text-warning' />
        </div>
      </div>

      {/* OPCION CHAT WAB */}
      {canAccessFeature(DRG_STANDAR_MODULES.CHATWAB.value, userType, 1) ||
      canAccessFeature(DRG_STANDAR_MODULES.CHATWAB.value, userType, 2) ||
      canAccessFeature(DRG_STANDAR_MODULES.CHATWAB.value, userType, 3) ||
      canAccessFeature(DRG_STANDAR_MODULES.CHATWAB.value, userType, 4) ? (
        <SidebarMenuItem
          to='/chat-wab'
          icon='message-notif'
          title={intl.formatMessage({id: 'MENU.MODULES.CHAT_WAB'})}
          fontIcon='bi-layers'
        />
      ) : null}

      {/* OPCION BI */}
      <SidebarMenuItem
        to='/bi'
        icon='graph-up'
        title={intl.formatMessage({id: 'MENU.MODULES.BI'})}
        fontIcon='bi-layers'
      />

      {/* OPCION DATES */}
      <SidebarMenuItem
        to='/dates'
        icon='calendar'
        title={intl.formatMessage({id: 'MENU.MODULES.DATES'})}
        fontIcon='calendar'
      />

      {/* OPCION PRE-CRM */}
      <SidebarMenuItem
        to='/pre-CRM'
        icon='bi bi-calendar2-range-fill'
        title='Pre-CRM'
        fontIcon='bi-layers'
      />
      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTIcon iconName='code' className='fs-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}

export {SidebarMenuMain}
