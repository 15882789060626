import axios from 'axios'
import {DragonMainApi,  EPNewPassword} from '../../utils/constants/_Constants'

// const API_DRAGON_URL= 'https://dev-api-main.dcem.io/auth/login'

async function UpdatePassword(token:string, idUser: string, password: string) {
  try {
    const response = await axios.put(DragonMainApi + EPNewPassword, {idUser:idUser, password:password, headers:{
        'Content-Type': 'application/json',
        'x-token': token
      } })
    // Ahora, la respuesta JSON está almacenada en la variable 'response.data'
    const jsonData = response.data
    const jsonStatus = response.status

    // Volvemos una cadena de texto la data devuelta por la api
    const jsonString = JSON.stringify(jsonData)
    console.log(jsonString)

    if (jsonData) {
      //Manejar la respuesta de la api
    }
    return {jsonData, jsonStatus} // Si deseas retornar los datos
  } catch (error) {
    throw error // Puedes manejar el error aquí o propagarlo.
  }
  
}

export {UpdatePassword}
