// Importamos hooks de react
import React, { useEffect, useState } from 'react'
import { formatTextMIddleDash } from '../../../utils/functions/_Functions'
import { useIntl } from 'react-intl'

type Props = {
  switchOn: boolean
  currentClientNameSelected: string
  currentClientIdSelected: string
}

export const ModalActiveUnactiveClient: React.FC<Props> = ({
  switchOn,
  currentClientNameSelected,
  currentClientIdSelected
}) => {
  const intl = useIntl()
  const [Body, setBody] = useState<any>()

  const handleShowBody = () => {
    if (switchOn) {
      setBody(
        <div>
          <div className='alert alert-dismissible bg-light-warning border border-warning mb-4'>
            <div className='d-flex flex-column flex-sm-row p-5'>
              <span className='svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0 text-dark'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-exclamation-triangle-fill'
                  viewBox='0 0 16 16'
                >
                  <path d='M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2' />
                </svg>
              </span>
              <div className='d-flex flex-column text-primary pe-0 pe-sm-10'>
                <h5 className='mb-1'>{intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.DEACTIVATE_CLIENT'})}</h5>
                <span className='text-dark'>
                  <p>{intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.CONFIRM_DEACTIVATE'}, { clientName: formatTextMIddleDash(currentClientNameSelected) })}</p>
                  <p>
                    {intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.AFFECTED_ENTITIES'})}
                  </p>
                  <p>{intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.PROVIDE_REASON'})}</p>
                </span>
              </div>
            </div>
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.DEACTIVATION_REASON'})}</label>
            <select className='form-select form-select-lg form-select-solid mb-4'>
              <option value='0'>--{intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.SELECT_OPTION'})}--</option>
              <option value='1'>{intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.REASON_1'})}</option>
              <option value='2'>{intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.REASON_2'})}</option>
              <option value='3'>{intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.REASON_3'})}</option>
              <option value='4'>{intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.REASON_4'})}</option>
              <option value='5'>{intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.REASON_5'})}</option>
            </select>
            {/* <input className='form-control form-control-solid mb-4' placeholder='Motivo de baja' />
            <textarea
              value='Ingresa el motivo por el cual darás de baja a este cliente...'
              className='form-control form-control-solid'
            /> */}
          </div>
        </div>
      )
    } else {
      setBody(
        <div className='alert alert-dismissible bg-light-success border border-success  mb-2'>
          <div className='d-flex flex-column flex-sm-row p-5'>
            <span className='svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0 text-dark'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                fill='currentColor'
                className='bi bi-exclamation-triangle-fill'
                viewBox='0 0 16 16'
              >
                <path d='M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2' />
              </svg>
            </span>
            <div className='d-flex flex-column text-primary pe-0 pe-sm-10'>
              <h5 className='mb-1'>{intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.REACTIVATE_CLIENT'})}</h5>
              <span className='text-dark'>
                <p>{intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.CONFIRM_REACTIVATE'}, { clientName: currentClientNameSelected })}</p>
              </span>
            </div>
          </div>
        </div>
      )
    }
  }

  useEffect(() => {
    handleShowBody()
  }, [])
  return (
    <>
      <div>{Body}</div>
    </>
  )
}
