import { apiDragon, DragonMainApi, EPMailTemplatesList } from "../constants/_Constants";

import { apiGetDeletePetitions } from "../../../_metronic/helpers/apis";

export async function mailTemplatesListByProduct(userToken: string, productId: string) {
    try {
        const data = await apiGetDeletePetitions(apiDragon, `${DragonMainApi+EPMailTemplatesList+productId}`, {
            undefined
        }, {
            'Content-Type': 'application/json',
            'x-token': userToken 
        }, 'get')

        const jsonDataMailTemplatesList = data 
        console.log(jsonDataMailTemplatesList)
        return jsonDataMailTemplatesList;

    }catch (error) {
        console.error('Error obteniendo los mail templates: ', error);
        throw error;
    }
}