const MultimediaTabForm = () => {
    return (
        <>
            <div className="d-flex input-group mb-6">
                <label className="col-3">Welcome Rec:</label>
                <div className="col-9">
                    <span className='text-muted mb-4'>
                        Archivo actual: <strong>hola.mp3</strong>
                    </span>
                    <input 
                        type="file"
                        id="welcomeRec"
                        name="welcomeRec"
                        className="form-control form-control-solid"
                        accept="audio/mp3"
                    />
                </div>
            </div>

            <div className="d-flex input-group mb-6">
                <label className="col-3">Welcome rec notify:</label>
                <div className="col-9">
                    <span className="text-muted mb-4">
                        Archivo actual: <strong>enlazando.mp3</strong>
                    </span>
                    <input 
                        type="file"
                        id="welcomeRecNotify"
                        name="welcomeRecNotify"
                        className="form-control form-control-solid"
                        accept="audio/mp3"
                    />
                </div>
            </div>

            <div className='input-group mb-3'>
                <div className='input-group-prepend'>
                    <button 
                        type="submit" 
                        className='mt-8 btn btn-primary btn-sm' 
                        onClick={() => {
                            console.log('se envia formulario')
                        }}
                    >
                        Guardar
                    </button>
                </div>
            </div>
        </>
    )
}

export {MultimediaTabForm}