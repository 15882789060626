import React from 'react'
import {formatTextMIddleDash} from '../../../utils/functions/_Functions'
import {useIntl} from 'react-intl'

type Props = {
  currentClientNameSelected: string
}

const ModalRenameClient: React.FC<Props> = ({currentClientNameSelected}) => {
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  return (
    <>
      <div className='mb-6'>
        <label className='fs-6'>
          {intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.CURRENT_NAME'})}
        </label>
        <input
          className='form-control form-control-solid'
          value={formatTextMIddleDash(currentClientNameSelected)}
          readOnly={true}
        />
      </div>

      <div>
        <label className='required fs-6'>
          {intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.NEW_NAME'})}
        </label>
        <input
          className='form-control form-control-solid'
          // value={currentClientNameSelected}
          placeholder=''
        />
      </div>

      <div className='mt-4'>
        <label className='required fw-bold fs-6 mb-2'>
          {intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.REASON_FOR_NAME_CHANGE'})}
        </label>
        <select className='form-select form-select-lg form-select-solid mb-4'>
          <option value='0'>
            --{intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.SELECT_UPDATE_REASON'})}--
          </option>
          <option value='1'>{intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.REASON_ROI'})}</option>
          <option value='2'>
            {intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.REASON_MARKETING_STRATEGY'})}
          </option>
          <option value='3'>
            {intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.REASON_END_OF_LIFE'})}
          </option>
          <option value='4'>
            {intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.REASON_REPLACEMENT'})}
          </option>
          <option value='5'>
            {intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.REASON_MARKET_DEMAND'})}
          </option>
        </select>
      </div>
    </>
  )
}

export {ModalRenameClient}
