import React, {useEffect, useState} from 'react'
import {LoadingAnimation} from '../../../../../../utils/components/loading/LoadingAnimation'
import {InteractionReportMailTable} from '../../tables/InteractionReportMailTable'
import {useFilterProductData} from '../hooks/useFilterProductDate'
import InteractionMailReportChart from '../../charts/interactionMailReportChart'
import {ReportApiMail} from '../../interfaces/interactionsReportsTypes'
import { useIntl } from 'react-intl'

export const InteractionMailReport: React.FC = () => {
  const {
    printFilterDataProduct,
    startDate,
    endDate,
    selectedIdProduct,
    productsList,
    selectedNameProduct,
  } = useFilterProductData()
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  //Constante para el manejo de estado de los datos para la gráfica
  const [dataInteractionMail, setDataInteractionMail] = useState<Array<object>>([])
  //Constante para el manejo de estado de la animacion de carga
  const [isLoading, setIsLoading] = useState<boolean>(false)
  //Constante para anejo de errores
  const [errorMessage, setErrorMessage] = useState<string>('')
  //Constante para el manejo de estado de los datos para la gráfica
  const [rowsInTable, setRowsInTable] = useState<ReportApiMail[]>([])

  useEffect(() => {
    setIsLoading(true)
    setErrorMessage('')
    setTimeout(() => {
      setIsLoading(false)
    }, 4000)
  }, [selectedIdProduct, productsList, startDate, endDate])

  return (
    <>
      {printFilterDataProduct()}
      <div className='row gx-2 mb-6'>
        <div className='col-xl-12'>
          <div className='card card-custom px-8 py-10 h-100'>
            <div className='row-justify-content-center mb-2'>
              <div className='col-md-12'>
                {!selectedIdProduct && (
                  <div
                    className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                    role='alert'
                  >
                    <h5 className='mb-1 text-center'>{intl.formatMessage({id: 'REPORTS.INTERACTIONS.SELECT_PRODUCT'})}</h5>
                  </div>
                )}
                {/* {selectedIdProduct && (
                  <div className='d-flex mb-4 justify-content-center'>
                    <h5 className='card-label me-4 d-flex flex-wrap flex-stack '>
                      <span className='text-dark text-sm font-weight-bolder mt-3'>
                        Interacciones por correo:
                      </span>
                      &nbsp;&nbsp;
                      <span className='text-muted mt-3'> {selectedNameProduct}</span>
                    </h5>
                  </div>
                )} */}
                {selectedIdProduct && (
                  <>
                    <div className='d-flex  justify-content-center'>
                      <h5 className='card-label me-4 d-flex flex-wrap flex-stack '>
                        <span className='text-dark text-sm font-weight-bolder mt-3'>
                          {intl.formatMessage({id: 'REPORTS.INTERACTIONS.EVENTS'})}:
                        </span>
                        &nbsp;&nbsp;
                        <span className='text-muted mt-3'>
                          <em>{intl.formatMessage({id: 'REPORTS.INTERACTIONS.EVENTS_DESCRIPTION'})}</em>
                        </span>
                      </h5>
                    </div>
                    <div className='d-flex mb-4 justify-content-center'>
                      <span className='card-label me-4 d-flex flex-wrap flex-stack'>
                        {/* <i className='bi bi-dot fs-1' style={{color: labelCreadoPor.color}}></i> */}
                        {selectedNameProduct}
                      </span>
                    </div>
                  </>
                )}
                {!isLoading && errorMessage && (
                  <div
                    className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                    role='alert'
                  >
                    <h5 className='mb-1 text-center'>{errorMessage}</h5>
                  </div>
                )}
                {isLoading && selectedIdProduct && (
                  <div className='py-6'>
                    <LoadingAnimation alignment='center' label={intl.formatMessage({id: 'REPORTS.INTERACTIONS.GENERATING_GRAPH'})} size='lg' />
                  </div>
                )}
                {!errorMessage && !isLoading && productsList.length > 0 && selectedIdProduct && (
                  <InteractionMailReportChart
                    rowsInTable={rowsInTable}
                    arrInteracciones={dataInteractionMail}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Begin Contenido tablas al seleccionar un producto */}
      <div className='row gx-2 mb-6'>
        <div className='col-xl-12'>
          <div className='card card-custom px-8 py-10 h-100'>
            <div className='row justify-content-center mb-2'>
              <div className='col-md-12'>
                {!selectedIdProduct && (
                  <>
                    <div
                      className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                      role='alert'
                    >
                      <h5 className='mb-1 text-center'>{intl.formatMessage({id: 'REPORTS.INTERACTIONS.SELECT_PRODUCT'})}</h5>
                    </div>
                  </>
                )}

                {isLoading && selectedIdProduct && (
                  <div className='py-6'>
                    <LoadingAnimation alignment='center' label={intl.formatMessage({id: 'REPORTS.INTERACTIONS.GENERATING_TABLE'})} size='lg' />
                  </div>
                )}

                {!isLoading && productsList.length > 0 && selectedIdProduct && !errorMessage && (
                  <div className='tab-content' id='myTabContent'>
                    <div
                      className='tab-pane py-5 fade show active'
                      id='kt_tab_pane_4'
                      role='tabpanel'
                    >
                      <InteractionReportMailTable
                        rowsInTable={(data) => setRowsInTable(data)}
                        productId={selectedIdProduct}
                        startDate={startDate}
                        endDate={endDate}
                        infoMessageLoading={(data) => setErrorMessage(data)}
                        arrDataInteractionMail={(data) => setDataInteractionMail(data)}
                      />
                    </div>
                  </div>
                )}

                {!isLoading && errorMessage && (
                  <div
                    className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                    role='alert'
                  >
                    <h5 className='mb-1 text-center'>{errorMessage}</h5>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End */}
    </>
  )
}
