import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {SellersHeader} from '../sellers/aux-components/UsersHeader'
import {GroupSellersListWrapper} from './aux-components/SellersGroupListWrapper'
import {useContext, useEffect} from 'react'
import {canAccessFeature, setPageTitle} from '../../../utils/functions/_Functions'
import {useIntl} from 'react-intl'
import {DragonCem} from '../../../utils/constants/_Constants'
import {GroupSellersTableComponent} from './Tables/SellersGroupTableComponent'
import {UserContext} from '../../../app-context/user-context/UserContext'
import {DRG_STANDAR_MODULES} from '../../../routing/constants/_Contstants'

const CONTROL_SELLERSGroupPage = () => {
  const intl = useIntl()
  //Obtener datos del context
  const {user} = useContext(UserContext)
  const userType: string = user.user_type

  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: 'CONTROL_SELLERS.GROUP_SELLERS'}),
      path: '/group-sellers',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  useEffect(() => {
    // Cambiar el título de la página al montar el componente
    setPageTitle(`${intl.formatMessage({id: 'CONTROL_SELLERS.GROUP_SELLERS'})} | ${DragonCem}`)
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>
        {intl.formatMessage({id: 'SELLERS.TITLE'})}
      </PageTitle>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='col-md-12'>
            <h1 className='my-2'>{intl.formatMessage({id: 'CONTROL_SELLERS.GROUP_SELLERS'})}</h1>
            {canAccessFeature(DRG_STANDAR_MODULES.USERS_GROUPS.value, userType, 2) && (
              <SellersHeader />
            )}
            {/* <GroupSellersListWrapper /> */}
            <div className='card card-custom'>
              {canAccessFeature(DRG_STANDAR_MODULES.USERS_GROUPS.value, userType, 2) && (
                <GroupSellersTableComponent />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CONTROL_SELLERSGroupPage
