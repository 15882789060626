import React, {FC, useEffect, useState} from 'react'
import {
  Column,
  Table,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  FilterFn,
  ColumnDef,
  flexRender,
} from '@tanstack/react-table'
import {RankingInfo, rankItem} from '@tanstack/match-sorter-utils'
import {Button, Dropdown, DropdownButton, Modal, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {LeadSpam} from '../../interfaces/leadsTypes'
import {KTIcon} from '../../../../../_metronic/helpers'
import {DropdownExport} from '../../../../../_metronic/partials/content/dropdown/DropdownExport'
import {DropdownExportLogs} from '../../../../../_metronic/partials/content/dropdown/DropdownExportLogs'
import {LeadSpamLogsTable} from './LeadSpamLogsTable'
import {SpamDataSimplyfied} from '../../interfaces/leadsTypes'
import {dateFormatterDateTimeExtended} from '../../../../utils/functions/_Functions'
import {ModalRestoreLead} from '../../modals/ModalRestoreLead'
import {ModalLeadToSpam} from '../../modals/ModalLeadToSpam'
import {useIntl} from 'react-intl'

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>
  }
  interface FilterMeta {
    itemRank: RankingInfo
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)

  // Store the itemRank info
  addMeta({
    itemRank,
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

type Props = {
  leadSpamList: SpamDataSimplyfied[]
  getLeadsSpamByClient: () => void
}

export const LeadSpamTable: FC<Props> = ({leadSpamList, getLeadsSpamByClient}) => {
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()
  const renderTooltip = (props) => <Tooltip {...props}>{intl.formatMessage({id: 'LEADS.COPY_ID'})}</Tooltip>
  const renderTooltipSpam = (props) => <Tooltip {...props}>Restaurar Lead</Tooltip>

  const [leadforModalsData, setLeadForModalsData] = useState<any>()

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = useState('')
  const [dataToExport, setDataToExport] = useState({}) // Definimos variables para la exportación de la data a formato hoja de calculos
  const [showModalRestoreLead, setShowModalRestoreLead] = useState<boolean>(false)
  const [showModalLeadToSpam, setShowModalLeadToSpam] = useState<boolean>(false)
  const [showModalLeadSpamLogs, setShowModalLeadSpamLogs] = useState<boolean>(false)

  const handleCopyToClipboard = (text: string) => {
    // Intentar copiar el texto al portapapeles
    navigator.clipboard.writeText(text).catch((error) => {
      console.error('Error al copiar al portapapeles:', error)
      alert('Error al copiar al portapapeles. Por favor, inténtelo de nuevo')
    })
  }

  // //Función para definir la data que se va a exportar
  // const handleSetData = () => {
  //   const dataToExport = table.getRowModel().flatRows.map((row) => row.original);
  //   setDataToExport(dataToExport)
  // }

  // Esta es la funcion en donde haremos la peticion al endpoint de historial de marcas

  //Función para abrir el modal de restauración de Lead
  const openModalLeadRestore = (lead) => {
    setShowModalRestoreLead(true)
    setLeadForModalsData(lead)
    console.log(lead)
  }

  //Función para cerrar el modal de restauración de Lead
  const closeModalRestoreLead = () => {
    setShowModalRestoreLead(false)
  }

  const openModalLeadtoSpam = (lead) => {
    setShowModalLeadToSpam(true)
    setLeadForModalsData(lead)
  }

  const closeModalLeadToSpam = () => {
    setShowModalLeadToSpam(false)
  }

  //Función para abrir el modal de logs de Lead spam
  const handleToOpenLeadSpamLogs = (lead) => {
    setShowModalLeadSpamLogs(true)
    console.log(lead.id)
  }

  //Función para cerrar el modal de logs de Lead spam
  const handleToCloseLeadSpamLogs = () => {
    setShowModalLeadSpamLogs(false)
  }

  //Disparamos la funcion con un useEffect
  // useEffect(() => {
  //   getLogs()
  // }, [])

  const columns = React.useMemo<ColumnDef<SpamDataSimplyfied, any>[]>(
    () => [
      {
        header: `${intl.formatMessage({id: 'LEADS.SPAM_LEAD_MARKED'})}`,
        footer: (props) => props.column.id,
        columns: [
          {
            accessorKey: 'lead_id',
            cell: (info) => (
              <OverlayTrigger placement='top' overlay={renderTooltip}>
                <button
                  className='btn btn-light-primary'
                  onClick={() => handleCopyToClipboard(info.getValue())}
                >
                  {info.getValue()}
                </button>
              </OverlayTrigger>
            ),
            header: () => <span>Id</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'status',
            cell: (info) => {
              const currentStatus = info.getValue()
              let stringStatus: string = ''
              let colorStatus: string = ''

              switch (currentStatus) {
                case false:
                  stringStatus = intl.formatMessage({id: 'LEADS.RESTORED'})
                  colorStatus = 'success'
                  break
                case true:
                  stringStatus = intl.formatMessage({id: 'LEADS.IN_SPAM'})
                  colorStatus = 'danger'
                  break
                default:
                  stringStatus = ''
                  colorStatus = ''
                  break
              }

              return <span className={`badge badge-light-${colorStatus}`}>{stringStatus}</span>
            },
            header: () => <span>{intl.formatMessage({id: 'LEADS.SPAM_LEAD_STATUS'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'date_add',
            id: 'date_add',
            cell: (info) => dateFormatterDateTimeExtended(info.getValue()),
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.DATE'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'pro_name', // Nueva clave de acceso para la combinación de producto y campaña
            cell: (info) => (
              <div>
                <strong>{info.row.original.pro_name}</strong>
              </div>
            ),
            filterValue: (rows, id, filterValue) => {
              // Lógica de filtrado por teléfono
              return rows.filter((row) => row.original.pro_name.includes(filterValue))
            },
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.PRODUCT'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'lead_phone', // Nueva clave de acceso para la combinación del numero y del correo del lead
            cell: (info) => (
              <div>
                <span>
                  <strong>{info.row.original.lead_name}</strong>
                </span>
                <br />
                <span>
                  {/* <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='16'
                      height='16'
                      fill='currentColor'
                      className='bi bi-telephone mr-2'
                      viewBox='0 0 16 16'
                    >
                      <path d='M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z' />
                    </svg> */}
                  {info.row.original.lead_phone}
                </span>
                <br />
                <span>
                  {/* <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='16'
                      height='16'
                      fill='currentColor'
                      className='bi bi-envelope'
                      viewBox='0 0 16 16'
                    >
                      <path d='M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z' />
                    </svg>{' '} */}
                  {info.row.original.lead_mail}
                </span>
              </div>
            ),
            filterValue: (rows, id, filterValue) => {
              // Lógica de filtrado por teléfono
              return rows.filter((row) => row.original.lead_phone.includes(filterValue))
            },
            header: () => <span>{intl.formatMessage({id: 'LEADS.LEAD_DATA'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'ban_comments',
            id: 'ban_comments',
            cell: (info) => info.getValue(),
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.COMMENT'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'actions',
            cell: (info) => {
              const statusCurrent: boolean = info.row.original.status
              let actionText: string = ''
              let actionColor: string = ''

              switch (statusCurrent) {
                case true:
                  actionText = 'Restaurar lead'
                  actionColor = 'primary'
                  break
                case false:
                  actionText = 'Agregar a SPAM'
                  actionColor = 'danger'
                  break
                default:
                  actionText = ''
                  actionColor = ''
                  break
              }
              return (
                <OverlayTrigger
                  placement='top'
                  overlay={(props) => <Tooltip {...props}>{actionText}</Tooltip>}
                >
                  <button
                    className={`btn btn-sm btn-${actionColor}`}
                    onClick={() =>
                      statusCurrent
                        ? openModalLeadRestore(info.row.original)
                        : openModalLeadtoSpam(info.row.original)
                    }
                  >
                    {actionText}
                  </button>
                </OverlayTrigger>
              )
            },
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.ACTIONS'})}</span>,
            footer: (props) => props.column.id,
          },
        ],
      },
    ],
    []
  )

  const table = useReactTable({
    data: leadSpamList,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  })

  React.useEffect(() => {
    if (table.getState().columnFilters[1]?.id === 'name') {
      if (table.getState().sorting[0]?.id !== 'name' || table.getState().sorting[0]?.desc) {
        table.setSorting([{id: 'name', desc: false}])
      }
    }
  }, [table.getState().columnFilters[1]?.id])

  return (
    <div className='p-2'>
      {/* <div className='card-header'>
        <div className='d-flex justify-content-between'>
          <DebouncedInput
            value={globalFilter ?? ''}
            onChange={(value) => setGlobalFilter(String(value))}
            className='form-control form-control-solid w-250px ps-14 me-3'
            placeholder='Buscar registro...'
          />

          <button
            type='button'
            className='btn btn-primary d-flex align-items-center me-3'
            onClick={handleToOpenLeadSpamLogs}
          >
            <span>Historial de cambios</span>
          </button>

          <DropdownExportLogs dataToExport={dataToExport} title={'Logs'} />
          <button
            type='button'
            className='btn btn-primary d-flex align-items-center me-3'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
            onClick={handleSetData}
          >
            <KTIcon iconType='outline' iconName='file-down' className='fs-2 me-2' />{' '}
            <span>Exportar datos</span>
          </button>

          <DropdownExportLogs dataToExport={dataToExport} title={'Logs'} />

          <button className='btn btn-primary me-3' onClick={() => handleExportToExcel()}>
            Descargar información
          </button>
        </div>
      </div> */}
      <div className='h-2' />

      <div className='card-body table-responsive'>
        <table className='table table-row-bordered gs-3 gy-4 gx-12'>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'
              >
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <>
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: ' 🔼',
                              desc: ' 🔽',
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                          {header.column.getCanFilter() ? (
                            <div>
                              <Filter column={header.column} table={table} />
                            </div>
                          ) : null}
                        </>
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      <div className='h-2' />

      <div className='card-footer'>
        <div className='d-flex justify-content-between gap-2'>
          <div id='SwitchPages'>
            <button
              className='btn btn-primary border rounded p-1'
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              {'<<'}
            </button>
            <button
              className='btn btn-primary border rounded p-1'
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {'<'}
            </button>
            <button
              className='btn btn-primary border rounded p-1'
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              {'>'}
            </button>
            <button
              className='btn btn-primary border rounded p-1'
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              {'>>'}
            </button>
          </div>

          <div id='PagesFinder' className='text-center'>
            {' '}
            <span className='flex items-center gap-1'>
              {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_PAGE'})}{' '}
              <strong>
                {table.getState().pagination.pageIndex + 1} de {table.getPageCount()}
              </strong>{' '}
              | {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_GO_TO_PAGE'})}:
              <input
                type='number'
                defaultValue={table.getState().pagination.pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  table.setPageIndex(page)
                }}
                className='form-control form-control-solid w-16'
              />
            </span>
          </div>
          <div id='showFiles' className='text-center'>
            <select
              className='form-select form-select-solid'
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_SHOW_PART_ONE'})} {pageSize}{' '}
                  {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_SHOW_PART_TWO'})}
                </option>
              ))}
            </select>
            <p>
              {table.getPrePaginationRowModel().rows.length}{' '}
              {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_TOTAL_RECORDS'})}
            </p>
          </div>
        </div>
      </div>

      {/* Modal para abrir el historial de cambios de Lead Spam */}
      <Modal
        show={showModalLeadSpamLogs}
        onHide={handleToCloseLeadSpamLogs}
        scrollable
        centered
        className='modal-xl'
      >
        <Modal.Header closeButton onClick={handleToCloseLeadSpamLogs}>
          <Modal.Title>
            <h1>{intl.formatMessage({id: 'LEADS.SPAM_CHANGES_HISTORY'})}</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LeadSpamLogsTable />
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-sm btn-secondary' onClick={handleToCloseLeadSpamLogs}>
            {intl.formatMessage({id: 'GLOBAL.CANCEL'})}
          </button>

          <button className='btn btn-sm btn-primary' type='submit' onClick={undefined}>
            {intl.formatMessage({id: 'LEADS.RESTORE'})}
          </button>
        </Modal.Footer>
      </Modal>

      {/* Modal para restaurar Lead */}
      <Modal show={showModalRestoreLead} onHide={closeModalRestoreLead} className='modal-default'>
        <ModalRestoreLead
          lead={leadforModalsData}
          closeModalRestoreLead={closeModalRestoreLead}
          getLeadsSpamByClient={getLeadsSpamByClient}
        />
      </Modal>

      <Modal show={showModalLeadToSpam} onHide={closeModalLeadToSpam} className='modal-default'>
        <ModalLeadToSpam
          lead={leadforModalsData}
          closeModalLeadToSpam={closeModalLeadToSpam}
          getLeadsSpamByClient={getLeadsSpamByClient}
          origin='spam'
        />
      </Modal>
    </div>
  )
}

function Filter({column, table}: {column: Column<any, unknown>; table: Table<any>}) {
  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id)
 //Variable para el uso de diccionario de lenguajes
 const intl = useIntl()
  const columnFilterValue = column.getFilterValue()

  const sortedUniqueValues = React.useMemo(
    () =>
      typeof firstValue === 'number'
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  )

  return typeof firstValue === 'number' ? (
    <div>
      <div className='flex space-x-2'>
        <DebouncedInput
          type='number'
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[0] ?? ''}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [value, old?.[1]])}
          placeholder={`Min ${
            column.getFacetedMinMaxValues()?.[0] ? `(${column.getFacetedMinMaxValues()?.[0]})` : ''
          }`}
          className='w-24 border shadow rounded'
        />
        <DebouncedInput
          type='number'
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[1] ?? ''}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [old?.[0], value])}
          placeholder={`Max ${
            column.getFacetedMinMaxValues()?.[1] ? `(${column.getFacetedMinMaxValues()?.[1]})` : ''
          }`}
          className='w-24 border shadow rounded'
        />
      </div>
      <div className='h-1' />
    </div>
  ) : (
    <>
      <datalist id={column.id + 'list'}>
        {sortedUniqueValues.slice(0, 5000).map((value: any) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <DebouncedInput
        type='text'
        value={(columnFilterValue ?? '') as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`${intl.formatMessage({id: 'GLOBAL.TABLE_SEARCH_THREE_DOTS'})} (${column.getFacetedUniqueValues().size})`}
        className='w-36 border shadow rounded'
        list={column.id + 'list'}
      />
      <div className='h-1' />
    </>
  )
}

// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return <input {...props} value={value} onChange={(e) => setValue(e.target.value)} />
}
