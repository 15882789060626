// Importamos hooks de react que usaremos para este componente
import React, {FC, useState, useEffect, useContext} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
// Importamos constante con prefijo para el titulo de la pagina
import {DragonCem} from '../../utils/constants/_Constants'
// Importamos libreria para manejo de lenguaje
import {useIntl} from 'react-intl'

// ---------------------------------------------------------------------------------------

// Importamos el contexto de usuario
import {UserContext} from '../../app-context/user-context/UserContext'

// ---------------------------------------------------------------------------------------

// Importamos el componente ProductsAccountManagerTable para generar la tabla de productos
import {ProductsAccountManagerTable} from './tables/ProductsAccountManagerTable'
// Importamos componente SelectedClientItems para generar el contenido de las tarjetas
import {SelectedClientItems} from './aux-components/SelectedClientItems'
// Importamos componente SelectedProductsItems para generar el contenido de las tarjetas
import {SelectedProductsItems} from './aux-components/SelectedProductsItems'
// Importamos el componente ClientSelectedButtonsCard para generar los elementos de acciones del cliente
import {ClientSelectedButtonsCard} from './aux-components/SelectedClientActions'
// Importamos componente con la animacion de carga de registros
import {LoadingAnimation} from '../../utils/components/loading/LoadingAnimation'

// ---------------------------------------------------------------------------------------

// Importamos la funcion amClientsBUList para conexion con API de clientes por BU
import {amClientsBUList} from './services/_AMClientsBUListApi'

import {amProductsByClientList} from './services/_AMProductsByClientBUListApi'

// ---------------------------------------------------------------------------------------

// Importamos funciones setPageTitle y sessionExpired para manejo de nombre de pagina y termino de sesion
import {
  setPageTitle,
  sessionExpired,
  mapProductStatusToWord,
  dateFormatterTextualEn,
  formatTextMIddleDash,
} from '../../utils/functions/_Functions'

// ---------------------------------------------------------------------------------------

// Importamos las interfaces de account-management que usaremos para este componente
import {
  ProductByClientBU,
  ClientSharedByBU,
  ClientSharedListApiResponse,
} from './interfaces/accountManagerTypes'

import {DateFormatter} from '@fullcalendar/core/internal'

import {
  AddressProductByClient,
  DataProductByClient,
  DataTwlProductByClient,
  CampaignForProduct,
  ProductsByClientListApiResponse,
} from './interfaces/accountManagerTypes'

// Construimos un funtional component para reenderizar AccountManagement
const AccountManagement: FC = () => {
  const {user, token, updateToken} = useContext(UserContext)
  const clientId: string = user.cli_id
  const userToken: string = token

  // Constante para el uso de diccionario de lenguajes
  const intl = useIntl()

  // Constante para guardar y manejar el listado de clientes por unidad de negocio
  const [clientsSharedList, setClientsSharedList] = useState<ClientSharedByBU[]>([])

  // Constante para guardar y manejar el nombre del cliente seleccionado
  const [currentClientNameSelected, setCurrentClientNameSelected] = useState<string>('')
  // Constante para guardar y manejar el id del cliente seleccionado
  const [currentClientIdSelected, setCurrentClientIdSelected] = useState<string>('')
  // constante para guardar y manejar el estatus del cliente seleccionado
  const [currentClientStatusSelected, setCurrentClientStatusSelected] = useState<
    number | undefined
  >(0)
  // Constante para guardar y manejar el mail del vendedor asignado al cliente seleccionado
  const [currentClientMailOwnerSelected, setCurrentclientMailOwnerSelected] = useState<
    string | undefined
  >('')

  // Constante para guardar y manejar el listado de productos por cliente y unidad de negocio
  const [productsByClientList, setProductsByClientList] = useState<ProductByClientBU[]>([])

  /* * Constantes para el manejo de animacion de carga y de mensajes de pantalla * */
  // Constante para animacion de carga en la tarjeta de resumen de clientes por cuenta
  const [isLoadingClientResume, setIsLoadingClientResume] = useState<boolean>(false)
  // Constante para mensajes en la tarjeta de resumen de clientes por cuenta
  const [infoMessageClientResume, setInfoMessageClientResume] = useState<string>('')
  // Constante para animacion de carga en la tarjeta de productos por cliente, acciones de cliente y tabla de productos
  const [isLoadingProduct, setIsLoadingProduct] = useState<boolean>(false)
  // Constante para mensajes en la tabla de productos
  const [infoMessageProduct, setInfoMessageProduct] = useState<string>('')
  // Constante para mensaje de productos no disponibles
  const [infoMessageNoProductsCreated, setInfoMessageNoProductsCreated] = useState<string>('')
  // Constante para mensajes en la tarjeta de conteos de productos por cliente y acciones de cliente
  const [infoMessageClientSelected, setInfoMessageClientSelected] = useState<string>('')

  /* * Constantes para el manejo de conteos de clientes activos, inactivos y en proceso * */
  // Clientes inactivos 0
  const [inactiveClientsCount, setInactiveClientsCount] = useState<number>(0)
  // Clientes activos 1
  const [activeClientsCount, setActiveClientsCount] = useState<number>(0)
  // Clientes con proceso pendiente de desactivación 2
  const [pendingClientsCount, setPendingClientsCount] = useState<number>(0)
  // Clientes con proceso pendiente de activación 3
  const [pendingClientsToActiveCount, setPendingClientsToActiveCount] = useState<number>(0)

  /* * Constantes para el manejo de conteos de productos activos, inactivos y en proceso * */
  // Productos Inactivos
  const [inactiveProductsCount, setInactiveProductsCount] = useState<number>(0)
  // Productos Activos
  const [activeProductsCount, setActiveProductsCount] = useState<number>(0)
  // Productos con proceso pendiente
  const [pendingProductsCount, setPendingProductsCount] = useState<number>(0)
  // Productos con proceso pendiente a activación
  const [pendingProductsCountToActive, setPendingProductsCountToActive] = useState<number>(0)

  // Aqui se verifica el tamaño del arreglo con los clientes por BU
  const checkClientsSharedListLength: () => void = () => {
    // Condicion para mandar mensaje en caso de que el tamaño del arreglo sea igual a '0'
    clientsSharedList.length === 0 &&
      setInfoMessageClientResume(
        intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.INFO.NOT_SHARED_CLIENT'})
      )
  }

  // Aqui se verifica el tamaño del arreglo con los productos por cliente
  const checkProductsByClientListLength: () => void = () => {
    productsByClientList.length === 0 &&
      setInfoMessageNoProductsCreated(
        intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.INFO.NOT_PRODUCTS'})
      )
  }

  // Funcion para obtener el listado de clientes compartidos por unidad de negocio
  const getClientsSharedBUList = async (): Promise<void> => {
    setIsLoadingClientResume(true) // Mostramos la animación de carga
    console.log({clientsSharedList})

    try {
      const {
        data: jsonData,
        code,
        msg,
      }: ClientSharedListApiResponse = await amClientsBUList(userToken, clientId)

      // Si la respuesta del servidor es un codigo '400' y un mensaje de 'Token no valido'
      if (code === 401 && msg === 'Token no valido') {
        sessionExpired(intl.formatMessage({id: 'GLOBAL.SESSION_EXPIRED'})) // Ejecutamos funcion de termino de sesion
        setIsLoadingClientResume(false) // Ocultamos la animación de carga
        return

        // Si la respuesta del servidor es diferente
      } else {
        // Guardamos el arreglo de objetos 'clients_shared'
        const clientsListReceived: ClientSharedByBU[] = jsonData.clients_shared

        // Guardamos el token de respuesta
        const userTokenNew: string = jsonData.token

        updateToken(userTokenNew) //Ejecutamos funcion para actualizar el token en UserContext, enviandole el nuevo token

        // console.log(clientsListReceived);

        // Mapeamos la respuesta de la API para llenar nuestra constante ClientsSharedList con el listado de clientes compartidos
        const clientItems: ClientSharedByBU[] = clientsListReceived.map(
          (client: ClientSharedByBU) => {
            return {
              cli_id: client.cli_id,
              cli_name: client.cli_name,
              cli_status: client.cli_status,
              date_add: client.date_add,
              dragon_owner: client.dragon_owner,
              mail_owner: client.mail_owner,
            }
          }
        )

        setClientsSharedList(clientItems) // Actualizamos clientsSharedList
        setIsLoadingClientResume(false) // Ocultamos la animacion de carga
        checkClientsSharedListLength() // Ejecutamos la funcion para verificar el tamaño de clientsSharedList
      }
    } catch (error) {
      console.log('error getting shared clients list: ', error)
    }
  }

  // Primer efecto que se ejecutara solo una vez (al reenderizar la pagina por primera vez)
  useEffect(() => {
    setPageTitle(`Gestión de cuenta | ${DragonCem}`) // Seteamos el nombre de la pagina
    getClientsSharedBUList() // Ejecutamos funcion para obtener listado de clientes compartidos

    // Mostrar el mensaje de alerta en tabla de productos y tarjetas cuando se monta el componente si no se ha seleccionado ninguna opción
    if (!clientsSharedList.some((client) => client.cli_id === '')) {
      setInfoMessageProduct(
        intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.INFO.WATCH_LIST_PRO.SELECT_CLIENT'})
      )
      setInfoMessageClientSelected(
        intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.INFO.SELECT_CLIENT'})
      )
    }
  }, [])

  const getProductsRegisters = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | string
  ): Promise<void> => {
    let clientSelectedId: string

    console.log({clientsSharedList})

    setIsLoadingProduct(true) // Activamos la animación de carga

    if (typeof e === 'string') {
      clientSelectedId = e
    } else {
      clientSelectedId = e.target.value
      if (e.target instanceof HTMLSelectElement) {
        setCurrentClientNameSelected(e.target.options[e.target.selectedIndex].text)
      }
    }

    let clientSelectedStatus: number | undefined
    let clientSelectedMailOwner: string | undefined

    console.log('id de cliente: ', clientId)

    const foundClientStatus: ClientSharedByBU | undefined = clientsSharedList.find(
      (client) => client.cli_id === clientSelectedId
    )
    const foundDragonOwner: ClientSharedByBU | undefined = clientsSharedList.find(
      (client) => client.cli_id === clientSelectedId
    )

    foundClientStatus
      ? (clientSelectedStatus = foundClientStatus.cli_status)
      : (clientSelectedStatus = undefined)
    foundDragonOwner
      ? (clientSelectedMailOwner = foundDragonOwner.mail_owner)
      : (clientSelectedMailOwner = undefined)

    console.log('client status: ', clientSelectedStatus)
    console.log('client owner: ', clientSelectedMailOwner)

    setCurrentClientStatusSelected(clientSelectedStatus)
    setCurrentclientMailOwnerSelected(clientSelectedMailOwner)

    // Si se selecciona una opción con valor vacío, mostrar la alerta y no hacer más acciones
    if (clientSelectedId === '') {
      setCurrentClientNameSelected('')
      setCurrentClientIdSelected('')
      setCurrentClientStatusSelected(0)
      setCurrentclientMailOwnerSelected('')

      setInfoMessageProduct(
        intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.INFO.WATCH_LIST_PRO.SELECT_CLIENT'})
      )
      setInfoMessageClientSelected(
        intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.INFO.SELECT_CLIENT'})
      )
      setIsLoadingProduct(false) // Desactivamos la animación de carga

      return
    } else {
      setCurrentClientIdSelected(clientSelectedId)
    }

    try {
      const {
        data: jsonData,
        code,
        msg,
      }: ProductsByClientListApiResponse = await amProductsByClientList(userToken, clientSelectedId)

      if (code === 401 && msg === 'Token no valido') {
        sessionExpired(intl.formatMessage({id: 'GLOBAL.SESSION_EXPIRED'}))
        setIsLoadingProduct(false)
        return
      } else {
        console.log(jsonData)
        const producstListReceived = jsonData.data
        const inactiveCountProducts = jsonData.prodsInactive
        const activeCountProducts = jsonData.prodsActive
        const procesCountProducts = jsonData.waitingProds
        const procesCountProductsToActive = jsonData.waitingProdsToActivate

        const userTokenNew = token

        updateToken(userTokenNew)

        const productItem = producstListReceived.map((product) => {
          const productDateAddFormatted = dateFormatterTextualEn(product.date_add)
          const productDateDownFormatted = dateFormatterTextualEn(product.date_down)

          const addressProduct: AddressProductByClient = {
            country: product.address.country,
            state: product.address.state,
            city: product.address.city,
            zone: product.address.zone,
          }

          const dataProductInfo: DataProductByClient = {
            merch_pdf: product.data_product.merch_pdf,
            price: product.data_product.price,
            mails: product.data_product.mails,
            duplicates: product.data_product.duplicates,
            parser: product.data_product.parser,
            merch: product.data_product.merch,
            logo: product.data_product.logo,
            header: product.data_product.header,
            whaimg: product.data_product.whaimg,
            intro: product.data_product.intro,
            virtual: product.data_product.virtual,
            web: product.data_product.web,
            gmaps: product.data_product.gmaps,
            waze: product.data_product.waze,
            legals: product.data_product.legals,
          }

          const dtaTwlProduct: DataTwlProductByClient = {
            // twl_accsid: product.data_twl.twl_accsid,
            // twl_accat: product.data_twl.twl_accat,
            // twl_wab: product.data_twl.twl_wab,
            // twl_sellwab: product.data_twl.twl_sellwab,
            // twl_sms: product.data_twl.twl_sms,
            // twl_callid: product.data_twl.twl_callid,
            // wab_status: product.data_twl.wab_status,

            twl_wab: product.data_twl.twl_wab,
            twl_lead_service: product.data_twl.twl_lead_service,
            twl_sellwab: product.data_twl.twl_sellwab,
            twl_sell_service: product.data_twl.twl_sell_service,
            twl_sms: product.data_twl.twl_sms,
            twl_sms_accsid: product.data_twl.twl_sms_accsid,
            twl_sms_accat: product.data_twl.twl_sms_accat,
            twl_callid: product.data_twl.twl_callid,
            twl_call_accsid: product.data_twl.twl_call_accsid,
            twl_call_accat: product.data_twl.twl_call_accat,
            wab_status: product.data_twl.wab_status,
          }

          return {
            address: addressProduct,
            data_product: dataProductInfo,
            data_twl: dtaTwlProduct,
            pro_id: product.pro_id,
            // brand_id: product.brand_id,
            crm_id: product.crm_id,
            status: product.status,
            name: product.name,
            type: product.type,
            date_add: productDateAddFormatted,
            date_down: productDateDownFormatted,
            sellwab_status: product.sellwab_status,
            sms_status: product.sms_status,
            wab_mdl_status: product.wab_mdl_status,
            campaigns: product.campaigns,
            totalCampaigns: product.totalCampaigns,
          }
        })
        console.log('se setean los valores')

        setProductsByClientList(productItem)

        console.log({productsByClientList})

        setInactiveProductsCount(inactiveCountProducts)
        setActiveProductsCount(activeCountProducts)
        setPendingProductsCount(procesCountProducts)
        setPendingProductsCountToActive(procesCountProductsToActive)

        setIsLoadingProduct(false)
        checkProductsByClientListLength()
      }

      console.log(productsByClientList)
    } catch (error) {
      console.error('hubo error con los datos: ', error)
      setInfoMessageProduct(intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.INFO.INFO_NOT_FOUND'}))
      setInfoMessageClientSelected(
        intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.INFO.INFO_NOT_FOUND'})
      )
      setIsLoadingProduct(false) // Desactivamos la animación de carga en caso de error
    }
  }

  const updateProductsByClientList = async (clientId, clientStatus, currentClientNameSelected) => {
    console.log({clientsSharedList})

    setIsLoadingProduct(true) // Activamos la animación de carga

    console.log('id de cliente: ', clientId)

    const foundClientStatus = clientsSharedList.find((client) => client.cli_id === clientId)

    foundClientStatus ? (clientStatus = foundClientStatus.cli_status) : (clientStatus = undefined)

    console.log('client status: ', clientStatus)
    setCurrentClientStatusSelected(clientStatus)

    // Si se selecciona una opción con valor vacío, mostrar la alerta y no hacer más acciones
    if (clientId === '') {
      setCurrentClientNameSelected('')
      setCurrentClientIdSelected('')
      setInfoMessageProduct(
        intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.INFO.WATCH_LIST_PRO.SELECT_CLIENT'})
      )
      setInfoMessageClientSelected(
        intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.INFO.SELECT_CLIENT'})
      )
      setIsLoadingProduct(false) // Desactivamos la animación de carga
      return
    } else {
      setCurrentClientIdSelected(clientId)

      if (currentClientNameSelected) {
        setCurrentClientNameSelected(currentClientNameSelected)
      }
    }

    try {
      const {
        data: jsonData,
        code,
        msg,
      }: ProductsByClientListApiResponse = await amProductsByClientList(userToken, clientId)

      if (code === 401 && msg === 'Token no valido') {
        sessionExpired('La sesion ha expirado')
        setIsLoadingProduct(false)
        return
      } else {
        const producstListReceived = jsonData.data
        const activeCountProducts = jsonData.prodsActive
        const inactiveCountProducts = jsonData.prodsInactive
        const procesCountProducts = jsonData.waitingProds
        const procesCountProductsToActive = jsonData.waitingProdsToActivate

        const userTokenNew = token

        updateToken(userTokenNew)

        const productItem = producstListReceived.map((product) => {
          const productDateAddFormatted = dateFormatterTextualEn(product.date_add)
          const productDateDownFormatted = dateFormatterTextualEn(product.date_down)

          const addressProduct: AddressProductByClient = {
            country: product.address.country,
            state: product.address.state,
            city: product.address.city,
            zone: product.address.zone,
          }

          const dataProductInfo: DataProductByClient = {
            merch_pdf: product.data_product.merch_pdf,
            price: product.data_product.price,
            mails: product.data_product.mails,
            duplicates: product.data_product.duplicates,
            parser: product.data_product.parser,
            merch: product.data_product.merch,
            logo: product.data_product.logo,
            header: product.data_product.header,
            whaimg: product.data_product.whaimg,
            intro: product.data_product.intro,
            virtual: product.data_product.virtual,
            web: product.data_product.web,
            gmaps: product.data_product.gmaps,
            waze: product.data_product.waze,
            legals: product.data_product.legals,
          }

          const dtaTwlProduct: DataTwlProductByClient = {
            // twl_accsid: product.data_twl.twl_accsid,
            // twl_accat: product.data_twl.twl_accat,
            // twl_wab: product.data_twl.twl_wab,
            // twl_sellwab: product.data_twl.twl_sellwab,
            // twl_sms: product.data_twl.twl_sms,
            // twl_callid: product.data_twl.twl_callid,
            // wab_status: product.data_twl.wab_status,

            twl_wab: product.data_twl.twl_wab,
            twl_lead_service: product.data_twl.twl_lead_service,
            twl_sellwab: product.data_twl.twl_sellwab,
            twl_sell_service: product.data_twl.twl_sell_service,
            twl_sms: product.data_twl.twl_sms,
            twl_sms_accsid: product.data_twl.twl_sms_accsid,
            twl_sms_accat: product.data_twl.twl_sms_accat,
            twl_callid: product.data_twl.twl_callid,
            twl_call_accsid: product.data_twl.twl_call_accsid,
            twl_call_accat: product.data_twl.twl_call_accat,
            wab_status: product.data_twl.wab_status,
          }

          return {
            address: addressProduct,
            data_product: dataProductInfo,
            data_twl: dtaTwlProduct,
            pro_id: product.pro_id,
            // brand_id: product.brand_id,
            crm_id: product.crm_id,
            status: product.status,
            name: product.name,
            type: product.type,
            date_add: productDateAddFormatted,
            date_down: productDateDownFormatted,
            sellwab_status: product.sellwab_status,
            sms_status: product.sms_status,
            wab_mdl_status: product.wab_mdl_status,
            campaigns: product.campaigns,
            totalCampaigns: product.totalCampaigns,
          }
        })

        console.log('se setean los valores')

        setProductsByClientList(productItem)

        setActiveProductsCount(activeCountProducts)
        setInactiveProductsCount(inactiveCountProducts)
        setPendingProductsCount(procesCountProducts)
        setPendingProductsCountToActive(procesCountProductsToActive)

        setIsLoadingProduct(false)
        checkProductsByClientListLength()
      }

      console.log(productsByClientList)
    } catch (error) {
      setInfoMessageProduct(intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.INFO.INFO_NOT_FOUND'}))
      setInfoMessageClientSelected(
        intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.INFO.INFO_NOT_FOUND'})
      )
      setIsLoadingProduct(false) // Desactivamos la animación de carga en caso de error
    }
  }

  // Gestionamos los estatus numericos para darles una cadena
  const getStatusText = (status: number) => {
    switch (status) {
      case 0:
        return intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.STATUS.INACTIVE'})
      case 1:
        return intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.STATUS.ACTIVE'})
      case 2:
        return intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.STATUS.PENDING_PROCESS_DEACTIVATION'})
      case 3:
        return intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.STATUS.PENDING_PROCESS_ACTIVATION'})
      default:
        return intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.STATUS.UNKNOWN'})
    }
  }

  // Se itera el arreglo de clientes compartidos para ordenar por estatus
  const sortedClients: ClientSharedByBU[] = clientsSharedList.sort((a, b) => {
    const statusA: string = getStatusText(a.cli_status)
    const statusB: string = getStatusText(b.cli_status)

    if (statusA === statusB) {
      return a.cli_name.localeCompare(b.cli_name)
    }

    // Ordenar por el orden de importancia: Activo, Proceso pendiente, Inactivo
    const statusOrder: {[key: string]: number} = {
      [intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.STATUS.ACTIVE'})]: 1,
      [intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.STATUS.PENDING_PROCESS_DEACTIVATION'})]: 2,
      [intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.STATUS.PENDING_PROCESS_ACTIVATION'})]: 3,
      [intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.STATUS.INACTIVE'})]: 4,
    }
    return statusOrder[statusA] - statusOrder[statusB]
  })

  console.log('Es este bro:', clientsSharedList)

  // Segundo efecto que se ejecutara cuando clientsSharedList cambie
  useEffect(() => {
    // Obtenemos un conteo de los diferentes clientes con los 4 estatus posibles
    const countStatus = () => {
      let activeCount: number = 0 // Variable para el total activo 0
      let inactiveCount: number = 0 // Variable para el total inactivo 1
      let pendingCountToInactive: number = 0 // Variable para el total proceso pendiente (De activo a Inactivo) 2
      let pendingCountToActive: number = 0 // Variable para el total proceso pendiente (De Inactivo a Activo) 3

      // Iteramos clientsSharedList y vamos modificanto el total de cada estatus
      clientsSharedList.forEach((client: ClientSharedByBU) => {
        switch (client.cli_status) {
          case 0:
            inactiveCount++
            break
          case 1:
            activeCount++
            break
          case 2:
            pendingCountToInactive++ //Se van a desactivar
            break
          case 3:
            pendingCountToActive++ //Se van a activar
            break
          default:
            break
        }
      })

      /* Seteamos las constantes con los
      conteos finales para cada estatus disponible*/
      setInactiveClientsCount(inactiveCount)
      setActiveClientsCount(activeCount)
      setPendingClientsCount(pendingCountToInactive)
      setPendingClientsToActiveCount(pendingCountToActive)
    }

    // Ejecutamos la funcion de conteos
    countStatus()

    getProductsRegisters(currentClientIdSelected)
  }, [clientsSharedList])

  // Componente HTML que se reenderizará
  return (
    <>
      <div className='container-fluid'>
        {/* Begin: Titulo de pagina y dropdown de clientes compartidos */}
        <div className='row justify-content-center mb-2'>
          <div className='col-md-12'>
            <div className='d-flex flex-wrap flex-stack mb-6'>
              <h1 className='my-2'>{intl.formatMessage({id: 'ACCOUNT_MANAGEMENT'})}</h1>
              <div className='d-flex align-items-center my-2'>
                <div className='w-190px'>
                  <select
                    name='seller'
                    data-control='selectSeller'
                    data-hidden-search='true'
                    className='form-select form-select-solid form-select-sm'
                    onChange={(e) => {
                      getProductsRegisters(e)
                    }}
                  >
                    <option value=''>
                      --{intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.SELECT_CLIENT'})}--
                    </option>
                    {sortedClients.map((client: ClientSharedByBU) => (
                      <option key={client.cli_id} value={client.cli_id}>
                        {client.cli_name} - {getStatusText(client.cli_status)}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End: Titulo de pagina y dropdown de clientes compartidos */}

        {/* Begin: Row con con dos cards */}
        <div className='row gx-2 mb-6'>
          {/* Begin: Card con resumen de clientes en tu cuenta */}
          <div className='col-xl-12 mb-6'>
            <div className='card card-custom px-12 py-12 h-100'>
              <div className='d-flex mb-4 justify-content-center'>
                <h5 className='card-label me-4'>
                  <span className='text-dark text-sm font-weight-bolder mt-3'>
                    {intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.CUSTOMER_OVERVIEW'})}
                  </span>
                </h5>
              </div>
              {/* Mostrar animación de carga si isLoading es verdadero */}
              {isLoadingClientResume && (
                <LoadingAnimation
                  alignment={'center'}
                  label={intl.formatMessage({id: 'GLOBAL.LOADING'})}
                />
              )}
              {/* Renderizar componente si hay datos disponibles */}
              {!isLoadingClientResume && clientsSharedList.length > 0 && (
                <SelectedClientItems
                  productsByClientList={productsByClientList}
                  inactiveClientsCount={inactiveClientsCount}
                  activeClientsCount={activeClientsCount}
                  pendingClientsCount={pendingClientsCount}
                  pendingClientsCountToActive={pendingClientsToActiveCount} //Se agrega el estado extra para los inactivos que van a pasar a ser activos
                  //Falta agregar esta prop extra en los 2 componentes donde marca error
                />
              )}
              {/* Mostrar mensaje si no hay datos disponibles */}
              {!isLoadingClientResume && clientsSharedList.length === 0 && (
                <p>{infoMessageClientResume}</p>
              )}
            </div>
          </div>
          {/* End: Card con resumen de clientes en tu cuenta */}

          {/* Begin: Card con resumen de productos del cliente Seleccionado */}
          <div className='col-xl-8'>
            <div className='card card-custom px-4 py-12 h-100'>
              <div className='d-flex mb-4 flex-wrap justify-content-center'>
                {/* Begin: Cliente seleccionado card */}
                <div className='border border-gray-300 border-dashed rounded min-w-95px py-3 px-4 me-8 mb-3'>
                  <h5 className='card-label me-4'>
                    <span className='text-dark text-sm font-weight-bolder mt-3 me-4'>
                      {intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.CUSTOMER_SELECTED'})}
                    </span>
                  </h5>
                  <h5 className='text-muted text-lg font-weight-normal'>
                    {formatTextMIddleDash(currentClientNameSelected)} - {currentClientIdSelected}
                  </h5>
                </div>
                {/* End: Cliente seleccionado card */}

                {/* Begin: Seller asigned info card */}
                <div className='border border-gray-300 border-dashed rounded min-w-115px py-3 px-4 me-8 mb-3'>
                  <h5 className='card-label me-4'>
                    <span className='text-dark text-sm font-weight-bolder mt-3'>
                      {intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.ASSIGNED_SELLER.CUSTOMER'})}{' '}
                      <i>{formatTextMIddleDash(currentClientNameSelected)}</i>
                    </span>
                  </h5>
                  <h5 className='text-muted text-lg font-weight-normal'>
                    {currentClientMailOwnerSelected}
                  </h5>
                </div>
                {/* End: Seller asigned info card */}
              </div>

              {/* Mostrar el mensaje de alerta */}
              {infoMessageClientSelected && currentClientIdSelected === '' && (
                <div
                  className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                  role='alert'
                >
                  <div>{infoMessageClientSelected}</div>
                </div>
              )}

              {/* Mostrar animación de carga si isLoading es verdadero */}
              {isLoadingProduct && currentClientIdSelected !== '' && (
                <LoadingAnimation
                  alignment={'center'}
                  label={intl.formatMessage({id: 'GLOBAL.LOADING'})}
                  size='lg'
                />
              )}
              {/* Mostrar tabla si hay datos disponibles */}
              {!isLoadingProduct &&
                productsByClientList.length !== 0 &&
                currentClientIdSelected !== '' && (
                  <SelectedProductsItems
                    productsByClientList={productsByClientList}
                    inactiveProductsCount={inactiveProductsCount}
                    activeProductsCount={activeProductsCount}
                    pendingProductsCount={pendingProductsCount}
                    pendingProductsCountToActive={pendingProductsCountToActive}
                  />
                )}

              {/* Mostrar mensaje si no hay datos disponibles */}
              {!isLoadingProduct &&
                productsByClientList.length === 0 &&
                currentClientIdSelected !== '' && (
                  <div
                    className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                    role='alert'
                  >
                    <div>{infoMessageNoProductsCreated}</div>
                  </div>

                  // <p>{infoMessageProduct}</p>
                )}
            </div>
          </div>
          {/* End: Card con resumen de productos del cliente Seleccionado */}

          {/* Begin: Card con las acciones para el cliente seleccionado */}
          <div className='col-xl-4'>
            <div className='card card-custom px-12 py-12 h-100'>
              <div className='d-flex mb-4 justify-content-center'>
                <h5 className='card-label me-4'>
                  <span className='text-dark text-sm font-weight-bolder mt-3'>
                    {intl.formatMessage({id: 'GLOBAL.ACTIONS'})}
                  </span>
                </h5>
              </div>

              {/* Mostrar el mensaje de alerta */}
              {infoMessageClientSelected && currentClientIdSelected === '' && (
                <div
                  className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                  role='alert'
                >
                  <div>{infoMessageClientSelected}</div>
                </div>
              )}

              {/* Mostrar animación de carga si isLoading es verdadero */}
              {isLoadingProduct && currentClientIdSelected !== '' && (
                <LoadingAnimation
                  alignment={'center'}
                  label={intl.formatMessage({id: 'GLOBAL.LOADING'})}
                  size='lg'
                />
              )}
              {/* Mostrar tabla si hay datos disponibles */}
              {!isLoadingProduct && currentClientIdSelected !== '' && (
                <ClientSelectedButtonsCard
                  productsByClientList={productsByClientList}
                  currentClientNameSelected={currentClientNameSelected}
                  currentClientIdSelected={currentClientIdSelected}
                  currentClientStatusSelected={currentClientStatusSelected}
                  currentClientMailOwnerSelected={currentClientMailOwnerSelected}
                  getClientsSharedBUList={getClientsSharedBUList}
                  getProductsRegisters={getProductsRegisters}
                  // currentClientOwnerSelected={currentClientOwnerSelected}
                />
              )}
              {/* Mostrar mensaje si no hay datos disponibles */}
              {/* {!isLoadingProduct && currentClientIdSelected === '' && (
                <p>{infoMessageProduct}</p>
              )} */}
            </div>
          </div>
          {/* End: Card con las acciones para el cliente seleccionado */}
        </div>
        {/* End: Row con con dos cards */}

        {/* Begin: Espacio para renderizar la tabla de productos */}
        <div className='card card-custom px-12 py-12'>
          {/* Mostrar el mensaje de alerta */}
          {infoMessageProduct && currentClientIdSelected === '' && (
            <div
              className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
              role='alert'
            >
              <div>{infoMessageProduct}</div>
            </div>
          )}
          {/* Mostrar animación de carga si isLoading es verdadero */}
          {isLoadingProduct && currentClientIdSelected !== '' && (
            <LoadingAnimation
              alignment={'center'}
              label={intl.formatMessage({id: 'GLOBAL.LOADING'})}
              size='lg'
            />
          )}
          {/* Mostrar tabla si hay datos disponibles */}
          {!isLoadingProduct &&
            productsByClientList.length > 0 &&
            currentClientIdSelected !== '' && (
              <ProductsAccountManagerTable
                productsByClientList={productsByClientList}
                currentClientIdSelected={currentClientIdSelected}
                currentClientNameSelected={currentClientNameSelected}
                currentClientStatusSelected={currentClientStatusSelected}
                updateProductsByClientList={() =>
                  updateProductsByClientList(
                    currentClientIdSelected,
                    currentClientStatusSelected,
                    currentClientNameSelected
                  )
                } // Propagación de la función
              />
              // <h1>hola</h1>
            )}
          {/* Mostrar mensaje si no hay datos disponibles */}
          {!isLoadingProduct &&
            productsByClientList.length === 0 &&
            currentClientIdSelected !== '' && (
              <div
                className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                role='alert'
              >
                <div>{infoMessageNoProductsCreated}</div>
              </div>

              // <p>{infoMessageProduct}</p>
            )}
        </div>
        {/* End: Espacio para renderizar la tabla de productos */}
      </div>
    </>
  )
}

export {AccountManagement} // Exportamos el componente
