import {Dropdown} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import * as XLSX from 'xlsx'

export const DropdownItemExportXLSX = ({dataToExport, title}) => {
  console.log(dataToExport)
  console.log(title)
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()
  const handleToExportProductsAssignedDataXLSX = () => {
    try {
      // Verificar si hay datos para exportar
      if (!dataToExport || !dataToExport.brandProducts) {
        console.error(intl.formatMessage({id: 'DROPDOWNS.NO_DATA_TO_EXPORT'}))
        return
      }

      // Obtener el usuario y sus productos
      const {id: id, name: name, brandProducts} = dataToExport

      // Crear una nueva hoja de cálculo
      const wb = XLSX.utils.book_new()

      const worksheetData = [
        [intl.formatMessage({id: 'DROPDOWNS.ASSIGNED_PRODUCTS'}), '', '', '', ''],
        [
          intl.formatMessage({id: 'DROPDOWNS.USER_ID'}),
          intl.formatMessage({id: 'DROPDOWNS.USER_NAME'}),
          intl.formatMessage({id: 'DROPDOWNS.PRODUCT_ID'}),
          intl.formatMessage({id: 'DROPDOWNS.PRODUCT_NAME'}),
          intl.formatMessage({id: 'DROPDOWNS.ASSIGNMENT_DATE'}),
        ],
      ]

      // Recorrer los productos del usuario
      brandProducts.forEach((product) => {
        // Agregar datos del producto a la hoja de cálculo
        const productId = product.pro_id
        const productName = product.name

        // Agregar fila a la matriz de datos de la hoja de cálculo
        worksheetData.push([id, name, productId, productName, ''])
      })

      // Crear la hoja de cálculo
      const ws = XLSX.utils.aoa_to_sheet(worksheetData)

      // Fusionar las celdas para cada propiedad
      const mergeRanges = [
        {s: {r: 0, c: 0}, e: {r: 0, c: 4}}, // Productos asignados
        {s: {r: 1, c: 0}, e: {r: 1, c: 0}}, // Id usuario
        {s: {r: 1, c: 1}, e: {r: 1, c: 1}}, // Nombre usuario
        {s: {r: 1, c: 2}, e: {r: 1, c: 2}}, // Id del producto
        {s: {r: 1, c: 4}, e: {r: 1, c: 4}}, // Nombre del producto
        {s: {r: 1, c: 4}, e: {r: 1, c: 4}}, // Fecha de asignación
      ]

      // Especificar el ancho de las columnas
      ws['!cols'] = [
        {wch: 25}, // Id usuario
        {wch: 30}, // Nombre usuario
        {wch: 25}, // Id del producto
        {wch: 30}, // Nombre del producto
        {wch: 30}, // Fecha de asignación
      ]

      ws['!merges'] = mergeRanges

      // Agregar la hoja de cálculo al libro de trabajo
      XLSX.utils.book_append_sheet(
        wb,
        ws,
        intl.formatMessage({id: 'DROPDOWNS.ASSIGNED_PRODUCTS'})
      )

      // Guardar el libro de trabajo en un archivo XLSX
      XLSX.writeFile(
        wb,
        intl.formatMessage({id: 'DROPDOWNS.EXPORTED_DATA_FILENAME'}, {title}) + '.xlsx'
      )
    } catch (error) {
      console.error('Error al generar el archivo XLSX:', error)
    }
  }

  return (
    <Dropdown.Item>
      <span onClick={handleToExportProductsAssignedDataXLSX}>
        {`${intl.formatMessage({id: 'DROPDOWNS.EXPORT_ASSIGNED_PRODUCTS'})} (.xlsx)`}
      </span>
    </Dropdown.Item>
  )
}
