import {lazy, FC, Suspense, useContext} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardNew} from '../standard-modules/dashboard/DashboardNew'
// import {MenuTestPage} from '../standard-modules/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {FaqPage} from '../standard-modules/support/Faq'
import {Leads} from '../standard-modules/leads/Leads'
import {AccountManagement} from '../standard-modules/account-management/AccountManagement'
import SellersPage from '../standard-modules/control-sellers/sellers/Sellers'
import {Products} from '../standard-modules/products/Products'
import {Brands} from '../standard-modules/brands/Brands'
import {Campaigns} from '../standard-modules/campaigns/Campaigns'
import {DetailsPage} from '../standard-modules/details/DetailModal'
import {Assigns} from '../standard-modules/assigns/Assigns'
import {Logs} from '../standard-modules/logs/Logs'
import {ReportsPage} from '../standard-modules/reports/Reports'
import {LeadDetail} from '../standard-modules/leads/lead-detail/LeadDetail'
import {LeadSpam} from '../standard-modules/leads/lead-spam/LeadSpam'
import {ShareAccess} from '../standard-modules/share-access/ShareAccess'
import {LinksDcem} from '../standard-modules/linksdcem/LinksDcem'
import {ChatWab} from '../plus-modules/chat-wab/ChatWab'
import {Login} from '../auth/aux-components/Login'
import {Dates} from '../plus-modules/dates/Dates'
import {Bi} from '../plus-modules/bi/Bi'
import {PreCRM} from '../plus-modules/crm/PreCRM'
import SellersGroupPage from '../standard-modules/control-sellers/sellers-groups/SellersGroups'
import {Support} from '../standard-modules/help/support/Support'
import {Faq} from '../standard-modules/help/faq/Faq'
import {Manual} from '../standard-modules/help/manual/Manual'

// Paginas de reportes
import {LeadsReport} from '../standard-modules/reports/each-report/leads/LeadsReport'
import {GradesReport} from '../standard-modules/reports/each-report/grades/GradesReport'
import {CrmReport} from '../standard-modules/reports/each-report/crm/CrmReport'
import {QualityReport} from '../standard-modules/reports/each-report/quality/QualityReport'
import {CampaignsReport} from '../standard-modules/reports/each-report/campaigns/CampaignsReport'
import {InteractionsReport} from '../standard-modules/reports/each-report/interactions/InteractionsReport'
import {IscoreReport} from '../standard-modules/reports/each-report/iscore/IscoreReport'
import {YearlyReport} from '../standard-modules/reports/each-report/yearly/YearlyReport'
// Servicios de Twlio
import {TwlioServices} from '../standard-modules/twlio-services/twlio-services/TwlioServices'
import {TwlioTemplates} from '../standard-modules/twlio-services/twlio-templates/TwlioTemplates'
import {TwlioTemplatesAddForm} from '../standard-modules/twlio-services/twlio-templates/AddTemplatesForm'
import {UserContext} from '../app-context/user-context/UserContext'
import {DRG_STANDAR_MODULES, DRG_USERS_TYPES} from './constants/_Contstants'
import {canAccessFeature} from '../utils/functions/_Functions'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))

  const {user, token, updateToken} = useContext(UserContext)
  const userType: string = user.user_type

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Common routes  */}
        <Route path='/login' element={<Login />} />
        {/* DASBOARD MODULE */}
        {canAccessFeature(DRG_STANDAR_MODULES.DASHBOARD.value, userType, 1) ||
        canAccessFeature(DRG_STANDAR_MODULES.DASHBOARD.value, userType, 2) ||
        canAccessFeature(DRG_STANDAR_MODULES.DASHBOARD.value, userType, 3) ||
        canAccessFeature(DRG_STANDAR_MODULES.DASHBOARD.value, userType, 4) ? (
          <Route path='dashboard' element={<DashboardNew />} />
        ) : null}
        {/* EXPORT LEADS MODULE */}
        {/* LEADS MODULE */}
        {userType === DRG_USERS_TYPES.ASISTENTE.value ? (
          <Route path='auth/*' element={<Navigate to='/assigns' />} />
        ) : canAccessFeature(DRG_STANDAR_MODULES.LEADS.value, userType, 1) ||
          canAccessFeature(DRG_STANDAR_MODULES.LEADS.value, userType, 2) ||
          canAccessFeature(DRG_STANDAR_MODULES.LEADS.value, userType, 3) ||
          canAccessFeature(DRG_STANDAR_MODULES.LEADS.value, userType, 4) ? (
          <>
            <Route path='auth/*' element={<Navigate to='/leads' />} />
            <Route path='/leads' element={<Leads />} />
            <Route path='/lead-spam' element={<LeadSpam />} />
            <Route path='/lead-detail' element={<LeadDetail />} />
            <Route path='/lead-spam' element={<LeadSpam />} />
          </>
        ) : null}
        {/* REASSIGN MODULE */}
        {/* ASSIGNS MODULE */}
        {canAccessFeature(DRG_STANDAR_MODULES.ASSIGNS.value, userType, 1) ||
        canAccessFeature(DRG_STANDAR_MODULES.ASSIGNS.value, userType, 2) ||
        canAccessFeature(DRG_STANDAR_MODULES.ASSIGNS.value, userType, 3) ||
        canAccessFeature(DRG_STANDAR_MODULES.ASSIGNS.value, userType, 4) ? (
          <Route path='/assigns' element={<Assigns />} />
        ) : null}
        {/* HELP CENTER MODULE */}
        {canAccessFeature(DRG_STANDAR_MODULES.HELP_CENTER.value, userType, 1) ||
        canAccessFeature(DRG_STANDAR_MODULES.HELP_CENTER.value, userType, 2) ||
        canAccessFeature(DRG_STANDAR_MODULES.HELP_CENTER.value, userType, 3) ||
        canAccessFeature(DRG_STANDAR_MODULES.HELP_CENTER.value, userType, 4) ? (
          <>
            <Route path='support' element={<Support />} />
            <Route path='faq' element={<Faq />} />
            <Route path='manual' element={<Manual />} />
          </>
        ) : null}
        {/* ALL REPORTS MODULE */}
        {!canAccessFeature(DRG_STANDAR_MODULES.REPORTS.value, userType, 2) && (
          <>
            <Route path='/report-leads' element={<LeadsReport />} />
            <Route path='/grades-report' element={<GradesReport />} />
            <Route path='/quality-report' element={<QualityReport />} />
            <Route path='/yearly-report' element={<YearlyReport />} />
            <Route path='/campaigns-report' element={<CampaignsReport />} />
            <Route path='/interactions-report' element={<InteractionsReport />} />
            <Route path='/iscore-report' element={<IscoreReport />} />
          </>
        )}
        ()
        {/* CRM REPORT MODULE */}
        {canAccessFeature(DRG_STANDAR_MODULES.REPORT_CRM.value, userType, 2) && (
          <Route path='/crm-report' element={<CrmReport />} />
        )}
        {/* BRANDS MODULE */}
        {canAccessFeature(DRG_STANDAR_MODULES.BRANDS.value, userType, 1) ||
        canAccessFeature(DRG_STANDAR_MODULES.BRANDS.value, userType, 2) ||
        canAccessFeature(DRG_STANDAR_MODULES.BRANDS.value, userType, 3) ||
        canAccessFeature(DRG_STANDAR_MODULES.BRANDS.value, userType, 4) ? (
          <Route path='/brands' element={<Brands />} />
        ) : null}
        {/* PRODUCTS MODULE */}
        {canAccessFeature(DRG_STANDAR_MODULES.PRODUCTS.value, userType, 1) ||
        canAccessFeature(DRG_STANDAR_MODULES.PRODUCTS.value, userType, 2) ||
        canAccessFeature(DRG_STANDAR_MODULES.PRODUCTS.value, userType, 3) ||
        canAccessFeature(DRG_STANDAR_MODULES.PRODUCTS.value, userType, 4) ? (
          <Route path='/products' element={<Products />} />
        ) : null}
        {/* LINK DCEM MODULE */}
        {canAccessFeature(DRG_STANDAR_MODULES.LINK_DCEM.value, userType, 1) ||
        canAccessFeature(DRG_STANDAR_MODULES.LINK_DCEM.value, userType, 2) ||
        canAccessFeature(DRG_STANDAR_MODULES.LINK_DCEM.value, userType, 3) ||
        canAccessFeature(DRG_STANDAR_MODULES.LINK_DCEM.value, userType, 4) ? (
          <Route path='/linksdcem' element={<LinksDcem />} />
        ) : null}
        {/* SHARE ACCESS MODULE */}
        {canAccessFeature(DRG_STANDAR_MODULES.SHARE_ACCESS.value, userType, 1) ||
        canAccessFeature(DRG_STANDAR_MODULES.SHARE_ACCESS.value, userType, 2) ||
        canAccessFeature(DRG_STANDAR_MODULES.SHARE_ACCESS.value, userType, 3) ||
        canAccessFeature(DRG_STANDAR_MODULES.SHARE_ACCESS.value, userType, 4) ? (
          <Route path='/share-access' element={<ShareAccess />} />
        ) : null}
        {/* CAMPAIGN MODULE */}
        {canAccessFeature(DRG_STANDAR_MODULES.CAMPAIGNS.value, userType, 1) ||
        canAccessFeature(DRG_STANDAR_MODULES.CAMPAIGNS.value, userType, 2) ||
        canAccessFeature(DRG_STANDAR_MODULES.CAMPAIGNS.value, userType, 3) ||
        canAccessFeature(DRG_STANDAR_MODULES.CAMPAIGNS.value, userType, 4) ? (
          <Route path='/campaigns' element={<Campaigns />} />
        ) : null}
        {/* WAB MODULE */}
        {canAccessFeature(DRG_STANDAR_MODULES.CHATWAB.value, userType, 1) ||
        canAccessFeature(DRG_STANDAR_MODULES.CHATWAB.value, userType, 2) ||
        canAccessFeature(DRG_STANDAR_MODULES.CHATWAB.value, userType, 3) ||
        canAccessFeature(DRG_STANDAR_MODULES.CHATWAB.value, userType, 4) ? (
          <Route path='/chat-wab' element={<ChatWab />} />
        ) : null}
        {/* Twilio */}
        <Route path='/twlio-services/twlio-services' element={<TwlioServices />} />
        <Route path='/twlio-services/twlio-templates' element={<TwlioTemplates />} />
        <Route
          path='/twlio-services/twlio-templates/AddTemplatesForm'
          element={<TwlioTemplatesAddForm />}
        />
        {/* USER GROUPS MODULE */}
        {canAccessFeature(DRG_STANDAR_MODULES.USERS_GROUPS.value, userType, 1) ||
        canAccessFeature(DRG_STANDAR_MODULES.USERS_GROUPS.value, userType, 2) ||
        canAccessFeature(DRG_STANDAR_MODULES.USERS_GROUPS.value, userType, 3) ||
        canAccessFeature(DRG_STANDAR_MODULES.USERS_GROUPS.value, userType, 4) ? (
          <Route path='group-sellers' element={<SellersGroupPage />} />
        ) : null}
        {/* USER MODULE */}
        {canAccessFeature(DRG_STANDAR_MODULES.USERS.value, userType, 1) ||
        canAccessFeature(DRG_STANDAR_MODULES.USERS.value, userType, 2) ||
        canAccessFeature(DRG_STANDAR_MODULES.USERS.value, userType, 3) ||
        canAccessFeature(DRG_STANDAR_MODULES.USERS.value, userType, 4) ? (
          <Route path='sellers' element={<SellersPage />} />
        ) : null}
        {/* ROLES AND PRIVILEGES MODULE */}
        {/* CLIENTS MODULE */}
        {/* SPAM MODULE */}
        {/* RECORDINGS MODULE */}
        {/* Redirect to Dashboard after success login/registartion */}
        {/* Pages */}
        {/* ACCOUNT MANAGEMENT MODULE */}
        {canAccessFeature(DRG_STANDAR_MODULES.ACCOUNT_MANAGEMENT.value, userType, 1) ||
        canAccessFeature(DRG_STANDAR_MODULES.ACCOUNT_MANAGEMENT.value, userType, 2) ||
        canAccessFeature(DRG_STANDAR_MODULES.ACCOUNT_MANAGEMENT.value, userType, 3) ||
        canAccessFeature(DRG_STANDAR_MODULES.ACCOUNT_MANAGEMENT.value, userType, 4) ? (
          <Route path='/account-management' element={<AccountManagement />} />
        ) : null}
        {/* LOGS MODULE */}
        {canAccessFeature(DRG_STANDAR_MODULES.LOGS.value, userType, 1) ||
        canAccessFeature(DRG_STANDAR_MODULES.LOGS.value, userType, 2) ||
        canAccessFeature(DRG_STANDAR_MODULES.LOGS.value, userType, 3) ||
        canAccessFeature(DRG_STANDAR_MODULES.LOGS.value, userType, 4) ? (
          <Route path='/logs' element={<Logs />} />
        ) : null}
        <Route path='/bi' element={<Bi />} />
        <Route path='/dates' element={<Dates />} />
        <Route path='/pre-CRM' element={<PreCRM />} />
        {/* <Route path='/assigns' element={<Assigns />} /> */}
        {/* <Route path='dashboard' element={<DashboardWrapper />} /> */}
        {/* <Route path='dashboard' element={<DashboardWrapper />} /> */}
        {/* <Route path='/lead-detail/:leadId' element={<LeadDetail />} /> */}
        {/* <Route path='menu-test' element={<MenuTestPage />} /> */}
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='details/sellers/*'
          element={
            <SuspensedView>
              <DetailsPage />
            </SuspensedView>
          }
        />
        <Route
          path='reports/*'
          element={
            <SuspensedView>
              <ReportsPage />
            </SuspensedView>
          }
        />
        {canAccessFeature(DRG_STANDAR_MODULES.HELP_CENTER.value, userType, 1) ||
        canAccessFeature(DRG_STANDAR_MODULES.HELP_CENTER.value, userType, 2) ||
        canAccessFeature(DRG_STANDAR_MODULES.HELP_CENTER.value, userType, 3) ||
        canAccessFeature(DRG_STANDAR_MODULES.HELP_CENTER.value, userType, 4) ? (
          <Route
            path='Support/*'
            element={
              <SuspensedView>
                <Support />
              </SuspensedView>
            }
          />
        ) : null}
        {canAccessFeature(DRG_STANDAR_MODULES.CHATWAB.value, userType, 1) ||
        canAccessFeature(DRG_STANDAR_MODULES.CHATWAB.value, userType, 2) ||
        canAccessFeature(DRG_STANDAR_MODULES.CHATWAB.value, userType, 3) ||
        canAccessFeature(DRG_STANDAR_MODULES.CHATWAB.value, userType, 4) ? (
          <Route
            path='/chat-wab/*'
            element={
              <SuspensedView>
                <ChatWab />
              </SuspensedView>
            }
          />
        ) : null}
        {canAccessFeature(DRG_STANDAR_MODULES.SHARE_ACCESS.value, userType, 1) ||
        canAccessFeature(DRG_STANDAR_MODULES.SHARE_ACCESS.value, userType, 2) ||
        canAccessFeature(DRG_STANDAR_MODULES.SHARE_ACCESS.value, userType, 3) ||
        canAccessFeature(DRG_STANDAR_MODULES.SHARE_ACCESS.value, userType, 4) ? (
          <Route
            path='/share-access/*'
            element={
              <SuspensedView>
                <ShareAccess />
              </SuspensedView>
            }
          />
        ) : null}
        <Route
          path='/dates/*'
          element={
            <SuspensedView>
              <Dates />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
