// Importamos hooks de react que usaremos para este componente
import {useEffect, useState, useContext} from 'react'
// Importamos libreria para menejo de lenguaje
import {useIntl} from 'react-intl'

// ---------------------------------------------------------------------------------------

// Importamos el contexto de usuario
import {UserContext} from '../../../../app-context/user-context/UserContext'

// ---------------------------------------------------------------------------------------

// Importamos la funcion productInfoById para conexion con la API de productos
import {productInfoById} from '../../../products/services/_ProductsListApi'
// Importamos la funcion autoCampaignsNew para conexion con la API de campañas
import {autoCampaignsNew} from '../../services/_CampaignsNewApi'

// ---------------------------------------------------------------------------------------

// Importamos funcion para vencimiento de la sesion
import {sessionExpired} from '../../../../utils/functions/_Functions'

// ---------------------------------------------------------------------------------------

// Importamos las interfaces globales que usaremos para este modulo
import {AlertInfo} from '../../../../utils/interfaces/_InterfacesTypes'
// Importamos las interfaces de campañas que usaremos para este componente
import {CampaignInProduct} from '../../interfaces/campaignsTypes'

// Iniciamos funcion para construir componente AutoTabForm
function AutoTabForm({productId, closeModal, getCampaignsRegisters, setCurrentPage}) {
  console.log('id producto: ', productId)

  const {user, token} = useContext(UserContext)
  const cli_id = user.cli_id
  const userToken = token

  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  const [productCampaigns, setProductCampaigns] = useState<CampaignInProduct[]>([])
  const [newAutoCampaigns, setNewAutoCampaigns] = useState<string[]>([])

  const [alertInfo, setAlertInfo] = useState<AlertInfo>({
    text: '',
    variant: 'primary',
  })

  const getProductInfo = async () => {
    try {
      const {data: jsonData, code, msg} = await productInfoById(userToken, productId)

      if (code === 401 && msg === 'Token no valido') {
        sessionExpired(intl.formatMessage({id: 'GLOBAL.ALERT.SESSION_EXPIRED'}))
        return
      } else {
        const campanas = jsonData.campaigns.map((campaignData: any) => ({
          name: campaignData.name,
          cam_channel: campaignData.cam_channel,
        }))

        setProductCampaigns(campanas)
        console.log('campañas en tab auto: ', campanas)
      }
    } catch (error) {
      console.log('Error receiving products: ', error)
    }
  }

  const shouldRenderCheckbox = (camName: string, camChannel: string) => {
    return !productCampaigns.some(
      (campaign) => campaign.name === camName && campaign.cam_channel === camChannel
    )
  }

  const shouldRenderSubmitButton = () => {
    let contador = 0

    if (
      productCampaigns.some(
        (campaign) => campaign.name === 'Lead Gen' && campaign.cam_channel === 'Facebook Ads'
      )
    ) {
      contador++
    }

    if (
      productCampaigns.some(
        (campaign) => campaign.name === 'Lead Gen' && campaign.cam_channel === 'Instagram'
      )
    ) {
      contador++
    }

    if (
      productCampaigns.some(
        (campaign) => campaign.name === 'Landing' && campaign.cam_channel === 'Google Ads'
      )
    ) {
      contador++
    }

    if (
      productCampaigns.some(
        (campaign) => campaign.name === 'Visita en Sitio' && campaign.cam_channel === 'Walk-in'
      )
    ) {
      contador++
    }

    if (
      productCampaigns.some(
        (campaign) => campaign.name === 'Referenciado' && campaign.cam_channel === 'Referenciado'
      )
    ) {
      contador++
    }

    return contador === 5
  }

  useEffect(() => {
    getProductInfo()
  }, [productId])

  const muestraArray = () => {
    console.log('campañas automaticas: ', newAutoCampaigns)
  }

  const handleChangeAutoCam = (value: string) => {
    const updatedAutoCampaigns = [...newAutoCampaigns]

    const index = updatedAutoCampaigns.indexOf(value)

    if (index === -1) {
      updatedAutoCampaigns.push(value)
    } else {
      updatedAutoCampaigns.splice(index, 1)
    }

    setNewAutoCampaigns(updatedAutoCampaigns)

    muestraArray()
  }

  const handleFormSubmit = async () => {
    const automaticsCampaigns = newAutoCampaigns

    try {
      const {
        data: jsonData,
        code,
        msg,
      } = await autoCampaignsNew(cli_id, productId, automaticsCampaigns, userToken)

      if (code === 401 && msg === 'Token no valido') {
        sessionExpired(intl.formatMessage({id: 'GLOBAL.ALERT.SESSION_EXPIRED'}))
        return
      } else {
        setAlertInfo({
          text:
            code === 200
              ? intl.formatMessage({id: 'CAMPAIGNS.MODAL_NEW.SUCCESS_CAMPAIGN_CREATION'})
              : intl.formatMessage({id: 'CAMPAIGNS.MODAL_NEW.FAIL_CAMPAIGN_CREATION'}),
          variant: code === 200 ? 'success' : 'danger',
        })

        setTimeout(() => {
          setAlertInfo({
            text: '',
            variant: '',
          })
          closeModal()
          getCampaignsRegisters()
          setCurrentPage(1)
        }, 3000)
      }
    } catch (error) {
      setAlertInfo({
        text: intl.formatMessage({id: 'GLOBAL.ALERT_ERROR_AXIOS_REQUEST'}),
        variant: 'danger',
      })
    }
  }

  return (
    <>
      <div className='mb-8'>
        <span className='text-muted'>
          {intl.formatMessage({id: 'AUTO_TAB_FORM.SELECT_CAMPAIGNS'})}
        </span>
        <br />
        <span className='text-muted'>
          {intl.formatMessage({id: 'AUTO_TAB_FORM.CAMPAIGN_PARAMETERS'})}
        </span>
        <br />
        <span className='text-muted'>
          {intl.formatMessage({id: 'AUTO_TAB_FORM.NOTIFICATIONS'})}
        </span>
        <br />
        <span className='text-muted'>
          {intl.formatMessage({id: 'AUTO_TAB_FORM.DUPLICATE_NOTIFICATION_DAYS'})}
        </span>
        <br />
        <br />
        <span className='text-muted'>
          {intl.formatMessage({id: 'AUTO_TAB_FORM.AUTOMATIC_ROLE'})}
        </span>
        <br />
        <br />
        <span className='text-muted'>
          {intl.formatMessage({id: 'AUTO_TAB_FORM.ATTENTION_DAYS'})}
        </span>
        <br />
        <span className='text-muted'>{intl.formatMessage({id: 'AUTO_TAB_FORM.START_TIME'})}</span>
        <br />
        <span className='text-muted'>{intl.formatMessage({id: 'AUTO_TAB_FORM.END_TIME'})}</span>
        <br />
      </div>

      <table className='table'>
        <thead>
          <tr>
            <th>{intl.formatMessage({id: 'AUTO_TAB_FORM.CAMPAIGN_NAME'})}</th>
            <th>{intl.formatMessage({id: 'GLOBAL.TYPE'})}</th>
          </tr>
        </thead>
        <tbody>
          <tr className='mx-1 my-2'>
            <td>Lead Gen</td>
            <td>
              {shouldRenderCheckbox('Lead Gen', 'Facebook Ads') ? (
                <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid mb-6'>
                  &nbsp;&nbsp;
                  <input
                    type='checkbox'
                    className='form-check-input'
                    // id="leadGenFace"
                    value='leadGenFace'
                    defaultChecked={false}
                    onChange={(e) => handleChangeAutoCam(e.target.value)}
                  />
                  Facebook Ads
                </label>
              ) : (
                <p className='text-muted'>
                  {intl.formatMessage({id: 'AUTO_TAB_FORM.FACEBOOK_ADS_EXISTS'})}
                </p>
              )}
            </td>
          </tr>
          <tr className='mx-1 my-2'>
            <td>Lead Gen</td>
            <td>
              {shouldRenderCheckbox('Lead Gen', 'Instagram') ? (
                <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid mb-6'>
                  &nbsp;&nbsp;
                  <input
                    type='checkbox'
                    className='form-check-input'
                    // id="leadGenInsta"
                    value='leadGenInsta'
                    defaultChecked={false}
                    onChange={(e) => handleChangeAutoCam(e.target.value)}
                  />
                  Instagram
                </label>
              ) : (
                <p className='text-muted'>
                  {intl.formatMessage({id: 'AUTO_TAB_FORM.INSTAGRAM_EXISTS'})}
                </p>
              )}
            </td>
          </tr>
          <tr className='mx-1 my-2'>
            <td>Landing</td>
            <td>
              {shouldRenderCheckbox('Landing', 'Google Ads') ? (
                <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid mb-6'>
                  &nbsp;&nbsp;
                  <input
                    type='checkbox'
                    className='form-check-input'
                    // id="visitaWalk"
                    value='landingGoogle'
                    defaultChecked={false}
                    onChange={(e) => handleChangeAutoCam(e.target.value)}
                  />
                  Google Ads
                </label>
              ) : (
                <p className='text-muted'>
                  {intl.formatMessage({id: 'AUTO_TAB_FORM.GOOGLE_ADS_EXISTS'})}
                </p>
              )}
            </td>
          </tr>
          <tr className='mx-1 my-2'>
            <td>{intl.formatMessage({id: 'CAMPAIGNS.ON_SITE_VISIT'})}</td>
            <td>
              {shouldRenderCheckbox('Visita en Sitio', 'Walk-in') ? (
                <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid mb-6'>
                  &nbsp;&nbsp;
                  <input
                    type='checkbox'
                    className='form-check-input'
                    // id="visitaWalk"
                    value='visitaWalk'
                    defaultChecked={false}
                    onChange={(e) => handleChangeAutoCam(e.target.value)}
                  />
                  Walk-in
                </label>
              ) : (
                <p className='text-muted'>
                  {intl.formatMessage({id: 'AUTO_TAB_FORM.WALK_IN_EXISTS'})}
                </p>
              )}
            </td>
          </tr>

          <tr className='mx-1 my-2'>
            <td>{intl.formatMessage({id: 'PRODUCTS.REFERENCED'})}</td>
            <td>
              {shouldRenderCheckbox('Referenciado', 'Referenciado') ? (
                <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid mb-6'>
                  &nbsp;&nbsp;
                  <input
                    type='checkbox'
                    className='form-check-input'
                    // id="refRef"
                    value='refRef'
                    defaultChecked={false}
                    onChange={(e) => handleChangeAutoCam(e.target.value)}
                  />
                  {intl.formatMessage({id: 'PRODUCTS.REFERENCED'})}
                </label>
              ) : (
                <p className='text-muted'>
                  {intl.formatMessage({id: 'AUTO_TAB_FORM.REFERRAL_EXISTS'})}
                </p>
              )}
            </td>
          </tr>
        </tbody>
      </table>
      {alertInfo.text && (
        <div className={`alert alert-${alertInfo.variant} d-flex align-items-center`} role='alert'>
          <div>{alertInfo.text}</div>
        </div>
      )}

      {shouldRenderSubmitButton() ? (
        <button type='submit' className='mt-4 btn btn-primary btn-sm' disabled>
          {intl.formatMessage({id: 'GLOBAL.SAVE'})}
        </button>
      ) : (
        <button type='submit' className='mt-4 btn btn-primary btn-sm' onClick={handleFormSubmit}>
          {intl.formatMessage({id: 'GLOBAL.SAVE'})}
        </button>
      )}
    </>
  )
}

export {AutoTabForm}
