import axios from 'axios'
import {DragonMainApi, EPLogin} from '../../utils/constants/_Constants'
import { DRG_USERS_TYPES } from '../../routing/constants/_Contstants'

// const API_DRAGON_URL= 'https://dev-api-main.dcem.io/auth/login'


async function LoginApi(mail: string, password: string, userType:string) {
  try {
    const response = await axios.post(DragonMainApi + EPLogin, {
      mail,
      password,
    })

    // Comprobar si existe 'miData' en localStorage
    if (localStorage.getItem('miData') !== null) {
      // La clave 'miData' existe y contiene información, así que podemos eliminarla
      localStorage.removeItem('miData')
      console.log('Había información, ya se borró')
    }

    // Comprobar si existe 'miData' en localStorage
    if (localStorage.getItem('clientData') !== null) {
      // La clave 'miData' existe y contiene información, así que podemos eliminarla
      localStorage.removeItem('clientData')
      console.log('Había información del cliente, ya se borró')
    }

    // Ahora, la respuesta JSON está almacenada en la variable 'response.data'
    const jsonData = response.data

    // Volvemos una cadena de texto la data devuelta por la api
    const jsonString = JSON.stringify(jsonData)
    console.log(jsonString)

    if (jsonData.token) {
      // Guardamos la información (en formato string) que nos regresan, en localStorage
      localStorage.setItem('miData', jsonString)

      // Guardar cli_id y cli_name en clientData
      const clientData = {
        cli_id: jsonData.user.cli_id, // Asegúrate de que estas propiedades existan en jsonData
        cli_name: jsonData.user.cli_name,
      }
      localStorage.setItem('clientData', JSON.stringify(clientData))

      // Redirige al path '/dashboard' si existe 'token'
      if (localStorage.getItem('leadPath')) {
        // Acá la funcionalidad para armar la url y redireccionar a la ruta detalle de lead
        let path = localStorage.getItem('leadPath')
        window.location.replace(path ? path : userType === DRG_USERS_TYPES.CAPTURISTA.value ? '/assigns':'/leads')
      } else {
        // Actualizar el item al valor de la ruta actual, si no existe, agregar una por defecto.
        const actualPath = localStorage.getItem('actualPath') as string | null
        window.location.replace(actualPath ? actualPath : userType === DRG_USERS_TYPES.CAPTURISTA.value ? '/assigns':'/leads')
      }

      return
    }
    return jsonData // Si deseas retornar los datos
  } catch (error) {
    throw error // Puedes manejar el error aquí o propagarlo.
  }
}

export {LoginApi}
