import {FC} from 'react'
import {LinkDCEM} from '../interfaces/linksDcemTypes'
import {useIntl} from 'react-intl'

type Props = {
  linkDcem: any
}

const ModalDcemEdit: FC<Props> = ({linkDcem}) => {
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  return (
    <>
      <div className='px-7'>
        <span className='text-danger'>
          *{intl.formatMessage({id: 'LINKDCEM.EDIT_URL_SECTION'})}
        </span>
        <br />
        <br />

        <div className='mb-5'>
          <div>
            <label className='fs-6 fw-bold required'>
              {intl.formatMessage({id: 'LINKDCEM.LINK_DCEM'})}
            </label>
            <input
              required
              type='url'
              name='shortedLink'
              className='form-control form-control-solid mb-3 mb-lg-0 required'
              value={linkDcem.shortedLink}
              readOnly
            />
          </div>
        </div>

        <div className='mb-5'>
          <div>
            <label className='fs-6 fw-bold required'>
              {intl.formatMessage({id: 'LINKDCEM.ASSOCIATED_URL'})}
            </label>
            <input
              required
              type='url'
              name='OriginalLink'
              className='form-control form-control-solid mb-3 mb-lg-0 required'
              value={linkDcem.originalLink}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export {ModalDcemEdit}
