import { FC, useEffect, useState, useContext } from 'react'
import { LoadingAnimation } from '../../../../utils/components/loading/LoadingAnimation'
import { campaignsListForProduct } from '../../../campaigns/services/_CampaignsListApi'
import { UserContext } from '../../../../app-context/user-context/UserContext'
import {
  sessionExpired,
  setPageTitle,
  dateFormatterISO8601Variant,
  dateFormatterTextualEnTwo,
} from '../../../../utils/functions/_Functions'
import { DragonCem } from '../../../../utils/constants/_Constants'
import { IscoreLeadData, iscoreReport } from './interfaces/iscoreReportTypes'
import { LeadsIscoreCountsItem } from './aux-components/LeadsIscoreCountsItem'
import { KTIcon } from '../../../../../_metronic/helpers'
import { IscoreReportMainChart } from './charts/IscoreReportMainChart'
import { IscoreReportComparativeChart } from './charts/IscoreReportComparativeChart'
import { DateRangePickerFull } from '../../../../utils/components/date-pickers/DateRangePickerFull'
import { ProductsByUserDropdown } from '../../../../utils/components/dropdowns/ProductsByUserDropdown'
import { CampaignsByProductDropdown } from '../../../../utils/components/dropdowns/CampaignsByProductDropdown'
import { iscoreReportDataPetition } from './services/_IscoreReportApi'
import { useIntl } from 'react-intl'

const IscoreReport: FC = () => {
  //Algunas variables desde el usercontext
  const { user, token, updateToken } = useContext(UserContext)
  const clientId = user.cli_id
  const userToken = token
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  const initialIscoreLeadData: IscoreLeadData = {
    data: [],
  }

  const initialIscoreReportData: iscoreReport = {
    data: [initialIscoreLeadData],
    totalLeads: 0,
  }

  // Valores iniciales para el rango de fechas
  const initialStartDate: Date = new Date(new Date().setDate(new Date().getDate() - 30))
  const initialEndDate: Date = new Date()

  // Constantes que usaremos para manejar las variables para los labels que muestran la fecha
  const [labelStartDate, setLabelStartDate] = useState<Date>(initialStartDate)
  const [labelEndDate, setLabelEndDate] = useState<Date>(initialEndDate)

  // Constantes que usaremos para manejar las fechas que enviaremos a la peticion
  const [startDate, setStartDate] = useState<string>(dateFormatterISO8601Variant(initialStartDate))
  const [endDate, setEndDate] = useState<string>(dateFormatterISO8601Variant(initialEndDate))

  // Manejador para la disponibilidad del dateRangePicker
  const [dataRangeState, setDataRangeState] = useState<boolean>(true)

  // const initialIscoreReportDataByCampaign: iscoreReport = {
  //   iscoreLead: [],
  // }

  //Constante para manejar el item con los registros de calificaciones
  const [iscoreReportItemByProduct, setIscoreReportItemByProduct] = useState<iscoreReport>(initialIscoreReportData)

  const [iscoreReportItemCampaignOne, setIscoreReportItemCampaignOne] = useState<iscoreReport>(initialIscoreReportData)

  const [iscoreReportItemCampaignTwo, setIscoreReportItemCampaignTwo] = useState<iscoreReport>(initialIscoreReportData)

  const [productsList, setProductsList] = useState<any[]>([])
  const [campaignsList, setCampaignsList] = useState<any[]>([])

  const [selectedIdProduct, setSelectedIdProduct] = useState<string>('')
  const [selectedNameProduct, setSelectedNameProduct] = useState<string>('')
  const [productsDropdownState, setProductsDropdownState] = useState<boolean>(false)

  const [selectedIdCampaign, setSelectedIdCampaign] = useState<string>('')
  const [selectedNameCampaign, setSelectedNameCampaign] = useState<string>('Todas las campañas')
  const [selectedIdCampaignOne, setSelectedIdCampaignOne] = useState<string>('')
  const [selectedNameCampaignOne, setSelectedNameCampaignOne] = useState<string>('')
  const [selectedIdCampaignTwo, setSelectedIdCampaignTwo] = useState<string>('')
  const [selectedNameCampaignTwo, setSelectedNameCampaignTwo] = useState<string>('')

  const [campaignDropdownState, setCampaignDropdownState] = useState<boolean>(true)
  const [comparativeCampaignDropdownState, setComparativeCampaignDropdownState] = useState<boolean>(true)

  //Constante para el manejo de estado de la animacion de carga
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoadingComparativeChart, setIsLoadingComparativeChart] = useState<boolean>(false)

  //Constante para el manejo de estado del mensaje post carga de registros
  const [infoMessageLoading, setInfoMessageLoading] = useState<string>('')
  const [infoMessageLoadingcomparative, setInfoMessageLoadingcomparative] = useState<string>('')

  // const checkIscoreListLength = () => {
  //   if (iscoreReportItemByProduct.totalLeads === 0) {
  //     setInfoMessageLoading('No se encontro ningún registro de iscore');
  //     setCampaignDropdownState(true);
  //   } else {
  //     setCampaignDropdownState(false);
  //   }
  // }

  // const checkIscoreListByCampLength = () => {
  //     iscoreReportItemCampaignTwo.iscoreLead.length === 0 && setInfoMessageLoading("No se encontro ningún registro de calificaciones")
  // }

  //Funcion para obtener el registro de los conteos de calificaciones
  const getIscoreRegistersByProduct = async (productId: string, campaign?: string, startDateNew?: string, endDateNew?: string) => {

    let campaignId: string;
    let startDateFinal: string;
    let endDateFinal: string;
    
    if (startDateNew && startDateNew !== '') {
      startDateFinal = startDateNew
    } else {
      startDateFinal = startDate
    }

    if (endDateNew && endDateNew !== '') {
      endDateFinal = endDateNew
    } else {
      endDateFinal = endDate
    }

    if (campaign && campaign !== '0') {
      campaignId = campaign
    } else {
      campaignId = ''
    }

    try {
      const { data: jsonData, code, msg } = await iscoreReportDataPetition(userToken, clientId, productId, campaignId, startDateFinal, endDateFinal)

      if (code === 401 && msg === 'Token no valido') {
        sessionExpired(intl.formatMessage({id: 'GLOBAL.SESSION_EXPIRED'}))
      } else {
        const { data: iscoreReportData, totalLeads, token: userTokenNew } = jsonData;

        // Actualiza el token
        updateToken(userTokenNew);

        console.log({ iscoreReportData });

        // Mapear los datos para asegurar que coincidan con la estructura esperada
        const datosUnited: iscoreReport = {
          data: iscoreReportData.map((item: any) => ({
            category: Object.keys(item)[0], // Extrae el nombre de la categoría
            count: Object.values(item)[0], // Extrae el valor de la categoría
            data: item.data || [], // Asegúrate de incluir los datos de la categoría
          })),
          totalLeads,
        };

        console.log(datosUnited)

        if(datosUnited.totalLeads === 0){
          setComparativeCampaignDropdownState(true) 
          setInfoMessageLoading(intl.formatMessage({id: 'REPORTS.INTERACTIONS.NO_ISCORE_DATA_FOUND'}))
        }else if(datosUnited.data.length === 0){
          setComparativeCampaignDropdownState(true) 
          setInfoMessageLoading(intl.formatMessage({id: 'REPORTS.INTERACTIONS.NO_ISCORE_DATA_FOUND'}))
        }else{
          setComparativeCampaignDropdownState(false)
        }
          
        // Actualiza el estado con el objeto mapeado
        setIscoreReportItemByProduct(datosUnited);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error en la peticion del reporte: ', error)
      setIsLoading(false)
      setInfoMessageLoading(intl.formatMessage({id: 'GLOBAL.REQUEST_ERROR'}))

    }
  }

  const getIscoreRegistersByCampaignOne = async (productId: string, campaign?: string, startDateNew?: string, endDateNew?: string) => {
    // meter codigo aqui
    console.log('funcion para campaña uno')

    let campaignId: string;
    let startDateFinal: string;
    let endDateFinal: string;

    if (startDateNew && startDateNew !== '') {
      startDateFinal = startDateNew
    } else {
      startDateFinal = startDate
    }

    if (endDateNew && endDateNew !== '') {
      endDateFinal = endDateNew
    } else {
      endDateFinal = endDate
    }

    if (campaign && campaign !== '0') {
      campaignId = campaign
    } else {
      campaignId = ''
    }

    try {
      const { data: jsonData, code, msg } = await iscoreReportDataPetition(userToken, clientId, productId, campaignId, startDateFinal, endDateFinal)

      if (code === 401 && msg === 'Token no valido') {
        sessionExpired('La sesion ha caducado')
      } else {
        const { data: iscoreReportData, totalLeads, token: userTokenNew } = jsonData;

        // Actualiza el token
        updateToken(userTokenNew);

        console.log('campaign one: ', { iscoreReportData });

        // Mapear los datos para asegurar que coincidan con la estructura esperada
        const datosUnited: iscoreReport = {
          data: iscoreReportData.map((item: any) => ({
            category: Object.keys(item)[0], // Extrae el nombre de la categoría
            count: Object.values(item)[0], // Extrae el valor de la categoría
            data: item.data || [], // Asegúrate de incluir los datos de la categoría
          })),
          totalLeads,
        };

        // Actualiza el estado con el objeto mapeado
        setIscoreReportItemCampaignOne(datosUnited);
        // checkIscoreListLength()
        setIsLoadingComparativeChart(false)
      }
    } catch (error) {
      console.error('Error en la peticion del reporte: ', error)
      setIsLoading(false)
      setInfoMessageLoading(intl.formatMessage({id: 'REPORTS.INTERACTIONS.ERROR_REQUEST'}))

    }

  }

  const getIscoreRegistersByCampaignTwo = async (productId: string, campaign?: string, startDateNew?: string, endDateNew?: string) => {
    // meter codigo aqui
    console.log('funcion para campaña dos')
    let campaignId: string;
    let startDateFinal: string;
    let endDateFinal: string;

    if (startDateNew && startDateNew !== '') {
      startDateFinal = startDateNew
    } else {
      startDateFinal = startDate
    }

    if (endDateNew && endDateNew !== '') {
      endDateFinal = endDateNew
    } else {
      endDateFinal = endDate
    }

    if (campaign && campaign !== '0') {
      campaignId = campaign
    } else {
      campaignId = ''
    }

    try {
      const { data: jsonData, code, msg } = await iscoreReportDataPetition(userToken, clientId, productId, campaignId, startDateFinal, endDateFinal)

      if (code === 401 && msg === 'Token no valido') {
        sessionExpired('La sesion ha caducado')
      } else {
        const { data: iscoreReportData, totalLeads, token: userTokenNew } = jsonData;

        // Actualiza el token
        updateToken(userTokenNew);

        console.log('campaign two: ', { iscoreReportData });

        // Mapear los datos para asegurar que coincidan con la estructura esperada
        const datosUnited: iscoreReport = {
          data: iscoreReportData.map((item: any) => ({
            category: Object.keys(item)[0], // Extrae el nombre de la categoría
            count: Object.values(item)[0], // Extrae el valor de la categoría
            data: item.data || [], // Asegúrate de incluir los datos de la categoría
          })),
          totalLeads,
        };

        // Actualiza el estado con el objeto mapeado
        setIscoreReportItemCampaignTwo(datosUnited);
        // checkIscoreListLength()
        setIsLoadingComparativeChart(false)
      }
    } catch (error) {
      console.error('Error en la peticion del reporte: ', error)
      setIsLoading(false)
      setInfoMessageLoading(intl.formatMessage({id: 'REPORTS.INTERACTIONS.ERROR_REQUEST'}))

    }
  }

  const handleProductSelected = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setIsLoading(true)
    setSelectedNameCampaign('Todas las campañas')
    setSelectedIdCampaign('0')
    setSelectedIdCampaignOne('')
    setSelectedIdCampaignTwo('')
    setSelectedNameCampaignOne('')
    setSelectedNameCampaignTwo('')
    setIscoreReportItemCampaignOne(initialIscoreReportData)
    setIscoreReportItemCampaignTwo(initialIscoreReportData)

    let productIdSelected: string = e.target.value

    if (productIdSelected) {
      setDataRangeState(false)
    } else {
      setDataRangeState(true)
    }

    if (e.target instanceof HTMLSelectElement) {
      setSelectedNameProduct(e.target.options[e.target.selectedIndex].text)
    }

    setSelectedIdProduct(productIdSelected)
    getIscoreRegistersByProduct(productIdSelected)

    if (productIdSelected !== '' && productIdSelected !== '0') {
      // getCampaignsByProduct(productIdSelected)
    }
  }

  const handleCampaignSelected = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {

    let campaignIdSelected = e.target.value
    let camStep = e.target.name
    let campNamerovitional: string


    if (e.target instanceof HTMLSelectElement) {
      campNamerovitional = e.target.options[e.target.selectedIndex].text

      if (e.target.name === 'campaignOne') {
        setIsLoadingComparativeChart(true)
        setSelectedNameCampaignOne(e.target.options[e.target.selectedIndex].text)
        setSelectedIdCampaignOne(campaignIdSelected)
        getIscoreRegistersByCampaignOne(selectedIdProduct, campaignIdSelected)
        // setIscoreReportItemCampaignOne(initialIscoreReportDataByCampaign)
      } else if (e.target.name === 'campaignTwo') {
        setIsLoadingComparativeChart(true)
        setSelectedNameCampaignTwo(e.target.options[e.target.selectedIndex].text)
        setSelectedIdCampaignTwo(campaignIdSelected)
        getIscoreRegistersByCampaignTwo(selectedIdProduct, campaignIdSelected)
        // setIscoreReportItemCampaignTwo(initialIscoreReportDataByCampaign)
      } else {
        setIsLoading(true)
        setSelectedNameCampaign(e.target.options[e.target.selectedIndex].text)
        setSelectedIdCampaign(campaignIdSelected)
        getIscoreRegistersByProduct(selectedIdProduct, campaignIdSelected)
      }
    }
  }

  // Manejamos las acciones que queremos para cuando se interactue con las fechas del DateRange
  const handleDates = (): void => {
    setIsLoading(true)
    setSelectedIdCampaignOne('')
    setSelectedIdCampaignTwo('')
    setSelectedNameCampaignOne('')
    setSelectedNameCampaignTwo('')
    setIscoreReportItemCampaignOne(initialIscoreReportData)
    setIscoreReportItemCampaignTwo(initialIscoreReportData)
    const startDateNew: string = dateFormatterISO8601Variant(labelStartDate)
    const endDateNew: string = dateFormatterISO8601Variant(labelEndDate)

    setStartDate(startDateNew)
    setEndDate(endDateNew)

    getIscoreRegistersByProduct(selectedIdProduct, selectedIdCampaign, startDateNew, endDateNew)

  }

  useEffect(() => {
    setPageTitle(`${intl.formatMessage({id: 'REPORTS.INTERACTIONS.REPORT_ISCORE_TITLE'})} | ${DragonCem}`)
  }, [])

  return (
    <>
      <div className='container-fluid'>
        {/* Begin: Titulo de pagina y dropdown de clientes compartidos */}
        <div className='row justify-content-center mb-2'>
          <div className='col-md-12'>
            <div className='d-flex flex-wrap flex-stack mb-6'>
              <h1 className='my-2 d-flex flex-wrap flex-stack'>
                {/* <span className='d-flex flex-wrap flex-stack fs-4 text-gray-500 fw-bold ms-1'>
                  1 Abril 2024 a 30 Abril 2024
                </span> */}
                {intl.formatMessage({id: 'REPORTS.INTERACTIONS.REPORT_ISCORE_TITLE'})}
              </h1>
              <div className='d-flex flex-wrap flex-stack align-items-center my-2'>
                <div className='w-300px me-3'>
                  {/* Componente con el dropdown de seleccion de producto */}
                  <ProductsByUserDropdown
                    updateToken={updateToken}
                    clientId={clientId}
                    userToken={userToken}
                    sessionExpired={sessionExpired}
                    productIdSelected={selectedIdProduct}
                    handleProductChange={handleProductSelected}
                    productsList={productsList}
                    setProductsList={setProductsList}
                    productsDropdownState={productsDropdownState}
                    dropdownClass='form-select form-select-sm form-select-solid mb-2 '
                    allProductsOption={false}
                    onlyActiveProducts={true}
                  />
                </div>

                <div className='w-300px me-3'>
                  <CampaignsByProductDropdown
                    updateToken={updateToken}
                    clientId={clientId}
                    userToken={userToken}
                    productIdSelected={selectedIdProduct}
                    selectedIdCampaign={selectedIdCampaign}
                    selectedIdCampaignOne={selectedIdCampaignOne}
                    selectedIdCampaignTwo={selectedIdCampaignTwo}
                    campaignsList={campaignsList}
                    setCampaignsList={setCampaignsList}
                    campaignDropdownState={campaignDropdownState}
                    setCampaignDropdownState={setCampaignDropdownState}
                    comparativeCampaignDropdownState={comparativeCampaignDropdownState}
                    setComparativeCampaignDropdownState={setComparativeCampaignDropdownState}
                    dropdownClass='form-select form-select-solid form-select-sm'
                    dropdownName={'campaignSelect'}
                    onlyActiveCampaigns={true}
                    handleCampaignSelected={handleCampaignSelected}
                    iscomparative={false}
                  />

                </div>

                <div className='d-flex'>
                  <button
                    type='button'
                    className='btn btn-sm btn-icon btn-secondary me-1'
                    disabled={dataRangeState}
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                  >
                    <KTIcon iconName='calendar' className='fs-2' />
                  </button>

                  <button
                    type='button'
                    className='btn btn-sm btn-secondary disabled'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                  >
                    {`${intl.formatMessage({id: 'REPORTS.INTERACTIONS.CURRENT_RANGE'})}: ` +
                      dateFormatterTextualEnTwo(labelStartDate) +
                      '  -  ' +
                      dateFormatterTextualEnTwo(labelEndDate)}
                  </button>

                  {/* Componente con el DateRangePicker */}
                  <DateRangePickerFull
                    initialStartDate={initialStartDate}
                    initialEndDate={initialEndDate}
                    months={2}
                    handleDates={handleDates}
                    setLabelStartDate={setLabelStartDate}
                    setLabelEndDate={setLabelEndDate}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End: Titulo de pagina y dropdown de clientes compartidos */}

        <div className='row gx-2 mb-6'>
          <div className='col-xl-12'>
            <div className='card card-custom px-8 py-10 h-100'>
              <div className='row justify-content-center mb-2'>
                <div className='col-md-12'>
                  {selectedIdProduct && (
                    <div className='d-flex mb-4 justify-content-center'>
                      <h5 className='card-label me-4 d-flex flex-wrap flex-stack '>
                        <span className='text-dark text-sm font-weight-bolder mt-3'>
                          {intl.formatMessage({id: 'REPORTS.INTERACTIONS.SUMMARY'})}:
                        </span>
                        &nbsp;&nbsp;
                        <span className='text-muted mt-3'> {selectedNameProduct} - {selectedNameCampaign} </span>
                      </h5>
                    </div>
                  )}

                  {!selectedIdProduct && (
                    <>
                      <div
                        className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                        role='alert'
                      >
                        <h5 className='mb-1 text-center'>{intl.formatMessage({id: 'REPORTS.INTERACTIONS.SELECT_PRODUCT'})}</h5>
                      </div>
                    </>
                  )}

                  {/* Si isLoading es igual a 'true' mostramos animacion de carga */}
                  {isLoading && selectedIdProduct && (
                    <div className='py-6'>
                      <LoadingAnimation
                        alignment='center'
                        label={intl.formatMessage({id: 'REPORTS.INTERACTIONS.LOADING_ISCORE'})}
                        size='lg'
                      />
                    </div>
                  )}
                  {/* Si isLoading es diferente a 'true' y el arreglo de conteo de calificaciones es mayor a '0' mostramos tarjetas de conteo */}
                  {!isLoading && selectedIdProduct && iscoreReportItemByProduct.totalLeads !== 0 && iscoreReportItemByProduct.data.length > 0 && (
                    <LeadsIscoreCountsItem
                      iscoreReportItemByProduct={iscoreReportItemByProduct}
                      selectedIdProduct={selectedIdProduct}
                    />
                  )}
                  

                  {/* Si isLoading es diferente a 'true' y el arreglo de conteo de calificaciones es igual a '0' mostramos mensaje informativo  */}
                  {!isLoading && selectedIdProduct && iscoreReportItemByProduct.totalLeads === 0 && iscoreReportItemByProduct.data.length === 0 &&(
                    // <div className="py-6 d-flex justify-content-center"><p>{infoMessageLoading}</p></div>

                    <div
                      className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                      role='alert'
                    >
                      <span className='mb-1 text-center'>{infoMessageLoading}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row gx-2 mb-6'>
          <div className='col-xl-12'>
            <div className='card card-custom px-8 py-10 h-100'>
              <div className='row-justify-content-center mb-2'>
                <div className='col-md-12'>
                  {selectedIdProduct && (
                    <div className='d-flex flex-wrap flex-stack mb-4 justify-content-center'>
                      <h5 className='card-label me-4 d-flex flex-wrap flex-stack '>
                        <span className='text-dark text-sm font-weight-bolder mt-3'>
                          Gráfica de iScores:
                        </span>
                        &nbsp;&nbsp;
                        <span className='text-muted mt-3'> {selectedNameProduct} - {selectedNameCampaign}</span>
                      </h5>
                    </div>
                  )}

                  {!selectedIdProduct && (
                    <>
                      <div
                        className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                        role='alert'
                      >
                        <h5 className='mb-1 text-center'>{intl.formatMessage({id: 'REPORTS.INTERACTIONS.SELECT_PRODUCT'})}</h5>
                      </div>
                    </>
                  )}

                  {/* si isLoading es igual a true mostramos animacion de carga  */}
                  {isLoading && selectedIdProduct && (
                    <div className='py-6'>
                      <LoadingAnimation alignment='center' label={intl.formatMessage({id: 'REPORTS.INTERACTIONS.GENERATING_CHART'})} size='lg' />
                    </div>
                  )}

                  {/* Si isLoading es diferente a true y el arreglo de calificaciones es mayor a '0' mostramos la grafica */}
                  {!isLoading && selectedIdProduct && iscoreReportItemByProduct.totalLeads !== 0 && iscoreReportItemByProduct.data.length > 0 &&(
                    <IscoreReportMainChart
                      iscoreReportItemByProduct={iscoreReportItemByProduct}
                      selectedIdProduct={selectedIdProduct}
                    />
                  )}

                  {/* Si isLoading es diferente a true y el arreglo de calificaciones es mayor a '0' mostramos mensaje informativo */}
                  {!isLoading && selectedIdProduct && iscoreReportItemByProduct.totalLeads === 0 && iscoreReportItemByProduct.data.length === 0 && (
                    // <div className="py-6 d-flex justify-content-center"><p>{infoMessageLoading}</p></div>
                    <div
                      className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                      role='alert'
                    >
                      <span className='mb-1 text-center'>{infoMessageLoading}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row gx-2 mb-6'>
          <div className='col-xl-12'>
            <div className='card card-custom px-8 py-6 h-100'>
              <div className='row justify-content-center mb-2'>
                <div className='col-md-12'>
                  <div className='d-flex flex-wrap flex-stack mb-6'>
                    <h5 className='my-2 d-flex flex-wrap flex-stack '>
                      {intl.formatMessage({id: 'REPORTS.INTERACTIONS.CAMPAIGN_PERFORMANCE'})}: &nbsp;
                      <span className='text-muted mt-1'>{selectedNameProduct}</span>
                    </h5>
                    <div className='d-flex flex-wrap flex-stack align-items-center my-2'>
                      <div className='w-300px me-3'>

                        {/* Dropdown para seleccionar la campaña uno */}
                        <CampaignsByProductDropdown
                          updateToken={updateToken}
                          clientId={clientId}
                          userToken={userToken}
                          productIdSelected={selectedIdProduct}
                          selectedIdCampaign={selectedIdCampaign}
                          selectedIdCampaignOne={selectedIdCampaignOne}
                          selectedIdCampaignTwo={selectedIdCampaignTwo}
                          campaignsList={campaignsList}
                          setCampaignsList={setCampaignsList}
                          campaignDropdownState={campaignDropdownState}
                          setCampaignDropdownState={setCampaignDropdownState}
                          comparativeCampaignDropdownState={comparativeCampaignDropdownState}
                          setComparativeCampaignDropdownState={setComparativeCampaignDropdownState}
                          dropdownClass='form-select form-select-solid form-select-sm'
                          dropdownName={'campaignOne'}
                          onlyActiveCampaigns={true}
                          handleCampaignSelected={handleCampaignSelected}
                          iscomparative={true}
                        />
                      </div>

                      <div className='w-300px'>

                        {/* Dropdown para seleccionar la campaña dos */}
                        <CampaignsByProductDropdown
                          updateToken={updateToken}
                          clientId={clientId}
                          userToken={userToken}
                          productIdSelected={selectedIdProduct}
                          selectedIdCampaign={selectedIdCampaign}
                          selectedIdCampaignOne={selectedIdCampaignOne}
                          selectedIdCampaignTwo={selectedIdCampaignTwo}
                          campaignsList={campaignsList}
                          setCampaignsList={setCampaignsList}
                          campaignDropdownState={campaignDropdownState}
                          setCampaignDropdownState={setCampaignDropdownState}
                          comparativeCampaignDropdownState={comparativeCampaignDropdownState}
                          setComparativeCampaignDropdownState={setComparativeCampaignDropdownState}
                          dropdownClass='form-select form-select-solid form-select-sm'
                          dropdownName={'campaignTwo'}
                          onlyActiveCampaigns={true}
                          handleCampaignSelected={handleCampaignSelected}
                          iscomparative={true}
                        />
                      </div>
                    </div>
                  </div>

                  {/* si isLoading es igual a true mostramos animacion de carga  */}
                  {isLoadingComparativeChart && selectedIdCampaignOne !== '' && (
                    <div className='py-6'>
                      <LoadingAnimation alignment='center' label={intl.formatMessage({id: 'REPORTS.INTERACTIONS.GENERATING_CHART'})} />
                    </div>
                  )}

                  {!isLoadingComparativeChart &&
                    selectedIdCampaignOne !== '' &&
                    iscoreReportItemCampaignOne.totalLeads !== 0 &&
                    selectedIdCampaignTwo !== '' &&
                    iscoreReportItemCampaignTwo.totalLeads !== 0 && (
                      <>
                        <IscoreReportComparativeChart
                          iscoreReportItemCampaignOne={iscoreReportItemCampaignOne}
                          iscoreReportItemCampaignTwo={iscoreReportItemCampaignTwo}
                          selectedNameCampaignOne={selectedNameCampaignOne}
                          selectedNameCampaignTwo={selectedNameCampaignTwo}
                          selectedIdCampaignOne={selectedIdCampaignOne}
                          selectedIdCampaignTwo={selectedIdCampaignTwo}
                          selectedIdProduct={selectedIdProduct}
                        />
                      </>
                    )}

                  {!isLoadingComparativeChart &&
                    selectedIdCampaignOne !== '' &&
                    iscoreReportItemCampaignOne.totalLeads !== 0 &&
                    selectedIdCampaignTwo !== '' &&
                    iscoreReportItemCampaignTwo.totalLeads === 0 && (
                      <>
                        <IscoreReportComparativeChart
                          iscoreReportItemCampaignOne={iscoreReportItemCampaignOne}
                          iscoreReportItemCampaignTwo={iscoreReportItemCampaignTwo}
                          selectedNameCampaignOne={selectedNameCampaignOne}
                          selectedNameCampaignTwo={selectedNameCampaignTwo}
                          selectedIdCampaignOne={selectedIdCampaignOne}
                          selectedIdCampaignTwo={selectedIdCampaignTwo}
                          selectedIdProduct={selectedIdProduct}
                        />
                      </>
                    )}

                  {!isLoadingComparativeChart &&
                    selectedIdCampaignOne !== '' &&
                    iscoreReportItemCampaignOne.totalLeads !== 0 &&
                    selectedIdCampaignTwo === '' &&
                    iscoreReportItemCampaignTwo.totalLeads === 0 && (
                      <>
                        <IscoreReportComparativeChart
                          iscoreReportItemCampaignOne={iscoreReportItemCampaignOne}
                          iscoreReportItemCampaignTwo={iscoreReportItemCampaignTwo}
                          selectedNameCampaignOne={selectedNameCampaignOne}
                          selectedNameCampaignTwo={selectedNameCampaignTwo}
                          selectedIdCampaignOne={selectedIdCampaignOne}
                          selectedIdCampaignTwo={selectedIdCampaignTwo}
                          selectedIdProduct={selectedIdProduct}
                        />
                      </>
                    )}

                  {!isLoadingComparativeChart &&
                    selectedIdCampaignOne !== '' &&
                    iscoreReportItemCampaignOne.totalLeads === 0 &&
                    selectedIdCampaignTwo !== '' &&
                    iscoreReportItemCampaignTwo.totalLeads !== 0 && (
                      <>
                        <IscoreReportComparativeChart
                          iscoreReportItemCampaignTwo={iscoreReportItemCampaignTwo}
                          selectedNameCampaignOne={selectedNameCampaignOne}
                          selectedNameCampaignTwo={selectedNameCampaignTwo}
                          selectedIdCampaignOne={selectedIdCampaignOne}
                          selectedIdCampaignTwo={selectedIdCampaignTwo}
                          iscoreReportItemCampaignOne={iscoreReportItemCampaignOne}
                          selectedIdProduct={selectedIdProduct}
                        />
                      </>
                    )}

                  {!isLoadingComparativeChart &&
                    selectedIdCampaignOne !== '' &&
                    iscoreReportItemCampaignOne.totalLeads === 0 &&
                    selectedIdCampaignTwo === '' &&
                    iscoreReportItemCampaignTwo.totalLeads === 0 && (
                      // <div className="py-6"><i>No hay datos en tu primer selección</i></div>
                      <div
                        className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                        role='alert'
                      >
                        <span className='mb-1 text-center'>
                          {intl.formatMessage({id: 'REPORTS.INTERACTIONS.NO_DATA_IN_FIRST_SELECTION'})}
                        </span>
                      </div>
                  )}

                  {!isLoadingComparativeChart &&
                    selectedIdCampaignOne !== '' &&
                    iscoreReportItemCampaignOne.totalLeads === 0 &&
                    selectedIdCampaignTwo !== '' &&
                    iscoreReportItemCampaignTwo.totalLeads === 0 && (
                      // <div className="py-6"><i>No hay datos en ninguna campaña</i></div>
                      <div
                        className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                        role='alert'
                      >
                        <span className='mb-1 text-center'>
                          {intl.formatMessage({id: 'REPORTS.INTERACTIONS.NO_DATA_IN_ANY_CAMPAIGN'})}
                        </span>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { IscoreReport }