import {useState, useEffect, useContext} from 'react'
import {useIntl} from 'react-intl'
import PhoneInput from 'react-phone-number-input'
import {allowedCountries} from '../../../../utils/constants/_Constants'
import { Modal } from 'react-bootstrap'
import { KTIcon } from '../../../../../_metronic/helpers'
import { AlertInfo } from '../../../../utils/interfaces/_InterfacesTypes'
import { sessionExpired } from '../../../../utils/functions/_Functions'
import { userStatusFalse, userStatusTrue } from '../services/_SellersStatusApi'
import { UserContext } from '../../../../app-context/user-context/UserContext'

// Componente para el formulario, le pasamos el sellerData que es donde viene la data previa del vendedor seleccionado, formData que es la data que esta escrita en los inputs y el setformdata que es la actualizacion de estado de esa data
function SellerEditModalForm({sellerData, formData, setFormData, errors}) {
  const intl = useIntl()

  console.log(sellerData)

  // console.log(errors)

  const [emailError, setEmailError] = useState('')
  const [showModalStatus, setShowModalStatus] = useState(false)
  const [userStatus, setUserStatus] = useState<boolean>(sellerData.status);
  const idUser = sellerData.id;

  console.log(idUser)

  const { user, token, updateToken } = useContext(UserContext);
  const userToken = token;

  const isEmailValid = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handlePhoneInputChange = (value: any) => {
    setFormData((prevData) => ({
      ...prevData,
      phone: value,
    }))
  }

  const handleWhatsAppInputChange = (value: any) => {
    setFormData((prevData) => ({
      ...prevData,
      whatsapp: value,
    }))
  }

  // Actualizar el estado del formulario cuando cambia la información del vendedor
  useEffect(() => {
    if (sellerData) {
       console.log(sellerData)
      setFormData({
        name: sellerData.name || '',
        email: sellerData.email || '',
        phone: sellerData.usr_phone || '',
        whatsapp: sellerData.wha_phone || '',
        newpassword: '',
        verifypassword: '',
        calendly: sellerData.calendly || '',
        role: sellerData.user_type || '',
        brandProducts: sellerData.brandProducts || [],
      })
    }
  }, [sellerData, setFormData])

  // Manejar el cambio en los campos del formulario, con esta funcion tomamos lo que se ha escrito en los inputs del formulario, se ejecuta cada que se escribe algo
  const handleInputChange = (e) => {
    const {name, type, checked, value} = e.target;
  
    if (type === 'checkbox') {
      const productName = sellerData.brandProducts.find((product) => product.pro_id === value)
        ?.name;
  
      // Crear una copia del arreglo de productos del vendedor
      const updatedSellerProducts = [...formData.brandProducts];
  
      // Buscar el índice del producto en el arreglo actualizado
      const index = updatedSellerProducts.findIndex((product) => product.name === productName);
  
      if (index !== -1) {
        // Si el producto ya está en el arreglo, actualizar su estado
        updatedSellerProducts[index].status_assigned = checked;
      } else {
        // Si el producto no está en el arreglo, agregarlo con su estado
        updatedSellerProducts.push({name: productName, status_assigned: checked});
      }
  
      // Actualizar el estado formData con los productos actualizados
      setFormData((prevData) => ({
        ...prevData,
        brandProducts: updatedSellerProducts,
      }));
    } else {
      // Si no es un checkbox ni el campo de status, actualizar el estado directamente
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  
    // Validación específica para el campo de correo electrónico
    if (name === 'email') {
      if (!isEmailValid(value)) {
        setEmailError(intl.formatMessage({id: 'GLOBAL.ALERT.INVALID.EMAIL'}));
      } else {
        setEmailError('');
      }
    }
  };

  const handleSwitchChange = () => {
    // Actualiza userStatus de forma asincrónica y devuelve el nuevo valor
    setUserStatus(prevStatus => !prevStatus);
    
    // Actualiza el formData después de que userStatus se haya actualizado correctamente
    setFormData(prevData => ({
      ...prevData,
      status: !userStatus // Utiliza el nuevo valor de userStatus
    }));
  };

  const closeModalStatus = () => {
    setShowModalStatus(false);
    setUserStatus(!userStatus)
  }

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' noValidate>
        {/* ... (resto del formulario) */}
        <div className='row mb-7'>
          <div className='col-md-6'>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-5'>{intl.formatMessage({id: 'GLOBAL.NAME'})}</label>
              <input
                placeholder={intl.formatMessage({id: 'GLOBAL.NAME'})}
                type='text'
                name='name'
                value={formData.name}
                className={`form-control form-control-lg form-control-solid ${
                  errors.SellerName && 'is-invalid' // Agrega la clase 'is-invalid' si hay un error en SellerName
                }`}
                autoComplete='off'
                onChange={handleInputChange}
              />

              {errors.SellerName && <div className='invalid-feedback'>{errors.SellerName}</div>}
            </div>
          </div>
          <div className='col-md-6'>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-5'>{intl.formatMessage({id: 'GLOBAL.EMAIL'})}</label>
              <input
                placeholder={intl.formatMessage({id: 'GLOBAL.EMAIL'})}
                type='email'
                name='email'
                value={formData.email}
                className={`form-control form-control-lg form-control-solid ${
                  (errors.SellerMail || emailError) && 'is-invalid'
                }`}
                autoComplete='off'
                onChange={handleInputChange}
              />

              {errors.SellerMail && <div className='invalid-feedback'>{errors.SellerMail}</div>}
              {emailError && <div className='invalid-feedback'>{emailError}</div>}
            </div>
          </div>
        </div>
        <div className='row mb-7'>
          <div className='col-md-6'>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-5'>{intl.formatMessage({id: 'GLOBAL.PHONE'})}</label>
              <PhoneInput
                placeholder={intl.formatMessage({id: 'GLOBAL.ENTER_PHONE'})}
                className={`form-control form-control-lg form-control-solid ${
                  errors.SellerWhatsApp && 'is-invalid' // Agrega la clase 'is-invalid' si hay un error en SellerName
                }`}
                name='phone'
                value={formData.phone}
                onChange={handlePhoneInputChange}
                countryCallingCodeEditable={false}
                defaultCountry='MX'
                international
                autoComplete='off'
                countries={allowedCountries}
              />
              {errors.SellerPhone && <div className='invalid-feedback'>{errors.SellerPhone}</div>}
            </div>
          </div>

          <div className='col-md-6'>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-5'>WhatsApp</label>
              <PhoneInput
                placeholder={intl.formatMessage({id: 'GLOBAL.ENTER_WHATSAPP'})}
                className={`form-control form-control-lg form-control-solid ${
                  errors.SellerWhatsApp && 'is-invalid' // Agrega la clase 'is-invalid' si hay un error en SellerName
                }`}
                name='whatsapp'
                value={formData.whatsapp}
                onChange={handleWhatsAppInputChange}
                countryCallingCodeEditable={false}
                defaultCountry='MX'
                international
                autoComplete='off'
                countries={allowedCountries}
              />
              {errors.SellerWhatsApp && (
                <div className='invalid-feedback'>{errors.SellerWhatsApp}</div>
              )}
            </div>
          </div>
        </div>

        <div className='row mb-7'>
          <div className='col-md-6'>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-5'>{intl.formatMessage({id: 'GLOBAL.NEW_PASSWORD'})}</label>
              <input
                placeholder={intl.formatMessage({id: 'GLOBAL.NEW_PASSWORD'})}
                type='password'
                name='newpassword'
                value={formData.newpassword}
                className='form-control form-control-lg form-control-solid'
                autoComplete='off'
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className='col-md-6'>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-5'>{intl.formatMessage({id: 'GLOBAL.VERIFY_PASSWORD'})}</label>
              <input
                placeholder={intl.formatMessage({id: 'GLOBAL.VERIFY_PASSWORD'})}
                type='password'
                name='verifypassword'
                value={formData.verifypassword}
                className='form-control form-control-lg form-control-solid'
                autoComplete='off'
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>

        <div className='row mb-7'>
          <div className='col-md-6'>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-5'>{intl.formatMessage({id: 'GLOBAL.CALENDLY'})}</label>
              <input
                placeholder={intl.formatMessage({id: 'GLOBAL.CALENDLY'})}
                type='text'
                name='calendly'
                value={formData.calendly}
                className='form-control form-control-lg form-control-solid'
                autoComplete='off'
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className='col-md-6'>
            <div className='mb-5'>
              {/* begin::Label */}
              <label className='fw-bold fs-6 mb-5'>{intl.formatMessage({id: 'CONTROL_SELLERS.USER_TYPE'})}</label>
              {/* end::Label */}
              {/* begin::Roles */}
              <select
                name='role'
                className='form-control form-control-lg form-control-solid'
                value={formData.role}
                onChange={handleInputChange}
              >
                {formData.role && <option value={formData.role}>{formData.role}</option>}

                {['DEVELOPER', 'SUPER__ADMIN', 'ADMIN_DC']
                  .filter((option) => option !== formData.role)
                  .map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
              </select>
              {/* end::Roles */}
            </div>
          </div>

          {/* <div className='mb-2 mt-2'>
    <label className='fw-bold fs-6'>Status</label>
    <div className='form-check form-switch form-check-custom form-check-solid d-flex align-items-center'>
        <input
            className='form-check-input'
            type='checkbox'
            checked={userStatus}
            onChange={handleSwitchChange} 
        />
    </div>
</div> */}
                <Modal show={showModalStatus} className='modal-md'>
                <div className="modal-content border border-warning">
      <Modal.Header>
      <Modal.Title>
                      <h1>{intl.formatMessage({id: 'CONTROL_SELLERS.CONFIRM_STATUS'})}</h1>
                    </Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <div className='alert alert-primary d-flex align-items-center' role='danger'>
            <KTIcon iconName='information-4' className='fs-4x' />
            <div className='ml-6'>
            {intl.formatMessage({id: 'CONTROL_SELLERS.STATUS_CHANGE_WARNING'})}
            </div>
          </div>
      </Modal.Body>
      {/* {alertInfo.text && (
                    <div className={`alert alert-${alertInfo.variant} align-items-center mt-3 mx-10 text-center`} role='alert'>
                      <div>{alertInfo.text}</div>
                    </div>
                  )} */}
      <Modal.Footer>
        <button className='btn btn-sm btn-primary'>
          {intl.formatMessage({id: 'GLOBAL.CONFIRM'})}
        </button>
        <button className='btn btn-sm btn-secondary' onClick={closeModalStatus}>
          {intl.formatMessage({id: 'GLOBAL.CANCEL'})}
        </button>
      </Modal.Footer>
      </div>
    </Modal>

          <div className='mb-10'>
            <label className='form-label fw-bold'>{intl.formatMessage({id: 'CONTROL_SELLERS.PRODUCT_ASSIGNMENT'})}</label>

            {sellerData.brandProducts.map((item) => (
              <div
                className='form-check form-switch form-switch-sm form-check-custom form-check-solid mb-4'
                key={item.pro_id}
              >
                <input
                  className='form-check-input'
                  type='checkbox'
                  name={`product_${item.pro_id}`}
                  checked={item.status_assigned} // Aquí se utiliza item.status para determinar si el checkbox debe estar marcado
                  onChange={handleInputChange}
                  value={item.pro_id}
                />

                <label className='form-check-label'>{item.name}</label>
              </div>
            ))}
          </div>
        </div>
        <div>
                <label className="mb-4">{intl.formatMessage({id: 'GLOBAL.NOTIFICATIONS'})}:</label>
            </div>
            <div className="d-flex mb-8">
                <label className="mr-8">{intl.formatMessage({id: 'CONTROL_SELLERS.CLIENT_EMAIL'})}: </label>&nbsp;&nbsp;&nbsp;
                <div className="form-check form-switch">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id="mail_cliente"
                        name="mail_cliente"
                        defaultChecked
                        // onChange={handleInputChange}
                    />
                                 
                </div>
               

                &nbsp;&nbsp; | &nbsp;&nbsp;
                <label className="mr-8">{intl.formatMessage({id: 'CONTROL_SELLERS.CLIENT_WHATSAPP'})}: </label>&nbsp;&nbsp;&nbsp;
                <div className="form-check form-switch">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id="whats_client"
                        name="whats_client"
                        defaultChecked
                        // onChange={handleInputChange}

                    />
                
                </div>
            </div>

           <div id="schedules">
                    <h3 className="mt-6 mb-3">{intl.formatMessage({id: 'CONTROL_SELLERS.CALL_SCHEDULE'})}</h3>
                    <span className="text-muted">{intl.formatMessage({id: 'CONTROL_SELLERS.SELECT_DAYS_AND_HOURS'})}</span>

                    <div className="d-flex mt-4">
                        <label>
                            <input type="checkbox" className="form-check-input"/> {intl.formatMessage({id: 'GLOBAL.MONDAY'})}
                        </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        <label>
                            <input type="checkbox" className="form-check-input"/> {intl.formatMessage({id: 'GLOBAL.TUESDAY'})}
                        </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        <label>
                            <input type="checkbox" className="form-check-input"/> {intl.formatMessage({id: 'GLOBAL.WEDNESDAY'})}
                        </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        <label>
                            <input type="checkbox" className="form-check-input"/> {intl.formatMessage({id: 'GLOBAL.THURSDAY'})}
                        </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        <label>
                            <input type="checkbox" className="form-check-input"/> {intl.formatMessage({id: 'GLOBAL.FRIDAY'})}
                        </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        <label>
                            <input type="checkbox" className="form-check-input"/> {intl.formatMessage({id: 'GLOBAL.SATURDAY'})}
                        </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        <label>
                            <input type="checkbox" className="form-check-input"/> {intl.formatMessage({id: 'GLOBAL.SUNDAY'})}
                        </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>

                    <div className="d-flex mt-8">
                        <div className="col-6 me-3">
                            <div>
                                <label htmlFor="startHour">{intl.formatMessage({id: 'CONTROL_SELLERS.START_HOUR'})}:</label>
                            </div>

                            <div>
                                <input
                                    type="time"
                                    className="form-control form-control-solid"
                                    id="startHour"
                                    name="startHour"
                                    // value={formDataManual.startHour}
                                    // onChange={handleInputChange}
                                />
                            </div>
                        </div>

                        <div className="col-6 me-3">
                            <div>
                                <label htmlFor="endHour">{intl.formatMessage({id: 'CONTROL_SELLERS.END_HOUR'})}:</label>
                            </div>
                        
                            <div>
                                <input
                                    type="time"
                                    className="form-control form-control-solid"
                                    id="endHour"
                                    name="endHour"
                                    // value={formDataManual.endHour}
                                    // onChange={handleInputChange}
                                />
                            </div>
                        </div>

                    </div>
                </div>
        {/* ... (repite el patrón para otros campos de formulario) */}
      </form>
    </>
  )
}

export {SellerEditModalForm}
