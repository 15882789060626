// Formulario de templates

import React, {useState} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'

export const TwlioTemplatesAddForm = () => {
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()
  const [selectedOption, setSelectedOption] = useState('')

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value)
  }

  const renderTooltip = (props) => (
    <Tooltip {...props}>{intl.formatMessage({id: 'WHATSAPP.TWLIO.RETURN_REGISTER_TABLE'})}</Tooltip>
  )

  return (
    <>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='col-md-12'>
            <div className='d-flex flex-wrap flex-stack mb-6'>
              <h1 className='my-2'>{intl.formatMessage({id: 'WHATSAPP.TWLIO.ADD_TEMPLATES_TITLE'})}</h1>
            </div>
            <div className='d-flex justify-content-between'>
              <div>
                <p>
                {intl.formatMessage({id: 'WHATSAPP.TWLIO.ADD_TEMPLATES_DESCRIPTION_ONE'})}
                </p>
                <p>
                {intl.formatMessage({id: 'WHATSAPP.TWLIO.ADD_TEMPLATES_DESCRIPTION_TWO'})}
                </p>
              </div>
              <div>
                <OverlayTrigger placement='bottom' overlay={renderTooltip}>
                  <Link to={'/twlio-services/twlio-templates'}>
                    <button className='btn btn-primary mb-3'>{intl.formatMessage({id: 'GLOBAL.RETURN'})}</button>
                  </Link>
                </OverlayTrigger>
              </div>
            </div>
            <div className='card card-custom'>
              <div className='card card-body'>
                <h2 className='my-2'>{intl.formatMessage({id: 'WHATSAPP.TWLIO.ADD_TEMPLATES_TITLE_CARD_ONE'})}</h2>
                <div className='d-flex row'>
                  <div className='col-md-6'>
                    <div className='fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'WHATSAPP.TWLIO.TEMPLATES_NAME'})}</label>
                      <input
                        placeholder={intl.formatMessage({id: 'GLOBAL.NAME'})}
                        type='text'
                        name='template_name'
                        value={''}
                        className='form-control form-control-lg form-control-solid'
                        //   className={`form-control form-control-lg form-control-solid ${
                        //     errors.SellerName && 'is-invalid'
                        //   }`}
                        autoComplete='off'
                        //   onChange={handleInputChange}
                      />
                      {/* {errors.SellerName && <div className='invalid-feedback'>{errors.SellerName}</div>} */}
                      <div className='p-2 mt-2'>
                        <label htmlFor='fs-6 mt-1 text-muted'>
                        {intl.formatMessage({id: 'WHATSAPP.TWLIO.ADD_TEMPLATES_INFO_INPUT_NAME'})}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'WHATSAPP.TWLIO.ADD_TEMPLATES_CATEGORY'})}
                      </label>

                      <select
                        name='template_category'
                        className='form-control form-control-lg form-control-solid'
                        //   className={`form-control form-control-lg form-control-solid ${
                        //     errors.SellerName && 'is-invalid'
                        //   }`}
                      >
                        {/* {errors.SellerName && <div className='invalid-feedback'>{errors.SellerName}</div>} */}
                        <option value=''>{intl.formatMessage({id: 'GLOBAL.DROPDOWN_SELECT_OPTION'})}</option>
                        <option value='1'>Categoría 1</option>
                        <option value='2'>Categoría 2</option>
                      </select>
                      <div className='p-2 mt-2'>
                        <label htmlFor='fs-6 mt-1 text-muted'>
                        {intl.formatMessage({id: 'WHATSAPP.TWLIO.ADD_TEMPLATES_INFO_INPUT_CAT_ONE'})}
                        </label>
                        <label htmlFor='fs-6 mt-1 text-muted'>
                          {' '}
                          {intl.formatMessage({id: 'WHATSAPP.TWLIO.ADD_TEMPLATES_INFO_INPUT_CAT_TWO'})}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='card card-custom mt-5'>
              <div className='card card-body'>
                <h2 className='my-2'>{intl.formatMessage({id: 'WHATSAPP.TWLIO.ADD_TEMPLATES_TITLE_CARD_TWO'})}</h2>
                <p>
                {intl.formatMessage({id: 'WHATSAPP.TWLIO.ADD_TEMPLATES_MESSAGE_DESCRIPTION'})}
                </p>
                <div className='row'>
                  <div className='col-md-2'></div>
                  <div className='col-md-8'>
                    <div className='card card-bordered py-5'>
                      <div className='d-flex row justify-content-center'>
                        <div className='col-md-10'>
                          <div className='fv-row mb-3'>
                            <label className='required fw-bold fs-6 mb-2'>
                            {intl.formatMessage({id: 'WHATSAPP.TWLIO.TEMPLATES_MESSAGE_TEXT_LANGUAGE'})}
                            </label>

                            <select
                              name='template_category'
                              className='form-control form-control-lg form-control-solid'
                              //   className={`form-control form-control-lg form-control-solid ${
                              //     errors.SellerName && 'is-invalid'
                              //   }`}
                            >
                              {/* {errors.SellerName && <div className='invalid-feedback'>{errors.SellerName}</div>} */}
                              <option value=''>{intl.formatMessage({id: 'GLOBAL.DROPDOWN_SELECT_OPTION'})}</option>
                              <option value='1'>Español (MEX)</option>
                              <option value='2'>Español (ARG)</option>
                              <option value='2'>Inglés (EEUU)</option>
                            </select>
                            <div className='p-2 mt-2'>
                              {/* Aquí va el label o los mensajes de error */}
                            </div>
                          </div>
                          <div className='fv-row mb-3'>
                            <label className='required fw-bold fs-6 mb-2'>
                            {intl.formatMessage({id: 'WHATSAPP.TWLIO.ADD_TEMPLATES_INPUT_MESSAGE_BODY'})}
                            </label>
                            <textarea
                              name='message_body'
                              className='form-control form-control-lg form-control-solid'
                              rows={8}
                              cols={70}
                            ></textarea>
                            {/* {errors.SellerName && <div className='invalid-feedback'>{errors.SellerName}</div>} */}
                            <div className='p-2 mt-2'>
                              <p className='fs-6 mt-1 text-muted'>
                              {intl.formatMessage({id: 'WHATSAPP.TWLIO.ADD_TEMPLATES_INFO_INPUT_MESSAGE_BODY'})}
                              </p>
                            </div>
                          </div>
                          <div className='fv-row mb-3'>
                            <div className='d-flex align-items-center'>
                              <label className='fw-bold fs-6 mb-2 me-2'>{intl.formatMessage({id: 'GLOBAL.BUTTONS'})}</label>
                              <label className='fw-bold fs-6 mb-2 me-2'>-</label>
                              <label className='text-muted fs-6 mb-2 me-2'>
                                <em>{intl.formatMessage({id: 'GLOBAL.OPTIONAL'})}</em>
                              </label>
                            </div>
                            <label className='fs-6 mt-1 text-muted mb-3'>
                            {intl.formatMessage({id: 'WHATSAPP.TWLIO.ADD_TEMPLATES_INFO_BUTTONS'})}
                            </label>
                            <div className='d-flex flex-column'>
                              {/* Radio Button - None */}
                              <div className='form-check form-check-custom form-check-solid'>
                                <input
                                  className='form-check-input'
                                  type='radio'
                                  value=''
                                  name='buttonsOption'
                                  id='radioNone'
                                  checked={selectedOption === ''}
                                  onChange={handleOptionChange}
                                />
                                <label className='form-check-label' htmlFor='radioNone'>
                                {intl.formatMessage({id: 'GLOBAL.NONE'})}
                                </label>
                              </div>

                              {/* Radio Button - call_to_action */}
                              <div className='form-check form-check-custom form-check-solid mt-3'>
                                <input
                                  className='form-check-input'
                                  type='radio'
                                  value='call_to_action'
                                  name='buttonsOption'
                                  id='radioOption2'
                                  checked={selectedOption === 'call_to_action'}
                                  onChange={handleOptionChange}
                                />
                                <div className='d-flex flex-column'>
                                  <label className='form-check-label' htmlFor='radioOption2'>
                                  {intl.formatMessage({id: 'WHATSAPP.TWLIO.ADD_TEMPLATES_BUTTONS_CALL_TO_ACTIONS'})}
                                  </label>
                                  <label className='form-check-label' htmlFor='radioOption2'>
                                  {intl.formatMessage({id: 'WHATSAPP.TWLIO.ADD_TEMPLATES_BUTTONS_CALL_TO_ACTIONS_DES'})}
                                  </label>
                                </div>
                              </div>
                              {/* Conditional rendering for 'call_to_action' */}
                              {selectedOption === 'call_to_action' && (
                                <div className='card card-bordered p-5' id='forCall_to_action'>
                                  <div className='row'>
                                    <div className='col-md-12'>
                                      <div className='d-flex align-items-center'>
                                        <div className='d-flex flex-column me-5'>
                                          <label
                                            htmlFor='action_type'
                                            className='fw-bold fs-6 mb-2'
                                          >
                                            {intl.formatMessage({id: 'WHATSAPP.TWLIO.ADD_TEMPLATES_BUTTONS_TYPE_OF_ACTION'})}
                                          </label>
                                          <select
                                            name='action_type'
                                            id='type_of_action'
                                            className='form-control form-control-md form-control-solid'
                                          >
                                            <option value='phone_number'>{intl.formatMessage({id: 'GLOBAL.PHONE_NUMBER'})}</option>
                                            <option value='other'>{intl.formatMessage({id: 'GLOBAL.OTHER'})}</option>
                                          </select>
                                        </div>
                                        <div className='d-flex flex-column me-5'>
                                          <label
                                            htmlFor='action_type'
                                            className='required fw-bold fs-6 mb-2'
                                          >
                                            {intl.formatMessage({id: 'GLOBAL.BUTTON_TEXT'})}
                                          </label>
                                          <input
                                            placeholder='Call us'
                                            type='text'
                                            name='button_text'
                                            value={''}
                                            className='form-control form-control-lg form-control-solid'
                                            //   className={`form-control form-control-lg form-control-solid ${
                                            //     errors.SellerName && 'is-invalid'
                                            //   }`}
                                            autoComplete='off'
                                            //   onChange={handleInputChange}
                                          />
                                        </div>
                                        <div className='d-flex flex-column me-5'>
                                          <label htmlFor='lada_phone' className='fw-bold fs-6 mb-2'>
                                          {intl.formatMessage({id: 'GLOBAL.COUNTRY'})}
                                          </label>
                                          <select
                                            name='lada_phone'
                                            id='phone_lada'
                                            className='form-control form-control-md form-control-solid'
                                          >
                                            <option value='phone_number'>US +1</option>
                                            <option value='other'>MX +52</option>
                                          </select>
                                        </div>
                                        <div className='d-flex flex-column me-5'>
                                          <label
                                            htmlFor='action_type'
                                            className='required fw-bold fs-6 mb-2'
                                          >
                                            {intl.formatMessage({id: 'GLOBAL.PHONE_NUMBER'})}
                                          </label>
                                          <input
                                            type='text'
                                            name='phone_number'
                                            value={''}
                                            className='form-control form-control-lg form-control-solid'
                                            //   className={`form-control form-control-lg form-control-solid ${
                                            //     errors.SellerName && 'is-invalid'
                                            //   }`}
                                            autoComplete='off'
                                            //   onChange={handleInputChange}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className='col-md-12'>
                                      <div className='d-flex align-items-center'>
                                        <div className='d-flex flex-column me-5'>
                                          <label
                                            htmlFor='action_type'
                                            className='fw-bold fs-6 mb-2'
                                          >
                                            {intl.formatMessage({id: 'WHATSAPP.TWLIO.ADD_TEMPLATES_BUTTONS_TYPE_OF_ACTION'})}
                                          </label>
                                          <select
                                            name='action_type'
                                            id='type_of_action'
                                            className='form-control form-control-md form-control-solid'
                                          >
                                            <option value='phone_number'>{intl.formatMessage({id: 'GLOBAL.WEBSIDE'})}</option>
                                            <option value='other'>{intl.formatMessage({id: 'GLOBAL.PHONE_NUMBER'})}</option>
                                          </select>
                                        </div>
                                        <div className='d-flex flex-column me-5'>
                                          <label
                                            htmlFor='action_type'
                                            className='required fw-bold fs-6 mb-2'
                                          >
                                            {intl.formatMessage({id: 'GLOBAL.BUTTON_TEXT'})}
                                          </label>
                                          <input
                                            placeholder='Visit our website'
                                            type='text'
                                            name='button_text'
                                            value={''}
                                            className='form-control form-control-lg form-control-solid'
                                            //   className={`form-control form-control-lg form-control-solid ${
                                            //     errors.SellerName && 'is-invalid'
                                            //   }`}
                                            autoComplete='off'
                                            //   onChange={handleInputChange}
                                          />
                                        </div>
                                        <div className='d-flex flex-column me-5 flex-grow-1'>
                                          <label
                                            htmlFor='action_type'
                                            className='required fw-bold fs-6 mb-2'
                                          >
                                            {intl.formatMessage({id: 'GLOBAL.WEBSIDE_URL'})}
                                          </label>
                                          <input
                                            type='text'
                                            name='website_url'
                                            value={''}
                                            className='form-control form-control-lg form-control-solid'
                                            //   className={`form-control form-control-lg form-control-solid ${
                                            //     errors.SellerName && 'is-invalid'
                                            //   }`}
                                            autoComplete='off'
                                            //   onChange={handleInputChange}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Radio Button - quick_reply */}
                              <div className='form-check form-check-custom form-check-solid mt-3'>
                                <input
                                  className='form-check-input'
                                  type='radio'
                                  value='quick_reply'
                                  name='buttonsOption'
                                  id='radioOption2'
                                  checked={selectedOption === 'quick_reply'}
                                  onChange={handleOptionChange}
                                  // Aquí puedes definir la lógica para el estado checked de Option 2
                                />
                                <div className='d-flex flex-column'>
                                  <label className='form-check-label' htmlFor='radioOption2'>
                                  {intl.formatMessage({id: 'WHATSAPP.TWLIO.ADD_TEMPLATES_BUTTONS_QUICK_REPLY'})}
                                  </label>
                                  <label className='form-check-label' htmlFor='radioOption2'>
                                  {intl.formatMessage({id: 'WHATSAPP.TWLIO.ADD_TEMPLATES_BUTTONS_QUICK_REPLY_DES'})}
                                  </label>
                                </div>
                              </div>
                              {/* Conditional rendering for 'quick_reply' */}
                              {selectedOption === 'quick_reply' && (
                                <div className='card card-bordered p-5' id='forQuick_reply'>
                                  <div className='d-flex flex-column me-5'>
                                    <div className='d-flex align-items-end mb-4'>
                                      <div className='me-3'>
                                        <label htmlFor='button_text1' className='fw-bold fs-6 mb-2'>
                                          {intl.formatMessage({id: 'GLOBAL.BUTTON_TEXT'})}
                                        </label>
                                        <input
                                          placeholder={intl.formatMessage({id: 'GLOBAL.TEXT_HERE_THREE_DOTS'})}
                                          type='text'
                                          name='button_text1'
                                          value={''}
                                          className='form-control form-control-lg form-control-solid'
                                          autoComplete='off'
                                        />
                                      </div>
                                      <button className='btn btn-bg-light btn-color-danger mb-2'>
                                        {intl.formatMessage({id: 'GLOBAL.REMOVE'})}
                                      </button>
                                    </div>
                                    <div className='d-flex align-items-end mb-4'>
                                      <div className='me-3'>
                                        <label htmlFor='button_text1' className='fw-bold fs-6 mb-2'>
                                          {intl.formatMessage({id: 'GLOBAL.BUTTON_TEXT'})}
                                        </label>
                                        <input
                                          placeholder={intl.formatMessage({id: 'GLOBAL.TEXT_HERE_THREE_DOTS'})}
                                          type='text'
                                          name='button_text1'
                                          value={''}
                                          className='form-control form-control-lg form-control-solid'
                                          autoComplete='off'
                                        />
                                      </div>
                                      <button className='btn btn-bg-light btn-color-danger mb-2'>
                                        {intl.formatMessage({id: 'GLOBAL.REMOVE'})}
                                      </button>
                                    </div>
                                    <div className='d-flex align-items-end mb-4'>
                                      <div className='me-3'>
                                        <label htmlFor='button_text1' className='fw-bold fs-6 mb-2'>
                                          {intl.formatMessage({id: 'GLOBAL.BUTTON_TEXT'})}
                                        </label>
                                        <input
                                          placeholder={intl.formatMessage({id: 'GLOBAL.TEXT_HERE_THREE_DOTS'})}
                                          type='text'
                                          name='button_text1'
                                          value={''}
                                          className='form-control form-control-lg form-control-solid'
                                          autoComplete='off'
                                        />
                                      </div>
                                      <button className='btn btn-bg-light btn-color-danger mb-2'>
                                        {intl.formatMessage({id: 'GLOBAL.REMOVE'})}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <button className='btn btn-primary mt-5'>
                            {intl.formatMessage({
                              id: 'WHATSAPP.TWLIO.ADD_TEMPLATES_ADD_TRANSLATE_TEMPLATE',
                            })}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-2'></div>
                </div>
                <div className='row'>
                  <div className='col-md-2'></div>
                  <div className='col-md-8'>
                    <div className='d-flex align-items-center'>
                      <button className='btn btn-primary mt-5 me-3'>
                        {intl.formatMessage({
                          id: 'WHATSAPP.TWLIO.ADD_TEMPLATES_SAVE_TRANSLATE_TEMPLATE',
                        })}
                      </button>
                      <button className='btn btn-bg-light btn-color-danger mt-5 me-3'>
                        {intl.formatMessage({id: 'WHATSAPP.TWLIO.ADD_TEMPLATES_DELETE_DRAFT'})}
                      </button>
                    </div>
                  </div>
                  <div className='col-md-2'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
