import { FC } from "react";

type Props = {
    alignment: string
    label: string
    size?: string
}

export const LoadingAnimation: FC<Props> = ({
    alignment,
    label,
    size
}) => {
    return (
        <>
            <div className={`d-flex justify-content-${alignment}`}>
                <div className={`text-primary spinner-border spinner-border-${size}`} role="status"></div>     
            </div>

            <div className="mt-4 d-flex justify-content-center">
                <label>{`${label}`}</label>
            </div>
        </>
    );
};