import {FC} from 'react'
import {shortenString} from '../../../utils/functions/_Functions'
import {useIntl} from 'react-intl'

type Props = {
  linkDcem: any
}

const ModalDcemDetail: FC<Props> = ({linkDcem}) => {
  const intl = useIntl()

  return (
    <div className='d-flex'>
      <div className='col-6'>
        <label>{intl.formatMessage({id: 'LINKDCEM.TOTAL_CLICKS'})}: </label>
        <span className='text-muted'>{linkDcem.totalClicks}</span>
        <br />
        <br />
        <br />
        <label>{intl.formatMessage({id: 'LINKDCEM.LINK_DCM'})}: </label>
        <span className='text-muted'>{linkDcem.shortedLink}</span>
        <br />
        <br />
        <br />
        <label>{intl.formatMessage({id: 'LINKDCEM.ASSOCIATED_URL'})}: </label>
        <span className='text-muted'>{shortenString(linkDcem.originalLink)}</span>
        <br />
        <br />
        <br />
        <label>{intl.formatMessage({id: 'LINKDCEM.CREATED_BY'})}: </label>
        <span className='text-muted'>{linkDcem.autorLinkName}</span>
        <br />
        <br />
        <br />
        <label>{intl.formatMessage({id: 'LINKDCEM.LAST_MODIFICATION_DATE'})}: </label>
        <span className='text-muted'> 2022-11-01 11:37:32</span>
      </div>

      <div className='col-6'>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          {linkDcem.qrRoute ? (
            <img
              src={linkDcem.qrRoute}
              style={{width: '300px', height: 'auto', margin: '0 auto'}}
            />
          ) : (
            <span>{intl.formatMessage({id: 'LINKDCEM.NO_QR_AVAILABLE'})}</span>
          )}
        </div>
      </div>
    </div>
  )
}

export {ModalDcemDetail}
