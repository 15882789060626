// Importamos hooks de react que usaremos para este componente
import React, {useEffect, useState, useContext} from 'react'
// Importamos libreria para manejo de lenguaje
import {useIntl} from 'react-intl'

// ---------------------------------------------------------------------------------------

// Importamos el contexto de usuario
import {UserContext} from '../../../app-context/user-context/UserContext'

// ---------------------------------------------------------------------------------------

// Importamos componente con la animacion de carga de registros
import {LoadingAnimation} from '../../../utils/components/loading/LoadingAnimation'

// ---------------------------------------------------------------------------------------

// Importamos la funcion productsListByClient para conexion con API de productos
import {productsListByClient} from '../../products/services/_ProductsListApi'
// Importamos la funcion rolesListByClient ara conexion con API de roles
import {rolesListByClient} from '../../assigns/services/_RolesListApi'

// ---------------------------------------------------------------------------------------

// Importamos las interfaces de marcas que usaremos para este componente
import {ProductsForBrand} from '../interfaces/brandsTypes'
// Importamos las interfaces de roles que ocuparemos en este componente
import {Role} from '../../assigns/interfaces/rolesTypes'

// ---------------------------------------------------------------------------------------

// Importamos funcion para vencimiento de session
import {sessionExpired} from '../../../utils/functions/_Functions'

// Iniciamos funcion para construir el componente ModalEditBrand y recibimos props
export function ModalEditBrand({
  formDataEdit,
  setFormDataEdit,
  selectedProductIds,
  setSelectedProductIds,
  productsBrand,
  errors,
  setErrors,
}) {
  // console.log('renderizamos componente modal de edicion')
  //Algunas variables desde el usercontext
  const {user, token, updateToken} = useContext(UserContext)
  const clientId = user.cli_id
  const userToken = token

  //Constante para el manejo de estado de la animacion de carga
  const [isLoading, setIsLoading] = useState<boolean>(false)
  //Constante para el manejo de estado del mensaje post carga de registros
  const [infoMessageLoading, setInfoMessageLoading] = useState<string>('')

  const [brandProductsList, setBrandProductsList] = useState<ProductsForBrand[]>([])
  const [rolesList, setRolesList] = useState<Role[]>([])

  const checkProductsListLenght = () => {
    //Si el arreglo tiene un tamaño igual a cero
    if (brandProductsList.length === 0) {
      //Mandamos mensaje de productos no existentes
      setInfoMessageLoading(intl.formatMessage({id: 'BRANDS.MODAL_EDIT.NO_PRODUCTS'}))
      //Ocultamos animacion de carga
      setIsLoading(false)
      //Si el arreglo tiene un tamaño mayor a cero
    } else {
      //Solo ocultamos animacion de carga
      setIsLoading(false)
    }
  }

  const getProductsRegisters = async () => {
    //Mostramos la animacion de carga
    setIsLoading(true)

    try {
      const {data: jsonData, code, msg} = await productsListByClient(userToken, clientId)

      if (code === 401 && msg === 'Token no valido') {
        sessionExpired(
          `${intl.formatMessage({id: 'GLOBAL.ALERT.SESSION_EXPIRED'})} - productsregisters on brands`
        )
        return
      } else {
        const productsListReceived = jsonData.data

        const userTokenNew = await jsonData.token

        // console.log('actualiza token productsList (brands): ', userTokenNew)
        updateToken(userTokenNew)

        // console.log({productsListReceived});

        const productsSwitches = productsListReceived.map((product: ProductsForBrand) => {
          return {
            pro_id: product.pro_id,
            name: product.name,
          }
        })

        setBrandProductsList(productsSwitches)
        /* Ejecutamos la funcion para verificar  
        el tamaño del arreglo de marcas */
        checkProductsListLenght()
      }
    } catch (error) {
      setInfoMessageLoading(intl.formatMessage({id: 'BRANDS.MODAL_EDIT.ERROR_PRODUCTS'}))
      setIsLoading(false)
      //  console.log('error de productos ', error);
    }
  }

  const getRolesRegisters = async () => {
    try {
      const {data: jsonData, code, msg} = await rolesListByClient(userToken, clientId)

      if (code === 401 && msg === 'Token no valido') {
        sessionExpired(intl.formatMessage({id: 'GLOBAL.ALERT.SESSION_EXPIRED'}))
        return
      } else {
        const rolesListReceived = jsonData.assigns

        console.log(rolesListReceived)

        const userTokenNew = jsonData.token

        updateToken(userTokenNew)

        setRolesList(rolesListReceived)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getProductsRegisters()
    const productIds = productsBrand.map((product) => product.pro_id)
    setSelectedProductIds(productIds)
    getRolesRegisters()
  }, [productsBrand])

  // const { user, token, updateToken } = useContext(UserContext);
  // const clientId = user.cli_id;
  // const userToken = token;

  // Importamos libreria para manejo de lenguaje
  const intl = useIntl()

  // Funcion para el manejo en la interaccion de los inputs
  const handleInputChangeEdit = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, type, checked, value} = e.target

    if (name === 'nameBrand') {
      if (!formDataEdit.nameBrand) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          nameBrand: undefined,
        }))
      }
    }

    setFormDataEdit((prevData) => {
      const newData = {
        ...prevData,
        [name]: type === 'checkbox' ? checked : value,
      }
      return newData
    })
  }

  // Funcion para verificar si el producto ya esta asignado a la marca
  const isProductAssigned = (pro_id: string) => {
    return selectedProductIds.includes(pro_id)
  }

  // Funcion para manejar los inputs de tipo switch cada que sean manipulados
  const handleCheckboxChange = (pro_id: string) => {
    if (selectedProductIds.includes(pro_id)) {
      setSelectedProductIds((prevIds) => prevIds.filter((id) => id !== pro_id))
    } else {
      setSelectedProductIds((prevIds) => [...prevIds, pro_id])
    }
  }

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value

    setFormDataEdit((prevData) => ({
      ...prevData,
      roleId: value,
    }))
  }

  return (
    <>
      <div className='px-7 py-5'>
        <div className='mb-5'>
          <label className='fs-6 fw-bold required'>
            {intl.formatMessage({id: 'BRANDS.MODAL_EDIT.INPUT_NAME'})}
          </label>
          <div>
            <input type='hidden' value={formDataEdit.idBrand} />
            <input
              type='text'
              className={`form-control form-control-solid mb-3 mb-lg-0 ${errors.nameBrand && 'is-invalid'}`}
              name='nameBrand'
              value={formDataEdit.nameBrand}
              onChange={handleInputChangeEdit}
            />
            {errors.nameBrand && <div className='invalid-feedback'>{errors.nameBrand}</div>}
          </div>
        </div>

        <div className='mb-5'>
          <label className='fs-6 fw-bold'>CRM</label>
          <div>
            <input
              type='text'
              className='form-control form-control-solid mb-3 mb-lg-0'
              value={formDataEdit.crmBrand}
              name='crmBrand'
              onChange={handleInputChangeEdit}
            />
          </div>
        </div>

        <div className='mb-5'>
          <label className='fs-6 fw-bold'>
            {intl.formatMessage({id: 'BRANDS.MODAL_EDIT.ACTIVE_ROLES'})}
          </label>
          <select
            className='form-select form-select-solid'
            name='roles'
            onChange={handleSelectChange}
            value={formDataEdit.roleId}
          >
            <option value=''>
              {intl.formatMessage({id: 'BRANDS.MODAL_EDIT.CONFIGURED_ROLES'})}
            </option>
            {rolesList.map((role, index) => (
              <option key={index} id={role.role_id} value={role.role_id}>
                {role.role_name}
              </option>
            ))}
          </select>
          <span className='text-muted mt-4'>
            {intl.formatMessage({id: 'BRANDS.MODAL_EDIT.ROLE_CHANGE_INFO'})}
          </span>
        </div>

        <div className='mb-5'>
          <div className='alert alert-info d-flex align-items-center' role='alert'>
            <div>{intl.formatMessage({id: 'BRANDS.MODAL_EDIT.MSG_ALERT_ASSIGNS'})}</div>
          </div>
        </div>
        <div className='mb-10'>
          <label className='fs-6 fw-bold mb-4'>
            {intl.formatMessage({id: 'BRANDS.MODAL_EDIT.SWITCH_PRODUCTS'})}
          </label>

          {/* Validaciones para determinar que elementos mostraremos en la pantalla */}

          {/* Si isLoading es igual a 'true' lo mostramos */}
          {isLoading && <LoadingAnimation alignment='center' label={intl.formatMessage({id: 'BRANDS.MODAL_EDIT.LOADING_PRODUCTS'})} size='lg' />}

          {/* Si isLoading es diferente de 'true' y el tamaño de brandProductsList
          es mayor a 0 iniciamos el ciclo para pintar la lista de productos con su respectivo switch */}
          {!isLoading &&
            brandProductsList.length > 0 &&
            brandProductsList.map((item: ProductsForBrand) => (
              <div
                className='form-check form-switch form-switch-sm form-check-custom form-check-solid mb-4'
                key={item.pro_id}
              >
                <input
                  className='form-check-input'
                  type='checkbox'
                  name={`product_${item.pro_id}`}
                  checked={isProductAssigned(item.pro_id)}
                  onChange={() => handleCheckboxChange(item.pro_id)}
                />
                <label className='form-check-label'>{item.name}</label>
              </div>
            ))}

          {/* Si isLoading es diferente de 'true' y el tamaño de brandProductsList
          es igual a 0 mostramos el mensaje informativo */}
          {!isLoading && brandProductsList.length === 0 && <p>{infoMessageLoading}</p>}
        </div>
      </div>
    </>
  )
}
