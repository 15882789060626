// Importamos hooks de react que usaremos para este componente
import React, {useState, useContext, useEffect} from 'react'
// Importamos libreria para manejo de lenguaje
import {useIntl} from 'react-intl'

// ---------------------------------------------------------------------------------------

// Importamos el contexto de usuario
import {UserContext} from '../../../../app-context/user-context/UserContext'

// ---------------------------------------------------------------------------------------

// Importamos la funcion campaignConnectInfoEdit para conexion con API de campañas
import {campaignConnectInfoEdit} from '../../services/_CampaignsEditApi'

// ---------------------------------------------------------------------------------------

/* Importamos funcion sessionExpired, convertObjectsToText y countKeys para vencimiento de sesion
para conversion de objetos a texto y conteo de indices */
import {
  convertObjectsToText,
  countKeys,
  sessionExpired,
} from '../../../../utils/functions/_Functions'

// ---------------------------------------------------------------------------------------

// Importamos las interfaces globales que usaremos para este modulo
import {
  AlertInfo,
  CrmSetup,
  MailTemplate,
  WabaBot,
  WabaTemplate,
  GradeSetupList,
  GradeSetup,
} from '../../../../utils/interfaces/_InterfacesTypes'

import {gradesSetupListByProduct} from '../../../../utils/services/_GradesSetupListApi'

import {mailTemplatesListByProduct} from '../../../../utils/services/_MailTemplatesApi'
import { FormDataEditConnect } from '../../interfaces/campaignsTypes';

// Iniciamos funcion para construir componente ConnectTabForm
function ConnectTabForm({
  productId,
  formDataEditConnect,
  setFormDataEditConnect,
  cam_idCampaign,
  closeModalEdit,
  getCampaignsRegisters,
}) {
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()
  console.log('grade_options: ', formDataEditConnect.grade_options)

  const initialCrmSetupList: CrmSetup = {
    client_id: '',
    crm_setups: [
      {
        crm_id: '',
        crm_name: '',
        crm_description: '',
      },
    ],
  }

  const initialMailTemplatesList: string[] = ['']

  const initialWabaBotsList: WabaBot = {
    client_id: '',
    wab_bots: [
      {
        wab_bot_id: '',
        wab_bot_name: '',
        wab_bot_description: '',
      },
    ],
  }

  const initialGradesSetupList: GradeSetup = {
    standard: {
      '1': intl.formatMessage({id: 'GLOBAL.GRADES_SETUP.NOT_LOCATABLE'}),
      '2': intl.formatMessage({id: 'GLOBAL.GRADES_SETUP.NOT_INTERESTED'}),
      '3': intl.formatMessage({id: 'GLOBAL.GRADES_SETUP.LOW_INTEREST'}),
      '4': intl.formatMessage({id: 'GLOBAL.GRADES_SETUP.MEDIUM_INTEREST'}),
      '5': intl.formatMessage({id: 'GLOBAL.GRADES_SETUP.HIGH_INTEREST'}),
    },
  }

  console.log(formDataEditConnect)
  console.log(formDataEditConnect.grade_options)

  //Variables desde el usercontext
  const {user, token, updateToken} = useContext(UserContext)
  const clientId = user.cli_id
  const userToken = token

  const [crmSetupsList, setCrmSetupsList] = useState<CrmSetup>(initialCrmSetupList)
  const [mailTemplatesList, setMailTemplatesList] = useState<string[]>(initialMailTemplatesList)
  const [wabaBotsList, setWabaBotsList] = useState<WabaBot>(initialWabaBotsList)

  const [gradesSetupsList, setGradesSetupsList] = useState<GradeSetup>(initialGradesSetupList)
  const [gradesCategoryKeys, setGradesCategoryKeys] = useState<string[]>(['standard'])
  const [gradesSetupJson, setGradesSetupJson] = useState<{[key: string]: string} | null>(null)
  const [gradesSetupJsonText, setGradesSetupJsonText] = useState<string>('')

  const [alertInfo, setAlertInfo] = useState<AlertInfo>({
    text: '',
    variant: 'primary',
  })

  const gradeOptionsText = convertObjectsToText(formDataEditConnect.grade_options)

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const {name, value} = event.target

    setFormDataEditConnect((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleFormSubmit = async () => {
    console.log('FormDatasConnect values:')
    console.log('crm:', formDataEditConnect.crm)
    console.log('channel_crm_id:', formDataEditConnect.channel_crm_id)
    console.log('crm_id: ', formDataEditConnect.crm_id)
    console.log('mail_template:', formDataEditConnect.mail_template)
    console.log('set_option', formDataEditConnect.set_option)
    // console.log('mail_template: ', formDataEditConnect.mail_template)
    // console.log('wab_bot: ', formDataEditConnect.wab_bot)
    // console.log('crm_id: ', formDataEditConnect.crm_id)
    // console.log('grade_options: ', formDataEditConnect.grade_options)

    const crm: string = formDataEditConnect.crm
    const channel_crm_id: string = formDataEditConnect.channel_crm_id
    const crm_id: string = formDataEditConnect.crm_id
    const mail_template: string = formDataEditConnect.mail_template
    const set_option: string = formDataEditConnect.set_option
    // const wab_bot = formDataEditConnect.wab_bot
    // const grade_options = formDataEditConnect.grade_options

    try {
      const {
        data: jsonData,
        code,
        msg,
      } = await campaignConnectInfoEdit(
        // mail_template,
        // wab_bot,
        // crm_id,
        // grade_options,
        crm,
        channel_crm_id,
        crm_id,
        mail_template,
        set_option,
        userToken,
        cam_idCampaign
      )

      if (code === 401 && msg === 'Token no valido') {
        sessionExpired(intl.formatMessage({id: 'GLOBAL.ALERT.SESSION_EXPIRED'}))
        return
      } else {
        setAlertInfo({
          text:
            code === 200
              ? intl.formatMessage({id: 'CAMPAIGNS.CONNECT_TAB_FORM.INFO_EDITED'})
              : intl.formatMessage({id: 'GLOBAL.ALERT_SOMETHING_WENT_WRONG'}),
          variant: code === 200 ? 'success' : 'danger',
        })

        setTimeout(() => {
          setAlertInfo({
            text: '',
            variant: '',
          })
          closeModalEdit()
          getCampaignsRegisters()
        }, 3000)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getCrmSetups = () => {
    const crmSetupListReceived: CrmSetup = {
      client_id: 'API-DFGO-06YO',
      crm_setups: [
        {
          crm_id: 'CRMSTP-API-001',
          crm_name: 'HubSpot DragonCEM',
          crm_description: '',
        },
        {
          crm_id: 'CRMSTP-API-002',
          crm_name: 'Zoho DragonCEM',
          crm_description: '',
        },
        {
          crm_id: 'CRMSTP-API-003',
          crm_name: 'Odoo DragonCEM',
          crm_description: '',
        },
      ],
    }

    setCrmSetupsList(crmSetupListReceived)
  }

  const getMailTemplates = async (): Promise<void> => {
    try {
      const {data: jsonData, code, msg} = await mailTemplatesListByProduct(userToken, productId)

      const mailTemplatesReceived: string[] = jsonData.data

      const newUserToken: string = jsonData.token

      updateToken(newUserToken)

      console.log(mailTemplatesReceived)

      setMailTemplatesList(mailTemplatesReceived)
    } catch (error) {
      console.error('Error obteniendo los mail templates: ', error)
    }
  }

  const getWabaBots = () => {
    const wabaBotsListReceived: WabaBot = {
      client_id: 'API-DFGO-06YO',
      wab_bots: [
        {
          wab_bot_id: 'WBOT-API-001',
          wab_bot_name: 'botGralDragonCEM',
          wab_bot_description: '',
        },
        {
          wab_bot_id: 'WBOT-API-002',
          wab_bot_name: 'botGeneralbaahrPrueba',
          wab_bot_description: '',
        },
      ],
    }

    setWabaBotsList(wabaBotsListReceived)
  }

  const getGradesSetups = async (): Promise<void> => {
    try {
      const {data: jsonData, code, msg} = await gradesSetupListByProduct(userToken, productId)

      const gradesSetups = jsonData.data
      const categortKeys = Object.keys(gradesSetups)

      gradesSetups &&
        Object.keys(gradesSetups).length > 0 &&
        (() => {
          setGradesSetupsList(gradesSetups)
          setGradesCategoryKeys(categortKeys)
        })()
    } catch (error) {
      console.error('Error obteniendo el lsitado de configuracion de calificaciones: ', error)
    }
  }

  const handleGradeSetUp = (
    gradeCategory: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const {name, value, type} = gradeCategory.target
    console.log('categoria: ', value)
    console.log({gradesSetupsList})

    const gradesSetupSelected = gradesSetupsList[value]

    console.log(gradesSetupSelected)

    const formattedGradesSetupSelected = convertObjectsToText(gradesSetupSelected)

    setGradesSetupJson(gradesSetupSelected)
    setGradesSetupJsonText(formattedGradesSetupSelected)
  }

  useEffect(() => {
    getCrmSetups()
    getMailTemplates()
    getWabaBots()
    getGradesSetups()
  }, [])

  return (
    <>
      <div className='d-flex mb-6'>
        <label className='col-3'>{intl.formatMessage({id: 'CAMPAIGNS.CRM_CONFIGURATION'})}: </label>
        <select
          className='form-select form-select-solid'
          name='crm'
          id='crm'
          value={formDataEditConnect.crm}
          onChange={handleInputChange}
        >
          {crmSetupsList.crm_setups.map((crmSetup, index) => (
            <option key={crmSetup.crm_id} id={crmSetup.crm_id} value={crmSetup.crm_id}>
              {crmSetup.crm_name}
            </option>
          ))}
        </select>
      </div>

      <div className='d-flex mb-6'>
        <label className='col-3'>{intl.formatMessage({id: 'CAMPAIGNS.CRM_CHANNEL_ID'})}: </label>
        <input
          type='text'
          className='form-control form-control-solid'
          id='channel_crm_id'
          name='channel_crm_id'
          value={formDataEditConnect.channel_crm_id}
          onChange={handleInputChange}
        />
      </div>

      <div className='d-flex mb-6'>
        <label className='col-3'>CRM ID: </label>
        <input
          type='text'
          className='form-control form-control-solid'
          value={formDataEditConnect.crm_id}
          id='crm_id'
          name='crm_id'
          onChange={handleInputChange}
        />
      </div>

      <div className='d-flex mb-6'>
        <label className='col-3'>
          {intl.formatMessage({id: 'CAMPAIGNS.CONNECT_TAB_FORM.MAIL_TEMPLATE'})}:{' '}
        </label>
        <select
          className='form-select form-select-solid'
          name='mail_template'
          id='mail_template'
          value={formDataEditConnect.mail_template}
          onChange={handleInputChange}
        >
          <option value={formDataEditConnect.mail_template}>{formDataEditConnect.mail_template}</option>
          {/* <option value="mail_option_example">Opcion de ejemplo</option> */}
          {mailTemplatesList.map((mailTemplatesList, index) => (
            <option key={index} id={mailTemplatesList} value={mailTemplatesList}>
              {mailTemplatesList}
            </option>
          ))}
        </select>
      </div>

      <div className='d-flex mb-6'>
        <label className='col-3'>
          {intl.formatMessage({id: 'CAMPAIGNS.GRADE_CONFIGURATION'})}:{' '}
        </label>
        <select
          className='form-select form-select-solid'
          name='set_option'
          id='set_option'
          value={formDataEditConnect.set_option}
          onChange={(e) => {
            handleInputChange(e)
            // handleGradeSetUp(e)
          }}
        >
          {/* <option value={formDataEditConnect.grade_options}>Configuración de {countKeys(formDataEditConnect.grade_options)} ponderadores</option> */}
          {gradesCategoryKeys.map((gradeCategory) => (
            <option key={gradeCategory} value={gradeCategory}>
              {gradeCategory}
            </option>
          ))}
        </select>
      </div>

      <div className='d-flex mb-6'>
        <label className='col-3'>{intl.formatMessage({id: 'CAMPAIGNS.WEIGHTS'})}:</label>
        <textarea
          id='ponderadores'
          className='form-control form-control-solid text-muted'
          // value={gradesSetupJsonText}
          value={convertObjectsToText(formDataEditConnect.grade_options)}
          readOnly
          rows={4}
        >hola</textarea>
      </div>

      {alertInfo.text && (
        <div className={`alert alert-${alertInfo.variant} d-flex align-items-center`} role='alert'>
          <div>{alertInfo.text}</div>
        </div>
      )}

      <button 
        type='submit' 
        className='btn btn-primary btn-sm mt-8' 
        onClick={handleFormSubmit}
      >
        {intl.formatMessage({id: 'GLOBAL.SEND'})}
      </button>
    </>
  )
}

export {ConnectTabForm}
