import {FC, useState, useEffect} from 'react'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {sessionExpired} from '../../utils/functions/_Functions'
import {GetHash} from '../services/_GetHash'
import {UpdatePassword} from '../services/_NewPassword'
import {AuthPageGetHash} from '../interfaces/getHashTypes'

type PropsAuth = {
  getHashType: AuthPageGetHash[]
}

const NewPassword: FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false)
  //Constante para el manejo de estado del mensaje post carga de registros
  const [infoMessageLoading, setInfoMessageLoading] = useState<string>('')
  const [showInfo, setShowInfo] = useState<boolean>(false)
  const navigate = useNavigate() // Hook para redirección
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  // Definir el esquema de validación usando Yup
  const validationSchema = Yup.object({
    newPassword: Yup.string()
      .min(6, intl.formatMessage({id: 'AUTH.ALERT.PASSWORD.LENGTH'}))
      .required(intl.formatMessage({id: 'AUTH.ALERT.PASSWORD.REQUIRED'})),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword')], intl.formatMessage({id: 'AUTH.ALERT.MATCHING.PASSWORD'}))
      .required(intl.formatMessage({id: 'AUTH.ALERT.PASSWORD.CONFIRM.REQUIRED'})),
  })
  // Configurar Formik
  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema,

    onSubmit: async (values) => {
      // Simular una solicitud
      // setShowModal(true);

      // Comprobar el hash en la ruta actual
      const params = new URLSearchParams(window.location.search)

      if (params.has('hash')) {
        const hash = params.get('hash')
        if (hash) {
          try {
            // Hay hash en la URL, continúa con la petición getHash
            const result: AuthPageGetHash | undefined = await getHash(hash)

            if (result) {
              // Ya existe data, ahora hacer la actualización de contraseña
              const userId = result.user_id
              const pw = values.confirmPassword
              const token = result.token
              const updatePw = await putPassword(token, userId, pw)

              if (updatePw) {
                // Actualizó de forma correcta.
                setShowModal(true) // Mostrar modal de éxito
              } else {
                // Hubo un error al actualizar. Mostrar diálogo con alerta
                console.error('Error al actualizar la contraseña.')
              }
            } else {
              console.warn('El hash no devolvió un resultado válido.')
            }
          } catch (error) {
            console.error('Error al obtener el hash:', error)
          }
        } else {
          console.warn('El parámetro "hash" está vacío.')
        }
      } else {
        console.error('El parámetro "hash" no está presente en la URL.')
      }
    },
  })

  const handleToCloseModalForNewService = () => {
    setShowModal(false)
    navigate('/login') // Redirigir después de cerrar el modal
  }

  //Solicitudes post
  const getHash = async (hash: string): Promise<AuthPageGetHash | undefined> => {
    try {
      const {jsonData, jsonStatus: code} = await GetHash(hash)
      const {msg, data} = jsonData

      console.log('msg', jsonData.msg)
      if (code === 401 && msg === 'Token no valido') {
        sessionExpired(intl.formatMessage({id: 'GLOBAL.SESSION_EXPIRED'}))
        return
      } else if (code === 200 && msg === 'Hash encontrado') {
        // } else if ( msg === 'Hash encontrado') {
        return data
      } else {
        console.error('Hubo un error')
        setInfoMessageLoading(intl.formatMessage({id: 'GLOBAL.REQUEST_ERROR'}))
        setShowInfo(true)
        return
      }
    } catch (error) {
      console.error('Hubo un error en la petición: ', error)
      setInfoMessageLoading(intl.formatMessage({id: 'GLOBAL.REQUEST_ERROR'}))
      setShowInfo(true)
    }
  }
  const putPassword = async (
    token: string,
    idUser: string,
    password: string
  ): Promise<string | undefined> => {
    try {
      const {jsonData, jsonStatus: code} = await UpdatePassword(token, idUser, password)
      const {msg, data} = jsonData

      if (code === 401 && msg === 'Token no valido') {
        sessionExpired(intl.formatMessage({id: 'GLOBAL.SESSION_EXPIRED'}))
        return
      } else if (code === 200 && msg === 'Contraseña actualizada correctamente') {
        return msg
      } else {
        setInfoMessageLoading(intl.formatMessage({id: 'GLOBAL.REQUEST_ERROR'}))
        setShowInfo(true)
        return undefined
      }
    } catch (error) {
      console.error('Hubo un error en la petición: ', error)
      setInfoMessageLoading(intl.formatMessage({id: 'GLOBAL.REQUEST_ERROR'}))
      setShowInfo(true)
    }
  }
  useEffect(() => {
    if (infoMessageLoading != '') {
      setTimeout(() => {
        setShowInfo(false)
        setInfoMessageLoading('')
      }, 4000)
    }
  }, [infoMessageLoading])

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-11'>
          <img
            className='mb-4'
            src='https://storage.googleapis.com/dragon-bucket-prod/app/img/LogoLogin.png'
            alt='icon'
          />

          {/* begin::Title */}
          <h1 className='text-dark fw-bolder mb-2'>
            {intl.formatMessage({id: 'AUTH.INPUT.RESET.PASSWORD'})}
          </h1>
          {/* end::Title */}
        </div>

        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>
            {intl.formatMessage({id: 'AUTH.INPUT.NEW.PASSWORD'})}
          </label>
          <input
            type='password'
            placeholder='*****'
            className='form-control bg-transparent'
            autoComplete='off'
            name='newPassword'
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.newPassword && formik.errors.newPassword ? (
            <div className='text-danger'>{formik.errors.newPassword}</div>
          ) : null}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>
            {intl.formatMessage({id: 'AUTH.INPUT.CONFIRM.NEW.PASSWORD'})}
          </label>
          <input
            type='password'
            placeholder='*****'
            className='form-control bg-transparent'
            autoComplete='off'
            name='confirmPassword'
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
            <div className='text-danger'>{formik.errors.confirmPassword}</div>
          ) : null}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-primary me-4'
            disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
          >
            <span className='indicator-label'>
              {intl.formatMessage({id: 'AUTH.INPUT.CHANGE.PASSWORD'})}
            </span>
          </button>
        </div>
        {/* end::Form group */}
      </form>
      {showInfo && (
        <div
          className='alert alert-warning d-flex align-items-center  text-center mt-3'
          role='alert'
        >
          <div>{infoMessageLoading}</div>
        </div>
      )}

      {/* Modal */}
      <Modal
        show={showModal}
        onHide={handleToCloseModalForNewService}
        scrollable
        centered
        className='modal-md'
      >
        <Modal.Body>
          <div className='text-center'>
            {intl.formatMessage({id: 'AUTH.MODAL.PASSWORD.RESET.SUCCESS'})}
          </div>
        </Modal.Body>

        <Modal.Footer className='justify-content-center'>
          <button
            className='btn btn-primary btn-sm'
            type='button'
            onClick={handleToCloseModalForNewService}
          >
            {intl.formatMessage({id: 'GLOBAL.INPUT.ACCEPT'})}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export {NewPassword}
