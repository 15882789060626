// Importamos hooks de react que usaremos para este componente
import { useState, useContext, useEffect } from "react";
// Importamos libreria para manejo de lenguaje 
import { useIntl } from "react-intl";

import { UserContext } from "../../../../app-context/user-context/UserContext";

// ---------------------------------------------------------------------------------------
import { brandsListByClient } from "../../../brands/services/_BrandsListApi";

import { BrandForProduct } from "../../interfaces/productsTypes";

// Importamos las interfaces de productos que usaremos en este componente
import { Step5Props } from "../../interfaces/productsTypes";

import { sessionExpired, countArrayObjects } from "../../../../utils/functions/_Functions";

import { LoadingAnimation } from "../../../../utils/components/loading/LoadingAnimation";

import { Step5Errors } from "../../interfaces/productsTypes";

// Iniciamos funcion para construir el componete Step4
function Step5({ onNext, handlePrev, prevData, setStep5Data }: Step5Props) {
  const [productBrand, setProductBrand] = useState(prevData?.productBrand || '');
  const [errors, setErrors] = useState<Step5Errors>({ productBrand: '' });
  const intl = useIntl();
  const { user, token, updateToken } = useContext(UserContext);
  const clientId = user.cli_id;
  const userToken = token;
  const [brandsList, setBrandsList] = useState<BrandForProduct[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getBrandsRegisters = async () => {
    setIsLoading(true);
    try {
      const { data: jsonData, code, msg } = await brandsListByClient(userToken, clientId);
      if (code === 401 && msg === 'Token no valido') {
        sessionExpired(intl.formatMessage({ id: "GLOBAL.ALERT.SESSION_EXPIRED" }));
        return;
      } else {
        const brandsListReceived = jsonData.data;
        const userTokenNew = jsonData.token;
        updateToken(userTokenNew);
        const brandsItems = brandsListReceived.map((brand) => {
          const totalBrandProducts = countArrayObjects(brand.products);
          return {
            bid: brand.bid,
            name: brand.name,
            crm_id: brand.crm_id,
            products: totalBrandProducts
          };
        });
        setBrandsList(brandsItems);
        setIsLoading(false);
      }
    } catch (error) {
      console.log("Error receiving brands: ", error);
      setIsLoading(false);
    }
  };

  const validateInputs = () => {
    let isValid = true;
    if (!productBrand) {
      setErrors(prevErrors => ({ ...prevErrors, productBrand: 'Selecciona una marca' }));
      isValid = false;
    } else {
      setErrors(prevErrors => ({ ...prevErrors, productBrand: '' }));
    }
    return isValid;
  };

  const handleNext = () => {
    if (validateInputs()) {
      onNext({ productBrand });
      setStep5Data({ productBrand });
    }
  };

  useEffect(() => {
    getBrandsRegisters();
  }, [userToken]);

  //UseEffect para traer el valor del prevData si es que ya hubo una seleccion anteriormente
  useEffect(() => {
    setProductBrand(prevData?.productBrand || '');
  }, [prevData]);

  const handlePrevious = () => {
    // Aquí deberías guardar los datos del estado local si lo necesitas
    handlePrev();
  };

  return (
    <div>
      <h2 className="modal-title mb-4">{intl.formatMessage({ id: 'PRODUCTS.MODAL_NEW.LABEL_PROBRAND' })}</h2>
      <form>
        <div className='d-flex mb-8'>
          <i className='bi bi-info-circle text-blue-100'></i>
          <span className='card-label d-block text-blue-100 ml-6'>
            {intl.formatMessage({ id: 'PRODUCTS.MODAL_NEW.INPUTBRAND' })}
          </span>
        </div>
        {isLoading && <LoadingAnimation alignment={'center'} label={'Loading...'} size='lg' />}
        <ul>
          {brandsList.map((brand) => (
            <li key={brand.bid} className="list-group-item">
              <input
                type="radio"
                id={brand.bid}
                name="selectedBrand"
                value={brand.bid}
                className="form-check-input me-2"
                checked={productBrand === brand.bid}
                onChange={(e) => setProductBrand(e.target.value)}
              />
              <label htmlFor={brand.bid} className="form-check-label">
                {brand.name} - Productos: {brand.products}
              </label>
            </li>
          ))}
        </ul>
        <div className='text-danger mb-6'>{errors.productBrand}</div>
      </form>
      <button className="btn btn-light btn-sm mt-6 me-2" onClick={handlePrevious}>{intl.formatMessage({ id: 'PRODUCTS.MODAL_NEW.BUTTONLAST' })}</button>
      <button className="btn btn-primary btn-sm mt-6" onClick={handleNext}>{intl.formatMessage({ id: 'PRODUCTS.MODAL_NEW.BUTTONNEXT' })}</button>
    </div>
  );
}

export { Step5 };