import React, {useContext, useEffect, useMemo, useState} from 'react'

import {
  Column,
  Table,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getGroupedRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  FilterFn,
  ColumnDef,
  flexRender,
} from '@tanstack/react-table'

import {ReportApiMail} from '../interfaces/interactionsReportsTypes'
import {RankingInfo, rankItem} from '@tanstack/match-sorter-utils'
import {mailsReportDataPetition} from '../services/_ReporteInteractionMailApi'
import {Modal} from 'react-bootstrap'
import {InteractionDetailReportMailTable} from './InteractionDetailReportMailTable'
import {sessionExpired} from '../../../../../utils/functions/_Functions'
import {UserContext} from '../../../../../app-context/user-context/UserContext'
import {DropdownExportGral} from '../../../../../../_metronic/partials/content/dropdown/DropdownExportGral'
import {useIntl} from 'react-intl'

type PropsInteractionMailReportTable = {
  arrDataInteractionMail: (data: any[]) => void
  infoMessageLoading: (data: string) => void
  rowsInTable: (data: any[]) => void
  productId: string
  startDate: string
  endDate: string
}
const initialInteractionMailReportData: ReportApiMail[] = []
declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>
  }
  interface FilterMeta {
    itemRank: RankingInfo
  }
}
const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)

  // Store the itemRank info
  addMeta({
    itemRank,
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

export const InteractionReportMailTable: React.FC<PropsInteractionMailReportTable> = ({
  arrDataInteractionMail,
  rowsInTable,
  infoMessageLoading,
  productId,
  startDate,
  endDate,
}) => {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = useState<string>('')

  const {token, updateToken} = useContext(UserContext)
  const userToken: string = token
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  // Constante para el manejo inicial de la data de la tabla
  const [interactionMailList, setInteractionMailList] = useState<ReportApiMail[]>(
    initialInteractionMailReportData
  )
  // Constante para el manejo de objeto
  const [objTableSelect, setObjTableSelect] = useState<Array<any>>([])
  //Estado para almacenar la informacion de la tabla y utilizarla en el boton exportar
  const [actualRows, setActualRows] = useState<ReportApiMail[]>([])

  // Constante y funciones para el manejo de estado para mostrar/ocultar modal
  const [showModal, setShowModal] = useState<boolean>(false)
  const openModal = (obj: object) => {
    setShowModal(true)
    setObjTableSelect([obj])
  }
  const closeModal = () => {
    setShowModal(false)
    setObjTableSelect([])
  }
  //Hook para el manejo del cambio de estados en props.
  useEffect(() => {
    //Ejecutar endpoint para actualizar la data de la tabla
    getMailsReportData(productId, startDate, endDate)
  }, [productId, startDate, endDate])

  const getMailsReportData = async (
    productId: string,
    startDate: string,
    endDate: string
  ): Promise<void> => {
    try {
      const {
        data: jsonData,
        code,
        msg,
      } = await mailsReportDataPetition(userToken, productId, startDate, endDate)

      console.log('msg', msg)
      if (code === 401 && msg === 'Token no valido') {
        sessionExpired(intl.formatMessage({id: 'GLOBAL.SESSION_EXPIRED'}))
        return
      } else if (code === 200 && msg === 'Datos de reporte de mails con exito') {
        if (jsonData.data.length <= 0) {
          infoMessageLoading(intl.formatMessage({id: 'REPORTS.INTERACTIONS.NO_DATA_FOUND'}))
        } else {
          const userTokenNew: string = jsonData.token
          updateToken(userTokenNew)
          setInteractionMailList(jsonData.data)
          infoMessageLoading('')
        }
        return
      } else if (code === 200 && msg === 'No tienes acceso al producto seleccionado') {
        infoMessageLoading(intl.formatMessage({id: 'REPORTS.INTERACTIONS.NO_ACCESS'}))
      }
      // else {
      //   const userTokenNew: string = jsonData.token
      //   updateToken(userTokenNew)
      //   setInteractionMailList(jsonData.data)
      //   infoMessageLoading('')
      // }
    } catch (error) {
      console.error('Hubo un error en la petición: ', error)
      infoMessageLoading(intl.formatMessage({id: 'GLOBAL.REQUEST_ERROR'}))
    }
  }

  const columns = useMemo<ColumnDef<ReportApiMail, any>[]>( //5 Se vuelve a definir la interfaz del objeto como prop de las columnas de la tabla
    () => [
      {
        header: `${intl.formatMessage({id: 'REPORTS.INTERACTIONS.REPORT_TITLE'})} | ${intl.formatMessage({id: 'REPORTS.INTERACTIONS.EMAIL'})}`, // 5.1
        footer: (props) => props.column.id,
        columns: [
          {
            accessorKey: 'event',
            cell: (info) => info.getValue(),
            header: () => <span>{intl.formatMessage({id: 'REPORTS.INTERACTIONS.EVENT'})}</span>, // Evento
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'count',
            cell: (info) => info.getValue(),
            header: () => <span>{intl.formatMessage({id: 'REPORTS.INTERACTIONS.COUNT'})}</span>, // Conteo
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'actions',
            cell: (info) => {
              return (
                <button
                  className='btn btn-sm btn-primary px-3 py-1'
                  onClick={() => openModal(info.row.original)}
                >
                  {intl.formatMessage({id: 'REPORTS.INTERACTIONS.VIEW'})} // Ver
                </button>
              )
            },
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.ACTIONS'})}</span>, // Acciones
            footer: (props) => props.column.id,
          },
        ],
      },
    ],
    []
  )

  const table = useReactTable({
    data: interactionMailList,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    getCoreRowModel: getCoreRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getGroupedRowModel: getGroupedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  })
  //Observar cambios en la tabla, creando un nuevo arreglo y mandarlos como props a la gráfica
  useEffect(() => {
    const rows = table.getPrePaginationRowModel().rows
    let arrChart: {count: number; event: string}[] = []
    // Verifica si rows está definido
    if (rows && rows.length > 0) {
      // Crear un nuevo arreglo con los datos agrupados
      rows.forEach((obj) => {
        const {count, event} = obj.original
        arrChart.push({count, event})
      })
    }
    let actualRows = rows.map((obj) => obj.original)
    setActualRows(actualRows.map(({['leadData']: _, ...rest}) => rest))
    rowsInTable(actualRows)
    arrDataInteractionMail(arrChart)
  }, [table.getPrePaginationRowModel().rows])
  return (
    <>
      {/* Modal */}
      <Modal show={showModal} onHide={closeModal} className='modal-xl' scrollable>
        <Modal.Header
          closeButton
          onClick={() => {
            closeModal()
          }}
        >
          <Modal.Title>
            <div className='container-fluid'>
              <div className='row justify-content-center mb-2'>
                <div className='col-md-12'>
                  <div className='d-flex flex-wrap flex-column mb-6'>
                    <h1 className='my-2'>
                      {intl.formatMessage({id: 'REPORTS.INTERACTIONS.DETAIL_TITLE'})}
                      <span className='ms-2 fs-3 text-gray-500 fw-bold'>
                        {' '}
                        {objTableSelect.length && objTableSelect[0].evento}
                      </span>
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>{/* <QaReportGeneralTable cardSelected={cardSelected} /> */}</>
          <InteractionDetailReportMailTable detailReportMail={objTableSelect} />
        </Modal.Body>
        <Modal.Footer>
          <button
            type='reset'
            className='btn btn-sm btn-secondary'
            onClick={() => {
              closeModal()
            }}
          >
            Cerrar
          </button>
        </Modal.Footer>
      </Modal>
      {/* Table */}
      <div className='col-md-12'>
        <div className='row gy-10 gx-xl-12 mb-10'>
          <div className='card card-custom'>
            <div className='p-2'>
              <div className='card-header pt-6 pb-6'>
                <div className='d-flex justify-content-between'>
                  <DebouncedInput //7. Filtrado global
                    value={globalFilter ?? ''}
                    onChange={(value) => setGlobalFilter(String(value))}
                    className={`form-control form-control-solid w-250px ps-14 me-3`}
                    placeholder={intl.formatMessage({id: 'GLOBAL.TABLE_SEARCH_RECORD'})}
                    //   disabled={disabledButtons}
                  />

                  {/* <button //8. Botón que muestra el dropdown para exportar datos
                    type='button'
                    className={`btn btn-primary d-flex align-items-center me-3`}
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                    //   onClick={handleSetData}
                    //   disabled={disabledButtons}
                  >
                    <KTIcon iconType='outline' iconName='file-down' className='fs-2 me-2' />{' '}
                    <span>Exportar datos</span>
                  </button> */}
                  <DropdownExportGral
                    dataToExport={actualRows}
                    title={'Interacciones_correos'}
                    size='btn-lg'
                  />
                </div>
              </div>
              <div className='h-2' />
              <div id='forTable'>
                <div className='card-body table-responsive'>
                  <table className='table table-row-bordered gs-3 gy-4 gx-12'>
                    <thead>
                      {table.getHeaderGroups().map((headerGroup, index) => (
                        <tr
                          key={headerGroup.id}
                          className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'
                        >
                          {headerGroup.headers.map((header) => {
                            return (
                              <th key={header.id} colSpan={header.colSpan}>
                                {header.isPlaceholder ? null : (
                                  <>
                                    <div
                                      {...{
                                        className: header.column.getCanSort()
                                          ? 'cursor-pointer select-none'
                                          : '',
                                        onClick: header.column.getToggleSortingHandler(),
                                      }}
                                    >
                                      {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                      )}
                                      {{
                                        asc: ' 🔼',
                                        desc: ' 🔽',
                                      }[header.column.getIsSorted() as string] ?? null}
                                    </div>
                                    {header.column.getCanFilter() ? (
                                      <div>
                                        <Filter column={header.column} table={table} />
                                      </div>
                                    ) : null}
                                  </>
                                )}
                              </th>
                            )
                          })}
                        </tr>
                      ))}
                    </thead>
                    <tbody>
                      {table.getRowModel().rows.map((row) => {
                        return (
                          <tr key={row.id}>
                            {row.getVisibleCells().map((cell, index) => {
                              return (
                                <td key={cell.id}>
                                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                              )
                            })}
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>

                <div className='h-2' />

                <div className='card-footer'>
                  <div className='d-flex justify-content-between gap-2'>
                    <div id='SwitchPages'>
                      <button
                        className='btn btn-primary border rounded p-1'
                        onClick={() => table.setPageIndex(0)}
                        disabled={!table.getCanPreviousPage()}
                      >
                        {'<<'}
                      </button>
                      <button
                        className='btn btn-primary border rounded p-1'
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                      >
                        {'<'}
                      </button>
                      <button
                        className='btn btn-primary border rounded p-1'
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                      >
                        {'>'}
                      </button>
                      <button
                        className='btn btn-primary border rounded p-1'
                        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                        disabled={!table.getCanNextPage()}
                      >
                        {'>>'}
                      </button>
                    </div>

                    <div id='PagesFinder' className='text-center'>
                      {' '}
                      <span className='flex items-center gap-1'>
                        {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_PAGE'})}{' '}
                        <strong>
                          {table.getState().pagination.pageIndex + 1}{' '}
                          {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_PAGE_OF'})}{' '}
                          {table.getPageCount()}
                        </strong>{' '}
                        | {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_GO_TO_PAGE'})}:
                        <input
                          type='number'
                          defaultValue={table.getState().pagination.pageIndex + 1}
                          onChange={(e) => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            table.setPageIndex(page)
                          }}
                          className='form-control form-control-solid w-16'
                        />
                      </span>
                    </div>

                    <div id='showFiles' className='text-center'>
                      <select
                        className='form-select form-select-solid'
                        value={table.getState().pagination.pageSize}
                        onChange={(e) => {
                          table.setPageSize(Number(e.target.value))
                        }}
                      >
                        {[10, 20, 30, 40, 50].map((pageSize) => (
                          <option key={pageSize} value={pageSize}>
                            {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_SHOW_PART_ONE'})}{' '}
                            {pageSize}{' '}
                            {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_SHOW_PART_TWO'})}
                          </option>
                        ))}
                      </select>
                      <p>
                        {table.getPrePaginationRowModel().rows.length}{' '}
                        {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_TOTAL_RECORDS'})}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
function Filter({column, table}: {column: Column<any, unknown>; table: Table<any>}) {
  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id)
  const columnFilterValue = column.getFilterValue()
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()
  const sortedUniqueValues = React.useMemo(
    () =>
      typeof firstValue === 'number'
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  )

  // Especifica el ID de la columna para la que no deseas mostrar el filtro
  const excludedColumnId = 'actions' // Cambia esto al ID real de tu columna "acciones"

  // Si la columna actual es la que queremos excluir, no renderizamos el input de filtro
  if (column.id === excludedColumnId) {
    return (
      <>
        <br></br>
      </>
    ) // O puedes devolver un fragmento vacío: <></>
  }
  return typeof firstValue === 'number' ? (
    <div>
      <div className='flex space-x-2'>
        <DebouncedInput
          type='number'
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[0] ?? ''}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [value, old?.[1]])}
          placeholder={`Min ${
            column.getFacetedMinMaxValues()?.[0] ? `(${column.getFacetedMinMaxValues()?.[0]})` : ''
          }`}
          className='w-24 border shadow rounded'
        />
        <DebouncedInput
          type='number'
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[1] ?? ''}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [old?.[0], value])}
          placeholder={`Max ${
            column.getFacetedMinMaxValues()?.[1] ? `(${column.getFacetedMinMaxValues()?.[1]})` : ''
          }`}
          className='w-24 border shadow rounded'
        />
      </div>
      <div className='h-1' />
    </div>
  ) : (
    <>
      <datalist id={column.id + 'list'}>
        {sortedUniqueValues.slice(0, 5000).map((value: any) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <DebouncedInput
        type='text'
        value={(columnFilterValue ?? '') as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`${intl.formatMessage({id: 'GLOBAL.TABLE_SEARCH_THREE_DOTS'})} (${column.getFacetedUniqueValues().size})`}
        className='w-36 border shadow rounded'
        list={column.id + 'list'}
      />
      <div className='h-1' />
    </>
  )
}
// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return <input {...props} value={value} onChange={(e) => setValue(e.target.value)} />
}
