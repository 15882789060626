//Importar hooks de react
import React, {useContext, useEffect, useState} from 'react'
// ---------------------------------------------------------------------------------------
// Importar el componente general de DateRangepicker
import {DateRangePickerFull} from '../../../../../../utils/components/date-pickers/DateRangePickerFull'
// ---------------------------------------------------------------------------------------
//Importar funciones generales
import {
  dateFormatterISO8601Variant,
  dateFormatterTextualEnTwo,
  sessionExpired,
} from '../../../../../../utils/functions/_Functions'
// ---------------------------------------------------------------------------------------
// Importar componente general de metronic
import {KTIcon} from '../../../../../../../_metronic/helpers'
// ---------------------------------------------------------------------------------------
// Importar libreria monment para formatear fechas
import moment from 'moment'
// ---------------------------------------------------------------------------------------
//Importar componente general para listar productos por cliente
import {ProductsByUserDropdown} from '../../../../../../utils/components/dropdowns/ProductsByUserDropdown'
// ---------------------------------------------------------------------------------------
// Importar contexto del usuario
import {UserContext} from '../../../../../../app-context/user-context/UserContext'
// ---------------------------------------------------------------------------------------
//Importar interfaces para
import {ProductList} from '../../../../../../utils/interfaces/_InterfacesTypes'
//Importar la librería para el cambio de idioma.
import { useIntl } from 'react-intl'

export const useFilterProductData = () => {
  //Manejador del id del producto seleccionado
  const [selectedIdProduct, setSelectedIdProduct] = useState<string>('')

  //Manejador del nombre del producto seleccionado
  const [selectedNameProduct, setSelectedNameProduct] = useState<string>('')

  // Manejamos el lsitado de productos para el dropdown
  const [productsList, setProductsList] = useState<ProductList[]>([])

  // Valores iniciales para el rango de fechas
  const initialStartDate: Date = new Date(new Date().setDate(new Date().getDate() - 30))
  const initialEndDate: Date = new Date()

  // Constantes que usaremos para manejar las variables para los labels que muestran la fecha
  const [labelStartDate, setLabelStartDate] = useState<Date>(initialStartDate)
  const [labelEndDate, setLabelEndDate] = useState<Date>(initialEndDate)

  // Constantes que usaremos para manejar las fechas que enviaremos a la peticion
  const [startDate, setStartDate] = useState<string>(dateFormatterISO8601Variant(initialStartDate))
  const [endDate, setEndDate] = useState<string>(dateFormatterISO8601Variant(initialEndDate))

  //Constante para formato de fecha sin tiempo
  const [startDateFormat, setStartDateFormat] = useState<string>(
    moment(initialStartDate).format('YYYY-MM-DD')
  )
  const [endDateFormat, setEndDateFormat] = useState<string>(
    moment(initialEndDate).format('YYYY-MM-DD')
  )

  // Obtenemos propiedades del contexto de usuario
  const {user, token, updateToken} = useContext(UserContext)
  const clientId: string = user.cli_id
  const lvlUser: string = user.user_type
  const userToken: string = token
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  // Manejamos el id y el nombre del producto seleccionado
  const [productsDropdownState, setProductsDropdownState] = useState<boolean>(false)


  // Manejador para la disponibilidad del dateRangePicker
  const [dataRangeState, setDataRangeState] = useState<boolean>(true)

  //Manejar el estado del boton exportar datos
  const [disableButton, setDisableButton] = useState<boolean>(false)

  //Constante para el manejo de estado del mensaje post carga de registros
  const [infoMessageLoading, setInfoMessageLoading] = useState<string>('')

  // Manejamos las acciones que queremos para cuando se seleccione un producto
  const handleProductChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    let productId: string = e.target.value

    if (productId) {
      setDataRangeState(false)
      setSelectedIdProduct(productId)
      setSelectedNameProduct(e.target.options[e.target.selectedIndex].text)
      setDataRangeState(false)
    } else {
      setDataRangeState(true)
      setSelectedIdProduct('')
      setSelectedNameProduct('')
    }
  }

  // Manejamos las acciones que queremos para cuando se interactue con las fechas del DateRange
  const handleDates = (): void => {
    console.log('Ya cambié')
    const startDateNew: string = dateFormatterISO8601Variant(labelStartDate)
    const endDateNew: string = dateFormatterISO8601Variant(labelEndDate)

    setStartDate(startDateNew)
    setEndDate(endDateNew)

    setStartDateFormat(moment(labelStartDate).format('YYYY-MM-DD'))
    setEndDateFormat(moment(labelEndDate).format('YYYY-MM-DD'))
  }

  const printFilterDataProduct = () => {
    const fragment = (
      <React.Fragment>
        {/* Begin Filtro por producto y fecha */}
        <div className='row justify-content-left mb-4'>
          <div className='col-md-12'>
            <div className='d-flex flex-wrap flex-stack mb-6'>
              <h3 className='my-2 d-flex flex-wrap flex-stack'>
                {selectedNameProduct ? (
                  <div className='mb-2'>
                    {intl.formatMessage({id: 'REPORTS.INTERACTIONS.FILTERED_BY'})}:
                    <span className='ms-2 fs-3 text-gray-500 fw-bold'>
                      {selectedNameProduct}
                      {startDateFormat && endDateFormat && (
                        <span className='fs-4 text-gray-500 fw-bold ms-1'>
                          | {startDateFormat} {intl.formatMessage({id: 'REPORTS.INTERACTIONS.TO'})} {endDateFormat}
                        </span>
                      )}
                    </span>
                  </div>
                ) : (
                  <div className='mb-2'>{intl.formatMessage({id: 'REPORTS.INTERACTIONS.SELECT_PRODUCT'})}</div>
                )}
              </h3>
              <div className='d-flex flex-wrap flex-stack align-items-center my-2 w-50'>
                {/* SELECT PRODUCTOS POR CLIENTE */}
                <ProductsByUserDropdown
                  updateToken={updateToken}
                  clientId={clientId}
                  userToken={userToken}
                  sessionExpired={sessionExpired}
                  productIdSelected={selectedIdProduct}
                  handleProductChange={handleProductChange}
                  productsList={productsList}
                  setProductsList={setProductsList}
                  productsDropdownState={productsDropdownState}
                  dropdownClass='form-select form-select-sm form-select-solid mb-2'
                  allProductsOption={true}
                  onlyActiveProducts={true}
                />
                {/* RANGO DE FECHAS */}
                <div className='d-flex align-items-center my-2'>
                  <button
                    type='button'
                    className='btn btn-sm btn-icon btn-secondary me-1'
                    disabled={dataRangeState}
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                  >
                    <KTIcon iconName='calendar' className='fs-2' />
                  </button>

                  <button
                    type='button'
                    className='btn btn-sm btn-secondary disabled me-3'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                  >
                    {intl.formatMessage({id: 'REPORTS.INTERACTIONS.CURRENT_RANGE'}) + ': ' +
                      dateFormatterTextualEnTwo(labelStartDate) +
                      '  -  ' +
                      dateFormatterTextualEnTwo(labelEndDate)}
                  </button>

                  {/* Componente con el DateRangePicker */}
                  <DateRangePickerFull
                    initialStartDate={initialStartDate}
                    initialEndDate={initialEndDate}
                    months={2}
                    handleDates={handleDates}
                    setLabelStartDate={setLabelStartDate}
                    setLabelEndDate={setLabelEndDate}
                  />
                </div>
                {/* BOTÓN EXPORTAR DATOS  */}
                {/* <DropdownExportGral
                  disabled={disableButton}
                  dataToExport={dataExport}
                  title={'Interacciones'}
                /> */}
              </div>
            </div>
          </div>
        </div>
        {/* End */}
      </React.Fragment>
    )
    return fragment
  }

  return {
    printFilterDataProduct,
    startDate,
    endDate,
    startDateFormat,
    endDateFormat,
    selectedIdProduct,
    infoMessageLoading,
    selectedNameProduct,
    userToken,
    productsList,
  }
}
