//Inportar hoosk de react
import React, {useEffect, useState} from 'react'
//Importacion de animacion de carga
import {LoadingAnimation} from '../../../../../../utils/components/loading/LoadingAnimation'
//Importacion de Tabla de interaccion por whatsapp
import {InteractionReportWhatsappTable} from '../../tables/InteractionReportWhatsappTable'
import {useFilterProductData} from '../hooks/useFilterProductDate'
import InteractionWAReportChart from '../../charts/InteractionWAReportChart'
import {ReportApiWhatsapp, ReportUserDropdown} from '../../interfaces/interactionsReportsTypes'
import { useIntl } from 'react-intl'


export const InteractionWhatsappReport: React.FC = () => {
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()
  const initialArrayCreadoPor = [
    {
      id: '1',
      texto: intl.formatMessage({id: 'REPORTS.INTERACTIONS.MESSAGES_BY_LEAD'}),
      value: 'Leads',
      descripcion: intl.formatMessage({id: 'REPORTS.INTERACTIONS.LEAD_INTERACTION'}),
      color: '#3699FF',
    },
    {
      id: '2',
      texto: intl.formatMessage({id: 'REPORTS.INTERACTIONS.MESSAGES_BY_SELLER'}),
      value: 'Sellers',
      descripcion: intl.formatMessage({id: 'REPORTS.INTERACTIONS.SELLER_INTERACTION'}),
      color: '#8950FC',
    },
  ]
  const {
    printFilterDataProduct,
    startDate,
    endDate,
    selectedIdProduct,
    productsList,
    infoMessageLoading,
    selectedNameProduct,
  } = useFilterProductData()

  const arrUsersCall = initialArrayCreadoPor
  //Constantes para el manejo del estado de creado por
  const [labelCreadoPor, setLabelCreadoPor] = useState<ReportUserDropdown>({
    id: '1',
    texto: intl.formatMessage({id: 'REPORTS.INTERACTIONS.MESSAGES_BY_LEAD'}),
    value: 'Lead',
    descripcion: intl.formatMessage({id: 'REPORTS.INTERACTIONS.LEAD_INTERACTION'}),
    color: '#1BC5BD',
  })

  //Constante para el manejo de estado de los datos para la gráfica
  const [dataInteractionWA, setDataInteractionWA] = useState<Array<object>>([])
  //Constante para anejo de errores
  const [errorMessage, setErrorMessage] = useState<string>('')
  //Constante para el manejo de estado de los datos para la gráfica
  const [rowsInTable, setRowsInTable] = useState<ReportApiWhatsapp[]>([])

  //Constante para el manejo de estado de la animacion de carga
  const [isLoading, setIsLoading] = useState<boolean>(false)

  //Animacion de cargando antes de mostrar la tabla y gráfica
  useEffect(() => {
    setIsLoading(true)
    setErrorMessage('')
    setTimeout(() => {
      setIsLoading(false)
    }, 4000)
  }, [selectedIdProduct, productsList, startDate, endDate, labelCreadoPor])

  // Funcion para manejar el cambio de usuario
  const handleChangeUser = (e: string) => {
    let user = arrUsersCall.find((obj) => obj.id === e)
    if (user) {
      setLabelCreadoPor(user)
    }
  }
  return (
    <>
      {printFilterDataProduct()}
      <div className='row gx-2 mb-6'>
        <div className='row justify-content-start'>
          <div className='col-auto'>
            <div className='input-group mb-3'>
              <label className='input-group-text'>{intl.formatMessage({id: 'REPORTS.INTERACTIONS.USER_TYPE'})}</label>
              <select
                className='form-select'
                id='inputGroupSelect01'
                defaultValue={'1'}
                onChange={(e) => handleChangeUser(e.target.value)}
              >
                <option value='1'>Lead</option>
                <option value='2'>{intl.formatMessage({id: 'REPORTS.INTERACTIONS.ADVISOR'})}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className='row gx-2 mb-6'>
        <div className='col-xl-12'>
          <div className='card card-custom px-8 py-10 h-100'>
            <div className='row-justify-content-center mb-2'>
              <div className='col-md-12'>
                {!selectedIdProduct && (
                  <div
                    className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                    role='alert'
                  >
                    <h5 className='mb-1 text-center'>{intl.formatMessage({id: 'REPORTS.INTERACTIONS.SELECT_PRODUCT'})}</h5>
                  </div>
                )}
                {/* {selectedIdProduct && (
                    <div className="d-flex mb-4 justify-content-center">
                      <h5 className="card-label me-4 d-flex flex-wrap flex-stack ">
                        <span className="text-dark text-sm font-weight-bolder mt-3">
                          Interacciones por WhatsApp:
                        </span>&nbsp;&nbsp;
                        <span className="text-muted mt-3"> {selectedNameProduct}</span>
                      </h5>
                    </div>
                  )} */}
                {selectedIdProduct && (
                  <>
                    <div className='d-flex  justify-content-center'>
                      <h5 className='card-label me-4 d-flex flex-wrap flex-stack '>
                        <span className='text-dark text-sm font-weight-bolder mt-3'>
                          {labelCreadoPor.texto}:
                        </span>
                        &nbsp;&nbsp;
                        <span className='text-muted mt-3'>
                          <em>{labelCreadoPor.descripcion}</em>
                        </span>
                      </h5>
                    </div>
                    <div className='d-flex mb-4 justify-content-center'>
                      <span className='card-label me-4 d-flex flex-wrap flex-stack'>
                        {/* <i className='bi bi-dot fs-1' style={{color: labelCreadoPor.color}}></i> */}
                        {selectedNameProduct}
                      </span>
                    </div>
                  </>
                )}
                {!isLoading && errorMessage && (
                  <div
                    className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                    role='alert'
                  >
                    <h5 className='mb-1 text-center'>{errorMessage}</h5>
                  </div>
                )}
                {isLoading && selectedIdProduct && (
                  <div className='py-6'>
                    <LoadingAnimation alignment='center' label='Generando gráfica...' size='lg' />
                  </div>
                )}
                {!errorMessage && !isLoading && productsList.length > 0 && selectedIdProduct && (
                  <InteractionWAReportChart
                    rowsInTable={rowsInTable}
                    arrInteracciones={dataInteractionWA}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Begin Contenido tablas al seleccionar un producto */}
      <div className='row gx-2 mb-6'>
        <div className='col-xl-12'>
          <div className='card card-custom px-8 py-10 h-100'>
            <div className='row justify-content-center mb-2'>
              <div className='col-md-12'>
                {!selectedIdProduct && (
                  <>
                    <div
                      className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                      role='alert'
                    >
                      <h5 className='mb-1 text-center'>{intl.formatMessage({id: 'REPORTS.INTERACTIONS.SELECT_PRODUCT'})}</h5>
                    </div>
                  </>
                )}

                {isLoading && selectedIdProduct && (
                  <div className='py-6'>
                    <LoadingAnimation alignment='center' label='Generando tabla...' size='lg' />
                  </div>
                )}

                {!isLoading && productsList.length > 0 && selectedIdProduct && !errorMessage && (
                  <div className='tab-content' id='myTabContent'>
                    <div
                      className='tab-pane py-5 fade show active'
                      id='kt_tab_pane_4'
                      role='tabpanel'
                    >
                      <InteractionReportWhatsappTable
                        productId={selectedIdProduct}
                        startDate={startDate}
                        endDate={endDate}
                        infoMessageLoading={(data) => setErrorMessage(data)}
                        typeUser={labelCreadoPor.value}
                        arrDataInteractionWA={(data) => setDataInteractionWA(data)}
                        rowsInTable={(data) => setRowsInTable(data)}
                      />
                    </div>
                  </div>
                )}
                {!isLoading && errorMessage && (
                  <div
                    className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                    role='alert'
                  >
                    <h5 className='mb-1 text-center'>{errorMessage}</h5>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End */}
    </>
  )
}
