// Importamos hooks de react que usaremos para este componente
import { useState } from "react";
// Importamos componentes de la libreria de bootstrap para react
import { Tab, Tabs, Container, Row, Col, Card } from "react-bootstrap";

// ---------------------------------------------------------------------------------------

// Importamos el componente GeneralTabForm para el modal de edicion de campaña
import { GeneralTabForm } from "./tabsEditCampaign/GeneralTabForm";
// Importamos el componente ConnectTabForm para el modal de edicion de campaña
import { ConnectTabForm } from "./tabsEditCampaign/ConnectTabForm";
// Importamos el componente NotificationTabForm para el modal de edicion de campaña
import { NotificationTabForm } from "./tabsEditCampaign/NotificationTabForm";
// Importamos el componente WhatsappTabForm para el modal de edicion de campaña
import { WhatsappNotifyTabForm } from "./tabsEditCampaign/WhatsappNotifyTabForm";
import { MultimediaTabForm } from "./tabsEditCampaign/MultimediaTabForm";
import { useIntl } from "react-intl";

// Iniciamos funcion para construir el componente ModalEditCampaign 
function ModalEditCampaign({
  productId, 
  twlCredentials, 
  formDataEditGral, 
  setFormDataEditGral, 
  formDataEditConnect, 
  setFormDataEditConnect, 
  // formDataEditNotify, 
  // setFormDataEditNotify, 
  formDataEditWhatsappNotify,
  setFormDataEditWhatsappNotify,
  channelBuCampaign, 
  nameCampaign, 
  cam_idCampaign, 
  closeModalEdit, 
  getCampaignsRegisters
}){
    const [activeTab, setActiveTab] = useState('general');
    const [formData, setFormData] = useState({
      leadsField1: '',
      leadsField2: '',
      leadsField3: '',
      leadsField4: '',
      vendedoresField1: '',
      vendedoresField2: '',
      vendedoresField3: '',
      vendedoresField4: '',
    });
  
    //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()
    const handleTabChange = (tab) => {
      setActiveTab(tab);
    };
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      // Aquí puedes manejar la lógica de envío de datos, por ejemplo, enviar datos a un servidor.
      console.log('Datos enviados:', formData);
    };
  
    return (
      <Container>
        <h5>{nameCampaign} - {channelBuCampaign}</h5>
        <Row>
          <Col>
            <Tabs activeKey={activeTab} onSelect={handleTabChange}>
              <Tab eventKey="general" title={intl.formatMessage({id: 'GLOBAL.GENERAL'})}>
                      <Card className="rounded-0">
                          <Card.Body>
                              <GeneralTabForm productId={productId} formDataEditGral={formDataEditGral} setFormDataEditGral={setFormDataEditGral} cam_idCampaign={cam_idCampaign} closeModalEdit={closeModalEdit} getCampaignsRegisters={getCampaignsRegisters}/>
                          </Card.Body>
                      </Card>
              </Tab>
              <Tab eventKey="conectividad" title="Conectividad">
                      <Card className="rounded-0">
                          <Card.Body>
                            <ConnectTabForm productId={productId} formDataEditConnect={formDataEditConnect} setFormDataEditConnect={setFormDataEditConnect} cam_idCampaign={cam_idCampaign} closeModalEdit={closeModalEdit} getCampaignsRegisters={getCampaignsRegisters}/>
                          </Card.Body>
                      </Card>
              </Tab>
              <Tab eventKey="whatsapp" title="Whatsapp y Notificaciones">
                      <Card className="rounded-0">
                          <Card.Body>
                              <WhatsappNotifyTabForm productId={productId} twlCredentials={twlCredentials} formDataEditWhatsappNotify={formDataEditWhatsappNotify} setFormDataEditWhatsappNotify={setFormDataEditWhatsappNotify} cam_idCampaign={cam_idCampaign} closeModalEdit={closeModalEdit} getCampaignsRegisters={getCampaignsRegisters}/>
                          </Card.Body>
                      </Card>
              </Tab>
              <Tab eventKey="multimedia" title="Multimedia">
                <Card className="rounded-0">
                  <Card.Body>
                    <MultimediaTabForm />
                  </Card.Body>
                </Card>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    );
}

export {ModalEditCampaign};