import React, {FC} from 'react'
import {Lead, LeadDetailInfo, LeadDataDetail} from '../../../interfaces/leadsTypes'
import {dateFormatterDateTimeExtended} from '../../../../../utils/functions/_Functions'
import {useIntl} from 'react-intl'

type Props = {
  leadData: LeadDetailInfo | undefined
}

export const LeadInfoCard: FC<Props> = ({leadData}) => {
  console.log({leadData})
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  return (
    <>
      <div className='card card-custom h-100'>
        <div className='card-header ribbon ribbon ribbon-end ribbon-clip pt-3 pb-1'>
          <div className='card-title'>
            <div className='symbol symbol-50 symbol-lg-60 mr-2'>
              <img
                src='https://storage.googleapis.com/dragon-bucket-prod/app/img/mails/Phone Number.png'
                alt='campaigns'
                className='alt'
              />
            </div>
            &nbsp;&nbsp;&nbsp;
            <div className='mr-20'>
              <h1 className='card-label h4'>{leadData?.productName}</h1>
              <small>{leadData?.campaignName}</small>
            </div>
          </div>
          <div className='ribbon-label'>
            {intl.formatMessage({id: 'LEADS.INFO_CARD_LEAD_SCORE'})}: {leadData?.leadIscore}
            <span className='ribbon-inner bg-danger'></span>
          </div>
          <div className='ribbon-label mt-20'>
            {intl.formatMessage({id: 'LEADS.INFO_CARD_LAST_RATING'})}: {12}
            <span className='ribbon-inner bg-danger'></span>
          </div>
        </div>
        <div className='card-body'>
          {/* <h4 className='card-label'>Leonardo Guzmán</h4> */}
          <div className='my-2'>
            <i className='bi bi-key fs-4'></i>&nbsp;&nbsp;
            <span className='font-weight-bold'>
              <strong>{intl.formatMessage({id: 'LEADS.INFO_CARD_LEAD_ID'})}: </strong>
            </span>
            {leadData?.leadId}
          </div>
          <div className='my-2'>
            <i className='bi bi-person fs-4'></i>&nbsp;&nbsp;
            <span className='font-weight-bold'>
              <strong>{intl.formatMessage({id: 'LEADS.INFO_CARD_LEAD_NAME'})}: </strong>
            </span>
            {leadData?.leadData.leadName}
          </div>
          <div className='my-2'>
            <i className='bi bi-envelope fs-4'></i>&nbsp;&nbsp;
            <span className='font-weight-bold'>
              <strong>{intl.formatMessage({id: 'LEADS.INFO_CARD_LEAD_MAIL'})}: </strong>
            </span>
            {leadData?.leadData.leadMail}
          </div>
          <div className='my-2'>
            <i className='bi bi-phone fs-4'></i>&nbsp;&nbsp;
            <span className='font-weight-bold'>
              <strong>{intl.formatMessage({id: 'LEADS.INFO_CARD_LEAD_PHONE'})}: </strong>
            </span>
            {leadData?.leadData.leadPhone}
          </div>
          <div className='my-2'>
            <i className='bi bi-calendar fs-4'></i>&nbsp;&nbsp;
            <span className='font-weight-bold'>
              <strong>{intl.formatMessage({id: 'LEADS.INFO_CARD_LEAD_REGISTER_DATE'})}: </strong>
            </span>
            {dateFormatterDateTimeExtended(leadData?.dateAdd)}
          </div>
          <div className='my-2'>
            <i className='bi bi-box-seam fs-4'></i>&nbsp;&nbsp;
            <span className='font-weight-bold'>
              <strong>{intl.formatMessage({id: 'LEADS.INFO_CARD_LEAD_COMMENTS'})}: </strong>
            </span>
            {leadData?.leadComments}
          </div>
          <div className='my-2'>
            <i className='bi bi-gear fs-4'></i>&nbsp;&nbsp;
            <span className='font-weight-bold'>
              <strong>{intl.formatMessage({id: 'LEADS.INFO_CARD_CUSTOM_FIELDS'})}: </strong>
              {leadData?.dcfs ? (
                <>
                  {/* <p>hola</p> */}
                  <ul>
                    {leadData?.dcfs.map((dcfs: string, index: number) => (
                      <li key={index}>{dcfs}</li>
                    ))}
                  </ul>
                </>
              ) : (
                <strong>
                  {intl.formatMessage({id: 'LEADS.INFO_CARD_NOT_HAVE_CUSTOM_FIELDS'})}
                </strong>
              )}
            </span>
          </div>

          {/* ------------------------------- */}
        </div>
      </div>
    </>
  )
}
