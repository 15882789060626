import React, {FC} from 'react'
import {Lead, LeadDetailInfo, LeadDataDetail} from '../../../interfaces/leadsTypes'
import {useIntl} from 'react-intl'

type Props = {
  leadData: LeadDetailInfo | undefined
}

export const EndpointCard: FC<Props> = ({leadData}) => {
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()
  return (
    <>
      <div className='card card-custom gutter-b mt-6 h-100'>
        <div className='card-header border-bottom-0'>
          <div className='card-title'>
            <span className='btn btn-icon btn-light mr-5'>
              <i className='bi bi-plugin'></i>
              <span className='pulse-ring'></span>
            </span>
            <div className='container mb-2'>
              <div className='col-12 d-flex text-center'>
                <div className='me-4'>
                  <h4 className='mt-3'>{intl.formatMessage({ id: 'LEADS.ORIGIN' })}: </h4>
                </div>
                <div>
                  <h4 className='mt-3'>{leadData?.leadEndpoint}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
