// Importamos hooks de react que usaremos para este componente
import React, {useEffect, useState, useContext} from 'react'

import {useLocation} from 'react-router-dom'

// Importamos componente Modal de la libreria de bootstrap para react
import {Modal} from 'react-bootstrap'
// Importamos libreria para manejo de lenguaje
import {useIntl} from 'react-intl'

// ---------------------------------------------------------------------------------------

// Importamos el contexto de usuario
import {UserContext} from '../../app-context/user-context/UserContext'

// ---------------------------------------------------------------------------------------

// Importamos el componente ModalRolView que es donde tenemos el renderizado del calendario
import {ModalRolView} from './modals/ModalRolView'
// Importamos el componente ModalNewRol que es donde tenemos el formulario de creacion de rol
import {ModalNewRol} from './modals/ModalNewRol'
// Importamos el componente ModalEditRol que es donde tenemos el formulario de edicion de rol
import {ModalEditRol} from './modals/ModalEditRol'
// Improtamos componente de animacion de carga de informacion
import {LoadingAnimation} from '../../utils/components/loading/LoadingAnimation'

// ---------------------------------------------------------------------------------------

/* Importamos las funciones rolesListByClient y milestoneByRol para conexion a API 
para obener el istado de roles por cliente y el listado de hitos por rol */
import {rolesListByClient, milestoneByRol} from './services/_RolesListApi'
// Importamos la funcion roleEdit para la edicion de rol
import {roleEdit} from './services/_RolesEditApi'

// ---------------------------------------------------------------------------------------

// Importamos funciones sessionExpired y setPageTitle para vencimiento de sesion y seteo de nombre de pagina
import {canAccessFeature, sessionExpired, setPageTitle} from '../../utils/functions/_Functions'

// ---------------------------------------------------------------------------------------

// Importamos las interfaces globales que usaremos para este modulo
import {AlertInfo} from '../../utils/interfaces/_InterfacesTypes'
// Importamos las interfaces de roles que usaremos en este componente
import {Role, Milestone, RolData, RolDataEdit} from './interfaces/rolesTypes'
import {DRG_STANDAR_MODULES} from '../../routing/constants/_Contstants'

export interface groupAssociated {
  groupAssociated: string[]
}

// Iniciamos funcion para construir componente Assigns
export const Assigns = () => {
  const location = useLocation()

  const query = new URLSearchParams(location.search)

  const roleIdUrlParam = query.get('roleId')
  const roleNameUrlParam = query.get('roleName')

  console.log('aqui esta el id de rol: ', roleIdUrlParam)

  // Constante para uso de diccionario de lenguajes
  const intl = useIntl()

  // Constantes para el manejo de estado de modales de creacion y edicion
  const [showModalNew, setShowModalNew] = useState(false)
  const [showModalEdit, setShowModalEdit] = useState(false)

  // Constantes para el manejo de mensajes de respuesta del servidor
  const [alertInfo, setAlertInfo] = useState<AlertInfo>({
    text: '',
    variant: 'primary',
  })

  const [isLoading, setIsLoading] = useState<boolean>(false)

  // Constantes con parametros de configuracion del calendario
  const [selectableState, setSelectableState] = useState<boolean>(false)
  const [selectMirrorState, setSelectMirrorState] = useState<boolean>(false)
  const [editableState, setEditableState] = useState<boolean>(false)
  const [resizableState, setResizableState] = useState<boolean>(false)

  // Variable con el id del rol seleccionado
  const [selectedRole, setSelectedRole] = useState('')

  // Constante con el estado del rol, si esta prendido o apagado
  const [selectedRoleStatus, setSelectedRoleStatus] = useState<boolean>(false)

  // Listas de los roles del cliente
  const [rolesList, setRolesList] = useState<Role[]>([])

  // Constantes para el manejo de la informacion de edicion de rol
  const [rolDataEdit, setRolDataEdit] = useState<RolDataEdit>({
    updatedUsers: [],
    updatedGroups: [],
    rolName: '',
  })

  // Constantes para el manejo de la informacion de creacion de rol
  const [rolData, setRolData] = useState<RolData[]>([])

  // Constantes para la validacion de los usuarios y grupos de usuarios
  const [groupsAssociated, setGroupsAssociated] = useState<groupAssociated[]>()

  const [errorMessage, setErrorMessage] = useState('')

  // Constante para el manejo del rol id seleccionado
  const [rolId, setRolId] = useState<string>('')

  // Constante para el manejo del nombre del rol seleccionado
  const [rolName, setRolName] = useState<string>('')

  // Constante con el listado de hitos del rol seleccionado
  const [milestonesArray, setMilestonesArray] = useState<Milestone[]>([])

  //Algunas constantes desde el usercontext
  const {user, token, updateToken} = useContext(UserContext)
  const clientId = user.cli_id
  const userToken = token
  const userType: string = user.user_type

  //  Funcion para construir el arreglo con cada uno de los hitos por rol seleccionado
  const buildMilestonesArray = (
    rolListById: Record<
      string,
      {
        day: string
        start: string
        end: string
        color: string
        text_color: string
        borderColor: string
        display: string
      }
    >
  ) => {
    // Aqui se determinan las fechas fijas para mostrar en calendario
    const dayMapping: Record<string, string> = {
      Monday: '2024-02-05',
      Tuesday: '2024-02-06',
      Wednesday: '2024-02-07',
      Thursday: '2024-02-08',
      Friday: '2024-02-09',
      Saturday: '2024-02-10',
      Sunday: '2024-02-11',
    }

    // LLenado de la informacion del hito: dia, hora inicio, hora fin, color, color de texto
    const milestones: Milestone[] = Object.entries(rolListById).map(([id, item]) => {
      const {day, start, end, color, text_color, borderColor} = item
      const title = `${day} Milestone`
      const prefix = dayMapping[day]

      // Respuesta con la data por cada hito
      return {
        id,
        title,
        start: prefix + ' ' + start,
        end: prefix + ' ' + end,
        color,
        textColor: text_color,
        borderColor: '#FFFFFF',
      }
    })

    return milestones
  }

  // Funcion para abrir el modal de creacion
  const openModalNew = () => {
    setShowModalNew(true)
  }

  // Funcion para cerrar el modal de creacion
  const closeModalNew = () => {
    setShowModalNew(false)
  }

  // Funcion para abrir el modal de edicion
  const openModalEdit = () => {
    setShowModalEdit(true)
  }

  // Funcion para cerrar el modal de edicion
  const closeModalEdit = () => {
    setAlertInfo({
      text: '',
      variant: '',
    })
    setErrorMessage('')
    getMilestoneRegister(rolId)
    setShowModalEdit(false)
  }

  // Funcion para obtener el listado de roles registrados en el cliente
  const getRolesRegisters = async () => {
    try {
      const {data: jsonData, code, msg} = await rolesListByClient(userToken, clientId)

      if (code === 401 && msg === 'Token no valido') {
        sessionExpired(intl.formatMessage({id: 'GLOBAL.SESSION_EXPIRED'}))
        return
      } else {
        const rolesListReceived = jsonData.assigns

        // console.log(rolesListReceived)

        const userTokenNew = jsonData.token

        setRolesList(rolesListReceived)

        updateToken(userTokenNew)
      }
    } catch (error) {
      console.error('Error obteniendo el listado de roles: ', error)
    }
  }

  /* Funcion para obtener el listado de hitos por cada rol, 
  puede recibir elemento HTML o una cadena, depende desde donde se mande a llamar la funcion */
  const getMilestoneRegister = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | string
  ) => {
    setIsLoading(true)

    let rolId: string

    // Si el tipo es igual a una cadena
    if (typeof e === 'string') {
      // lo igualamos con el parametro
      rolId = e

      // Si el tipo es un elemento HTML
    } else {
      // lo igualamos con el valor del elemento HTMK
      rolId = e.target.value
    }

    // console.log('inicia funcion de hitos');
    // console.log(rolId);

    // Seteamos el valor de rolId con lo obtenido aqui
    setRolId(rolId)

    try {
      const {data: jsonData, code, msg} = await milestoneByRol(userToken, rolId)

      if (code === 401 && msg === 'Token no valido') {
        sessionExpired(intl.formatMessage({id: 'GLOBAL.SESSION_EXPIRED'}))
        return
      } else {
        const rolListById = jsonData.assign.schedule
        const rolData = jsonData
        const rolStatus = jsonData.assign.status
        const groups = jsonData.assign.groupsAssociated
        const milestones = buildMilestonesArray(rolListById)

        console.log(rolListById)
        console.log(rolData)
        console.log(rolStatus)
        console.log(groups)
        // Imprime el nuevo valor de eventsArray en la consola
        console.log('Nuevo valor de hitosArray:', milestones)

        setMilestonesArray(milestones)
        setRolData(rolData)
        setGroupsAssociated(groups)
        setSelectedRole(rolId)

        if (rolStatus === false) {
          setSelectableState(false)
          setSelectMirrorState(false)
          setSelectedRoleStatus(false)
          setEditableState(false)
          setResizableState(false)
        } else {
          setSelectableState(true)
          setSelectMirrorState(true)
          setSelectedRoleStatus(true)
          setEditableState(true)
          setResizableState(true)
        }

        for (let i = 0; i < rolesList.length; i++) {
          if (rolesList[i].role_id === rolId) {
            setRolName(rolesList[i].role_name)
          }
        }
      }

      setIsLoading(false)
    } catch (error) {
      console.error('Error obteniendo los hitos del rol: ', error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    // Cambiar el título de la página al montar el componente
    setPageTitle('Asignaciones')
    getRolesRegisters()
    if (
      roleIdUrlParam &&
      roleIdUrlParam !== null &&
      roleNameUrlParam &&
      roleNameUrlParam !== null
    ) {
      setSelectedRole(roleIdUrlParam)
      setRolName(roleNameUrlParam)
      getMilestoneRegister(roleIdUrlParam)
    }
  }, [])

  // const [selectedEditionType, setSelectedEditionType] = useState('')

  // console.log(rolesList);
  // console.log(rolId);
  // console.log(rolName);
  // console.log(typeof rolesList);

  // Funcion para el guardado de nuevo rol
  const handleSave = async () => {
    // Aquí implementa la lógica para guardar los datos actualizados
    let users
    let groups
    let name = rolDataEdit.rolName // Declarar name aquí si siempre es el mismo

    if (groupsAssociated && groupsAssociated.length > 0) {
      users = []
      groups = rolDataEdit.updatedGroups
      if (groups.length === 0) {
        setErrorMessage(intl.formatMessage({id: 'ASSIGNS.SELECT_AT_LEAST_ONE_GROUP'}))
        return
      }
      setErrorMessage('')
    } else {
      users = rolDataEdit.updatedUsers
      groups = []
      if (users.length === 0) {
        setErrorMessage(intl.formatMessage({id: 'ASSIGNS.SELECT_AT_LEAST_ONE_USER'}))
        return
      }
      setErrorMessage('')
    }

    console.log(users)
    console.log(groups)

    try {
      const {data: jsonData, code, msg} = await roleEdit(rolId, users, groups, name, userToken)
      setAlertInfo({
        text:
          code === 200
            ? intl.formatMessage({id: 'ASSIGNS.ROLE_EDITED_SUCCESSFULLY'})
            : intl.formatMessage({id: 'GLOBAL.ALERT_SOMETHING_WENT_WRONG'}),
        variant: code === 200 ? 'success' : 'danger',
      })

      if (msg === 'Token no válido') {
        sessionExpired(intl.formatMessage({id: 'GLOBAL.ALERT.SESSION_EXPIRED'}))
      }

      setTimeout(() => {
        if (code === 200) {
          setAlertInfo({
            text: '',
            variant: '',
          })
          getMilestoneRegister(rolId)
          getRolesRegisters()
          closeModalEdit()
        }
      }, 3000)
    } catch (error) {
      setAlertInfo({
        text: intl.formatMessage({id: 'GLOBAL.ALERT_ERROR_AXIOS_REQUEST'}),
        variant: 'danger',
      })
    }
  }

  return (
    <>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='col-md-12'>
            <div className='d-flex flex-wrap flex-stack mb-6'>
              <h1 className='my-2'>{intl.formatMessage({id: 'ASSIGNS.TITLE'})}</h1>

              <div className='d-flex align-items-center my-2'>
                <div className='w-190px me-5'>
                  <select
                    name='seller'
                    data-control='selectSeller'
                    data-hidden-search='true'
                    className='form-select form-select-solid form-select-sm'
                    onChange={(e) => {
                      getMilestoneRegister(e)
                    }}
                    value={selectedRole}
                  >
                    {/* //cambio */}
                    <option value=''>{intl.formatMessage({id: 'ASSIGNS.SELECT_ROLE'})}</option>
                    {rolesList.map((role, index) => (
                      <option key={index} id={role.role_id} value={role.role_id}>
                        {role.role_name}
                      </option>
                    ))}
                  </select>
                </div>
                <button
                  type='button'
                  className='btn btn-primary btn-sm me-5'
                  onClick={openModalNew}
                  disabled={!canAccessFeature(DRG_STANDAR_MODULES.ASSIGNS.value, userType, 1)}
                >
                  {intl.formatMessage({id: 'ASSIGNS.NEW_ROLE'})}
                </button>
                <button
                  type='button'
                  className='btn btn-primary btn-sm'
                  onClick={openModalEdit}
                  disabled={
                    !canAccessFeature(DRG_STANDAR_MODULES.ASSIGNS.value, userType, 3)
                      ? true
                      : !selectedRole
                  }
                >
                  {intl.formatMessage({id: 'ASSIGNS.EDIT_ROLE'})}
                </button>

                {/* Begin: Espacio para el codigo del modal nuevo rol */}

                <Modal show={showModalNew} onHide={closeModalNew} scrollable>
                  <Modal.Header>
                    <Modal.Title>
                      <h1>{intl.formatMessage({id: 'ASSIGNS.ADD_ROLE'})}</h1>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <ModalNewRol close={closeModalNew} updateRolesList={getRolesRegisters} />
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      className='btn btn-sm btn-secondary'
                      onClick={() => {
                        closeModalNew()
                      }}
                    >
                      {intl.formatMessage({id: 'GLOBAL.CANCEL'})}
                    </button>
                  </Modal.Footer>
                </Modal>
                {/* coment */}
                {/* End: Espacio para el codigo del modal nuevo rol */}

                {/* -------------------------------------------------------------- */}

                {/* Begin: Espacio para el codigo del modal editar rol */}

                <Modal show={showModalEdit} onHide={closeModalEdit} scrollable>
                  <Modal.Header>
                    <Modal.Title>
                      <h1>{intl.formatMessage({id: 'ASSIGNS.EDIT_ROLE'})}</h1>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <ModalEditRol
                      rolData={rolData}
                      rolDataEdit={rolDataEdit}
                      setRolDataEdit={setRolDataEdit}
                    />
                  </Modal.Body>
                  {alertInfo.text && (
                    <div
                      className={`alert alert-${alertInfo.variant} align-items-center mt-3 mx-10 text-center`}
                      role='alert'
                    >
                      <div>{alertInfo.text}</div>
                    </div>
                  )}
                  {errorMessage && (
                    <div
                      className={`alert alert-danger align-items-center mt-3 mx-10 text-center`}
                      role='alert'
                    >
                      <div>{errorMessage}</div>
                    </div>
                  )}
                  <Modal.Footer>
                    <button
                      className='btn btn-sm btn-secondary'
                      onClick={() => {
                        closeModalEdit()
                      }}
                    >
                      {intl.formatMessage({id: 'GLOBAL.CANCEL'})}
                    </button>
                    <button className='btn btn-sm btn-primary' type='submit' onClick={handleSave}>
                      {intl.formatMessage({id: 'GLOBAL.SAVE'})}
                    </button>
                  </Modal.Footer>
                </Modal>

                {/* End: Espacio para el codigo del modal editar rol */}
              </div>
            </div>

            <div className='row gy-12 gx-xl-12 mb-6'>
              <div className='card col-xl-12 mb-3 py-6'>
                {isLoading && (
                  <div className='py-12'>
                    <LoadingAnimation
                      alignment='center'
                      label='Obteniendo información del rol...'
                      size='lg'
                    />
                  </div>
                )}

                {!isLoading && selectedRoleStatus && canAccessFeature(DRG_STANDAR_MODULES.ASSIGNS.value, userType, 2) && (
                  <div className='px-8 py-5'>
                    <div className='d-flex mb-4 justify-content-center'>
                      <h5 className='card-label me-4 d-flex flex-wrap flex-stack '>
                        <span className='text-dark text-sm font-weight-bolder mt-3'>
                          {intl.formatMessage({id: 'ASSIGNS.SELECTED_ROLE_SCHEDULE'})}
                        </span>
                        &nbsp;&nbsp;
                        <span className='text-muted mt-3'> {rolName}</span>
                      </h5>
                    </div>
                    <ModalRolView
                      milestonesArray={milestonesArray}
                      selectableState={selectableState}
                      selectMirrorState={selectMirrorState}
                      editableState={editableState}
                      resizableState={resizableState}
                      selectedRoleStatus={selectedRoleStatus}
                      rolId={rolId}
                      rolName={rolName}
                      getMilestoneRegister={getMilestoneRegister}
                      alertInfo={alertInfo}
                      setAlertInfo={setAlertInfo}
                    />
                  </div>
                )}

                {!isLoading && selectedRoleStatus === false && (
                  <div className='py-10'>
                    <div
                      className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                      role='alert'
                    >
                      <div>{intl.formatMessage({id: 'ASSIGNS.INACTIVE_ROLE_MESSAGE'})}</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
