// Importamos hooks de react que usaremos para este componente
import { FC } from 'react'
// Importamos helpers de metronic
import { KTIcon } from '../../../../_metronic/helpers'

// ---------------------------------------------------------------------------------------

// Importamos las interfaces de account-management que usaremos para este componente
import { ProductByClientBU } from '../interfaces/accountManagerTypes'
import { useIntl } from 'react-intl'

// *************************************************************************************************************

// Tipado de Props para el componente SelectedProductsItems
type PropsCSPC = {
  productsByClientList: ProductByClientBU[]
  inactiveProductsCount: number
  activeProductsCount: number
  pendingProductsCount: number
  pendingProductsCountToActive: number
}

// Construimos un funtional component para reenderizar SelectedProductsItems
const SelectedProductsItems: FC<PropsCSPC> = ({
  productsByClientList,
  inactiveProductsCount,
  activeProductsCount,
  pendingProductsCount,
  pendingProductsCountToActive
}) => {
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  return (
    <>
      <div className='d-flex flex-wrap justify-content-center'>

        {/* Begin: Total de productos activos */}
        <div className='border border-gray-300 border-dashed rounded min-w-115px py-3 px-4 me-8 mb-3'>
          <div className='d-flex align-items-center'>
            <KTIcon iconName='toggle-on' className='fs-3 text-success me-2' />
            <div className='fs-2 fw-bolder'>{activeProductsCount}</div>
          </div>
          <div className='fw-bold fs-6 text-gray-400'>{intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.TOTAL_ACTIVE_PRODUCTS'})}</div>
        </div>
        {/* End: Total de productos activos */}

        {/* Begin: Total de productos con proceso pendiente */}
        <div className='border border-gray-300 border-dashed rounded min-w-95px py-3 px-4 me-8 mb-3'>
          <div className='d-flex align-items-center'>
            <KTIcon iconName='time' className='fs-3 text-warning me-2' />
            <div className='fs-2 fw-bolder'>{pendingProductsCount}</div>
          </div>
          <div className='fw-bold fs-6 text-gray-400'>{intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.TOTAL_PRODUCTS_TO_DEACTIVATE'})}</div>
        </div>
        {/* End: Total de productos con proceso pendiente */}

        {/* Begin: Total de productos con proceso pendiente a activación*/}
        <div className='border border-gray-300 border-dashed rounded min-w-95px py-3 px-4 me-8 mb-3'>
          <div className='d-flex align-items-center'>
            <KTIcon iconName='time' className='fs-3 text-warning me-2' />
            <div className='fs-2 fw-bolder'>{pendingProductsCountToActive}</div>
          </div>
          <div className='fw-bold fs-6 text-gray-400'>{intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.TOTAL_PRODUCTS_TO_ACTIVATE'})}</div>
        </div>
        {/* End: Total de productos con proceso pendiente */}

        {/* Begin: Total de productos inactivos */}
        <div className='border border-gray-300 border-dashed rounded min-w-115px py-3 px-4 me-8 mb-3'>
          <div className='d-flex align-items-center'>
            <KTIcon iconName='toggle-off' className='fs-3 text-danger me-2' />
            <div className='fs-2 fw-bolder'>{inactiveProductsCount}</div>
          </div>
          <div className='fw-bold fs-6 text-gray-400'>{intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.TOTAL_DEACTIVE_PRODUCTS'})}</div>
        </div>
        {/* End: Total de productos inactivos */}

      </div>
    </>
  )
}

// Exportamos componentes ClientSelectedInfoCard y SelectedProductsItems
export { SelectedProductsItems }