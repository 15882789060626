import { Dropdown } from 'react-bootstrap';
import { useIntl } from 'react-intl';

export const DropdownItemExportJSON = ({ dataToExport, title }) => {
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  const handleToExportProductsAssignedDataJSON = () => {
    try {
      // Verificar si hay datos para exportar
      if (!dataToExport || !dataToExport.brandProducts) {
        console.error(intl.formatMessage({id: 'DROPDOWNS.NO_DATA_TO_EXPORT'}));
        return;
      }

      // Obtener el usuario y sus productos
      const { id, name, brandProducts } = dataToExport;

      // Crear un objeto con los datos del usuario y sus productos
      const jsonData = {
        userId: id,
        userName: name,
        products: brandProducts.map(product => ({
          productId: product.pro_id,
          productName: product.name,
        })),
      };

      // Convertir el objeto a formato JSON
      const jsonString = JSON.stringify(jsonData, null, 2);

      // Crear un enlace temporal para descargar el archivo JSON
      const blob = new Blob([jsonString], { type: 'application/json' });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', intl.formatMessage({id: 'DROPDOWNS.EXPORTED_DATA_FILENAME'}, {title}) + '.json');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error('Error al generar el archivo JSON:', error);
    }
  };

  return (
    <Dropdown.Item>
      <span onClick={handleToExportProductsAssignedDataJSON}>
      {`${intl.formatMessage({id: 'DROPDOWNS.EXPORT_ASSIGNED_PRODUCTS'})} (.json)`}
      </span>
    </Dropdown.Item>
  );
};
