import {FC, useEffect, ChangeEvent, Dispatch, SetStateAction} from 'react'
import {useIntl} from 'react-intl'
import {campaignsListForProduct} from '../../../standard-modules/campaigns/services/_CampaignsListApi'
import {CampaignList} from '../../interfaces/_InterfacesTypes'

type Props = {
  updateToken: (newToken: string | undefined) => void
  clientId: string
  userToken: string
  productIdSelected: string
  selectedIdCampaign: string
  selectedIdCampaignOne: string
  selectedIdCampaignTwo?: string
  campaignsList: CampaignList[]
  setCampaignsList: Dispatch<SetStateAction<CampaignList[]>>
  campaignDropdownState: boolean
  setCampaignDropdownState: Dispatch<SetStateAction<boolean>>
  comparativeCampaignDropdownState: boolean
  setComparativeCampaignDropdownState: any
  dropdownClass: string
  dropdownName: string
  onlyActiveCampaigns: boolean
  handleCampaignSelected: (e: ChangeEvent<HTMLSelectElement>) => void
  iscomparative: boolean
}
const CampaignsByProductDropdown: FC<Props> = ({
  updateToken,
  clientId,
  userToken,
  productIdSelected,
  selectedIdCampaign,
  selectedIdCampaignOne,
  selectedIdCampaignTwo,
  campaignsList,
  setCampaignsList,
  campaignDropdownState,
  setCampaignDropdownState,
  comparativeCampaignDropdownState,
  setComparativeCampaignDropdownState,
  dropdownClass,
  dropdownName,
  onlyActiveCampaigns,
  handleCampaignSelected,
  iscomparative,
}) => {
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  const getCampaignsByProduct = async (): Promise<void> => {
    try {
      const {
        data: jsonData,
        code,
        msg,
      } = await campaignsListForProduct(userToken, productIdSelected)

      const campaignsListReceived: CampaignList[] = jsonData.data

      const userTokenNew: string = jsonData.token

      updateToken(userTokenNew)

      const noInactiveCampaigns: CampaignList[] = campaignsListReceived.filter(
        (campaign) => campaign.cam_status === true
      )

      setCampaignsList(noInactiveCampaigns)

    } catch (error) {
      console.error('error obteniendo las campañas: ', error)
    }
  }

  useEffect(() => {
    if (productIdSelected !== '' && productIdSelected !== '0') {
      console.log('funcion de campañas')
      setCampaignDropdownState(false)
      setComparativeCampaignDropdownState(false)
      getCampaignsByProduct()
    }else{
      setCampaignDropdownState(true)
      setComparativeCampaignDropdownState(true)

    }

    console.log('id producto: ', productIdSelected)
  }, [productIdSelected])

  return (
    <>
    {/* Seran dropdown de comparacion de campañas? */}
      {iscomparative ? (
        <>
          <select
            className={dropdownClass}
            id={dropdownName}
            name={dropdownName}
            disabled={
              dropdownName === 'campaignOne'
                ? comparativeCampaignDropdownState
                : dropdownName === 'campaignTwo' && selectedIdCampaignOne !== ''
                  ? false
                  : true
            }
            value={dropdownName === 'campaignOne' ? selectedIdCampaignOne : selectedIdCampaignTwo}
            onChange={(e) => handleCampaignSelected(e)}
          >
            <option value={''} disabled>
              {dropdownName === 'campaignOne' ? intl.formatMessage({id: 'REPORT.CAMPAIGN.SELECT_CAMPAIGN_ONE'}) : intl.formatMessage({id: 'REPORT.CAMPAIGN.SELECT_CAMPAIGN_TWO'})}
            </option>
            {dropdownName === 'campaignOne'
              ? campaignsList.map((campaign: CampaignList) => (
                  <option key={campaign.cam_id} value={campaign.cam_id}>
                    {campaign.name}
                  </option>
                ))
              : campaignsList.map(
                  (campaign: CampaignList) =>
                    campaign.cam_id !== selectedIdCampaignOne && (
                      <option key={campaign.cam_id} value={campaign.cam_id}>
                        {campaign.name}
                      </option>
                    )
                )}
          </select>
        </>
    //   Es para una sola campaña?
      ) : (
        <>
          <select
            className={dropdownClass}
            id='campaignSelect'
            name={dropdownName}
            disabled={campaignDropdownState}
            value={selectedIdCampaign}
            onChange={(e) => handleCampaignSelected(e)}
          >
            <option value={''} disabled>{intl.formatMessage({id: 'LEADS.SELECT_A_CAMPAIGN'})}</option>
            <option value='0'>{intl.formatMessage({id: 'REPORT.CAMPAIGN.ALL_CAMPAIGNS'})}</option>
            {campaignsList.map(
              (campaign: CampaignList) =>
                  <option key={campaign.cam_id} value={campaign.cam_id}>
                    {campaign.name}
                  </option>
                
            )}
          </select>
        </>
      )}
    </>
  )
}

export {CampaignsByProductDropdown}
