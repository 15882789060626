import {useIntl} from 'react-intl'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {useEffect} from 'react'
import {setPageTitle} from '../../utils/functions/_Functions'
import {DragonCem} from '../../utils/constants/_Constants'
import { LogsMainTable } from './table/LogsMainTable'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Logs',
    path: '/Logs',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const Logs = () => {
  const intl = useIntl()
  useEffect(() => {
    // Cambiar el título de la página al montar el componente
    setPageTitle(`${intl.formatMessage({id: 'LOGS.TITLE'})} | ${DragonCem}`)
  }, [])

  return (
    <>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='col-md-12'>
            <h1 className='my-2'>{intl.formatMessage({id: 'GLOBAL.HISTORY'})}</h1>
            <PageTitle breadcrumbs={usersBreadcrumbs}>{intl.formatMessage({id: 'GLOBAL.HISTORY'})}</PageTitle>
            <div className='card card-custom'>
              <LogsMainTable />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {Logs}
