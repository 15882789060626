import React from 'react'
import {Tab, Tabs, Form, Button, Container, Row, Col, Card} from 'react-bootstrap'
import {useIntl} from 'react-intl'

function ModalQrCampaign() {
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()
  return (
    <div className='container'>
      <Card style={{width: '32rem'}} className='py-4'>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <img
            src='https://storage.googleapis.com/dragon-bucket-prod/app/img/qrs/cam_id10367-DcemQrs.png'
            style={{width: '300px', height: 'auto', margin: '0 auto'}}
          />
        </div>
      </Card>
      <button className='mt-8 btn btn-primary btn-sm'>
        {intl.formatMessage({id: 'GLOBAL.DOWNLOAD'})}
      </button>
    </div>
  )
}

export {ModalQrCampaign}
