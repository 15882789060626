import {FC, useRef, useState, useEffect} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {Modal} from 'react-bootstrap'
import {getCSS, getCSSVariableValue} from '../../../../../../_metronic/assets/ts/_utils'
import {useThemeMode} from '../../../../../../_metronic/partials'
import {IscoreReportByIscoreTable} from '../tables/IscoreReportByIscoreTable'
// import { iscoreLead, iscoreReport } from '../interfaces/iscoreReportTypes';
import {useIntl} from 'react-intl'
import {IscoreReportCountsTable} from '../tables/IscoreReportCountsTable'

type Props = {
  iscoreReportItemByProduct: any
  selectedIdProduct: any
}

const IscoreReportMainChart: FC<Props> = ({iscoreReportItemByProduct, selectedIdProduct}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const intl = useIntl()

  console.log(iscoreReportItemByProduct.data)

  const categoriesArray = iscoreReportItemByProduct.data.map((item) => item.category)

  console.log(categoriesArray)

  const countsArray = iscoreReportItemByProduct.data.map((item) => item.count)

  console.log(countsArray)

  const initialIscoreReportData = {
    iscoreLead: [],
  }

  const [optionTypeIntSelected, setOptionTypeIntSelected] = useState<string>('')
  const [optionNumberSelected, setOptionNumberSelected] = useState<number>(0)
  const [optionTextSelected, setOptionTextSelected] = useState<string>('')
  const [showModal, setShowModal] = useState<boolean>(false)
  const [cardTitleSelected, setCardTitleSelected] = useState<string>('')

  const openModal = () => {
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const aggregateData = (leads) => {
    const interactionTotals: {[key: string]: number} = {
      WithoutInt: 0,
      LowInt: 0,
      MediumInt: 0,
      HighInt: 0,
      VeryHighInt: 0,
    }

    // leads.forEach(lead => {
    //   interactionTotals[lead.interactionType] += lead.count;
    // });

    return interactionTotals
  }

  // const toChangeIscoreTexts = (intType: string) => {
  //   return intl.formatMessage({ id: `REPORT.ISCORE.${intType}` });
  // };

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, iscoreReportItemByProduct])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))
    const c_type = 'bar'
    const interactionTotals = aggregateData(iscoreReportItemByProduct.iscoreLead)
    const serieOptions = categoriesArray
    const serieCounts = countsArray
    const iscoreInteracctionsTexts = categoriesArray

    console.log(iscoreInteracctionsTexts) // Nombres cambiados de los tipos de interacciones a sus respectivos idiomas

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(
        height,
        c_type,
        iscoreReportItemByProduct,
        iscoreInteracctionsTexts,
        serieCounts,
        handleSelection // Ajustamos aquí el paso de la función
      )
    )
    if (chart) {
      chart.render()
    }

    return chart
  }

  const handleSelection = (indexSelection: number | undefined) => {
    console.log(indexSelection)

    if (indexSelection !== undefined && indexSelection >= 0) {
      console.log(categoriesArray)

      const categorySelected = String(categoriesArray[indexSelection])

      console.log(typeof categorySelected)

      setCardTitleSelected(categorySelected)

      // const interactionType = categoriesArray[indexSelection] // Obtener tipo de interacción
      // console.log(interactionType)
      // setCardSelected(interactionTypes)
      // const leads = initialIscoreReportData.iscoreLead.filter(lead => lead.interactionType === interactionType)
      // const id = leads.length > 0 ? leads[0].id : 0
      // setOptionNumberSelected(id);
      // setOptionTextSelected(toChangeIscoreTexts(interactionType));

      // setOptionTypeIntSelected(interactionType) // Establecer tipo de interacción seleccionada

      openModal()
    } else {
      console.log('Índice inválido o no definido')
    }
  }
  function getChartOptions(
    height: number,
    c_type: any,
    iscoreReportItemByProduct: any,
    iscoreInteracctionsTexts: any,
    serieCounts: any,
    handleSelection: (indexSelection: number | undefined) => void // Ajustamos aquí la función
  ): ApexOptions {
    const labelColor = getCSSVariableValue('--bs-gray-500')
    const borderColor = getCSSVariableValue('--bs-gray-200')
    const colors = [
      '#E20000',
      '#FF6C0E',
      '#E2B900',
      '#62B702',
      '#02DE87',
      '#02DEC0',
      '#02CADE',
      '#029FDE',
      '#0270DE',
      '#0237DE',
      '#2002DE',
      '#5502DE',
      '#003462',
    ]

    return {
      series: [
        {
          name: intl.formatMessage({id: 'REPORTS.INTERACTIONS.QUALIFIED_LEADS'}), // Reemplazado
          data: serieCounts,
        },
      ],
      chart: {
        height,
        type: c_type,
        fontFamily: 'inherit',
        events: {
          legendClick: function (chartContext, seriesIndex, config) {
            handleSelection(seriesIndex)
          },
          dataPointSelection: function (event, chartContext, config) {
            const indexSelection: number = config.dataPointIndex
            handleSelection(indexSelection)
          },
        },
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
          },
          export: {
            csv: {
              filename: intl.formatMessage({id: 'REPORTS.INTERACTIONS.REPORT_I_SCORES'}),
            },
            svg: {
              filename: intl.formatMessage({id: 'REPORTS.INTERACTIONS.REPORT_I_SCORES'}), 
            },
            png: {
              filename: intl.formatMessage({id: 'REPORTS.INTERACTIONS.REPORT_I_SCORES'}),
            },
          },
        },
      },
      colors: colors,
      plotOptions: {
        bar: {
          distributed: true,
          horizontal: false,
          columnWidth: '30%',
          borderRadius: 5,
        },
      },
      legend: {
        show: true,
        fontFamily: 'inherit',
        fontSize: '13px',
        labels: {
          colors: labelColor,
        },
        position: 'top',
        horizontalAlign: 'center',
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: iscoreInteracctionsTexts, // Utilizamos los textos formateados
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      fill: {
        opacity: 1,
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: '12px',
        },
        y: {
          formatter: function (val) {
            return val + ' leads'
          },
        },
      },
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    }
  }

  return (
    <>
      <div className='card-xxl-stretch mb-5 mb-xl-10'>
        <div className=''>
          <div ref={chartRef} id='kt_charts_widget_1_chart' style={{height: '350px'}} />
        </div>
      </div>

      <Modal show={showModal} onHide={closeModal} className='modal-xl' scrollable>
        <Modal.Header closeButton onClick={closeModal}>
          <Modal.Title>
            <div className='d-flex'>
              <div className='me-2'>
                <h1>{cardTitleSelected}</h1>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <IscoreReportCountsTable
              selectedIdProduct={selectedIdProduct}
              iscoreReportItemByProduct={iscoreReportItemByProduct}
              cardTitleSelected={cardTitleSelected}
            />
            {/* <IscoreReportByIscoreTable optionNumberSelected={optionNumberSelected} optionTypeIntSelected={optionTypeIntSelected} /> */}
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            type='reset'
            className='btn btn-sm btn-secondary'
            onClick={() => {
              closeModal()
            }}
          >
            {intl.formatMessage({id: 'GLOBAL.CLOSE'})}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export {IscoreReportMainChart}
