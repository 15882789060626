import {FC, useRef, useEffect} from 'react'
import ApexCharts from 'apexcharts'
import {useThemeMode} from '../../../../../../_metronic/partials'
import {getCSS, getCSSVariableValue} from '../../../../../../_metronic/assets/ts/_utils'
import {CampaignReport} from '../interfaces/campaignsReportTypes'
import {dataExtract, formatNumber} from '../../../../../utils/functions/_Functions'
import {equalizeArraysRangesIscore} from '../../../../../utils/functions/_Functions'
import {combinateDatesIscore} from '../../../../../utils/functions/_Functions'
import { useIntl } from 'react-intl'

type Props = {
  campaignReportItemROne: CampaignReport
  campaignReportItemRTwo: CampaignReport
}

const CampaignsReportIscoreChart: FC<Props> = ({
  campaignReportItemROne,
  campaignReportItemRTwo,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))
    const c_type = 'line'
    // const serieOptRangeOne = dataExtract(campaignReportItemROne.iscore.iscoreByDay, "iscore")
    // const serieOptRangeTwo = dataExtract(campaignReportItemRTwo.iscore.iscoreByDay, "iscore")

    const [equalizedRange1, equalizedRange2] = equalizeArraysRangesIscore(
      campaignReportItemROne.iscore.iscoreByDay,
      campaignReportItemRTwo.iscore.iscoreByDay,
      intl.formatMessage({id: 'REPORT.CAMPAIGN.NO_DATA_AVAILABLE'}), 
      0
    )
    const combinedDates = combinateDatesIscore(equalizedRange1, equalizedRange2)
    const serieOptions = dataExtract(combinedDates, 'date')
    // const serieOptions = [
    //     '1/abril-1/mayo', '2/abril-2/mayo', '3/abril-3/mayo', '4/abril-4/mayo',
    //     '5/abril-5/mayo', '6/abril-6/mayo', '7/abril-7/mayo', '8/abril-8/mayo',
    //     '9/abril-9/mayo', '10/abril-10/mayo', '11/abril-11/mayo', '12/abril-12/mayo',
    //     '13/abril-13/mayo', '14/abril-14/mayo', '15/abril-15/mayo', '16/abril-16/mayo',
    //     '17/abril-17/mayo', '18/abril-18/mayo', '19/abril-19/mayo', '20/abril-20/mayo',
    //     '21/abril-21/mayo', '22/abril-22/mayo', '23/abril-23/mayo', '24/abril-24/mayo',
    //     '25/abril-25/mayo', '26/abril-26/mayo', '27/abril-27/mayo', '28/abril-28/mayo',
    //     '29/abril-29/mayo', '30/abril-30/mayo', '31/abril-31/mayo'
    // ];

    const serieCounts = []

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height, c_type, serieOptions, serieCounts, equalizedRange1, equalizedRange2)
    )
    if (chart) {
      chart.render()
    }

    return chart
  }
  function getChartOptions(
    height: number,
    c_type: any,
    serieOptions: any,
    serieCounts: any,
    equalizedRange1: any,
    equalizedRange2: any
  ) {
    const labelColor = getCSSVariableValue('--bs-gray-500')
    const borderColor = getCSSVariableValue('--bs-gray-200')

    const extractedRange1 = dataExtract(equalizedRange1, 'iscore')
    const extractedRange2 = dataExtract(equalizedRange2, 'iscore')

    let series = []

    return {
      series: [
        {
          name: intl.formatMessage({id: 'REPORT.CAMPAIGN.RANGE_1'}),
          // data: [19, 14, 11, 26, 15, 23, 26, 19, 23, 28, 26, 19, 22, 17, 13, 20, 23, 16, 21, 18, 26, 23, 21, 16, 19, 22, 24, 16, 25, 26, 25],
          data: extractedRange1,
          color: '#00BDCD', // Color para la serie 'Rango 1'
        },
        {
          name: intl.formatMessage({id: 'REPORT.CAMPAIGN.RANGE_2'}),
          // data: [19, 20, 23, 23, 24, 21, 14, 18, 28, 18, 22, 19, 13, 27, 22, 17, 20, 15, 22, 16, 28, 17, 20, 20, 15, 21, 27, 16, 20, 23, 15],
          data: extractedRange2,
          color: '#FF6C0E', // Color para la serie 'Rango 2'
        },
      ],
      chart: {
        type: c_type,
        height: '650',
        fontFamily: 'inherit',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        events: {},
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
          },
          export: {
            csv: {
              filename: intl.formatMessage({id: 'REPORT.CAMPAIGN.REPORT_NAME'}), // Reemplazo
            },
            svg: {
              filename: intl.formatMessage({id: 'REPORT.CAMPAIGN.REPORT_NAME'}), // Reemplazo
            },
            png: {
              filename: intl.formatMessage({id: 'REPORT.CAMPAIGN.REPORT_NAME'}), // Reemplazo
            },
          },
        },
      },
      plotOptions: {
        bar: {
          // columnWidth: '45%',
          horizontal: false,
          columnWidth: '85%',
          borderRadius: 5,
        },
        series: {
          colors: ['#7cb5ec', '#90ed7d'], // Colores para las series en orden correspondiente
        },
      },
      legend: {
        show: true,
        fontFamily: 'inherit',
        fontSize: '13px',
        labels: {
          colors: labelColor,
        },
        position: 'top',
        horizontalAlign: 'center',
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: serieOptions,
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      fill: {
        opacity: 1,
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: '12px',
        },
        y: {
          formatter: function (val) {
            return intl.formatMessage({id: 'REPORT.CAMPAIGN.AVERAGE_ISCORE'}) + ': ' + formatNumber(val) 
          },
        },
      },
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    }
  }

  return (
    <>
      {/* // <><p>hola</p></> */}
      <div className='card-xxl-stretch mb-5 mb-xl-10'>
        <div className=''>
          <div ref={chartRef} id='kt_charts_widget_1_chart' style={{height: '350px'}} />
        </div>
      </div>
    </>
  )
}

export {CampaignsReportIscoreChart}
