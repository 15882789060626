export const DRG_STANDAR_MODULES = {
  USERS: {id: 1, value: 'ACCOUNT_MANAGER'},
  LEADS: {id: 2, value: 'LEADS'},
  REASIGN: {id: 3, value: 'REASIGN'},
  EXPORT_LEADS: {id: 4, value: 'EXPORT_LEADS'},
  SPAM: {id: 5, value: 'SPAM'},
  RECORDINGS: {id: 6, value: 'RECORDINGS'},
  HELP_CENTER: {id: 7, value: 'HELP_CENTER'},
  REPORTS: {id: 8, value: 'REPORTS'},
  REPORT_CRM: {id: 9, value: 'REPORT_CRM'},
  BRANDS: {id: 10, value: 'BRANDS'},
  PRODUCTS: {id: 11, value: 'PRODUCTS'},
  ASSIGNS: {id: 12, value: 'ASSIGNS'},
  LINK_DCEM: {id: 13, value: 'LINK_DCEM'},
  SHARE_ACCESS: {id: 14, value: 'SHARE_ACCESS'},
  ROLES_PRIVILEGES: {id: 15, value: 'ROLES_PRIVILEGES'},
  CLIENTS: {id: 16, value: 'CLIENTS'},
  CAMPAIGNS: {id: 17, value: 'CAMPAIGNS'},
  USERS_GROUPS: {id: 18, value: 'USERS_GROUPS'},
  CHATWAB: {id: 19, value: 'CHATWAB'},
  ACCOUNT_MANAGEMENT: {id: 20, value: 'ACCOUNT_MANAGEMENT'},
  LOGS: {id: 21, value: 'LOGS'},
  DASHBOARD: {id: 22, value: 'DASHBOARD'},
}
export const DRG_USERS_TYPES = {
  ADMIN: {id: 1, value: 'ADMIN'},
  SUPER_ADMIN: {id: 2, value: 'SUPER_ADMIN'},
  ADMIN_DC: {id: 3, value: 'ADMIN_DC'},
  DEVELOPER: {id: 4, value: 'DEVELOPER'},
  MANAGER: {id: 5, value: 'MANAGER'},
  SELLER: {id: 6, value: 'SELLER'},
  PREMIUM_SELLER: {id: 7, value: 'PREMIUM_SELLER'},
  MARKETING: {id: 8, value: 'MARKETING'},
  CRM: {id: 9, value: 'CRM'},
  ASISTENTE: {id: 10, value: 'ASSISTANT'},
  CAPTURISTA: {id: 11, value: 'CAPTURIST'},
  SELLER_DC: {id: 12, value: 'SELLER_DC'},
}
