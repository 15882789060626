import {FC, useState, useRef, useEffect} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {useThemeMode} from '../../../../../../_metronic/partials'
import {dataExtract} from '../../../../../utils/functions/_Functions'
import {getCSS, getCSSVariableValue} from '../../../../../../_metronic/assets/ts/_utils'
import {CrmCountsReport} from '../interfaces/crmReportTypes'
import {Modal} from 'react-bootstrap'
import {CrmReportGeneralTable} from '../tables/CrmReportGeneralTable'
import { useIntl } from 'react-intl'

type Props = {
  crmReportItem: CrmCountsReport
}

const CrmReportLeadsChart: FC<Props> = ({crmReportItem}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  const [optionNumberSelected, setOptionNumberSelected] = useState<number>(0)
  const [optionTextSelected, setOptionTextSelected] = useState<string>('')

  const [modalTitle, setModalTitle] = useState<string>('')

  const [cardSelected, setCardSelected] = useState<number>()

  const [showModal, setShowModal] = useState<boolean>(false)

  const openModal = () => {
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, crmReportItem])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))
    const c_type = 'pie'
    const serieOptions = [
      intl.formatMessage({id: 'REPORT.CRM.LEADS_SENT_TO_CRM'}),
      intl.formatMessage({id: 'REPORT.CRM.LEADS_NOT_SENT_TO_CRM'})
    ]
    const serieCounts = [crmReportItem.totalLeadsToCrm, crmReportItem.totalLeadsNoToCrm]

    console.log(serieOptions)
    console.log(serieCounts)

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(
        height,
        c_type,
        crmReportItem,
        serieOptions,
        serieCounts,
        setModalTitle,
        openModal,
        cardSelected,
        setCardSelected
      )
    )
    if (chart) {
      chart.render()
    }

    return chart
  }
  function getChartOptions(
    height: number,
    c_type: any,
    crmReportItem: any,
    serieOptions: any,
    serieCounts: any,
    setModalTitle,
    openModal: any,
    cardSelected: any,
    setCardSelected: any
  ): ApexOptions {
    const labelColor = getCSSVariableValue('--bs-gray-500')
    const borderColor = getCSSVariableValue('--bs-gray-200')

    const colors = ['#00BDCD', '#FF6C0E']

    const handleSelection = (indexSelection: number | undefined) => {
      console.log(indexSelection)

      if (indexSelection === 0) {
        console.log('leads que fueron a crm')

        setModalTitle(intl.formatMessage({id: 'REPORT.CRM.LEADS_SENT_TO_CRM'}))

        setCardSelected(2)

        openModal()
      } else if (indexSelection === 1) {
        console.log('leads que no fueron a CRM')

        setModalTitle(intl.formatMessage({id: 'REPORT.CRM.LEADS_NOT_SENT_TO_CRM'}))

        setCardSelected(3)

        openModal()
      }
    }

    return {
      series: serieCounts,
      chart: {
        height,
        type: c_type,
        fontFamily: 'inherit',
        events: {
          legendClick: function (chartContext, seriesIndex, config) {
            console.log('se dio click legendclick')
            console.log('Se hizo clic en el elemento de la leyenda: ', seriesIndex)
            handleSelection(seriesIndex)
          },

          dataPointSelection: function (event, chartContext, config) {
            console.log('se dio click datapointselection')
            console.log('Se hizo clic en el elemento: ', config.dataPointIndex)
            const indexSelection: number = config.dataPointIndex
            handleSelection(indexSelection)
          },
        },
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
          },
          export: {
            csv: {
              filename: intl.formatMessage({id: 'REPORT.CRM.CRM_REPORT'}),
            },
            svg: {
              filename: intl.formatMessage({id: 'REPORT.CRM.CRM_REPORT'}),
            },
            png: {
              filename: intl.formatMessage({id: 'REPORT.CRM.CRM_REPORT'}),
            },
          },
        },
      },
      colors: colors,
      labels: serieOptions,
      legend: {
        show: true,
        fontFamily: 'inherit',
        fontSize: '13px',
        labels: {
          colors: labelColor,
        },
        position: 'bottom',
        horizontalAlign: 'center',
      },
      stroke: {
        show: false,
      },
    }
  }

  return (
    <>
      <div className='card-xxl-stretch mb-5 mb-xl-10'>
        <div className=''>
          <div ref={chartRef} id='kt_charts_widget_1_chart' style={{height: '350px'}} />
        </div>
      </div>

      <Modal show={showModal} onHide={closeModal} className='modal-xl' scrollable>
        <Modal.Header
          closeButton
          onClick={() => {
            closeModal()
          }}
        >
          <Modal.Title>
            <h1>{modalTitle}</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CrmReportGeneralTable cardSelected={cardSelected} />
        </Modal.Body>
        <Modal.Footer>
          <button
            type='reset'
            className='btn btn-sm btn-secondary'
            onClick={() => {
              closeModal()
            }}
          >
            Cerrar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export {CrmReportLeadsChart}
