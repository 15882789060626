import React from 'react'
import {formatTextMIddleDash} from '../../../utils/functions/_Functions'
import {useIntl} from 'react-intl'

type Props = {
  product: any
}

const ModalRenameProduct: React.FC<Props> = ({product}) => {
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  return (
    <>
      <div className='mb-6'>
        <label className='fs-6'>
          {intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.CURRENT_NAME'})}
        </label>
        <input
          className='form-control form-control-solid'
          value={formatTextMIddleDash(product.name)}
          readOnly={true}
        />
      </div>

      <div>
        <label className='required fs-6'>
          {intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.NEW_NAME'})}
        </label>
        <input
          className='form-control form-control-solid'
          // value={currentClientNameSelected}
          placeholder=''
        />
      </div>

      <div className='mt-4'>
        <label className='required fw-bold fs-6 mb-2'>
          {intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.REASON_FOR_NAME_CHANGE'})}
        </label>
        <select className='form-select form-select-lg form-select-solid mb-4'>
          <option value='0'>
            --{intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.SELECT_REASON_FOR_UPDATE'})}--
          </option>
          <option value='1'>{intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.REASON_1'})}</option>
          <option value='2'>{intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.REASON_2'})}</option>
          <option value='3'>{intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.REASON_3'})}</option>
          <option value='4'>{intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.REASON_4'})}</option>
          <option value='5'>{intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.REASON_5'})}</option>
        </select>
      </div>
    </>
  )
}

export {ModalRenameProduct}
