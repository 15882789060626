import React, { FC, useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../app-context/user-context/UserContext';

const ButtonHelpFreshdesk: FC = () => {

    const dataFromContext = useContext(UserContext)
    const { user } = dataFromContext
    const cliId = user.cli_id
    const [idBu, setIdBu] = useState<string>("")
    useEffect(() => {
        // Dividir el string usando el guion medio como delimitador
        const parts = cliId.split('-');
        console.log(cliId, parts[0])
        setIdBu(parts[0])
    }, [])

    useEffect(() => {
        // const script1 = document.createElement('script');
        // script1.innerHTML = `window.fwSettings={
        //     'widget_id': 66000003119
        // };`;

        // document.body.appendChild(script1);

        // const script2 = document.createElement('script');
        // script2.setAttribute('type', 'text/javascript');
        // script2.setAttribute('src', 'https://widget.freshworks.com/widgets/66000003119.js');
        // script2.setAttribute('async', 'true');
        // script2.setAttribute('defer', 'true');
        // document.body.appendChild(script2);

        if (idBu === "DTECH") {
            const script1 = document.createElement('script');
            script1.innerHTML = `window.fwSettings={
            'widget_id': 153000001497
        };`;

            document.body.appendChild(script1);

            const script2 = document.createElement('script');
            script2.setAttribute('type', 'text/javascript');
            script2.setAttribute('src', 'https://widget.freshworks.com/widgets/153000001497.js');
            script2.setAttribute('async', 'true');
            script2.setAttribute('defer', 'true');
            document.body.appendChild(script2);
        } else if ((idBu === "DAAD")) {
            const script1 = document.createElement('script');
            script1.innerHTML = `window.fwSettings={
            'widget_id': 154000001291
        };`;

            document.body.appendChild(script1);

            const script2 = document.createElement('script');
            script2.setAttribute('type', 'text/javascript');
            script2.setAttribute('src', 'https://widget.freshworks.com/widgets/154000001291.js');
            script2.setAttribute('async', 'true');
            script2.setAttribute('defer', 'true');
            document.body.appendChild(script2);
        }

        // Limpia los scripts cuando el componente se desmonta
        // return () => {
        //     document.body.removeChild(script1);
        //     document.body.removeChild(script2);
        // };


    }, [idBu]);

    return (
        <div>
        </div>
    );
}

export { ButtonHelpFreshdesk }