import { useState } from "react";
import { Step1 } from "./stepsNewClient/Step1";
import { Step2 } from "./stepsNewClient/Step2";
import { Step3 } from "./stepsNewClient/Step3";
import { Step4 } from "./stepsNewClient/Step4";
import { Step3Data } from "../interfaces/clientsTypes";
import { useIntl } from "react-intl";

const ModalNewClient = ({ onClose }) => {
    const intl = useIntl();
    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState({});


    const [step1Data, setStep1Data] = useState({
        clientUserName: '',
        clientUserMail: '',
        clientPass1 : '',
        clientPass2: '', 
        clientCallPhone: '',
        clientWhatsPhone: ''
    })

    const [step2Data, setStep2Data] = useState({
        clientName: '',
        clientRfc: '',
        clientPhone: '',
        clientIndustry: '',
        clientSize: '',
        clientGiro: '',
        clientEmployeeNumber: ''
    })

    const [step3Data, setStep3Data] = useState<Step3Data>({
        brandName: '',
        productName: '',
        productPrice: 0, 
        productMailsUsers:[], 
        productType: '', 
        productLegacy: '',
        productCountry: '',
        productState: '',
        productCity: '',
        productZone: ''
    })

    const handleNext = (data) => {
        setCurrentStep(currentStep + 1);
        setFormData({ ...formData, ...data });
    };

    const handlePrev = () => {
    setCurrentStep(currentStep - 1);
    };

    const totalSteps = 4;
    const progress = (currentStep / totalSteps) * 100;

    return(
        <>
        <div className="progress mb-8">
            <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${progress}%` }}
                aria-valuenow={progress}
                aria-valuemin={0}
                aria-valuemax={100}   
            >
                {progress}%    
            </div>
        </div>

        <div className="wizard">
            <h2 className="wizard-label mb-6">
                {intl.formatMessage({id: 'CLIENTS.STEP_OF_TOTAL'}, { currentStep, totalSteps })}
            </h2>
            {currentStep === 1 && <Step1 onNext={handleNext} prevData={step1Data} setStep1Data={setStep1Data}/>}
            {currentStep === 2 && <Step2 onNext={handleNext} handlePrev={handlePrev} prevData={step2Data} setStep2Data={setStep2Data} />}
            {currentStep === 3 && <Step3 onNext={handleNext} handlePrev={handlePrev} prevData={step3Data} setStep3Data={setStep3Data} />}
            {currentStep === 4 && <Step4 data={formData} handlePrev={handlePrev} onClose={onClose}/>}

            {/* <div className="wizard-buttons">
                {currentStep > 1 && (
                    <button className="btn btn-light btn-sm mt-6 me-2" onClick={handlePrev}>Anterior</button>
                )}
                {currentStep < 4 && (
                    <button className="btn btn-primary btn-sm mt-6" onClick={handleNext}>Siguiente</button>
                )}
                {currentStep == 4 && (
                    <button type="button" className="btn btn-primary btn-sm mt-6">Save changes</button>
                )}
            </div> */}
        </div>
        </>

        
    )
}

export{ModalNewClient};