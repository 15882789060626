import React, { FC } from 'react'
import { Lead, LeadDetailInfo, LeadDataDetail } from '../../../interfaces/leadsTypes'
import { useIntl } from 'react-intl'

type Props = {
  leadData: LeadDetailInfo | undefined
}

export const SellerInfoCard: FC<Props> = ({
  leadData
}) => {
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()
  return (
    <>
      <div className='card card-custom mt-6 h-100'>
        <div className='card-header ribbon ribbon ribbon-end ribbon-clip pt-3 pb-1'>
          <div className='card-title'>
            <div className='symbol symbol-50 symbol-lg-60 mr-2'>
              <img
                src='https://storage.googleapis.com/dragon-bucket-prod/app/img/mails/Phone Number.png'
                alt='campaigns'
                className='alt'
              />
            </div>&nbsp;&nbsp;&nbsp;
            <div className='mr-20'>
              <h1 className='card-label h4'>{leadData?.productName}</h1>
              <small>{leadData?.campaignName}</small>
            </div>
          </div>
          <div className='ribbon-label'>
            {intl.formatMessage({ id: 'LEADS.SELLER_SCORE' })}: {1}
            <span className='ribbon-inner bg-danger'></span>
          </div>
        </div>
        <div className='card-body'>
          <div className='my-2'>
            <span className='font-weight-bold'><strong>{intl.formatMessage({ id: 'LEADS.SELLER' })}: </strong></span>{leadData?.SellerName}
          </div>
          <div className='my-2'>
            <span className='font-weight-bold'><strong>{intl.formatMessage({ id: 'LEADS.LEAD_CREATED_BY' })}: </strong></span>API{' '}
          </div>
        </div>
      </div>
    </>
  )
}
