// Importamos hooks de react que usaremos para este componente
import React, {FC, useContext, useState, useEffect} from 'react'
// Importamos componentes de react-bootstrap
import {Button, Modal} from 'react-bootstrap'

// ---------------------------------------------------------------------------------------

// Importamos el contexto de usuario
import {UserContext} from '../../../app-context/user-context/UserContext'

// ---------------------------------------------------------------------------------------

// Importamos componente ModalActiveUnactiveClient para el modal de activacion / desactivacion de cliente
import {ModalActiveUnactiveClient} from '../modals/ModalActiveUnactiveClient'
// Importamos componente ModalReasignClient para el modal de reasignacion del cliente
import {ModalReasignClient} from '../modals/ModalReasignClient'
// Importamos componente ModalRenameClient para el modal de cambio de nombre a cliente
import {ModalRenameClient} from '../modals/ModalRenameClient'

import {clientReasign} from '../services/_AMClientsReasignApi'

// ---------------------------------------------------------------------------------------

// Importamos las interfaces de account-management que usaremos para este componente
import {
  ChangeStatusObject,
  ProductByClientBU,
  FormDataReasignClient,
} from '../interfaces/accountManagerTypes'
// Importamos interfaces globales que usaremos en este componente
import {AlertInfo} from '../../../utils/interfaces/_InterfacesTypes'
import {
  sessionExpired,
  formatTextMIddleDash,
  canAccessFeature,
} from '../../../utils/functions/_Functions'
import {AMClientStatusDeactivate} from '../services/_AMCLientStatusDeactivate'
import {AMClientStatusActivate} from '../services/_AMCLientStatusActivate'
import {useIntl} from 'react-intl'
import {DRG_STANDAR_MODULES} from '../../../routing/constants/_Contstants'

// Tipado de Props para el componente ClientSelectedButtonsCard
type PropsCSBC = {
  productsByClientList: ProductByClientBU[]
  currentClientNameSelected: string
  currentClientIdSelected: string
  currentClientStatusSelected: number | undefined
  currentClientMailOwnerSelected: string | undefined
  getProductsRegisters: any
  getClientsSharedBUList: any
  // currentClientOwnerSelected: string | undefined
}

// Construimos un funtional component para reenderizar ClientSelectedButtonsCard
export const ClientSelectedButtonsCard: FC<PropsCSBC> = ({
  productsByClientList,
  currentClientNameSelected,
  currentClientIdSelected,
  currentClientStatusSelected,
  currentClientMailOwnerSelected,
  getProductsRegisters,
  getClientsSharedBUList,
  // currentClientOwnerSelected
}) => {
  // Hook useContext para acceder al contexto del usuario
  const {user, token, updateToken} = useContext(UserContext)
  const userToken: string = token
  // const userToken: string = token;  const userToken = token

  // Definición del tipo de usuario
  const userType: string = user.user_type

  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()
  // Estados del componente
  const [switchOn, setSwitchOn] = useState(true) // Estado del interruptor
  const [showModalClientStatus, setshowModalClientStatus] = useState<boolean>(false) // Estado del modal de estado del cliente
  const [showModalClientReasign, setshowModalClientReasign] = useState<boolean>(false) // Estado del modal de reasignación del cliente
  const [showModalClientRename, setshowModalClientRename] = useState<boolean>(false) // Estado del modal de cambio de nombre del cliente
  const [selectedPerson, setSelectedPerson] = useState(1) // Estado para el checkbox seleccionado

  // Estado de la alerta
  const [alertInfo, setAlertInfo] = useState<AlertInfo>({
    text: '', // Texto de la alerta
    variant: 'primary', // Variante de la alerta
  })

  const initialFromDataReasignClient = {
    clientId: '',
    mail: '',
    status: true,
    lvl_access: 0,
    userType: 'dragon_seller',
    reasignReason: '',
  }

  const [formDataReasignClient, setFormDataReasignClient] = useState<FormDataReasignClient>(
    initialFromDataReasignClient
  )

  const handleCheckStatusclient = () => {
    if (currentClientStatusSelected === 0) {
      setSwitchOn(false)
    } else if (currentClientStatusSelected === 1) {
      setSwitchOn(true)
    } else {
      setSwitchOn(true)
    }
  }

  useEffect(() => {
    handleCheckStatusclient()
  }, [switchOn])

  // Función para abrir el modal de reasignación del cliente
  const handleReasignClientModal = (client: string) => {
    setshowModalClientReasign(true)

    setFormDataReasignClient({
      ...formDataReasignClient,
      clientId: client,
      mail: currentClientMailOwnerSelected,
      status: true,
      lvl_access: 0,
      userType: 'dragon_seller',
      reasignReason: '',
    })
  }

  // Función para abrir el modal de cambio de nombre del cliente
  const handleRenameClientModal = (client: string) => {
    setshowModalClientRename(true)
  }

  // Función para realizar la reasignación del cliente
  const handleReasignClientSubmit = async () => {
    const clientId: string = formDataReasignClient.clientId
    const mail: string | undefined = formDataReasignClient.mail
    const status: boolean = formDataReasignClient.status
    const lvl_access: number = formDataReasignClient.lvl_access
    const userType: string = formDataReasignClient.userType
    const reasignReason: string = formDataReasignClient.reasignReason

    try {
      const {
        data: jsonData,
        code,
        msg,
      } = await clientReasign(
        clientId,
        mail,
        status,
        lvl_access,
        userType,
        reasignReason,
        userToken
      )

      if (code === 401 && msg === 'Token no valido') {
        sessionExpired(intl.formatMessage({id: 'GLOBAL.SESSION_EXPIRED'}))
        return
      } else {
        setAlertInfo({
          text:
            code === 200
              ? intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.CUSTOMER_SUCCESS_REASSIGNED'})
              : intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.CUSTOMER_FAILED_REASSIGNED'}),
          variant: code === 200 ? 'success' : 'danger',
        })

        // Esperamos 3 segundos, limpiamos las alertas y cerramos el modal
        setTimeout(() => {
          setAlertInfo({
            text: '', // Limpia el texto de la alerta
            variant: '', // Limpia la variante de la alerta
          })
          setshowModalClientReasign(false) // Cierra el modal de reasignación del cliente
          getClientsSharedBUList()
          getProductsRegisters(currentClientIdSelected)
        }, 3000)
      }
    } catch (error) {
      // console.error('No se pudo editar la marca', error);
      setAlertInfo({
        text: intl.formatMessage({id: 'GLOBAL.ALERT_ERROR_AXIOS_REQUEST'}),
        variant: 'danger',
      })
    }
  }

  // Función para cerrar el modal de reasignación del cliente
  const handleCloseModalClientReasign = () => {
    setshowModalClientReasign(false)
  }

  // Función para cerrar el modal de cambio de nombre del cliente
  const handleCloseModalClientRename = () => {
    setshowModalClientRename(false)
  }

  // Función para abrir el modal de estado del cliente
  const handleClientStatusModal = (clientId: string) => {
    console.log(clientId)
    setshowModalClientStatus(true)
  }

  // Función para manejar el evento de recarga de la página
  const handleReload = () => {
    window.location.reload()
  }

  // Función para manejar el cambio del interruptor
  const handleChangeCheck = async (clientId: string) => {
    console.log(clientId)

    try {
      let response
      let petitionType
      if (switchOn) {
        response = await AMClientStatusDeactivate(clientId, userToken)
        petitionType = 'AMClientStatusDeactivate'
        console.log('Se intentó desactivar')
      } else {
        response = await AMClientStatusActivate(clientId, userToken)
        petitionType = 'AMClientStatusActivate'
        console.log('Se intentó activar')
      }

      const {code, msg}: ChangeStatusObject = response

      if (code === 401 && msg === 'Token no válido') {
        sessionExpired(intl.formatMessage({id: 'GLOBAL.SESSION_EXPIRED'}))
        return
      }

      if (code > 199 && code < 300 && petitionType === 'AMClientStatusDeactivate') {
        setAlertInfo({
          text: intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.INFO_SEND_REQUEST_DEACTIVATE_CLIENT'}),
          variant: 'success',
        })

        setTimeout(() => {
          setAlertInfo({
            text: '',
            variant: '',
          })
          // handleReload()
          getClientsSharedBUList()
          getProductsRegisters(currentClientIdSelected)
        }, 3000)
        return
      }

      if (code > 199 && code < 300 && petitionType === 'AMClientStatusActivate') {
        setAlertInfo({
          text: intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.INFO_SEND_REQUEST_ACTIVATE_CLIENT'}),
          variant: 'success',
        })

        setTimeout(() => {
          setAlertInfo({
            text: '',
            variant: '',
          })
          // handleReload()
          getClientsSharedBUList()
          getProductsRegisters(currentClientIdSelected)
        }, 3000)
        return
      }
    } catch (error) {
      setAlertInfo({
        text: `${error}`,
        variant: 'danger',
      })
      setTimeout(() => {
        setAlertInfo({
          text: '',
          variant: '',
        })
      }, 3000)
      throw error
    }
  }

  // Función para cerrar el modal de estado del cliente
  const handleCloseModalClientStatus = () => {
    setshowModalClientStatus(false)
  }

  return (
    <>
      <div className='d-flex justify-content-center mb-6'>
        <div
          className='form-check form-switch form-switch-sm form-check-custom form-check-solid mb-3'
          key='poo'
        >
          {currentClientStatusSelected === 0 ? (
            <label className='badge badge-light-danger p-4' htmlFor='statusClient'>
              {intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.INACTIVE_CLIENT'})}
            </label>
          ) : currentClientStatusSelected === 1 ? (
            <label className='badge badge-light-primary p-4' htmlFor='statusClient'>
              {intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.ACTIVE_CLIENT'})}
            </label>
          ) : currentClientStatusSelected === 2 ? (
            <label className='badge badge-light-warning p-4' htmlFor='statusClient'>
              {intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.PENDING_PROCESS_DEACTIVATION_CLIENT'})}
            </label>
          ) : (
            <label className='badge badge-light-warning p-4' htmlFor='statusClient'>
              {intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.PENDING_PROCESS_ACTIVATION_CLIENT'})}
            </label>
          )}
          &nbsp;&nbsp;&nbsp;
          {canAccessFeature(DRG_STANDAR_MODULES.ACCOUNT_MANAGEMENT.value, userType, 3) && (
            <input
              className='form-check-input form-check-input-lg'
              type='checkbox'
              id='statusClient'
              name='statusClient'
              checked={switchOn}
              disabled={
                currentClientStatusSelected === 2 || (currentClientStatusSelected === 3 && true)
              }
              onChange={() => handleClientStatusModal(currentClientIdSelected)}
            />
          )}
        </div>
      </div>
      <div className='d-flex justify-content-center'>
        {userType === 'SUPER_ADMIN' && (
          <button
            type='button'
            className='btn btn-primary btn-sm'
            disabled={
              currentClientStatusSelected === 2 ||
              currentClientStatusSelected === 3 ||
              (currentClientStatusSelected === 0 && true)
            }
            onClick={() => handleReasignClientModal(currentClientIdSelected)}
          >
            {intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.REASSIGN_CLIENT'})}
          </button>
        )}
        &nbsp;&nbsp;&nbsp;
        {userType === 'SUPER_ADMIN' && (
          <button
            type='button'
            className='btn btn-primary btn-sm'
            disabled={
              currentClientStatusSelected === 2 ||
              currentClientStatusSelected === 3 ||
              (currentClientStatusSelected === 0 && true)
            }
            onClick={() => handleRenameClientModal(currentClientIdSelected)}
          >
            {intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.ACCOUNT_MANAGEMENT.UPDATE_NAME'})}
          </button>
        )}
      </div>
      {/* Modal del status del cliente */}
      <Modal
        show={showModalClientStatus}
        onHide={handleCloseModalClientStatus}
        scrollable
        className='modal-lg'
      >
        <Modal.Header closeButton onClick={handleCloseModalClientStatus}>
          <Modal.Title>{intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.CLIENT_STATUS'})}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalActiveUnactiveClient
            switchOn={switchOn}
            currentClientNameSelected={currentClientNameSelected}
            currentClientIdSelected={currentClientIdSelected}
          />
        </Modal.Body>
        {alertInfo.text && (
          <div className='px-20'>
            <div
              className={`alert alert-${alertInfo.variant} d-flex align-items-center justify-content-center text-center`}
              role='alert'
            >
              <div>{alertInfo.text}</div>
            </div>
          </div>
        )}
        <Modal.Footer>
          <Button
            variant='primary'
            onClick={() => {
              handleChangeCheck(currentClientIdSelected)
            }}
          >
            {intl.formatMessage({id: 'GLOBAL.INPUT.ACCEPT'})}
          </Button>
          <Button
            variant='secondary'
            onClick={() => {
              handleCloseModalClientStatus()
            }}
          >
            {intl.formatMessage({id: 'GLOBAL.CANCEL'})}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal de la reasignacion del cliente */}
      <Modal
        show={showModalClientReasign}
        onHide={handleCloseModalClientReasign}
        scrollable
        className='modal-default'
      >
        <Modal.Header closeButton onClick={handleCloseModalClientReasign}>
          <Modal.Title>
            {intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.CLIENT_REASSIGNMENT'})}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalReasignClient
            formDataReasignClient={formDataReasignClient}
            setFormDataReasignClient={setFormDataReasignClient}
            currentClientNameSelected={currentClientNameSelected}
            currentClientIdSelected={currentClientIdSelected}
            currentClientMailOwnerSelected={currentClientMailOwnerSelected}
            // currentClientOwnerSelected={currentClientOwnerSelected}
          />
        </Modal.Body>
        {alertInfo.text && (
          <div className='px-20'>
            <div
              className={`alert alert-${alertInfo.variant} d-flex align-items-center justify-content-center text-center`}
              role='alert'
            >
              <div>{alertInfo.text}</div>
            </div>
          </div>
        )}
        <Modal.Footer>
          <Button
            variant='primary'
            onClick={() => {
              handleReasignClientSubmit()
            }}
          >
            {intl.formatMessage({id: 'GLOBAL.SAVE'})}
          </Button>
          <Button
            variant='secondary'
            onClick={() => {
              handleCloseModalClientReasign()
            }}
          >
            {intl.formatMessage({id: 'GLOBAL.CLOSE'})}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal de cambio de nombre del cliente */}
      <Modal
        show={showModalClientRename}
        onHide={handleCloseModalClientRename}
        scrollable
        className='modal-default'
      >
        <Modal.Header closeButton onClick={handleCloseModalClientRename}>
          <Modal.Title>
            {intl.formatMessage({id: 'ACCOUNT_MANAGEMENT.CHANGE_CUSTOMER_NAME'})}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalRenameClient currentClientNameSelected={currentClientNameSelected} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='primary'
            className='btn btn-sm'
            // onClick={() => {
            //   handleReasignClientSubmit()
            // }}
          >
            {intl.formatMessage({id: 'GLOBAL.SAVE'})}
          </Button>
          <Button
            variant='secondary'
            onClick={() => {
              handleCloseModalClientRename()
            }}
            className='btn btn-sm'
          >
            {intl.formatMessage({id: 'GLOBAL.CANCEL'})}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
