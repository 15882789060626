import {useEffect, useContext, useState} from 'react'
import {LoadingAnimation} from '../../../../utils/components/loading/LoadingAnimation'
import {
  setPageTitle,
  dateFormatterISO8601Variant,
  dateFormatterTextualEnTwo,
} from '../../../../utils/functions/_Functions'
import {DragonCem} from '../../../../utils/constants/_Constants'
import {Product, Campaign, QaCountsReport} from './interfaces/qaReportTypes'
import {campaignsListForProduct} from '../../../campaigns/services/_CampaignsListApi'
import {UserContext} from '../../../../app-context/user-context/UserContext'
import {LeadCountsItem} from './aux-components/LeadsQaCountsItem'
import {QaReportChart} from './charts/QaReportChart'
import {KTIcon} from '../../../../../_metronic/helpers'
import {DateRangePickerFull} from '../../../../utils/components/date-pickers/DateRangePickerFull'
import {ProductsByUserDropdown} from '../../../../utils/components/dropdowns/ProductsByUserDropdown'
import {sessionExpired} from '../../../../utils/functions/_Functions'
import {useIntl} from 'react-intl'

const QualityReport = () => {
  //Algunas variables desde el usercontext
  const {user, token, updateToken} = useContext(UserContext)
  const clientId = user.cli_id
  const userToken = token
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  const initialQaReportItem: QaCountsReport = {
    totalLeadsReceived: 0,
    totalQaSended: 0,
    totalPositiveQaResponse: 0,
    totalNegativeQaResponse: 0,
    totalNoQaResponse: 0,
  }

  // Valores iniciales para el rango de fechas
  const initialStartDate: Date = new Date(new Date().setDate(new Date().getDate() - 30))
  const initialEndDate: Date = new Date()

  // Constantes que usaremos para manejar las variables para los labels que muestran la fecha
  const [labelStartDate, setLabelStartDate] = useState<Date>(initialStartDate)
  const [labelEndDate, setLabelEndDate] = useState<Date>(initialEndDate)

  // Constantes que usaremos para manejar las fechas que enviaremos a la peticion
  const [startDate, setStartDate] = useState<string>(dateFormatterISO8601Variant(initialStartDate))
  const [endDate, setEndDate] = useState<string>(dateFormatterISO8601Variant(initialEndDate))

  // Manejador para la disponibilidad del dateRangePicker
  const [dataRangeState, setDataRangeState] = useState<boolean>(true)

  const [qaReportItem, setQaReportItem] = useState<QaCountsReport>(initialQaReportItem)

  const [productsList, setProductsList] = useState<Product[]>([])
  const [campaignsList, setCampaignsList] = useState<Campaign[]>([])

  const [selectedIdProduct, setSelectedIdProduct] = useState<string>('')
  const [selectedNameProduct, setSelectedNameProduct] = useState<string>('')
  const [productsDropdownState, setProductsDropdownState] = useState<boolean>(false)

  const [selectedIdCampaign, setSelectedIdCampaign] = useState<string>('0')
  const [selectedNameCampaign, setSelectedNameCampaign] = useState<string>('Todas las campañas')

  const [showCards, setShowCards] = useState<boolean>(false)

  //Constante para el manejo de estado de la animacion de carga
  const [isLoading, setIsLoading] = useState<boolean>(false)

  //Constante para el manejo de estado del mensaje post carga de registros
  const [infoMessageLoading, setInfoMessageLoading] = useState<string>('')

  const checkCountsLeadsQa = () => {
    qaReportItem.totalQaSended === 0 && setInfoMessageLoading('Ninguna encuesta enviada')
  }

  const getQualityCounts = (productId: string, campaignId?: string) => {
    if (productId !== '') {
      setTimeout(() => {
        try {
          const newQaCountList: QaCountsReport = {
            totalLeadsReceived: 6,
            totalQaSended: 6,
            totalPositiveQaResponse: 2,
            totalNegativeQaResponse: 2,
            totalNoQaResponse: 2,
          }

          setQaReportItem(newQaCountList)
          setIsLoading(false)
          setShowCards(true)
          checkCountsLeadsQa()
        } catch (error) {
          console.log('Error obteniendo los conteos del reporte')
          setIsLoading(false)
          setInfoMessageLoading('Hubo un error en la petición')
        }
      }, 3000)
    }
  }

  const getCampaignsByProduct = async (productValue: string): Promise<void> => {
    try {
      const {data: jsonData, code, msg} = await campaignsListForProduct(userToken, productValue)

      const campaignsListReceived = jsonData.data

      const userTokenNew = jsonData.token

      updateToken(userTokenNew)

      const campaignItem = campaignsListReceived.map((campaign: Campaign) => {
        return {
          cam_id: campaign.cam_id,
          name: campaign.name,
        }
      })

      setCampaignsList(campaignItem)
    } catch (error) {
      console.log('Ocurrio un error obteniendo las campañas del producto: ', error)
    }
  }

  const handleProductOrCampaignSelected = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    setIsLoading(true)

    let productIdSelected: string
    let campaignIdSelected: string

    if (e.target.name === 'product') {
      productIdSelected = e.target.value

      if (productIdSelected) {
        setDataRangeState(false)
      } else {
        setDataRangeState(true)
      }

      console.log('interaccion productos')

      if (e.target instanceof HTMLSelectElement) {
        setSelectedNameProduct(e.target.options[e.target.selectedIndex].text)
      }

      setSelectedIdProduct(productIdSelected)
      //Aqui ejecutamos peticion para los conteos del reporte
      getQualityCounts(productIdSelected)

      if (productIdSelected !== '' && productIdSelected !== '0') {
        getCampaignsByProduct(productIdSelected)
        setCampaignsList([])
        setSelectedIdCampaign('0')
        setSelectedNameCampaign('Todas las campañas')
      } else {
        setCampaignsList([])
        setSelectedIdCampaign('')
        setSelectedNameCampaign('')
      }
    } else if (e.target.name === 'campaign') {
      console.log('interaccion campañas')

      campaignIdSelected = e.target.value

      if (e.target instanceof HTMLSelectElement) {
        setSelectedNameCampaign(e.target.options[e.target.selectedIndex].text)
      }

      setSelectedIdCampaign(campaignIdSelected)
      //Aqui ejecutamos peticion para los conteos del reporte
      getQualityCounts(selectedIdProduct, campaignIdSelected)
    }
  }

  // Manejamos las acciones que queremos para cuando se interactue con las fechas del DateRange
  const handleDates = (): void => {
    const startDateNew: string = dateFormatterISO8601Variant(labelStartDate)
    const endDateNew: string = dateFormatterISO8601Variant(labelEndDate)

    setStartDate(startDateNew)
    setEndDate(endDateNew)
  }

  useEffect(() => {
    setPageTitle(`Reporte de Quality | ${DragonCem}`)
  }, [])

  return (
    <>
      <div className='container-fluid'>
        <div className='row justify-content-center mb-2'>
          <div className='col-md-12'>
            <div className='d-flex flex-wrap flex-stack mb-6'>
              <h1 className='my-2 d-flex flex-wrap flex-stack'>
                {/* Reporte Quality <span className="d-flex flex-wrap flex-stack fs-4 text-gray-500 fw-bold 
                ms-1">1 Abril 2024 a 30 Abril 2024</span> */}
                {intl.formatMessage({id: 'REPORTS.QUALITY.REPORT_TITLE'})}
              </h1>
              <div className='d-flex flex-wrap flex-stack align-items-center my-2'>
                <div className='w-300px me-3'>
                  {/* Componente con el dropdown de seleccion de producto */}
                  <ProductsByUserDropdown
                    updateToken={updateToken}
                    clientId={clientId}
                    userToken={userToken}
                    sessionExpired={sessionExpired}
                    productIdSelected={selectedIdProduct}
                    handleProductChange={handleProductOrCampaignSelected}
                    productsList={productsList}
                    setProductsList={setProductsList}
                    productsDropdownState={productsDropdownState}
                    dropdownClass='form-select form-select-sm form-select-solid mb-2 '
                    allProductsOption={true}
                    onlyActiveProducts={true}
                  />
                </div>

                <div className='w-300px me-3'>
                  <select
                    name='campaign'
                    data-control='selectCampaign'
                    data-hidden-search='true'
                    className='form-select form-select-solid form-select-sm'
                    value={selectedIdCampaign}
                    onChange={(e) => {
                      handleProductOrCampaignSelected(e)
                    }}
                    disabled={selectedIdProduct === '' || selectedIdProduct === '0'}
                  >
                    <option value='' disabled>
                      {intl.formatMessage({id: 'LEADS.SELECT_A_CAMPAIGN'})}
                    </option>
                    <option value='0'>{intl.formatMessage({id: 'REPORT.CAMPAIGN.ALL_CAMPAIGNS'})}</option>
                    {campaignsList.map((campaign: Campaign) => (
                      <option key={campaign.cam_id} value={campaign.cam_id}>
                        {campaign.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className='d-flex'>
                  <button
                    type='button'
                    className='btn btn-sm btn-icon btn-secondary me-1'
                    disabled={dataRangeState}
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                  >
                    <KTIcon iconName='calendar' className='fs-2' />
                  </button>

                  <button
                    type='button'
                    className='btn btn-sm btn-secondary disabled'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                  >
                    {`${intl.formatMessage({id: 'REPORTS.INTERACTIONS.CURRENT_RANGE'})}: ` +
                      dateFormatterTextualEnTwo(labelStartDate) +
                      '  -  ' +
                      dateFormatterTextualEnTwo(labelEndDate)}
                  </button>

                  {/* Componente con el DateRangePicker */}
                  <DateRangePickerFull
                    initialStartDate={initialStartDate}
                    initialEndDate={initialEndDate}
                    months={2}
                    handleDates={handleDates}
                    setLabelStartDate={setLabelStartDate}
                    setLabelEndDate={setLabelEndDate}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row gx-2 mb-6'>
          <div className='col-xl-12'>
            <div className='card card-custom px-8 py-10 h-100'>
              <div className='row justify-content-center mb-2'>
                <div className='col-md-12'>
                  {selectedIdProduct && (
                    <div className='d-flex mb-4 justify-content-center'>
                      <h5 className='card-label me-4 d-flex flex-wrap flex-stack'>
                        <span className='text-dark text-sm font-weight-bolder mt-3'>
                          {intl.formatMessage({id: 'REPORTS.QUALITY.QA_SURVEY_SUMMARY'})}:
                        </span>
                        &nbsp;&nbsp;
                        <span className='text-muted mt-3'>
                          {' '}
                          {selectedNameProduct} - {selectedNameCampaign}
                        </span>
                      </h5>
                    </div>
                  )}

                  {!selectedIdProduct && (
                    <>
                      <div
                        className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                        role='alert'
                      >
                        <h5 className='mb-1 text-center'>
                          {intl.formatMessage({id: 'REPORTS.QUALITY.SELECT_PRODUCT'})}
                        </h5>
                      </div>
                    </>
                  )}

                  {/* Si isLoading es igual a 'true' mostramos animacion de carga */}
                  {isLoading && selectedIdProduct && (
                    <div className='py-6'>
                      <LoadingAnimation
                        alignment='center'
                        label={intl.formatMessage({id: 'REPORTS.QUALITY.LOADING_LEADS'})}
                        size='lg'
                      />
                    </div>
                  )}
                  {/* Si isLoading es diferente a 'true' y el arreglo de conteo de calificaciones es mayor a '0' mostramos tarjetas de conteo */}
                  {!isLoading && selectedIdProduct && showCards && (
                    <LeadCountsItem qaReportItem={qaReportItem} />
                  )}

                  {!isLoading && selectedIdProduct && qaReportItem.totalQaSended === 0 && (
                    <>
                      <div
                        className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                        role='alert'
                      >
                        <h5 className='mb-1 text-center'>
                          {intl.formatMessage({id: 'REPORTS.QUALITY.NO_LEADS_SENT'})}
                        </h5>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row gx-2 mb-6'>
          <div className='col-xl-12'>
            <div className='card card-custom px-8 py-10 h-100'>
              <div className='row justify-content-center mb-2'>
                <div className='col-md-12'>
                  {selectedIdProduct && (
                    <div className='d-flex mb-4 justify-content-center'>
                      <h5 className='card-label me-4 d-flex flex-wrap flex-stack '>
                        <span className='text-dark text-sm font-weight-bolder mt-3'>
                        {intl.formatMessage({id: 'REPORTS.QUALITY.QA_RESPONSES'})}:
                        </span>
                        &nbsp;&nbsp;
                        <span className='text-muted mt-3'>
                          {' '}
                          {selectedNameProduct} - {selectedNameCampaign}
                        </span>
                      </h5>
                    </div>
                  )}

                  {!selectedIdProduct && (
                    <>
                      <div
                        className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                        role='alert'
                      >
                        <h5 className='mb-1 text-center'>
                          {intl.formatMessage({id: 'REPORTS.QUALITY.SELECT_PRODUCT'})}
                        </h5>
                      </div>
                    </>
                  )}

                  {/* si isLoading es igual a true mostramos animacion de carga  */}
                  {isLoading && selectedIdProduct && (
                    <div className='py-6'>
                      <LoadingAnimation alignment='center' label='Generando grafica...' size='lg' />
                    </div>
                  )}

                  {!isLoading && selectedIdProduct && qaReportItem.totalQaSended > 0 && (
                    <QaReportChart qaReportItem={qaReportItem} />
                  )}

                  {!isLoading && selectedIdProduct && qaReportItem.totalQaSended === 0 && (
                    <>
                      <div
                        className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                        role='alert'
                      >
                        <h5 className='mb-1 text-center'>
                          {intl.formatMessage({id: 'REPORTS.QUALITY.NO_LEADS_SENT'})}
                        </h5>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="row mb-10">
      <SelectProducts/>
      <SelectDates/>
      <SelectCampaigns/>
    </div> */}
      {/*     
    <div className="row">
      <div className="col-xl-4">
        <div>
          <div className="card card-custom card-stretch gutter-b">
            <div className="card-header h-auto border-0">
              <div className="py-5">
                <h5 className="card-label">
                  <span className="d-block text-dark text-sm font-weight-bolder mt-3">Encuestas QA enviadas</span>
                </h5>
                <p className="text-muted text-sm font-weight-normal" id="dateTag">
                  2023-10-01 a 2023-10-07
                </p>
              </div>
            </div>
            <div className="card-body d-flex align-items-center py-0">
              <div className="d-flex flex-column flex-grow-1 py-2" id="totalLeads">
                <div id="data1">
                  <span id="totalLeadsRecibidos" className="font-weight-bold">Total: 43</span>
                  <span>
                    <button className="btn btn-primary ml-20 mb-4 mx-10">Ver</button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='pt-3'>
          <div className="card card-custom card-stretch gutter-b">
            <div className="card-header h-auto border-0">
              <div className="py-5">
                <h5 className="card-label">
                  <span className="d-block text-dark text-sm font-weight-bolder mt-3">Respuestas positivas</span>
                </h5>
                <p className="text-muted text-sm font-weight-normal" id="dateTag">
                  2023-10-01 a 2023-10-07
                </p>
              </div>
            </div>
            <div className="card-body d-flex align-items-center py-0">
              <div className="d-flex flex-column flex-grow-1 py-2" id="totalLeads">
                <div id="data1">
                  <span id="totalLeadsRecibidos" className="font-weight-bold">Total: 37</span>
                  <span>
                    <button className="btn btn-primary ml-20 mb-4 mx-10">Ver</button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='pt-3'>
          <div className="card card-custom card-stretch gutter-b">
            <div className="card-header h-auto border-0">
              <div className="py-5">
                <h5 className="card-label">
                  <span className="d-block text-dark text-sm font-weight-bolder mt-3">Respuestas negativas</span>
                </h5>
                <p className="text-muted text-sm font-weight-normal" id="dateTag">
                  2023-10-01 a 2023-10-07
                </p>
              </div>
            </div>
            <div className="card-body d-flex align-items-center py-0">
              <div className="d-flex flex-column flex-grow-1 py-2" id="totalLeads">
                <div id="data1">
                  <span id="totalLeadsRecibidos" className="font-weight-bold">Total: 6</span>
                  <span>
                    <button className="btn btn-primary ml-20 mb-4 mx-10">Ver</button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='pt-3'>
          <div className="card card-custom card-stretch gutter-b">
            <div className="card-header h-auto border-0">
              <div className="py-5">
                <h5 className="card-label">
                  <span className="d-block text-dark text-sm font-weight-bolder mt-3">Leads que no han respondido</span>
                </h5>
                <p className="text-muted text-sm font-weight-normal" id="dateTag">
                  2023-10-01 a 2023-10-07
                </p>
              </div>
            </div>
            <div className="card-body d-flex align-items-center py-0">
              <div className="d-flex flex-column flex-grow-1 py-2" id="totalLeads">
                <div id="data1">
                  <span id="totalLeadsRecibidos" className="font-weight-bold">Total: 36</span>
                  <span>
                    <button className="btn btn-primary ml-20 mb-4 mx-10">Ver</button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        </div>

      <div className="col-xl-8">
        <div className="card card-custom card-stretch gutter-b">
          <div className="card-header h-auto border-0">
            <div className="py-5">
              <h5 className="card-label">
                <span className="d-block text-dark font-weight-bolder mt-3">Porcentaje de respuesta de QA</span>
              </h5>
            </div>
            <div className="card-toolbar" id="calissficacionGraph"></div>
          </div>
          <div className="card-body">
            <div id="leadGraph" className="mt-4 ml-4">
              <div id="chartCanvas" style={{ minHeight: '458.7px' }}>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}
    </>
  )
}

export {QualityReport}
