import { FC, useEffect, useState } from 'react';
import { Product, YearlyReportProduct, YearlyRegistersProductForTable, ChannelFilter } from '../../interfaces/yearlyReportTypes';
import { YearlyReportProductTable } from '../../tables/YearlyReportProductTable';
import { YearlyReportProductChart } from '../../charts/YearlyReportProductChart';
import { LoadingAnimation } from '../../../../../../utils/components/loading/LoadingAnimation';
import { useIntl } from 'react-intl';

type Props = {
    productsList: Product[];
}

export const YearlyProductsReport: FC<Props> = ({
    productsList
}) => {
    //Variable para el uso de diccionario de lenguajes
    const intl = useIntl()

    //Manejador del id del producto seleccionado
    const [selectedIdProduct, setSelectedIdProduct] = useState<string>("");

    //Manejador del nombre del producto seleccionado
    const [selectedNameProduct, setSelectedNameProduct] = useState<string>("");

    //Manejador del select de los años
    const [selectedYear, setSelectedYear] = useState<string>('2024');

    //Manejador del switch de duplicados
    const [duplicates, setDuplicates] = useState<boolean>(true);

    //Manejador de array con totales mensuales para la grafica
    const [monthlyTotals, setMonthlyTotals] = useState<number[]>([]);

    //Manejador par almacenar la data transformada para las tabla
    const [processedDataForTable, setProcessedDataForTable] = useState<YearlyRegistersProductForTable[]>([]);

    //Manejador para el array que guarda los canales para el dropdown de canales (cambia segun el producto seleccionado)
    const [channelsListByProduct, setChannelsListByProduct] = useState<ChannelFilter[]>([]);

    //Manejador para el valor del id y nombre de canal seleccionado en dropdown
    const [channelIdSelected, setChannelIdSelected] = useState<string>('')
    const [channelNameSelected, setChannelNameSelected] = useState<string>('')

    //Manejador para la data sin procesar obtenida en la peticion
    const [yearlyProductList, setYearlyProductList] = useState<YearlyReportProduct[]>([]);

    //Constante para el manejo de estado de la animacion de carga
    const [isLoading, setIsLoading] = useState<boolean>(false);

    //Constante para el manejo de estado del mensaje post carga de registros
    const [infoMessageLoading, setInfoMessageLoading] = useState<string>("");

    //funcion que procesa la data obtenida en peticion para la tabla
    const flattenData = (data: YearlyReportProduct[]): YearlyRegistersProductForTable[] => {
        return data.flatMap((product) => [
            ...product.registers.map((register) => ({
                channel: product.channel,
                channelId: product.channelId,
                ...register,
                isTotal: false,
            })),
            {
                channel: product.channel,
                channelId: product.channelId,
                campaign: "Totales",
                campaignId: "",
                isTotal: true,
                ...product.monthlyTotals,
            },
        ]);
    };

    //Funcion que arma el array de objetos de canal que vienen en la data de la peticion
    const setChannelArray = (flattened: YearlyRegistersProductForTable[]) => {

        let channelsMap = new Map<string, string>();

        flattened.forEach(item => {
            channelsMap.set(item.channel, item.channelId);
        });

        let uniqueChannels = Array.from(channelsMap, ([channel, channelId]) => ({ channel, channelId }));

        setChannelsListByProduct(uniqueChannels)

        console.log(uniqueChannels);

    }

    //Peticion principal para obtener la data del reporte
    const getYearlyProductList = (productId: string, year?: string, duplicates?: boolean) => {
        setTimeout(() => {
            try {
                const newYearlyProductListt: YearlyReportProduct[] = [
                    {
                        channel: "Facebook Ads",
                        channelId: "CH-FAD-001",
                        registers: [
                            {
                                campaign: "FB LEADS FORANEOS",
                                campaignId: "THRE-324",
                                january: 130,
                                february: 404,
                                march: 90,
                                april: 110,
                                may: 20,
                                june: 60,
                                july: 90,
                                august: 240,
                                september: 50,
                                october: 120,
                                november: 20,
                                december: 210,
                                total: 1544,
                            },
                            {
                                campaign: "FB Campaign",
                                campaignId: "THRE-323",
                                january: 13,
                                february: 40,
                                march: 903,
                                april: 100,
                                may: 200,
                                june: 30,
                                july: 20,
                                august: 210,
                                september: 100,
                                october: 90,
                                november: 40,
                                december: 20,
                                total: 1766,
                            },
                        ],
                        monthlyTotals: {
                            january: 143,
                            february: 444,
                            march: 993,
                            april: 110,
                            may: 220,
                            june: 90,
                            july: 110,
                            august: 450,
                            september: 150,
                            october: 210,
                            november: 60,
                            december: 230,
                            total: 3310,
                        },
                    },
                    {
                        channel: "Calendly",
                        channelId: "CH-CL-002",
                        registers: [
                            {
                                campaign: "CC Campaign",
                                campaignId: "THRE-322",
                                january: 3,
                                february: 4,
                                march: 90,
                                april: 10,
                                may: 20,
                                june: 3,
                                july: 2,
                                august: 20,
                                september: 10,
                                october: 9,
                                november: 40,
                                december: 2,
                                total: 213,
                            },
                            {
                                campaign: "CC Campaign 2",
                                campaignId: "THRE-321",
                                january: 3,
                                february: 4,
                                march: 90,
                                april: 10,
                                may: 20,
                                june: 3,
                                july: 2,
                                august: 20,
                                september: 10,
                                october: 9,
                                november: 40,
                                december: 2,
                                total: 213,
                            },
                            {
                                campaign: "CC Campaign 3",
                                campaignId: "THRE-320",
                                january: 31,
                                february: 41,
                                march: 91,
                                april: 11,
                                may: 21,
                                june: 31,
                                july: 21,
                                august: 21,
                                september: 11,
                                october: 91,
                                november: 41,
                                december: 21,
                                total: 432,
                            },
                        ],
                        monthlyTotals: {
                            january: 37,
                            february: 49,
                            march: 271,
                            april: 31,
                            may: 61,
                            june: 37,
                            july: 25,
                            august: 61,
                            september: 31,
                            october: 109,
                            november: 121,
                            december: 25,
                            total: 858,
                        },
                    },
                    {
                        channel: "Instagram Ads",
                        channelId: "CH-IAD-003",
                        registers: [
                            {
                                campaign: "IA Campaign",
                                campaignId: "THRE-319",
                                january: 31,
                                february: 41,
                                march: 91,
                                april: 11,
                                may: 21,
                                june: 31,
                                july: 21,
                                august: 21,
                                september: 11,
                                october: 91,
                                november: 41,
                                december: 21,
                                total: 432,
                            },
                            {
                                campaign: "IA Campaign 23",
                                campaignId: "THRE-318",
                                january: 310,
                                february: 40,
                                march: 90,
                                april: 111,
                                may: 211,
                                june: 313,
                                july: 214,
                                august: 214,
                                september: 115,
                                october: 916,
                                november: 40,
                                december: 210,
                                total: 2784,
                            },
                        ],
                        monthlyTotals: {
                            january: 341,
                            february: 81,
                            march: 181,
                            april: 122,
                            may: 232,
                            june: 343,
                            july: 234,
                            august: 234,
                            september: 125,
                            october: 1007,
                            november: 81,
                            december: 220,
                            total: 3216,
                        },
                    },
                ];

                setYearlyProductList(newYearlyProductListt)
                const flattened = flattenData(newYearlyProductListt)
                setProcessedDataForTable(flattened)
                setChannelArray(flattened)
                setIsLoading(false)
                // handleChannelSelected('0');

            } catch (error) {

                console.log("Error obteniendo la data del reporte anual: ", error);
                setIsLoading(false)
                setInfoMessageLoading(intl.formatMessage({id: 'GLOBAL.REQUEST_ERROR'}))

            }
        }, 3000);
    };

    const handleChannelSelected = (e: React.ChangeEvent<HTMLSelectElement> | string) => {
        console.log('arreglo: ', yearlyProductList)
        let channelValue: string;

        if (typeof e === 'string') {
            channelValue = e;
            console.log('entro numero string: ', channelValue)
            setChannelIdSelected(channelValue);
            setChannelNameSelected(intl.formatMessage({id: 'REPORTS.YEARLY.ALL_CHANNELS'}))

        } else {
            channelValue = e.target.value;
            console.log('entro numero HTML: ', channelValue)
            setChannelIdSelected(channelValue);
            setChannelNameSelected(e.target.options[e.target.selectedIndex].text)
        }

        if (channelValue === '0') {
            console.log('es igual a 0')
            console.log({ yearlyProductList })
            // Inicializar totales acumulados
            const totalesAcumulados = {
                january: 0,
                february: 0,
                march: 0,
                april: 0,
                may: 0,
                june: 0,
                july: 0,
                august: 0,
                september: 0,
                october: 0,
                november: 0,
                december: 0
            };

            // Sumar los totales de todos los objetos
            yearlyProductList.forEach(product => {
                totalesAcumulados.january += product.monthlyTotals.january;
                totalesAcumulados.february += product.monthlyTotals.february;
                totalesAcumulados.march += product.monthlyTotals.march;
                totalesAcumulados.april += product.monthlyTotals.april;
                totalesAcumulados.may += product.monthlyTotals.may;
                totalesAcumulados.june += product.monthlyTotals.june;
                totalesAcumulados.july += product.monthlyTotals.july;
                totalesAcumulados.august += product.monthlyTotals.august;
                totalesAcumulados.september += product.monthlyTotals.september;
                totalesAcumulados.october += product.monthlyTotals.october;
                totalesAcumulados.november += product.monthlyTotals.november;
                totalesAcumulados.december += product.monthlyTotals.december;
            });

            // Establecer el estado de monthlyTotals con el arreglo de totales mensuales
            setMonthlyTotals([
                totalesAcumulados.january,
                totalesAcumulados.february,
                totalesAcumulados.march,
                totalesAcumulados.april,
                totalesAcumulados.may,
                totalesAcumulados.june,
                totalesAcumulados.july,
                totalesAcumulados.august,
                totalesAcumulados.september,
                totalesAcumulados.october,
                totalesAcumulados.november,
                totalesAcumulados.december
            ]);
        } else {
            const findedChannel = yearlyProductList.find(product => product.channelId === channelValue);

            // Si se encuentra el objeto, extraer los totales mensuales en orden de enero a diciembre
            if (findedChannel) {
                const { monthlyTotals } = findedChannel;
                setMonthlyTotals([
                    monthlyTotals.january,
                    monthlyTotals.february,
                    monthlyTotals.march,
                    monthlyTotals.april,
                    monthlyTotals.may,
                    monthlyTotals.june,
                    monthlyTotals.july,
                    monthlyTotals.august,
                    monthlyTotals.september,
                    monthlyTotals.october,
                    monthlyTotals.november,
                    monthlyTotals.december
                ])
            }

            // Si no se encuentra el objeto, devolver null
            return null;
        }
    }

    const handleProductChange = async (productId: string) => {
        console.log('cambio de producto')

        setIsLoading(true)
        setChannelIdSelected('')
        setChannelNameSelected('')
        setMonthlyTotals([]);

        if (productId === '0') {
            setSelectedIdProduct(productId)
            setSelectedNameProduct(intl.formatMessage({id: 'REPORTS.YEARLY.ALL_PRODUCTS'}))
            getYearlyProductList(productId, selectedYear)
            return
        }

        const selectedProduct: Product | undefined = productsList.find((product: Product) => product.pro_id === productId)

        if (selectedProduct) {
            setSelectedIdProduct(productId)
            setSelectedNameProduct(selectedProduct.name)
            getYearlyProductList(productId, selectedYear, duplicates)

        } else {
            setSelectedIdProduct('')
            setSelectedNameProduct('')
        }
    }

    const handleYearChange = async (yearValue: string) => {
        console.log('cambio de año')
        setIsLoading(true)
        setChannelIdSelected('')
        setChannelNameSelected('')
        setMonthlyTotals([]);
        setSelectedYear(yearValue)
        getYearlyProductList(selectedIdProduct, yearValue, duplicates)
    }

    const handleDuplicatesChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log('cambio duplicados')
        setIsLoading(true)
        setChannelIdSelected('')
        setChannelNameSelected('')
        setMonthlyTotals([]);
        setDuplicates(event.target.checked)
        getYearlyProductList(selectedIdProduct, selectedYear, event.target.checked)
    }

    // Este useEffect observará cambios en yearlyProductList
    useEffect(() => {
        if (yearlyProductList.length > 0) {
            handleChannelSelected('0');
        }
    }, [yearlyProductList]);

    return (
        <>
            <div className='container-fluid'>
                <div className='row justify-content-left mb-4'>
                    <div className='col-md-12'>
                        <div className='d-flex flex-wrap flex-stack mb-6'>
                            <h3 className='my-2 d-flex flex-wrap flex-stack'>
                                {selectedNameProduct && selectedYear ? (
                                    <div className='mb-2'>
                                        {intl.formatMessage({id: 'REPORTS.INTERACTIONS.FILTERED_BY'})}
                                        <span className='ms-2 fs-3 text-gray-500 fw-bold'>{selectedNameProduct} - {selectedYear}</span>
                                    </div>
                                ) : (
                                    <div className='mb-2'>
                                        {intl.formatMessage({id: 'REPORTS.INTERACTIONS.SELECT_PRODUCT'})}
                                    </div>
                                )}
                            </h3>
                            <div className='d-flex flex-wrap flex-stack align-items-center my-2'>
                                <div className='w-280px me-4'>
                                    <select
                                        name='product'
                                        data-control='selectProduct'
                                        data-hidden-search='true'
                                        className='form-select form-select-solid form-select-lg'
                                        value={selectedIdProduct}
                                        onChange={(e) => {
                                            handleProductChange(e.target.value)
                                        }}
                                    >
                                        <option value=''>{intl.formatMessage({id: 'REPORTS.INTERACTIONS.SELECT_PRODUCT'})}</option>
                                        <option value='0'>{intl.formatMessage({id: 'REPORTS.YEARLY.ALL_PRODUCTS'})}</option>
                                        {productsList.map((product: Product) => (
                                            <option key={product.pro_id} value={product.pro_id}>{product.name}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className='w-100px me-4'>
                                    <select
                                        name='year'
                                        data-control='selectyear'
                                        data-hidden-search='true'
                                        className='form-select form-select-solid form-select-lg'
                                        value={selectedYear}
                                        onChange={(e) => {
                                            handleYearChange(e.target.value)
                                        }}
                                        disabled={selectedIdProduct === ''}
                                    >
                                        <option value=''>Año</option>
                                        <option value='2024'>2024</option>
                                        <option value='2023'>2023</option>
                                        <option value='2022'>2022</option>
                                        <option value='2021'>2021</option>
                                        <option value='2020'>2020</option>

                                    </select>
                                </div>

                                <div
                                    className='form-check form-switch form-check-custom form-check-solid mb-6'
                                    key='poo'
                                >
                                    <label className='form-check-label fs-4'>
                                        Duplicados
                                    </label>
                                    &nbsp;&nbsp;&nbsp;
                                    <input
                                        className='form-check-input'
                                        type='checkbox'
                                        checked={duplicates}
                                        onChange={(e) => {
                                            handleDuplicatesChange(e)
                                        }}
                                        disabled={selectedIdProduct === ''}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row gx-2 mb-6">
                    <div className="col-xl-12">
                        <div className='card card-custom px-8 py-10 h-100'>
                            <div className='row-justify-content-center mb-2'>
                                <div className='col-md-12'>

                                    {!selectedIdProduct && (
                                        <>
                                            <div
                                                className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                                                role='alert'
                                            >
                                                <h5 className='mb-1 text-center'>{intl.formatMessage({id: 'REPORTS.INTERACTIONS.SELECT_PRODUCT'})}</h5>
                                            </div>
                                        </>
                                    )}

                                    {isLoading && selectedIdProduct && <div className='py-6'><LoadingAnimation alignment="center" label={intl.formatMessage({id: 'REPORTS.YEARLY.GENERATING_GRAPH'})} size='lg' /></div>}

                                    {!isLoading && yearlyProductList.length > 0 && selectedIdProduct && (

                                        <>
                                            <div className='d-flex flex-wrap flex-stack mb-4'>
                                                <h5 className='card-label me-4 d-flex flex-wrap flex-stack'>
                                                    <span className='text-dark text-sm font-weight-bolder mt-3'>
                                                        Total mensual por canal:
                                                    </span>&nbsp;&nbsp;
                                                    <span className="text-muted mt-1">{channelNameSelected}</span>
                                                </h5>
                                                <div className='d-flex flex-wrap flex-stack align-items-center my-2'>
                                                    <div className='w-250px me-3'>
                                                        <select
                                                            name='channel'
                                                            data-control='selectChannel'
                                                            data-hidden-search='true'
                                                            className='form-select form-select-solid form-select-lg'
                                                            value={channelIdSelected}
                                                            onChange={(e) => {
                                                                handleChannelSelected(e)
                                                            }}
                                                            disabled={selectedIdProduct === '' || selectedYear === ''}
                                                        >
                                                            <option key='' value=''>{intl.formatMessage({id: 'REPORTS.YEARLY.SELECT_CHANNEL'})}</option>
                                                            <option key='all' value='0'>{intl.formatMessage({id: 'REPORTS.YEARLY.ALL_CHANNELS'})}</option>
                                                            {channelsListByProduct.map((channel: ChannelFilter) => (
                                                                <option key={channel.channelId} value={channel.channelId}>
                                                                    {channel.channel}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <YearlyReportProductChart
                                                className='card-xxl-stretch mb-5 mb-xl-10'
                                                serieData={monthlyTotals}
                                                serieDates={[
                                                    intl.formatMessage({id: 'GLOBAL.JANUARY'}),
                                                    intl.formatMessage({id: 'GLOBAL.FEBRUARY'}),
                                                    intl.formatMessage({id: 'GLOBAL.MARCH'}),
                                                    intl.formatMessage({id: 'GLOBAL.APRIL'}),
                                                    intl.formatMessage({id: 'GLOBAL.MAY'}),
                                                    intl.formatMessage({id: 'GLOBAL.JUNE'}),
                                                    intl.formatMessage({id: 'GLOBAL.JULY'}),
                                                    intl.formatMessage({id: 'GLOBAL.AUGUST'}),
                                                    intl.formatMessage({id: 'GLOBAL.SEPTEMBER'}),
                                                    intl.formatMessage({id: 'GLOBAL.OCTOBER'}),
                                                    intl.formatMessage({id: 'GLOBAL.NOVEMBER'}),
                                                    intl.formatMessage({id: 'GLOBAL.DECEMBER'})
                                                ]}
                                            />
                                        </>
                                    )}

                                    {!isLoading && yearlyProductList.length === 0 && selectedIdProduct && (
                                        <div
                                            className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                                            role='alert'
                                        >
                                            <span className='mb-1 text-center'>{infoMessageLoading}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row gx-2 mb-6'>
                    <div className='col-xl-12'>
                        <div className='card card-custom px-8 py-10 h-100'>
                            <div className='row justify-content-center mb-2'>
                                <div className='col-md-12'>

                                    {!selectedIdProduct && (
                                        <>
                                            <div
                                                className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                                                role='alert'
                                            >
                                                <h5 className='mb-1 text-center'>{intl.formatMessage({id: 'REPORTS.YEARLY.SELECT_PRODUCT'})}</h5>
                                            </div>
                                        </>
                                    )}

                                    {isLoading && selectedIdProduct && <div className='py-6'><LoadingAnimation alignment="center" label={intl.formatMessage({id: 'REPORTS.YEARLY.GENERATING_TABLE'})} size='lg' /></div>}

                                    {!isLoading && yearlyProductList.length > 0 && selectedIdProduct && (
                                        <YearlyReportProductTable processedDataForTable={processedDataForTable} />
                                    )}

                                    {!isLoading && yearlyProductList.length === 0 && selectedIdProduct && (
                                        <div
                                            className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                                            role='alert'
                                        >
                                            <span className='mb-1 text-center'>{infoMessageLoading}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}