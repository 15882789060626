import * as XLSX from 'xlsx'
import { KTIcon } from '../../../helpers'
import { useIntl } from 'react-intl';

interface DropdownExportProps {
  dataToExport: any; // Ajusta el tipo según lo que esperas
  title: string;
  disabled?: boolean; // La propiedad es opcional, usa '?' si puede ser undefined
}

export const DropdownExport: React.FC<DropdownExportProps> = ({ dataToExport, title, disabled }) => {
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()
  const handleToExportDataXLSX = () => {
    try {
      // Verificar si hay datos para exportar
      if (dataToExport.length === 0) {
        console.error('No hay datos para exportar.')
        return
      }

      // Crear la hoja de cálculo
      const worksheet = XLSX.utils.book_new()

      // Verificar si el título es 'SellersData' y si la columna 'brandProducts' está presente
      const isSellersData = title === 'SellersData'
      const hasBrandProducts = dataToExport.some((row) => 'brandProducts' in row)

      // Si es 'SellersData' y tiene 'brandProducts', realizar la exportación con formato específico
      if (isSellersData && hasBrandProducts) {
        const newData = dataToExport.map((row) => {
          const newRow = { ...row }
          row.brandProducts.forEach((product, index) => {
            newRow[`Product ${index + 1} ID`] = product.pro_id
            newRow[`Product ${index + 1} Name`] = product.name
            newRow[`Product ${index + 1} Status Assigned`] = product.status_assigned ? 'Yes' : 'No'
          })
          delete newRow.brandProducts
          return newRow
        })

        const sheet = XLSX.utils.json_to_sheet(newData)
        XLSX.utils.book_append_sheet(worksheet, sheet, title)
      }
      // Si es 'sellersGroupData' y tiene 'users', realizar la exportación con formato específico
      else if (title === 'sellersGroupData' && dataToExport.some((row) => 'users' in row)) {
        const newData = dataToExport.map((row) => {
          const newRow = { ...row }
          row.users.forEach((user, index) => {
            newRow[`User ${index + 1} ID`] = user.usr_id
            newRow[`User ${index + 1} Name`] = user.name
            newRow[`User ${index + 1} Type`] = user.user_type
          })
          delete newRow.users
          return newRow
        })

        const sheet = XLSX.utils.json_to_sheet(newData)
        XLSX.utils.book_append_sheet(worksheet, sheet, title)
      }
      // En otros casos, realizar la exportación normal
      else {
        const sheet = XLSX.utils.json_to_sheet(dataToExport)
        XLSX.utils.book_append_sheet(worksheet, sheet, title)
      }

      // Descargar el archivo Excel
      XLSX.writeFile(worksheet, `${title}.xlsx`)
    } catch (error) {
      console.error('Error al exportar a Excel:', error)
    }
  }

  const handleToExportDataCSV = () => {
    try {
      // Verificar si hay datos para exportar
      if (dataToExport.length === 0) {
        console.error('No hay datos para exportar.')
        return
      }

      // Convertir los datos a formato CSV
      let csvData
      if (title === 'sellersGroupData' && dataToExport.some((row) => 'users' in row)) {
        csvData = convertToCSVWithUsers(dataToExport)
      } else if (title === 'SellersData' && dataToExport.some((row) => 'brandProducts' in row)) {
        csvData = convertToCSVWithBrandProducts(dataToExport)
      } else {
        csvData = convertToCSV(dataToExport)
      }

      // Crear un Blob a partir de los datos CSV
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' })

      // Crear un objeto URL a partir del Blob
      const url = window.URL.createObjectURL(blob)

      // Crear un enlace de descarga
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${title}.csv`)
      document.body.appendChild(link)

      // Simular un clic en el enlace para iniciar la descarga
      link.click()

      // Limpiar el objeto URL y el enlace
      window.URL.revokeObjectURL(url)
      document.body.removeChild(link)
    } catch (error) {
      console.error('Error al exportar a CSV:', error)
    }
  }

  const convertToCSVWithUsers = (data) => {
    // Encabezados personalizados para los usuarios
    const userHeaders = ['User ID', 'User Name', 'User Type'];

    // Convertir la matriz de objetos a una cadena CSV
    let csv = '';

    data.forEach((row) => {
      // Verificar si el objeto tiene la propiedad 'users'
      if ('users' in row) {
        // Recorrer cada usuario en el array 'users'
        row.users.forEach((user) => {
          // Crear una fila para cada usuario con sus respectivos valores
          csv += `${user.usr_id},${user.name},${user.user_type}\n`;
        });

        // Eliminar la propiedad 'users' del objeto antes de agregarlo al CSV
        delete row.users;
      }

      // Agregar los datos de cada fila al CSV
      csv += Object.values(row).join(',') + '\n';
    });

    // Concatenar los encabezados de usuario al principio del CSV
    return userHeaders.join(',') + '\n' + csv;
  };


  const convertToCSVWithBrandProducts = (data) => {
    const header = Object.keys(data[0]).join(',')
    const rows = data.map((row) => {
      const newRow = { ...row }
      if (newRow.brandProducts) {
        const products = newRow.brandProducts.map((product) => {
          return `{Product ID: ${product.pro_id}, Name: ${product.name}, Status Assigned: ${product.status_assigned ? 'Yes' : 'No'
            }}`
        })
        newRow.brandProducts = products.join('|')
      }
      return Object.values(newRow).join(',')
    })
    return `${header}\n${rows.join('\n')}`
  }

  const convertToCSV = (data) => {
    // Obtener las cabeceras
    const headers = Object.keys(data[0])

    // Crear filas CSV
    const rows = data.map((row) => {
      return headers
        .map((header) => {
          // Escapar comillas dobles y agregar comillas dobles si es necesario
          let value = row[header]
          if (typeof value === 'string') {
            value = value.replace(/"/g, '""')
            if (value.includes(',') || value.includes('"') || value.includes('\n')) {
              value = `"${value}"`
            }
          }
          return value
        })
        .join(',')
    })

    // Unir las cabeceras y las filas
    return [headers.join(','), ...rows].join('\n')
  }

  // Función para exportar la data en formato JSON.
  // const handleToExportDataJSON = () => {
  //   try {
  //     // Verificar si hay datos para exportar
  //     if (dataToExport.length === 0) {
  //       console.error('No hay datos para exportar.')
  //       return
  //     }

  //     // Convertir los datos a formato JSON
  //     const jsonData = JSON.stringify(dataToExport, null, 2)

  //     // Crear un Blob a partir de los datos JSON
  //     const blob = new Blob([jsonData], {type: 'application/json'})

  //     // Crear un objeto URL a partir del Blob
  //     const url = window.URL.createObjectURL(blob)

  //     // Crear un enlace de descarga
  //     const link = document.createElement('a')
  //     link.href = url
  //     link.setAttribute('download', `${title}.json`)
  //     document.body.appendChild(link)

  //     // Simular un clic en el enlace para iniciar la descarga
  //     link.click()

  //     // Limpiar el objeto URL y el enlace
  //     window.URL.revokeObjectURL(url)
  //     document.body.removeChild(link)
  //   } catch (error) {
  //     console.error('Error al exportar a JSON:', error)
  //   }
  // }

  return (
    <>
      <button
        disabled={disabled}
        type='button'
        className='btn btn-primary d-flex align-items-center btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-flip='top-end'
      >
        <KTIcon iconType='outline' iconName='file-down' className='fs-2 me-2' />{' '}
        <span>{intl.formatMessage({id: 'GLOBAL.DATA_EXPORT'})}</span>
      </button>
      
      <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>{intl.formatMessage({id: 'GLOBAL.DATA_EXPORT'})}</div>
        </div>

        <div className='separator border-gray-200'></div>

        <div className='px-7 py-5'>
          <div className='mb-10'>
            <label className='form-label fw-bold'>{intl.formatMessage({id: 'GLOBAL.FORMAT'})}:</label>

            <div className='d-flex flex-column'>
              <button
                className='btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary mb-2'
                onClick={handleToExportDataCSV}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='22'
                  height='22'
                  fill='currentColor'
                  className='bi bi-filetype-csv'
                  viewBox='0 0 16 16'
                >
                  <path
                    fillRule='evenodd'
                    d='M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM3.517 14.841a1.13 1.13 0 0 0 .401.823q.195.162.478.252.284.091.665.091.507 0 .859-.158.354-.158.539-.44.187-.284.187-.656 0-.336-.134-.56a1 1 0 0 0-.375-.357 2 2 0 0 0-.566-.21l-.621-.144a1 1 0 0 1-.404-.176.37.37 0 0 1-.144-.299q0-.234.185-.384.188-.152.512-.152.214 0 .37.068a.6.6 0 0 1 .246.181.56.56 0 0 1 .12.258h.75a1.1 1.1 0 0 0-.2-.566 1.2 1.2 0 0 0-.5-.41 1.8 1.8 0 0 0-.78-.152q-.439 0-.776.15-.337.149-.527.421-.19.273-.19.639 0 .302.122.524.124.223.352.367.228.143.539.213l.618.144q.31.073.463.193a.39.39 0 0 1 .152.326.5.5 0 0 1-.085.29.56.56 0 0 1-.255.193q-.167.07-.413.07-.175 0-.32-.04a.8.8 0 0 1-.248-.115.58.58 0 0 1-.255-.384zM.806 13.693q0-.373.102-.633a.87.87 0 0 1 .302-.399.8.8 0 0 1 .475-.137q.225 0 .398.097a.7.7 0 0 1 .272.26.85.85 0 0 1 .12.381h.765v-.072a1.33 1.33 0 0 0-.466-.964 1.4 1.4 0 0 0-.489-.272 1.8 1.8 0 0 0-.606-.097q-.534 0-.911.223-.375.222-.572.632-.195.41-.196.979v.498q0 .568.193.976.197.407.572.626.375.217.914.217.439 0 .785-.164t.55-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.764a.8.8 0 0 1-.118.363.7.7 0 0 1-.272.25.9.9 0 0 1-.401.087.85.85 0 0 1-.478-.132.83.83 0 0 1-.299-.392 1.7 1.7 0 0 1-.102-.627zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879z'
                  />
                </svg>
                <span className='ms-2'>{intl.formatMessage({id: 'GLOBAL.DOWNLOAD'})} .csv</span>
              </button>

              <button
                className='btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary mb-2'
                onClick={handleToExportDataXLSX}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='22'
                  height='22'
                  fill='currentColor'
                  className='bi bi-filetype-xlsx'
                  viewBox='0 0 16 16'
                >
                  <path
                    fillRule='evenodd'
                    d='M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM7.86 14.841a1.13 1.13 0 0 0 .401.823q.195.162.479.252.284.091.665.091.507 0 .858-.158.355-.158.54-.44a1.17 1.17 0 0 0 .187-.656q0-.336-.135-.56a1 1 0 0 0-.375-.357 2 2 0 0 0-.565-.21l-.621-.144a1 1 0 0 1-.405-.176.37.37 0 0 1-.143-.299q0-.234.184-.384.188-.152.513-.152.214 0 .37.068a.6.6 0 0 1 .245.181.56.56 0 0 1 .12.258h.75a1.1 1.1 0 0 0-.199-.566 1.2 1.2 0 0 0-.5-.41 1.8 1.8 0 0 0-.78-.152q-.44 0-.777.15-.336.149-.527.421-.19.273-.19.639 0 .302.123.524t.351.367q.229.143.54.213l.618.144q.31.073.462.193a.39.39 0 0 1 .153.326.5.5 0 0 1-.085.29.56.56 0 0 1-.255.193q-.168.07-.413.07-.176 0-.32-.04a.8.8 0 0 1-.249-.115.58.58 0 0 1-.255-.384zm-3.726-2.909h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415H1.5l1.24-2.016-1.228-1.983h.931l.832 1.438h.036zm1.923 3.325h1.697v.674H5.266v-3.999h.791zm7.636-3.325h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415h-.861l1.24-2.016-1.228-1.983h.931l.832 1.438h.036z'
                  ></path>
                </svg>
                <span className='ms-2'>{intl.formatMessage({id: 'GLOBAL.DOWNLOAD'})} .xlsx</span>
              </button>
              {/* Botón JSON */}
              {/* <button
              className='btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary mb-2'
              onClick={handleToExportDataJSON}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='22'
                height='22'
                fill='currentColor'
                className='bi bi-filetype-json'
                viewBox='0 0 16 16'
              >
                <path
                  fillRule='evenodd'
                  d='M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM4.151 15.29a1.2 1.2 0 0 1-.111-.449h.764a.58.58 0 0 0 .255.384q.105.073.25.114.142.041.319.041.245 0 .413-.07a.56.56 0 0 0 .255-.193.5.5 0 0 0 .084-.29.39.39 0 0 0-.152-.326q-.152-.12-.463-.193l-.618-.143a1.7 1.7 0 0 1-.539-.214 1 1 0 0 1-.352-.367 1.1 1.1 0 0 1-.123-.524q0-.366.19-.639.192-.272.528-.422.337-.15.777-.149.456 0 .779.152.326.153.5.41.18.255.2.566h-.75a.56.56 0 0 0-.12-.258.6.6 0 0 0-.246-.181.9.9 0 0 0-.37-.068q-.324 0-.512.152a.47.47 0 0 0-.185.384q0 .18.144.3a1 1 0 0 0 .404.175l.621.143q.326.075.566.211a1 1 0 0 1 .375.358q.135.222.135.56 0 .37-.188.656a1.2 1.2 0 0 1-.539.439q-.351.158-.858.158-.381 0-.665-.09a1.4 1.4 0 0 1-.478-.252 1.1 1.1 0 0 1-.29-.375m-3.104-.033a1.3 1.3 0 0 1-.082-.466h.764a.6.6 0 0 0 .074.27.5.5 0 0 0 .454.246q.285 0 .422-.164.137-.165.137-.466v-2.745h.791v2.725q0 .66-.357 1.005-.355.345-.985.345a1.6 1.6 0 0 1-.568-.094 1.15 1.15 0 0 1-.407-.266 1.1 1.1 0 0 1-.243-.39m9.091-1.585v.522q0 .384-.117.641a.86.86 0 0 1-.322.387.9.9 0 0 1-.47.126.9.9 0 0 1-.47-.126.87.87 0 0 1-.32-.387 1.55 1.55 0 0 1-.117-.641v-.522q0-.386.117-.641a.87.87 0 0 1 .32-.387.87.87 0 0 1 .47-.129q.265 0 .47.129a.86.86 0 0 1 .322.387q.117.255.117.641m.803.519v-.513q0-.565-.205-.973a1.46 1.46 0 0 0-.59-.63q-.38-.22-.916-.22-.534 0-.92.22a1.44 1.44 0 0 0-.589.628q-.205.407-.205.975v.513q0 .562.205.973.205.407.589.626.386.217.92.217.536 0 .917-.217.384-.22.589-.626.204-.41.205-.973m1.29-.935v2.675h-.746v-3.999h.662l1.752 2.66h.032v-2.66h.75v4h-.656l-1.761-2.676z'
                />
              </svg>
              <span className='ms-2'>Descargar .json</span>
            </button> */}
            </div>
          </div>

          <div className='d-flex justify-content-end'>
            <button
              type='reset'
              className='btn btn-sm btn-light btn-active-light-primary me-2'
              data-kt-menu-dismiss='true'
            >
              {intl.formatMessage({id: 'GLOBAL.CLOSE'})}
            </button>
          </div>
        </div>
      </div>
    </>


  )
}
