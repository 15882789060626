import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {SellersHeader} from './aux-components/UsersHeader'
import {useEffect} from 'react'
import {canAccessFeature, setPageTitle} from '../../../utils/functions/_Functions'
import {useIntl} from 'react-intl'
import {DragonCem} from '../../../utils/constants/_Constants'
import * as React from 'react'
import {SellersTableComponent} from './Tables/SellersTableComponent'
import {DRG_STANDAR_MODULES} from '../../../routing/constants/_Contstants'
import {UserContext} from '../../../app-context/user-context/UserContext'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Sellers',
    path: '/sellers',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SellersPage = () => {
  const intl = useIntl()
  //Obtener datos del context
  const {user} = React.useContext(UserContext)
  const userType: string = user.user_type

  useEffect(() => {
    // Cambiar el título de la página al montar el componente
    setPageTitle(`${intl.formatMessage({id: 'SELLERS.TITLE'})} | ${DragonCem}`)
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>
        {intl.formatMessage({id: 'SELLERS.TITLE'})}
      </PageTitle>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='col-md-12'>
            <h1 className='my-2'>{intl.formatMessage({id: 'SELLERS.TITLE'})}</h1>
            {canAccessFeature(DRG_STANDAR_MODULES.USERS.value, userType, 2) && <SellersHeader />}
            <div className='card card-custom'>
              {canAccessFeature(DRG_STANDAR_MODULES.USERS.value, userType, 2) && (
                <SellersTableComponent />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SellersPage
