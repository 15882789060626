// Importamos hooks y aux de React
import { FC, 
    useEffect, 
    Dispatch, 
    ChangeEvent, 
    SetStateAction 
  } from "react";
  import { useIntl } from "react-intl";
  
  // ---------------------------------------------------------------------------------------
  
  // Importamos funcion que conecta al endpoint para obtener el listado de preductos por cliente
  import { productsListAssignedByUser } from "../../../standard-modules/products/services/_ProductsListApi";
  // Importamos interface ProductList para tipeado
  import { ProductList } from "../../interfaces/_InterfacesTypes";
  
  // Propiedades que tiene que recibir el componente
  type Props = {
    // Funcion para actualizar el token en el contexto de usuario
    updateToken: (newToken: string | undefined) => void;
    // Id del cliente
    clientId: string;
    // Token de sesion del usuario
    userToken: string;
    // Funcion para termino de sesión
    sessionExpired: (messageAlert: string) => void;
    // Id del producto seleccionado actualmente
    productIdSelected: string;
    // Funcion para manejar el cambio de seleccion en el dropdown
    handleProductChange: (e: ChangeEvent<HTMLSelectElement>) => void;
    // Arreglo para guardar el listado de productos
    productsList: ProductList[];
    // Manejador para el arreglo con listado de productos
    setProductsList: Dispatch<SetStateAction<ProductList[]>>;
    // Estado de disponibilidad del dropdown
    productsDropdownState: boolean;
    // Clase que le vamos a dar al dropdown
    dropdownClass: string;
    // Tiene opcion de todos los productos?
    allProductsOption: boolean
    // Tiene opcion de solo productos activos?
    onlyActiveProducts: boolean
  }
  
  const ProductsByUserDropdown: FC<Props> = ({
    updateToken,
    clientId,
    userToken,
    sessionExpired,
    productIdSelected,
    handleProductChange,
    productsList,
    setProductsList,
    productsDropdownState,
    dropdownClass,
    allProductsOption,
    onlyActiveProducts
  }) => {
  
    //Variable para el uso de diccionario de lenguajes
    const intl = useIntl()
  
    //Función para traer el listado de productos del cliente y usuario en sesion
    const getProductsByClient = async (): Promise<void> => {
      try {
        const { data: jsonData, code, msg } = await productsListAssignedByUser(userToken)
  
        if (code === 401 && msg === 'Token no valido') {
          sessionExpired(intl.formatMessage({ id: 'GLOBAL.ALERT.SESSION_EXPIRED' }))
          return
  
        } else {
          const productsListReceived: ProductList[] = jsonData.data
  
          const userTokenNew: string = await jsonData.token
  
          updateToken(userTokenNew)

          const noInactiveProductsList: ProductList[] = productsListReceived.filter(product => product.status === 1)

          setProductsList(noInactiveProductsList)

        }
      } catch (error) {
        console.error('Ocurrio un error al intentar obtener los productos: ', error)
        throw error
      }
    }
  
    // Efecto para disparar la funcion
    // getProductsByClient al montar el componente
    useEffect(() => {
      getProductsByClient()
    }, []);
  
    return (
      <>
        <select
          className={dropdownClass}
          id='productSelect'
          name='productSelect'
          disabled={productsDropdownState}
          value={productIdSelected}
          onChange={(e) => handleProductChange(e)}
        >
          <option value={''} disabled> {intl.formatMessage({id: 'LEADS.SELECT_A_PRODUCT'})} </option>
            {allProductsOption && allProductsOption === true && (
              <option value={'0'}>{intl.formatMessage({id: 'REPORTS.INTERACTIONS.ALL_PRODUCTS'})}</option>
            )}
          {productsList.map((product: ProductList) => (
            <option key={product.pro_id} value={product.pro_id}>
              {product.name}
            </option>
          ))}
        </select>
      </>
    );
  };
  
  export { ProductsByUserDropdown };