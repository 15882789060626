import { apiDragon, DragonMainApi, EPWabaTemplatesList } from "../constants/_Constants";

import { apiGetDeletePetitions } from "../../../_metronic/helpers/apis";

export async function wabaTemplatesListByProduct(userToken: string, productId: string){
    try{
        const data = await apiGetDeletePetitions(apiDragon, `${DragonMainApi+EPWabaTemplatesList+productId}`, {
            undefined
        }, {
            'Content-Type': 'application/json',
            'x-token': userToken
        }, 'get')

        const jsonDataWabaTemplatesList = data
        console.log(jsonDataWabaTemplatesList)
        return jsonDataWabaTemplatesList

    }catch (error){
        console.error('Error obteniendo los waba tempaltes: ', error)
        throw error;
    }
}