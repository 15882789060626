import { FC, useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { leadsListByClient } from "../../../../leads/services/_LeadsListApi";
import { sessionExpired, dateFormatterDateTimeExtended } from "../../../../../utils/functions/_Functions";
import { Lead, LeadData } from "../../../../leads/interfaces/leadsTypes";
import { LeadsRegistersTable } from "../tables/LeadsRegistersTable";
import { LoadingAnimation } from "../../../../../utils/components/loading/LoadingAnimation";

type Props = {
    modalTitle: string
    closeModal: () => void
    startDate: string
    endDate: string
    clientId: string
    userToken: string
    updateToken: (newToken: string | undefined) => void
    productIdSelected: string
    campaignIdSelected: string
    category: string
}

const LeadsRegistersModal: FC<Props> = ({
    modalTitle,
    closeModal,
    startDate,
    endDate,
    clientId,
    userToken,
    updateToken,
    productIdSelected,
    campaignIdSelected,
    category
}) => {
    //Constante para el manejo de la lista de leads
    const [leadsList, setLeadsList] = useState<Lead[]>([])

    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [infoMessageLoading, setInfoMessageLoading] = useState<string>("")

    const checkLeadsList = (): void => {
        leadsList.length === 0 && setInfoMessageLoading('No se encontraron registros para mostrar')
    }

    const getLeads = async (): Promise<void> => {
        try {
            const { data: jsonData, code, msg } = await leadsListByClient(userToken, startDate, endDate, clientId)

            if (code === 401 && msg === 'Token no valido') {
                sessionExpired('La sesion ha caducado')
                return
            } else {

                const leadsListReceived: Lead[] = jsonData.data

                const userTokenNew: string = jsonData.token

                updateToken(userTokenNew)

                console.log(leadsListReceived)

                const leadRow: Lead[] = leadsListReceived.map((lead: Lead) => {
                    const leadData: LeadData = {
                        name: lead.lead_data.name,
                        lead_mail: lead.lead_data.lead_mail,
                        lead_phone: lead.lead_data.lead_phone,
                    }

                    const formattedDate: string = dateFormatterDateTimeExtended(lead.lead_date)

                    return {
                        lead_date: formattedDate,
                        lead_data: leadData,
                        lead_id: lead.lead_id,
                        seller_score: lead.seller_score,
                        dcfs: lead.dcfs,
                        original: lead.original,
                        mail_delivered: lead.mail_delivered,
                        call_recording: lead.call_recording,
                        wha_sent: lead.wha_sent,
                        lead_qa: lead.lead_qa,
                        crm_insert: lead.crm_insert,
                        lead_comments: lead.lead_comments,
                        seller_id: lead.seller_id,
                        seller_name: lead.seller_name,
                        pro_name: lead.pro_name,
                        pro_id: lead.pro_id,
                        cam_id: lead.cam_id,
                        cam_name: lead.cam_name,
                        medium: lead.medium,
                        lead_endpoint: 'Zapier',
                        leadGrade: lead.leadGrade,
                        leadGradeComments: lead.leadGradeComments,
                        leadIscore: lead.leadIscore,
                        lead_original: '',
                        leadUrl: lead.leadUrl,
                        spamFlag: lead.spamFlag,
                    }
                })

                const leadRowByProduct: Lead[] = leadRow.filter(lead => lead.pro_id === productIdSelected);

                console.log('por producto: ', leadRowByProduct)

                const leadRowByCampaign: Lead[] = leadRow.filter(lead => lead.cam_id === campaignIdSelected);

                console.log('por campaña: ', leadRowByCampaign)

                if (category === 'originals') {
                    const leadRowOriginals: Lead[] = leadRow.filter(lead => lead.original === '')
                    console.log('originales: ', leadRowOriginals)
                    setLeadsList(leadRowOriginals)
                } else if (category === 'duplicated') {
                    const leadRowDuplicated: Lead[] = leadRow.filter(lead => lead.original !== '')
                    console.log('duplicados: ', leadRowDuplicated)
                    setLeadsList(leadRowDuplicated)
                } else {
                    console.log('totales: ', leadRowByCampaign)
                    setLeadsList(leadRowByCampaign)
                }

                setIsLoading(false)
                checkLeadsList()

            }
        } catch (error) {
            console.log('Error receiving leads list: ', error)
        }
    }

    useEffect(() => {
        getLeads()
    }, [])

    return (
        <>
            <Modal.Header
                closeButton
                onClick={() => {
                    closeModal()
                }}
            >
                <Modal.Title>
                    <h1>{modalTitle}</h1>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isLoading && (
                    <div className="py-12">
                        <LoadingAnimation
                            alignment="center"
                            label="Obteniendo leads..."
                            size='lg'
                        />
                    </div>
                )}

                {!isLoading && leadsList.length > 0 && (
                    <LeadsRegistersTable leadsList={leadsList} title={modalTitle}/>
                )}

                {!isLoading && leadsList.length === 0 && (
                    <>
                        <div
                            className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                            role='alert'
                        >
                            <h5 className='mb-1 text-center'>{infoMessageLoading}</h5>
                        </div>
                    </>
                )}

            </Modal.Body>
            <Modal.Footer>
                <button
                    type="reset"
                    className="btn btn-sm btn-secondary"
                    onClick={() => {
                        closeModal()
                    }}
                >
                    Cerrar
                </button>
            </Modal.Footer>
        </>
    )

}

export { LeadsRegistersModal }