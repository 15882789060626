import {useState, ChangeEvent, useEffect, useContext, ReactElement} from 'react'
import '../../../../../_metronic/assets/styles/PhoneInput.css'
import { HitosHour } from '../HitosHour'
import { Nav } from 'react-bootstrap'
import { useIntl } from 'react-intl'

// En este paso se almacenarán os horarios de los hitos por día
function Step4Rol({ data, onNext, handlePrev, prevData, setStep4Data }) {
  const intl = useIntl()
  const [selectedTab, setSelectedTab] = useState(intl.formatMessage({id: 'GLOBAL.MONDAY'}));
  const [schedules, setSchedules] = useState(prevData?.schedules || []);
  const [scheduleUpdated, setScheduleUpdated] = useState(false);

  const handleTabSelect = (tabKey) => {
    setSelectedTab(tabKey);
  };

  const handleNext = () => {
    onNext({
      schedules
    });
    setStep4Data({
      schedules
    });
  };

  useEffect(() => {
    if (scheduleUpdated) {
      handleNext();
      setScheduleUpdated(false);
    }
  }, [scheduleUpdated]);

  const replicateSchedule = async () => {
    console.log(schedules)
    const selectedIndex = [
      intl.formatMessage({id: 'GLOBAL.MONDAY'}),
      intl.formatMessage({id: 'GLOBAL.TUESDAY'}),
      intl.formatMessage({id: 'GLOBAL.WEDNESDAY'}),
      intl.formatMessage({id: 'GLOBAL.THURSDAY'}),
      intl.formatMessage({id: 'GLOBAL.FRIDAY'}),
      intl.formatMessage({id: 'GLOBAL.SATURDAY'}),
      intl.formatMessage({id: 'GLOBAL.SUNDAY'})
    ].indexOf(selectedTab);
    const selectedSchedule = schedules[0];
    const updatedSchedules = schedules.map((daySchedule, index) => (
      index === selectedIndex ? selectedSchedule : [...daySchedule, ...selectedSchedule.filter(newHito => !daySchedule.some(oldHito => oldHito.start === newHito.start && oldHito.end === newHito.end))]
    ));
    setSchedules(updatedSchedules);
    setScheduleUpdated(true);
  };
  
    return (
      <>
        <div>
          <Nav variant='tabs' activeKey={selectedTab} onSelect={handleTabSelect}>
            <Nav.Item className='w-10'>
              <Nav.Link eventKey={intl.formatMessage({id: 'GLOBAL.MONDAY'})} active={selectedTab === intl.formatMessage({id: 'GLOBAL.MONDAY'})} style={{ fontSize: '0.75rem' }}>
                {intl.formatMessage({id: 'GLOBAL.MONDAY'})}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className='w-10'>
              <Nav.Link eventKey={intl.formatMessage({id: 'GLOBAL.TUESDAY'})} style={{ fontSize: '0.75rem' }}>
                {intl.formatMessage({id: 'GLOBAL.TUESDAY'})}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className='w-10'>
              <Nav.Link eventKey={intl.formatMessage({id: 'GLOBAL.WEDNESDAY'})} style={{ fontSize: '0.75rem' }}>
                {intl.formatMessage({id: 'GLOBAL.WEDNESDAY'})}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className='w-10'>
              <Nav.Link eventKey={intl.formatMessage({id: 'GLOBAL.THURSDAY'})} style={{ fontSize: '0.75rem' }}>
                {intl.formatMessage({id: 'GLOBAL.THURSDAY'})}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className='w-10'>
              <Nav.Link eventKey={intl.formatMessage({id: 'GLOBAL.FRIDAY'})} style={{ fontSize: '0.75rem' }}>
                {intl.formatMessage({id: 'GLOBAL.FRIDAY'})}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className='w-10'>
              <Nav.Link eventKey={intl.formatMessage({id: 'GLOBAL.SATURDAY'})} style={{ fontSize: '0.75rem' }}>
                {intl.formatMessage({id: 'GLOBAL.SATURDAY'})}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className='w-10'>
              <Nav.Link eventKey={intl.formatMessage({id: 'GLOBAL.SUNDAY'})} style={{ fontSize: '0.75rem' }}>
                {intl.formatMessage({id: 'GLOBAL.SUNDAY'})}
              </Nav.Link>
            </Nav.Item>
            
          </Nav>
  
          <div className='tab-content mt-4'>
            <div id={intl.formatMessage({id: 'GLOBAL.MONDAY'})} className={selectedTab === intl.formatMessage({id: 'GLOBAL.MONDAY'}) ? 'tab-pane show active' : 'tab-pane'}>
              <HitosHour day={intl.formatMessage({id: 'GLOBAL.MONDAY'})} schedules={schedules} setSchedules={setSchedules} prevData={prevData} />
            </div>
            <div id={intl.formatMessage({id: 'GLOBAL.TUESDAY'})} className={selectedTab === intl.formatMessage({id: 'GLOBAL.TUESDAY'}) ? 'tab-pane show active' : 'tab-pane'}>
              <HitosHour day={intl.formatMessage({id: 'GLOBAL.TUESDAY'})} schedules={schedules} setSchedules={setSchedules} prevData={prevData} />
            </div>
            <div id={intl.formatMessage({id: 'GLOBAL.WEDNESDAY'})} className={selectedTab === intl.formatMessage({id: 'GLOBAL.WEDNESDAY'}) ? 'tab-pane show active' : 'tab-pane'}>
              <HitosHour day={intl.formatMessage({id: 'GLOBAL.WEDNESDAY'})} schedules={schedules} setSchedules={setSchedules} prevData={prevData} />
            </div>
            <div id={intl.formatMessage({id: 'GLOBAL.THURSDAY'})} className={selectedTab === intl.formatMessage({id: 'GLOBAL.THURSDAY'}) ? 'tab-pane show active' : 'tab-pane'}>
              <HitosHour day={intl.formatMessage({id: 'GLOBAL.THURSDAY'})} schedules={schedules} setSchedules={setSchedules} prevData={prevData} />
            </div>
            <div id={intl.formatMessage({id: 'GLOBAL.FRIDAY'})} className={selectedTab === intl.formatMessage({id: 'GLOBAL.FRIDAY'}) ? 'tab-pane show active' : 'tab-pane'}>
              <HitosHour day={intl.formatMessage({id: 'GLOBAL.FRIDAY'})} schedules={schedules} setSchedules={setSchedules} prevData={prevData} />
            </div>
            <div id={intl.formatMessage({id: 'GLOBAL.SATURDAY'})} className={selectedTab === intl.formatMessage({id: 'GLOBAL.SATURDAY'}) ? 'tab-pane show active' : 'tab-pane'}>
              <HitosHour day={intl.formatMessage({id: 'GLOBAL.SATURDAY'})} schedules={schedules} setSchedules={setSchedules} prevData={prevData} />
            </div>
            <div id={intl.formatMessage({id: 'GLOBAL.SUNDAY'})} className={selectedTab === intl.formatMessage({id: 'GLOBAL.SUNDAY'}) ? 'tab-pane show active' : 'tab-pane'}>
              <HitosHour day={intl.formatMessage({id: 'GLOBAL.SUNDAY'})} schedules={schedules}
                        setSchedules={setSchedules}
                        prevData={prevData} />
            </div>
            
          </div>
        </div>

        {/* Mostrar el botón solo si el día actual es 'Lunes' */}
      {selectedTab === intl.formatMessage({id: 'GLOBAL.MONDAY'}) && (
        <button className="btn btn-primary btn-sm mt-6" onClick={replicateSchedule}>
          {intl.formatMessage({id: 'ASSIGNS.REPLICATE_SCHEDULE'})}
        </button>
      )}

      <br />
  
        <button className='btn btn-light btn-sm mt-6 me-2' onClick={handlePrev}>
          {intl.formatMessage({id: 'GLOBAL.PREVIOUS'})}
        </button>
  
        <button className='btn btn-primary btn-sm mt-6' onClick={handleNext}>
          {intl.formatMessage({id: 'GLOBAL.NEXT'})}
        </button>
      </>
    );
  }
  

export {Step4Rol}